import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Category from './Category';
import { Link } from 'react-router-dom';
import axios from 'axios';

// const categories = [
//   "Clothing & Accesories",
//   "Department Stores",
//   "Food & Restaurants",
//   "Streaming & Gaming",
//   "Travel & Experiences",
//   "Electronics",
//   "General Merchandise",
//   "Heath, Spa & Beauty",
//   "Home & Garden",
//   // "Electronics",
//   "Movies & Entertainment",
//   "Prepaid Gift Cards & Mobile",
//   "Sports & Outdoors",
//   "Supermarkets & Grocery",

// ];

function MyVerticallyCenteredModal(props) {
  //    const [categories,setCategories] = useState([]);
  // useEffect(()=>{
  //     axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/get-categories`)
  //     .then((res)=>{
  //       setCategories(res.data.brands[0]);
  //     }).catch((err)=>{
  //       console.log(err);
  //     })
  // },[])
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="category-modal"
      >
        <Modal.Header closeButton className='border-0'></Modal.Header>
        <Modal.Body className="pt-0">
          <h3 className="categories-mtitle mb-4 text-center">Categories</h3>
          <div className="categories-area justify-content-center" style={{gap:'10px'}}>
            {props?.data?.map((category) => 
              <Category  catergoryOption={props.catergoryOption} setCategoryOption={props.setCategoryOption} home={props.home} key={category.toString()} title={category}
              btnColor={props.btnColor} btnText={props.btnText}/>
            )}
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
  }

  const ModalOpen = props => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
        {/* <Link variant="" to={props.giftcards} className="category-btn"> */}
     {
      props.cmss?   <div className={props.cssStyle === "button" ? "category-bttn" : props.colorCheck === "black" ?  "category-bttnn" : "category-btn"} style={{color: props.btnText, background: props.btnColor}} onClick={()=>setModalShow(true)}>
      {props.title}
  </div>:  <div className={props.cssStyle === "button" ? "category-bttn" : props.colorCheck === "black" ?  "category-bttnn" : "category-btn"}  onClick={()=>setModalShow(true)}>
            {props.title}
        </div>
     }

        <MyVerticallyCenteredModal
        data={props.data}
        catergoryOption={props.catergoryOption}
        setCategoryOption={props.setCategoryOption}
        num={props.num}
        home={props.home}
        btnColor={props.btnColor} 
        btnText={props.btnText}
        show={modalShow}
        onHide={() => setModalShow(false)}
        />
    </>
  )
}

export default ModalOpen
