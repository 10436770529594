// import React from 'react'
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import DelightBlock from "./elements/DelightBlock";
import { Autoplay } from "swiper";

const delightdatas= [
    {
        src: 'assets/images/gift-cards/card1.jpg',
        alt: 'delight image',
        title: 'For employees motivation',
        dlogo1: 'assets/images/gift-logos/Uber.png',
        dlogo2: 'assets/images/gift-logos/Buy_logo.png',
        dlogo3: 'assets/images/gift-logos/amazon.png',
        dlogo4: 'assets/images/gift-logos/fitbit.png',
        brandalt1: 'Delight logo',
        brandalt2: 'Delight logo',
        brandalt3: 'Delight logo',
        brandalt4: 'Delight logo'
    },
    {
        src: 'assets/images/gift-cards/card3.png',
        alt: 'delight image',
        title: 'For traveling without worries',
        dlogo1: 'assets/images/gift-logos/athleta.png',
        dlogo2: 'assets/images/gift-logos/callway.png',
        dlogo3: 'assets/images/gift-logos/visa.png',
        dlogo4: 'assets/images/gift-logos/delta.png',
        brandalt1: 'Delight logo',
        brandalt2: 'Delight logo',
        brandalt3: 'Delight logo',
        brandalt4: 'Delight logo'
    },
    {
        src: 'assets/images/gift-cards/card2.jpg',
        alt: 'delight image',
        title: 'For good and fun memories',
        dlogo1: 'assets/images/gift-logos/xbox.png',
        dlogo2: 'assets/images/gift-logos/nintendo.png',
        dlogo3: 'assets/images/gift-logos/gamestop.png',
        // dlogo4: '',
        brandalt1: 'Delight logo',
        brandalt2: 'Delight logo',
        brandalt3: 'Delight logo',
        brandalt4: 'Delight logo'
    },
]; 

const DelightSlider = (props) => {
  return (
    <Swiper navigation={false}
                modules={[Autoplay]}
                autoplay={{ delay: 3000 }}
                spaceBetween={10} 
                loop={true}
                // center={true}
                centeredSlides={true}
                centerInsufficientSlides={false}
                // freeMode={true}
                slidesPerView={2} 
                // slidesPerView={"auto"}
                breakpoints={{
                    576: {
                    slidesPerView: 2,
                    spaceBetween: 10
                    },
                    768: {
                    slidesPerView: 2,
                    spaceBetween: 20
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 30
                    },
                    1400: {
                        slidesPerView: 4,
                        spaceBetween: 40
                    },
                }}
                className="delight-slider" >
                    {delightdatas.map((delightdata,i) => 
                        <SwiperSlide key={i} >
                            <DelightBlock 
                            src={delightdata.src} 
                            title={delightdata.title}
                            dlogo1 ={delightdata.dlogo1}
                            dlogo2 ={delightdata.dlogo2}
                            dlogo3 ={delightdata.dlogo3}
                            dlogo4 ={delightdata.dlogo4}
                            >
                            </DelightBlock>
                        </SwiperSlide>
                    )}
            </Swiper>
  )
}


export default DelightSlider
