import axios from 'axios';
import { useState } from 'react';
import ReactFileReader from 'react-file-reader';
import { toast } from 'react-toastify';
const CsvForm = (props) => {
  const { business_id } = props;
  // const [filename, setFilename] = useState('');
    const handleFiles = (files) =>{
        var reader = new FileReader();
        reader.readAsText(files[0]);
        const csvFileNameChecker = files[0].name.includes('csv');
        props?.setFilename(files[0].name)
        if(csvFileNameChecker === false){
          toast.warning("Please Select The CSV File")
          return;
        }else{
          reader.onload = function(e) {
            const userDataInString = reader.result;
            props.setCustomerData(userDataInString);
        }

        }


    }

    const handleDownload = async () => {
      try {
        const csvData = [
          ['first_name', 'last_name', 'email', 'code', 'phone', 'balance', 'business_partner'],
          ['First Name 1', 'Last Name 1', 'first1@demo.com', '+1', '1234567890', '000', business_id],
          ['First Name 2', 'Last Name 2', 'first2@demo.com', '+1', '1234567891', '000', business_id]
        ];

        const csvContent = "data:text/csv;charset=utf-8," + csvData.map(row => row.join(",")).join("\n");

        const link = document.createElement('a');
        link.setAttribute('href', encodeURI(csvContent));
        link.setAttribute('download', 'ncentiva.csv');
        document.body.appendChild(link);
    
        link.click();
    
        document.body.removeChild(link);
        
      } catch (error) {
        console.error('Error downloading CSV:', error);
      }
    };
  
  return (
    <>
    <ReactFileReader handleFiles={handleFiles} fileTypes={".csv"}>
      <div className="w-100 border-1 border-secondary d-flex justify-content-center align-items-center"  style={{height: '80px', borderStyle:'dashed' }}>
        <h4 className='fs-6 text-dark mb-0'>Click to Upload</h4>
      </div>
    </ReactFileReader>
    {props?.filename && <p className='mt-3'> <span className='text-primary'>File Uploaded: </span>{props?.filename}</p>}

    <a onClick={handleDownload} className='text-danger mt-3 mb-2' style={{cursor: 'pointer'}}>Sample CSV file</a>
    {/* <button className='text-danger mt-3 mb-2' style={{cursor: 'pointer', backgroundColor: 'white', border: 'none'}} onClick={handleDownload}> */}
    {/* Sample CSV file */}
{/* </button> */}

    </>
  );
};

export default CsvForm;
