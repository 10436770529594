import { Link } from 'react-router-dom';
import { Col, Container, Row } from "react-bootstrap";

const CustomersDetails = () => {
   
  return (
	
    <div className="p-3 p-lg-4 p-xl-5">
        <h1 className='font20 mb-4 text-dark'>Transactions</h1>
        <Container fluid className="bg-white p-4 rounded shadow">
            <Row className='align-items-center'>
                <Col md={5}>
                    <div>
						<Link to="/customers" className='p-0 btn btnOrangeTransparent' variant='white'>
							<span className='me-2 fw-semibold'>
								<img src='/assets/images/back-org.png' className='img-fluid' alt='' />
							</span>
							<span>Back</span>
						</Link>
					</div>
                </Col>
                <Col md={7}>
				
                </Col>
                <Col md={12} className="mt-4">
                    <div className='table-responsive'>
                    <table className="table table-borderless fontGilroy text-center" style={{verticalAlign: 'middle'}}>
                        <thead className='bgLightRed font14'>
                            <tr>
                                <th>Transaction ID</th>
                                <th>Gift Card</th>
                                <th>Currency</th>
                                <th>Total Face Value</th>
                                <th>Qty</th>
                                <th>Bonus</th>
                            </tr>
                        </thead>
                        <tbody className='font14 fw-medium'>
                            <tr>
                                <td className='font14 py-3'>5463569</td>
                                <td className='py-3'>
									<img src='/assets/images/amazon.png' className='img-fluid' alt='' />
								</td>
                                <td className='py-3'>USD</td>
                                
                                <td className='py-3'>$3,500.00</td>
								<td className='py-3'>5</td>
                                <td className='py-3'>$200.00</td>                                
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
  )
}

export default CustomersDetails