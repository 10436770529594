import { CATEGORIES_NAME, CLEAR_CATEGORIES_NAME, SHOW_CATEGORIES_NAME } from "./actionType"

const initState = {
  category: [],
  showCategory: [],
}

export const sendCategorie_reducer = (state = initState, action) => {
  switch (action.type) {
    case CATEGORIES_NAME:
        const selectCategory = action.payload
        const categoryIndex = state.category.indexOf(selectCategory)
        let updateCategoryArray = state.category
        if (categoryIndex !== -1) {
          // Category already exists in array, remove it
          updateCategoryArray.splice(categoryIndex, 1)
        } else {
          // Category doesn't exist in array, add it
          updateCategoryArray.push(selectCategory)
        }
      return {
        ...state,
        category: updateCategoryArray,
      }
    case SHOW_CATEGORIES_NAME:
      const selectedCategory = action.payload
      const showcategoryIndex = state.showCategory.indexOf(selectedCategory)
      let updatedCategoryArray = state.showCategory
      if (showcategoryIndex !== -1) {
        // Category already exists in array, remove it
        updatedCategoryArray.splice(showcategoryIndex, 1)
      } else {
        // Category doesn't exist in array, add it
        updatedCategoryArray.push(selectedCategory)
      }
      return {
        ...state,
        showCategory: updatedCategoryArray,
      }
      case CLEAR_CATEGORIES_NAME:{
        return {
          ...state,
          showCategory:[],
          category:[],
        }
      }
    default:
      return state
  }
}