import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Error404 = () => {
  useEffect(() => {
    document.title = "ncentiva || 404";
  }, []);
  
  const pathname = window.location.pathname;
  const pathSegments = pathname?.split('/');

  const onClick = () => {
    window.location.assign(`${process.env.REACT_APP_URL}`); // Use window.location.assign for navigation
  };

  return (
    <div className="p-3 p-lg-4 p-xl-5">
      <Container className='text-center' style={{ maxWidth: '650px' }}>
        <img src="/assets/images/404.svg" className='img-fluid' alt='404' />
        <div className='mt-4 text-center'>
          {pathSegments[1] === "admin" ? 
            <Link to="/admin/dashboard" className='btn btn-primary px-4 py-2 fullRounded shadowPrimary'>Back to home</Link>
            : pathSegments[1] === "partner" ?
            <Link to="/business-login" className='btn btn-primary px-4 py-2 fullRounded shadowPrimary'>Back to home</Link>
            :
            <button onClick={onClick} className='btn btn-primary px-4 py-2 fullRounded shadowPrimary'>
              Back to home
            </button>
          }
        </div>
      </Container>
    </div>
  );
};

export default Error404;
