import { SET_BUSINESS_CONTACT_REQUEST, SET_BUSINESS_CONTACT_SUCCESS, SET_BUSINESS_CONTACT_FAILURE } from './actionTypes';

const initialState = {
  businessContact: null,
  loading: false,
  error: null,
};

export const businessContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUSINESS_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SET_BUSINESS_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        businessContact: action.payload,
        error: null,
      };
    case SET_BUSINESS_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
