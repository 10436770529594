import React, { useState , useEffect} from "react";
import CheckoutBar from './CheckoutBar';
import ProductItem from './elements/ProductItem';
  
const ProductListing = props => {
  const coupon = props.data;
  const [couponData, setCost] = useState([]);
  const customer = JSON.parse( window.localStorage.getItem('customer'));
  useEffect(() => {
            
    setCost(coupon);
   
}, [coupon]);

  return (
    <>
        
        <div className="container">
            <div className="row">
            <section className="product-listing mt-5">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                <h2 className="allbrands-head mb-4">Choose a Gift card</h2>
                </div>
                {couponData.map((productitem,i) => 
                    <ProductItem key={i} data={productitem}  />    
                )}
            </div>
        </div>
       {customer ? <CheckoutBar/> : ''} 
    </section> 
            </div>
        </div>
       
    </>
   
  )
}

export default ProductListing
