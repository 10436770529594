import React, { useState ,useEffect} from 'react';
import PropTypes from 'prop-types';
import '../../styles/category.css'
import { useDispatch, useSelector } from 'react-redux';
import { ClearhandleCategories, handleCategory_name, handleCategory_show } from '../../Redux/SendCategories/action';
import { useNavigate } from 'react-router-dom';
import axios from "axios";


const Category = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categories,setCategories] = useState([]) 
  const showCategory = useSelector(state => state.sendCategorie_reducer.showCategory);
    const handleClick = (event,title) => {
        event.currentTarget.classList.toggle('active');
        if(props?.home === "false"){
          props?.setCategoryOption(!props?.catergoryOption);
          dispatch(handleCategory_name(encodeURIComponent(title)));
          dispatch(handleCategory_show(title));
        }else{
          navigate('/giftcards');
          dispatch(ClearhandleCategories())
          dispatch(handleCategory_name(encodeURIComponent(title)));
          dispatch(handleCategory_show(title));
        }
      };

      // style={{background:props?.btnColor,color:props?.btnText}}
  return (
    <>
   {showCategory?.includes(props?.title) && props?.home === "false"  ? 
    <button className={`category-block  ${props.isActive ? "new_block" : "" } `} style={{background:props?.btnColor,color:props?.btnText,borderColor:`1px solid ${props?.btnColor}`}} onClick={(event)=>handleClick(event,props.title)}>{props.title}</button>
  :<> 
  <button className={`category-block ${props.isActive ? "new_block" : "" }`}  onClick={(event)=>handleClick(event,props.title)}>{props?.title}</button>
   </>}
  </>
  )
}

Category.propTypes = {
    title: PropTypes.string
}

Category.defaultProps = {
    title: 'category'
};


export default Category
