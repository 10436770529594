import React, { useRef, useCallback } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import "../styles/page/Login.scss";
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';
import { useLocation } from 'react-router-dom';
const Verify = () => {
    const location = useLocation();
    const nameDomain = (window.location.host.split('.'));
    const isSms = new URLSearchParams(location.search).get('isSms');
    useEffect(() => {
        document.title = "ncentiva || Verify"
    }, [])
    let email;
    let password;
    let business_partner_id;
    let customer_id;

    const navigate = useNavigate();
    const customer = JSON.parse(window.localStorage.getItem('customer_data'));
    if (customer != undefined) {
        const decryptedString = CryptoJS.AES.decrypt(customer?.data?.securedLogin, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
        const decryptedObj = JSON.parse(decryptedString);
        if (customer) {
            if (decryptedObj.newPassword) {
                password = decryptedObj.newPassword;
            } else {
                password = decryptedObj.password;
            }
            email = decryptedObj.email;
            business_partner_id = decryptedObj.business_partner_id;
            customer_id = customer.data._id;
        } else {
            email = null;
            password = null;
            business_partner_id = null;
            customer_id = null;
            navigate('/')
        }
    }
    const credentials = {
        email : email,
        password: password,
        business_partner_id: business_partner_id,
        customer_id : customer_id,
        otppath: false,
        timestamp: Date.now()
      };
      const jsonString = JSON.stringify(credentials);
    const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

    const [otp, setOtp] = useState("");
    const [otppath, setOtpPath] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [stillSendingOtp, setStillSendingOtp] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [apicall, setApicall] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [image, setImage] = useState("");
    const [btnColor, setBtnColor] = useState('');
    const [btnText, setBtnText] = useState('');
    const [loading, setLoading] = useState(true);
    const [cmsData, setCmsData] = useState(false)
    const [resendTimer, setResendTimer] = useState(60);
    const [canResend, setCanResend] = useState(true);
    const [otpError, setOtpError] = useState("");
    const inputRef = useRef(null);

    const handleOtpChange = useCallback((e) => {
        let trimValue = e.target.value.replace(/\D/g, '');
        setOtp(trimValue);
        setOtpError(trimValue.length !== 6 ? "OTP must be 6 digits" : "");
    }, []);

    useEffect(() => {
        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
            return null;
        };

        const calculateRemainingTime = () => {
            const timestamp = getCookie('customerOtpTiming');
            if (timestamp) {
                const otpTimestamp = parseInt(timestamp, 10);
                const currentTime = Date.now();
                const elapsed = (currentTime - otpTimestamp) / 1000; // in seconds
                const remaining = Math.max(0, 120 - Math.floor(elapsed)); // 120 seconds = 2 minutes
                setResendTimer(remaining);
                setCanResend(remaining <= 0);
            }
        };

        calculateRemainingTime();

        const timer = setInterval(() => {
            setResendTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(timer);
                    setCanResend(true);
                    return 30; // Reset timer to 30 seconds
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [resendTimer]);



    useEffect(() => {
        setLoading(true);
        if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
                .then((res) => {
                    if (res.data?.cms?.default_ncentiva === false) {
                        setCmsData(false)
                        setBtnColor(res.data?.cms?.btn_color);
                        setBtnText(res.data?.cms?.btn_text);
                        if (res.data?.cms?.brand_logo) {
                            setImage(res.data?.cms?.brand_logo)
                        }
                    } else {
                        setCmsData(true)
                        setImage("https://ncentiva-development.s3.eu-west-1.amazonaws.com/ncentiva_new_logo.png")
                        setBtnColor('#FFAD31');
                        setBtnText('white');
                    }
                    setLoading(false);
                }).catch((err) => {
                    console.log(err);
                })
        } else {
            setLoading(false);
        }
    }, []);
    const otpSend = async (e) => {
        e.preventDefault();
        console.table({ email, password, otppath, business_partner_id })
        if (!stillSendingOtp) {
            try {
                setStillSendingOtp(true);
                const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/otppage/`, {
                    securedLogin
                });
                if (data.status === "success") {
                    toast.success("OTP sent successfully!");
                    setOtpSent(true)
                    setStillSendingOtp(false);
                    // setCanResend(false);

                    // Set the cookie with current timestamp
                    // document.cookie = `customerOtpTiming=${Date.now()}; max-age=${60 * 2}; Secure=true; SameSite=Strict`;
                    // setResendTimer(300)
                }
                else {
                    if (data.message === "Please wait before requesting another OTP.") {
                        setCanResend(false);
                        setStillSendingOtp(false);
                        // Set the cookie with current timestamp
                        document.cookie = `customerOtpTiming=${Date.now()}; max-age=${60 * 2}; Secure=true; SameSite=Strict`;
                        setResendTimer(300)
                    }
                    toast.warning(data.message);

                    setTimeout(() => setStillSendingOtp(false), 3500);

                }
            } catch (err) {
                console.log(`Error : ${err}`);
                toast.error(err.response.data);
            }
        }

    }
    useEffect(() => {
        const timer = setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 300);
        return () => clearTimeout(timer);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setApicall(true);
        if (isSubmitting) return;
        setIsSubmitting(true);
        if (otp.length !== 6) {
            setOtpError("OTP must be 6 digits");
            setApicall(false);
            setIsSubmitting(false);
            return;
        }    
        if (!otp) {
            setTimeout(() => {
                setApicall(false);
                setDisabled(false);
            }, 500);
            toast.warning("Enter Your passcode")
            setIsSubmitting(false);
        } else {
            setOtpError("");
            setDisabled(true);
            const credentials = {
                email: email,
                password: password,
                otp: otp,
                business_partner_id: business_partner_id,
                customer_id: customer_id
            };
            const jsonString = JSON.stringify(credentials);
            const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

            try {
                const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/login/verify`, {
                    securedLogin
                });
                setTimeout(() => {
                    setApicall(false);
                    setDisabled(false);
                }, 500);
                if (data.status === "success") {
                    toast.success(data.message);
                    localStorage.removeItem('customer_email');
                    const url = data.brand_url || `${process.env.REACT_APP_URL}`;
                    const serializedData = JSON.stringify(data);
                    window.localStorage.setItem('customer', serializedData);
                    setTimeout(() => {
                        localStorage.removeItem('customer_data');
                        window.location.href = `${url}?data=${encodeURIComponent(serializedData)}`;
                    }, 0);
                } else if (data.status === "failed") {
                    toast.error(data.message)
                }
            } catch (err) {
                console.log(`Error : ${err}`);
                if (err?.response?.status === 403) {
                    toast.error(err?.response?.data?.message);
                } else {
                    toast.error(err?.response?.data);
                }
                setTimeout(() => {
                    setApicall(false);
                    setDisabled(false);
                }, 500);
            } finally {
                setIsSubmitting(false);
            }
        }
    }

    function checkWhitespace(event) {
        // Get the pasted data as plain text
        let data = event.clipboardData.getData("text/plain");
        
        // Remove all non-numeric characters (including spaces) from the pasted data
        let cleanData = data.replace(/\D/g, '');
    
        // Check if the cleaned data is empty
        if (cleanData.length === 0) {
            event.preventDefault();
        } else {
            // Prevent the default paste action and set the cleaned data
            event.preventDefault();
            setOtp(cleanData);
    
            // Show error if the OTP length is not exactly 6
            setOtpError(cleanData.length !== 6 ? "OTP must be 6 digits" : "");
        } 
      }  

    return (
        <>
            <>
                <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: 'var(--newLightBlue)' }}>
                    {loading ? "" :
                        <Container
                            className="bg-white loginContainer p-0 rounded"
                            style={{ maxWidth: "1065px" }}
                        >
                            <Row className="align-items-center g-0">
                                <Col style={{ height: "100%" }} md={6} className="loginLeft order-2 order-md-1">
                                    <div className="">
                                        <h4 className="text-dark">Enter OTP Code</h4>
                                        <h6 className="text-dark verifyPageText">Your code has been sent to your OTP preferred delivery method</h6>
                                        <Form className="form mt-3" onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    ref={inputRef}
                                                    onChange={handleOtpChange}
                                                    placeholder="Enter your OTP for Verification"
                                                    // maxLength={6}
                                                    autoComplete="off"
                                                />
                                                {otpError && <div className="text-danger mt-2">{otpError}</div>}
                                                <p className="font14 mt-2 text-dark">
                                            OTP not received?{" "}
                                            {canResend ? (
                                                <>
                                                    <span
                                                        className="fw-semibold resend-link"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={otpSend}
                                                    >
                                                        Click here
                                                    </span>
                                                    <span className="font14 mt-2 text-dark"> to send it again.</span>
                                                </>
                                            ) : (
                                                <span className="fw-semibold text-danger resend-timer">
                                                    Resend in {resendTimer}s
                                                </span>
                                            )}
                                        </p>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                {disabled ? (
                                                    <Button
                                                        style={cmsData
                                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                        }
                                                        variant="primary"
                                                        className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
                                                        disabled
                                                    >
                                                        {apicall ? 'Please wait ....' : 'Verify'}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        style={cmsData
                                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                        }
                                                        type="submit"
                                                        variant="primary"
                                                        className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary button_hover"

                                                    >
                                                        {apicall ? 'Please wait ....' : 'Verify'}
                                                    </Button>
                                                )}
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </Col>
                                <Col
                                    md={6}
                                    className="loginRight d-flex justify-content-center flex-column align-items-center order-1 order-md-1"
                                    style={{ height: "100%" }}
                                >
                                    <Link to="/" >
                                        <img
                                            src={image}
                                            className="imgLogo img-fluid img_hover"
                                            alt=""
                                            style={{ width: '200px', height: '100px', marginLeft: '18%', objectFit: 'scale-down' }}
                                        /></Link>
                                </Col>
                            </Row>
                        </Container>}
                </Container>
            </>
        </>

    );
};

export default Verify;