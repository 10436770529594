// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.favourite_slides__2A-11:nth-child(1){
    margin: inherit;
}
/* .slides{
    margin:auto;
} */`, "",{"version":3,"sources":["webpack://./src/components/favourite.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;;GAEG","sourcesContent":[".slides:nth-child(1){\n    margin: inherit;\n}\n/* .slides{\n    margin:auto;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slides": `favourite_slides__2A-11`
};
export default ___CSS_LOADER_EXPORT___;
