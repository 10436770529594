import React from 'react'

const FooterICon = () => {
  return (
    <div>
      <svg width="50" height="62" viewBox="0 0 70 82" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="70" height="82" rx="8" fill="#568EFE"/>
<circle cx="35.5" cy="41.5" r="17.5" fill="#FDFEFE"/>
<path d="M35.5009 45.7782C35.1979 45.7782 34.944 45.6756 34.7394 45.4702C34.5341 45.2656 34.4314 45.0118 34.4314 44.7088V37.0623L32.4262 39.0675C32.2123 39.2814 31.9628 39.3883 31.6776 39.3883C31.3924 39.3883 31.134 39.2724 30.9023 39.0407C30.6884 38.8268 30.5861 38.5727 30.5953 38.2782C30.6039 37.9845 30.7062 37.7396 30.9023 37.5435L34.7523 33.6935C34.8592 33.5866 34.9751 33.5106 35.0998 33.4657C35.2246 33.4215 35.3583 33.3994 35.5009 33.3994C35.6435 33.3994 35.7771 33.4215 35.9019 33.4657C36.0267 33.5106 36.1425 33.5866 36.2495 33.6935L40.0995 37.5435C40.3134 37.7574 40.4157 38.0112 40.4064 38.305C40.3979 38.5994 40.2955 38.8447 40.0995 39.0407C39.8856 39.2546 39.6318 39.3658 39.338 39.3744C39.0436 39.3837 38.7894 39.2814 38.5755 39.0675L36.5703 37.0623V44.7088C36.5703 45.0118 36.468 45.2656 36.2634 45.4702C36.0581 45.6756 35.8039 45.7782 35.5009 45.7782ZM29.0842 50.056C28.496 50.056 27.9927 49.8468 27.5741 49.4282C27.1549 49.009 26.9453 48.5053 26.9453 47.9171V45.7782C26.9453 45.4752 27.0476 45.2211 27.2522 45.0157C27.4576 44.8111 27.7117 44.7088 28.0148 44.7088C28.3178 44.7088 28.5719 44.8111 28.7773 45.0157C28.9819 45.2211 29.0842 45.4752 29.0842 45.7782V47.9171H41.9175V45.7782C41.9175 45.4752 42.0202 45.2211 42.2255 45.0157C42.4302 44.8111 42.684 44.7088 42.987 44.7088C43.29 44.7088 43.5438 44.8111 43.7484 45.0157C43.9538 45.2211 44.0564 45.4752 44.0564 45.7782V47.9171C44.0564 48.5053 43.8472 49.009 43.4287 49.4282C43.0094 49.8468 42.5057 50.056 41.9175 50.056H29.0842Z" fill="#568EFE"/>
</svg>
    </div>
  )
}

export default FooterICon
