import React, { useState, useEffect } from "react";
// import { FaMinus, FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FaMinus, FaPlus } from 'react-icons/fa';
// import ProductInput from "./ProductInput";
import { toast } from 'react-toastify';
import axios from "axios";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import ViewCardMode from "../components/ViewCardMode";
import { useSelector } from "react-redux";
const GiftProductItems = props => {
    const customer = JSON.parse(window.localStorage.getItem('customer'));
    // const contactList = JSON.parse(localStorage.getItem('listItem')) ?? [];
    const cartData = useSelector((state)=>state.cartReducer);
    const customerId =customer?.customer?._id
    const lists = JSON.parse(localStorage.getItem('listItem')) ?? [];
    // const contactList = lists.filter((item) => item?.customer_id === customerId);
    const contactList = cartData?.data?.cart ?? [];
    var token;
    var id;
    var bonus = 0;
    var minValue = 0;
    var minCost = 0;
    var bonusFee = 0;
    var max_discount = 0;
    var max_bonus_amount = 0;
    var max_cost = 0;
    var costValue = 0;
    var rbalance = 0;
    var balance = 0;
    var maxValue = 0;
    var rebate = 0;
    if (customer) {
        balance = customer.customer.balance;
    }
    contactList.length !== 0 ? contactList.findLast((item) => id = item.id) : id = 0
    const data = props.data
    if (data.minValue > 0) {
        minValue = data.minValue
        minCost = data.minCost;
        maxValue = data.maxValue;
    }
    if (data.faceValue > 0) {
        minValue = data.faceValue
        minCost = data.faceValue;
    }
    const [count, setCount] = useState(Number(minValue).toFixed(2));
    const [cminvalue] = useState(minValue);
    const [cmaxvalue] = useState(maxValue);
    const [cost, setCost] = useState(minCost);
    const [rFund, setrFund] = useState(balance);
    const [disabled,setDisabled] = useState(false);
    if (data.bonusFee > 0) {
        bonusFee = data.bonusFee;
    } else {
        if (data.discount > 0) {
            bonus = data.discount;
        }
    }

    const discount = data.discount;
    if (discount) {
        if (data.bonusFee > 0) {
            bonus = discount * (bonusFee / 100);
        }

    } else {

    }

    const [b] = useState(Number(bonus).toFixed(2));
   
   

    return (
        <>
            <div className="col-lg-3 col-md-6 mb-4">
                <div className="listing-block p-md-4 p-lg-2 p-xl-4">
                    <div className="listing-top">
                        <div className="row">
                            <div className="col-sm-12 mb-3 mb-sm-0">
                                <div className="listing-top-left justify-content-center justify-content-sm-start">
                                    <div className="listing-logo">
                                        <img
                                            src={data.img_path}
                                            alt={data.alt}
                                            className="img-fluid"
                                        />
                                    </div>
                                    <div className="listing-detail">
                                        <h6 className="listing-name">{data.productname}</h6>
                                        <p className="listing-categorydtl">{data.category}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-sm-5">
                                <div className="listing-top-right">
                                    <ViewCard brand_id={data.brand_id} primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} btnColor={props.btnColor} btnText={props.btnText} coupon={data._id} data={data} rbalance_redux={props.rbalance_redux} disableBtn={props.disableBtn} setDisableBtn={props.setDisableBtn}/>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="listing-bottom">
                        <div className="row">
                            <div className="col-sm-5 mb-3 mb-sm-0">
                                    <h6 className="youGot">You get</h6>
                            </div>
                            <div className="col-sm-5">
                                <div className="listing-bottom-right-giftcards">
                                    <h6 style={{margin:'0px'}}>
                                        {data.faceValue == 0.0 ? `+${Number(b).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%` : `+$${Number(0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-sm-11">
                                    <ViewCardMode brand_id={data.brand_id} primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} btnColor={props.btnColor} btnText={props.btnText} coupon={data._id} b={b} data={data} rbalance_redux={props.rbalance_redux} disableBtn={props.disableBtn} setDisableBtn={props.setDisableBtn}/>
                                </div>
                        </div>
                        
                    </div>
                </div>
            </div>

        </>


    )
}

GiftProductItems.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    price: PropTypes.number,
    percent: PropTypes.number,
}

GiftProductItems.defaultValue = {
    alt: "Product Image",
}

export default GiftProductItems
