import { useState } from 'react';
import ReactFileReader from 'react-file-reader';
import {toast} from 'react-toastify';
const CustomerForm = (props) => {
  // const [filename, setFilename] = useState('');
    const handleFiles = (files) =>{
        // Converting to String
        var reader = new FileReader();
        reader.readAsText(files[0]);
        props.setFilename(files[0].name)
        const csvFileNameChecker = files[0].name.includes('csv');
        if(csvFileNameChecker === false){
          toast.warning("Please Select The CSV File")
          return;
        }else{
          reader.onload = function(e) {
            const userDataInString = reader.result;
            props.setCustomerData(userDataInString);
        }

        }

    }
  return (
    <>
    <ReactFileReader handleFiles={handleFiles} fileTypes={".csv"}>
      <div className="w-100 border-1 border-secondary d-flex justify-content-center align-items-center"  style={{height: '80px', borderStyle:'dashed' }}>
        <h4 className='fs-6 text-dark mb-0'>Click to Upload</h4>
      </div>
      {/* <Button variant="primary"   className='me-3 fullRounded px-4 py-2 fw-bold font12'>
							<span className='me-2'>
								<img src="/assets/images/plus.svg" className="img-fluid" alt="" />
							</span>
							<span>Upload CSV</span>
						</Button> */}
    </ReactFileReader>
    {props?.filename && <p className='mt-3'> <span className='text-primary'>File Uploaded: </span>{props?.filename}</p>}

    <a href="/assets/images/ncentiva.csv" className='text-danger mt-3 mb-2' style={{cursor: 'pointer'}}>Sample CSV file</a>
    </>
  );
};

export default CustomerForm;
