// import React from 'react'
import React, { useState, useEffect,useRef } from "react";
// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";

// Import Slider and slick  styles
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";

// import required modules
// import { Autoplay, Navigation } from "swiper";
import ProductBlock from "./elements/ProductBlock";
import axios from "axios";

import CategoriesJsonData from './CategoriesJsonData';
import { useSelector } from "react-redux";



const ProductSlider = () => {

    const sliderRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    const [color, setColor] = useState();
    const cmsData = useSelector((state) => state.cmsReducer);


    useEffect(() => {
        const data = cmsData.data?.cms;
        if (data?.default_ncentiva === false) {
            if (data?.footer_logos.length > 0) {

                setColor(data?.primary_color);
                setIsLoading(false);
            }
        } else if (data?.default_ncentiva === true) {
            setColor('#FF7020');
            setIsLoading(false);
        }
    }, [cmsData])




    const [jsonData, setJsonData] = useState([]);

    useEffect(() => {
        setJsonData(CategoriesJsonData);
    }, []);

    const CustomNextArrow = (props) => (
        <button {...props} className="carousel-button next-button">
            <SlArrowRight />
        </button>
    );

    const CustomPrevArrow = (props) => (
        <button {...props} className="carousel-button prev-button">
            <SlArrowLeft />
        </button>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },

            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },

            
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            }
        ],
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
    };

    return (
        <div className="slider-area my-5 px-0">
            <div className="position-relative">
                {/* <Swiper navigation={true}
                    modules={[Navigation, Autoplay]}
                    autoplay={{ delay: 5000 }}
                    style={{ marginLeft: '80px', marginRight: '80px', position: 'unset' }}
                    spaceBetween={0}
                    loop={true}
                    slidesPerView={1}
                    breakpoints={{
                        576: {
                            slidesPerView: 1,
                            spaceBetween: 40
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 40
                        },
                        1200: {
                            slidesPerView: 4,
                            spaceBetween: 40
                        },
                        1400: {
                            slidesPerView: 5,
                            spaceBetween: 40
                        },
                    }}
                    className="product-slider" >
                    {jsonData.map((productSlide, i) =>
                        <SwiperSlide key={i} style={{ height: "15rem" }} >
                            <ProductBlock key={productSlide.id} color={color} name={productSlide.name} path={productSlide.path} xmlSpace={productSlide['xml:space']} xmlnsXlink={productSlide['xmlns:xlink']} xmlns={productSlide['xmlns']} product={productSlide.name} />
                        </SwiperSlide>
                    )}

                </Swiper> */}



                <Slider {...settings} ref={sliderRef} className="mb-5">

                {jsonData.map((productSlide, i) =>
                       <div className="carousel_slide" key={i}>
                            <ProductBlock  key={productSlide.id} color={color} name={productSlide.name} path={productSlide.path} xmlSpace={productSlide['xml:space']} xmlnsXlink={productSlide['xmlns:xlink']} xmlns={productSlide['xmlns']} product={productSlide.name} />
                            </div>
                    )}

                </Slider>






            </div>
        </div>
    )
}

export default ProductSlider;
