import React from 'react'
import PropTypes from 'prop-types';
const RedeemProgress = (props) => {
    // const data = props.data;
    var lwidth = 0;
    var rwidth = 0;
    var swidth = 0;
    var rCalc = 0;
    var lCalc = 0;
    var mCalc = 100;
   var mwidth = 0;
   var rfCalc = 0;
   var rfWallet = 0;
    mCalc = Number(props.amount) + Number(props.bonus) + Number(props.rFund) + Number(props.wallet);
    lCalc = (Number(props.amount) / Number(mCalc)) * 100;
    rCalc = (Number(props.bonus) / Number(mCalc)) * 100;
    rfCalc = (Number(props.rFund) / Number(mCalc)) * 100;
    rfWallet = (Number(props.wallet) / Number(mCalc)) * 100;
    lwidth = `${lCalc}%`;
    rwidth = `${rCalc}%`;
    mwidth = `${rfCalc}%`;
    swidth = `${rfWallet}%`;
  return (
    
    <div className="task-progress">
    <div className="progress_body">
        <div className="progres" style={{ width: lwidth, background : props?.primaryColor }} >
            <span style={{ background: props?.primaryColor }}>{`$${props.amount?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span>
            <span style={{ background: props?.primaryColor }}></span>
        </div>
        <div className="progres" style={{ width: swidth, backgroundColor:  props?.walletColor }} >
            <span style={{ background: props?.walletColor }} >{`$${props.wallet}`}</span>
            <span style={{ background: props?.walletColor }} ></span>
        </div>
        <div className="progres" style={{ width: rwidth, backgroundColor:  props?.btnColor }} >
            <span style={{ background: props?.btnColor }} >{`$${props?.bonus?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</span>
            <span style={{ background: props?.btnColor }} ></span>
        </div>
        <div className="progres" style={{ width: mwidth, backgroundColor: "transparent" }} >
            {/* <span style={{ background: "#747373" }} >{`$${props.rFund}`}</span> */}
            <span style={{ background: "#747373" }} >${Number(props.rFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
            <span style={{ background: "#747373" }} ></span>
        </div>
    </div>
  </div>
    // <div className="rdeem-progress">
    //     {props.primaryColor?<div
    //     className="progress-left"
    //     style={{ width: lwidth, background : props?.primaryColor}}
    //     >
    //         <span style={{color : props?.primaryColor}}>${Number(props.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
    //     </div>:''}
    //     {props.walletColor?<div
    //     className="progress-right redeem"
    //     style={{ width: swidth, background : props?.walletColor, borderRadius: '0 100px 100px 0'}}
    //     >
    //         <span style={{color : props?.walletColor, top: '12px', background: '#faf6d7'}}>${Number(props.wallet).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
    //     </div>:''}

    //     {props.btnColor?<div className="progress-right" style={{ width: rwidth}}>
    //         <span >${Number(props.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
    //     </div>:''}

    //     <div
    //     className="progress-left prg_middle"
    //     style={{ width: mwidth, background: "transparent", textAlign: "center", left:'18px', top:'-18px '}}
    //     >
            
    //         {props.rFund >= 0 ? (
    //                   <>
    //                    <span style={ {textAlign: "center"}}>${Number(props.rFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
    //                    </>
    //                   ) : (
    //                    <>
    //                    <span style={ {textAlign: "center"}}>${Math.abs(props.rFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
    //                 </>
    //                 )}


    //     </div>
    //     {/* {props.btnColor?<div className="progress-right" style={{ width: rwidth}}>
    //         <span >${Number(props.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
    //     </div>:''} */}
    //     {/* {props.walletColor?<div className="progress-right" style={{ width: rwidth}}>
    //         <span style={{color : props?.walletColor}} >${Number(props.wallet).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
    //     </div>:''} */}
    //      {/* {props.walletColor?<div
    //     className="progress-right redeem"
    //     style={{ width: swidth, background : props?.walletColor, borderRadius: '0 100px 100px 0'}}
    //     >
    //         <span style={{color : props?.walletColor, top: '12px',}}>${Number(props.wallet).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
    //     </div>:''} */}
    // </div>
  )
}

RedeemProgress.propTypes = {
    lwidth: PropTypes.string,
    rwidth: PropTypes.string,
    amount: PropTypes.number,
    bonus: PropTypes.number,
}

// RedeemProgress.defaultProps = {
//     lwidth: 80,
//     rwidth: 20,
//     amount: 300,
//     bonus: 10.60
// };

export default RedeemProgress
