import React from 'react'
import { useEffect } from 'react'
import CheckoutComp from '../components/CheckoutComp'

const CheckOut = () => {
  useEffect(()=>{
    document.title = "ncentiva || Checkout";
    const currentURL = window.location.href;

    if (!currentURL.includes('localhost')) {
        // Define _tip array in the global scope
        var _tip = _tip || [];
    
        const script = document.createElement('script');
        script.type = 'text/javascript';
        
        // Define js variable outside the template string
        var js = document.createElement('script'); // Create the script element here
    
        script.innerHTML = `
            // test
            (function(d,s,id){
                var tjs = d.getElementsByTagName(s)[0];
                if(d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.async = true;
                js.src = d.location.protocol + '//app.truconversion.com/ti-js/33039/a1d99.js';
                tjs.parentNode.insertBefore(js, tjs);
            }(document, 'script', 'ti-js'));
        `;
        document.head.appendChild(script);
    
        // Define onExternalScriptLoad function
        function onExternalScriptLoad() {
            _tip.push(['_trackIdentity', 'dev']);
        }
    
        // Wait for the dynamically created script to load
        js.onload = function() {
            onExternalScriptLoad();
        };
      }
  },[])

  return (
    <CheckoutComp/>
  )
}

export default CheckOut
