import { SEARCH_NAME } from "./actionType";



export const searchName =(data)=>({
   type:SEARCH_NAME,
   payload:data
})

export const globalSearch =(value)=> (dispatch)=>{
   dispatch(searchName(value));
}