import React from 'react'
import PropTypes from 'prop-types';

const SiteHeader = (props) => {
  return (
    <h2 className="section-header text-center">{props.title}</h2>
  )
}

SiteHeader.propTypes = {
    title: PropTypes.string
}
SiteHeader.defaultProps = {
    title: 'Site header text here'
};

export default SiteHeader
