import React, { useRef } from 'react'
import BtnArea from './elements/BtnArea'
import SiteHeader from './elements/SiteHeader'
import FavouriteSlider from './FavouriteSlider'
import { useState, } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import {useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const FavouriteBrands = (props) => {

  const nameDomain = ( window.location.host.split('.')); 
  const [couponData, setCouponData] = useState([]);
  const [noGift,setNoGift] = useState(true);
  const customer = JSON.parse(window.localStorage.getItem('customer'));
  const [giveClass,setGiveClass] = useState(false);
  const [btnColor,setBtnColor] = useState('');
  const [btnText,setBtnText] = useState('');
  const [isLoading,setIsLoading] = useState(true);
  const cmsData = useSelector((state) => state.cmsReducer);

  const checkCms = nameDomain[0].split('-');

  const navigate = useNavigate();
  var partner_id;
  // const render = useRef(0);

if (customer) {
 // var cust_id = customer.customer._id;
  partner_id = customer.customer.business_partner_id;
  
}else{
 //partner_id = "NCT-B-878611"
}
if (nameDomain[0] === "payouts") {
  partner_id = "NCT-B-604371"
 }
let token;
useEffect(()=>{
  document.title = "ncentiva || Products";
   getCouponsData();
  
},[])

useEffect(() => {
  const data = cmsData.data?.cms; 
       
  if (data?.default_ncentiva === false) {
      setBtnColor(data?.btn_color);
      setBtnText(data?.btn_text);
      
  } else if(data?.default_ncentiva === true){
      // setImage("https://dev.ncentiva.com/assets/images/logo.png");
      setBtnColor('#3D05B6');
      setBtnText('white');
  }
  setIsLoading(false);
}, [cmsData])

  const getCouponsData = () => {

    let check_name = "cms_url";
    if(nameDomain[0] === "dev" || nameDomain[0] ===  "staging" || nameDomain[0] ===  "payouts" || nameDomain[0] ===  "uat" || nameDomain[0] ===  "localhost:3000"){
  
      nameDomain[0]="";
    }
    if(checkCms[1]==="cms"){
      nameDomain[0]=checkCms[0];
      check_name = "partner_url";
      }
    if (customer) {
      token = customer.token;
    } else {
      token = null;
    }
    // setIsLoading(true);
    // if(props?.render.current === 0){
      axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/allcouponsforhome?${check_name}=${nameDomain[0]}&partner_id=${partner_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
         .then((response) => {
          if (response.data.message === "Cms url doesn't exist") {
            navigate('/*');
          }
            if(response.data.message === "Please Login"){
                localStorage.removeItem('customer');
                // localStorage.removeItem('listItem');
               navigate('/');
              toast.warning("Session Expired Please Login Again");
              // window.location.reload();
              }
             const allcoupons = response.data.coupons;
       //setIsLoading(false);
       setGiveClass(false);
         if(allcoupons.length <1){
           setNoGift(false);
         }else{

           if(allcoupons.length<3){
             setGiveClass(true);
            }
            setCouponData(allcoupons);
            setNoGift(true);
          }
         })
         .catch(error => console.error(`Error: ${error}`));
    // }
    // props.render.current++;
     }

     useEffect(()=>{
      if(cmsData){
        setIsLoading(false);
      }
    
    },[cmsData]);  
     const noDataStyle = {
      height: '300px',
      display: 'flex',
      alignItems: 'center'
     }
  return (
    <>
    {isLoading  ? "" 
     :
    <section className="fav-brands my-5">
        <div className="container-fluid custom-gap">
            {noGift?
            <><div className="mb-5">
                <SiteHeader className="mb-5" title="We have all your favorite brands"/>
            </div>
            <FavouriteSlider couponData={couponData} giveClass={giveClass}/>{
              btnColor === null ? "" :
            <BtnArea title="View all brands" giftcards={props.giftcards} btnColor={btnColor} btnText={btnText} />
          }
          </>:
          <div style={noDataStyle} className="mb-5">
                {/* <SiteHeader title="No brands to show please contact your company!"/> */}
                <h2 style={{margin:'auto'}} className="section-header text-center">No brands to show please contact your company!</h2>
            </div>
          }
        </div>
        </section>
        }
        </>
  )
}

export default FavouriteBrands;