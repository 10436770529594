import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';

import { Link } from 'react-router-dom';
import axios from 'axios';
import BusinessBrandCategoryss from './BusinessBrandCategoryss';




function MyVerticallyCenteredModal(props) {


    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="category-modal"
      >
        <Modal.Header closeButton className='border-0'></Modal.Header>
        <Modal.Body className="pt-0">
          <h3 className="categories-mtitle mb-4 text-center">Categories</h3>
          <div className="categories-area justify-content-center" style={{gap:'10px'}}>
            {props?.data?.map((category) => 
            
              <BusinessBrandCategoryss catergoryOption={props.catergoryOption} setCategoryOption={props.setCategoryOption} home={props.home} key={category.toString()} title={category}
              btnColor={props.btnColor} btnText={props.btnText} setClickBrand={props.setClickBrand}
              clickBrand={props.clickBrand}/>
            )}
          </div>
        </Modal.Body>

      </Modal>
    );
  }

  const ModalOpen = props => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
        <div className={props.cssStyle === "button" ? "category-bttn" : props.colorCheck === "black" ?  "category-bttnn" : "category-btn"} onClick={()=>setModalShow(true)}>
            {props.title}
        </div>

        <MyVerticallyCenteredModal
        data={props.data}
        catergoryOption={props.catergoryOption}
        setCategoryOption={props.setCategoryOption}
        num={props.num}
        home={props.home}
        btnColor={props.btnColor} 
        btnText={props.btnText}
        show={modalShow}
        setClickBrand={props.setClickBrand}
        clickBrand={props.clickBrand}
        onHide={() => setModalShow(false)}
        />
    </>
  )
}

export default ModalOpen
