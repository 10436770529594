import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams, Link } from "react-router-dom";
import "../styles/page/Login.scss";
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Bars } from "react-loader-spinner";

const CustomerCreatePassword = () => {
    const nameDomain = (window.location.host.split('.'));

    useEffect(() => {
        document.title = "ncentiva || Customer Set Password"
    }, [])

    const navigate = useNavigate();
    const { id } = useParams();
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newConfirmPassword, setNewConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [image, setImage] = useState("");
    const [loading, setLoading] = useState(true);
    const [btnColor, setBtnColor] = useState('');
    const [btnText, setBtnText] = useState('');
    const [imageError, setImageError] = useState(true);
    const customerEmail = localStorage.getItem('customer_email')
    const [cmsData, setCmsData] = useState(false)


    useEffect(() => {
        setLoading(true);
        if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
            axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
                .then((res) => {
                    if (res.data?.cms?.default_ncentiva === false) {
                        setCmsData(false)
                        setBtnColor(res.data?.cms?.btn_color);
                        setBtnText(res.data?.cms?.btn_text);
                        if (res.data?.cms?.brand_logo) {
                            setImage(res.data?.cms?.brand_logo)
                        }
                    } else {
                        setCmsData(true)
                        setImage("https://dev.ncentiva.com/assets/images/logo.png")
                        setBtnColor('#3D05B6');
                        setBtnText('white');
                    }
                    if (res.data?.cms?.brand_logo) {
                        setImageError(false)
                    } else {
                        setImageError(true);
                    }
                    setLoading(false);
                }).catch((err) => {
                    console.log(err);
                })
        } else {
            setLoading(false);
        }
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!password && !newPassword) {
            toast.warning("All Fields Are Required")
        }
        else if (!newPassword) {
            toast.warning("Please Enter Your  Password")
        }
        else if (newPassword !== newConfirmPassword) {
            toast.warning(" Password And  Confirm Password Doesn't Matched")
        } else {
            if (newPassword.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/) < 0) {
                toast.warning(<div>
                    <p><b>Password must be</b></p>
                    <br />
                    <ul>
                        <li>
                            Use at least 8 characters
                        </li>
                        <li>
                            Use upper and lower case characters
                        </li>
                        <li>
                            Use 1 or more numbers
                        </li>
                        <li>
                            Use 1 or more special character
                        </li>
                    </ul>
                </div>);
                // toast("password must be 8 characters including 1 uppercase letter, 1 special character and alphanumeric characters ");
            } else {


                try {

                    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/set-password/`, {
                        id,
                        password,
                        email,
                        newPassword,
                        newConfirmPassword
                    });
                    // toast.success(data.message);
                    if (data.status === "success") {
                        toast.success(data.message);
                        // window.localStorage.setItem("customer", JSON.stringify(data));
                        navigate('/login');
                    } else {
                        toast.success(data.message);
                    }
                } catch (err) {
                    console.log(err)
                    // toast(err.response.message);
                }

            }




        }
    }

    function keyDown(e) {
        var e = window.event || e;
        var key = e.keyCode;
        //space pressed
        if (key == 32) { //space
            e.preventDefault();
        }
    }

    function checkWhitespace(event) {
        var data = event.clipboardData.getData("text/plain");
        var isNullOrContainsWhitespace = (!data || data.length === 0 || /\s/g.test(data));

        if (isNullOrContainsWhitespace) {
            event.preventDefault();
        }

    }

    return (
        <>
            <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#fff8f3' }}>
                <Container
                    className="bg-white loginContainer p-0 rounded"
                    style={{ maxWidth: "1065px" }}
                >
                    <Row className="align-items-center g-0">
                        <Col style={{ height: "100%" }} md={6} className="loginLeft order-2 order-md-1">
                            <div className="">
                                <h4 className="text-dark">Set Password</h4>
                                <p className="font16 text-dark">

                                </p>



                                <Form className="form mt-5" onSubmit={handleSubmit}>


                                    <Form.Group className="mb-3">
                                        <Form.Label className=" text-dark">Email</Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type="email"
                                                required
                                                onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Enter Email"
                                                onKeyPress={(e) => keyDown(e)}
                                                onPaste={(e) => checkWhitespace(e)}
                                                value={customerEmail}
                                                disabled
                                            />

                                        </div>
                                    </Form.Group>


                                    <Form.Group className="mb-3">
                                        <Form.Label className=" text-dark">New Password</Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                // value={password}
                                                onChange={(e) => {
                                                    let trimValue = e.target.value;
                                                    trimValue = trimValue.replace(/\s/g, '')
                                                    setNewPassword(trimValue)
                                                }}
                                                placeholder="Enter New Password"
                                                onPaste={(e) => checkWhitespace(e)}
                                                value={newPassword}
                                            />
                                            {newPassword.length > 0 && <button type="button" className="bg-transparent border-0 position-absolute eyBtn" onClick={() => { setShowConfirmPassword(!showConfirmPassword) }} >
                                                {!showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                            </button>}
                                        </div>

                                    </Form.Group>


                                    <Form.Group className="mb-3">
                                        <Form.Label className=" text-dark"> Confirm New Password</Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type={showConfirmNewPassword ? 'text' : 'password'}

                                                onChange={(e) => {
                                                    let trimValue = e.target.value;
                                                    trimValue = trimValue.replace(/\s/g, '')
                                                    setNewConfirmPassword(trimValue)
                                                }}
                                                placeholder="Enter Confirm New Password"
                                                onPaste={(e) => checkWhitespace(e)}
                                                value={newConfirmPassword}
                                            />
                                            {newConfirmPassword.length > 0 && <button type="button" className="bg-transparent border-0 position-absolute eyBtn" onClick={() => { setShowConfirmNewPassword(!showConfirmNewPassword) }} >
                                                {!showConfirmNewPassword ? <FaEyeSlash /> : <FaEye />}
                                            </button>}
                                        </div>

                                    </Form.Group>

                                    <Form.Group className="mb-3">

                                        {error ? <Button
                                            variant="primary"
                                            className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
                                            // style={{backgroundColor:btnColor,color:btnText,border:`1px solid ${btnColor}`}}
                                            style={cmsData
                                                ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                            }
                                        >
                                            Submit
                                        </Button> : <Button
                                            type="submit"
                                            variant="primary"
                                            className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
                                            style={cmsData
                                                ? { backgroundColor: `${btnColor}80`, color: btnText, border: `1px solid transparent` }
                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                            }
                                        >
                                            submit
                                        </Button>}

                                    </Form.Group>
                                </Form>
                            </div>
                        </Col>
                        <Col
                            md={6}
                            className="loginRight d-flex justify-content-center flex-column align-items-center order-1 order-md-1"
                            style={{ height: "100%" }}
                        >

                            {imageError ? "" : (
                                <Link to="/login" >
                                    <img

                                        src={image}
                                        className="imgLogo img-fluid"
                                        style={{ width: '170px', height: '110px', objectFit: 'scale-down' }}
                                        alt=""

                                    /></Link>
                            )}


                        </Col>
                    </Row>
                </Container>
            </Container>

        </>

    );
};

export default CustomerCreatePassword;
