import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import Select from "react-select";
import { getNewToken } from "../config/cookie.config";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
// Rest of the code...



const UsersManagementDetails = () => {
    const { id } = useParams();
    const [active, setActive] = useState(false);
    const [name, setName] = useState("");
    const [user_id, setId] = useState("");
    const [email, setEmail] = useState("");
    const [sameEmail, setSameEmail] = useState(false);
    const [code, setCode] = useState("");
    const [phone, setPhone] = useState("");
    const [role, setRoles] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [wait, setWait] = useState(false);
    const [error, setError] = useState(false);
    const [phoneError, setPhoneError] = useState();
    const [errorPhone, setErrorPhone] = useState();
    const [errorName, setErrorName] = useState();
    const [nameError, setNameError] = useState();
    const [paste, setPaste] = useState(0);
    const [number, setNumber] = useState(0);
    let [realPhone, setRealPhone]=useState("")
    const user = JSON.parse(window.localStorage.getItem('client'));
    const loginUserId = user.user._id;
    const formikInitialValue = {
        name,
        user_id,
        email,
        code,
        phone,
        role
    };
    const formSchema =
        Yup.object().shape({
            name: Yup.string()
                .min(2, "Too Short!")
                .max(21, "Maximum 21 characters allowed for Name")
                .matches(/^[aA-zZ0-9\s]+$/, "Alphabets and numbers are allowed for Name")
                .required(" First Name cannot be empty"),
            email: Yup.string()
                .email("Invalid email")
                .matches(
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i,
                    "Invalid email format"
                )
                .required("Email cannot be empty"),

            code: Yup.string()
                .min(1, 'minimum 1 digit require'),


            phone: Yup.string()
                // .min(14, "Minimum 10 digits required for Phone number")
                // .max(14, "Maximum 10 digits allowed for Phone number")
                .required("Phone number cannot be empty"),
            role: Yup.string().required("Please select role")
        })


    const navigate = useNavigate();
    useEffect(() => {
        if (id) {
            getAllUsers();
        }
    }, []);

    const getPhone = () => {
        }

        const phoneCode = [
          { value: "+1", label: "+1 USA" },
          { value: "+7", label: "+7 RUS" },
          { value: "+20", label: "+20 EGY" },
          { value: "+27", label: "+27 ZAF" },
          { value: "+30", label: "+30 GRC" },
          { value: "+31", label: "+31 NLD" },
          { value: "+32", label: "+32 BEL" },
          { value: "+33", label: "+33 FRA" },
          { value: "+34", label: "+34 ESP" },
          { value: "+36", label: "+36 HUN" },
          { value: "+39", label: "+39 ITA" },
          { value: "+40", label: "+40 ROU" },
          { value: "+41", label: "+41 SWZ" },
          { value: "+43", label: "+43 AUT" },
          { value: "+44", label: "+44 GBR" },
          { value: "+45", label: "+45 DNK" },
          { value: "+46", label: "+46 SWE" },
          { value: "+47", label: "+47 NOR" },
          { value: "+48", label: "+48 POL" },
          { value: "+49", label: "+49 DEU" },
          { value: "+51", label: "+51 PER" },
          { value: "+52", label: "+52 MEX" },
          { value: "+53", label: "+53 CUB" },
          { value: "+54", label: "+54 ARG" },
          { value: "+55", label: "+55 BRA" },
          { value: "+56", label: "+56 CHL" },
          { value: "+57", label: "+57 COL" },
          { value: "+58", label: "+58 VEN" },
          { value: "+60", label: "+60 MYS" },
          { value: "+61", label: "+61 AUS" },
          { value: "+62", label: "+62 IDN" },
          { value: "+63", label: "+63 PHL" },
          { value: "+64", label: "+64 NZL" },
          { value: "+65", label: "+65 SGP" },
          { value: "+66", label: "+66 THA" },
          { value: "+81", label: "+81 JPN" },
          { value: "+82", label: "+82 KOR" },
          { value: "+84", label: "+84 VNM" },
          { value: "+86", label: "+86 CHN" },
          { value: "+90", label: "+90 TUR" },
          { value: "+91", label: "+91 IND" },
          { value: "+92", label: "+92 PAK" },
          { value: "+93", label: "+93 AFG" },
          { value: "+94", label: "+94 LKA" },
          { value: "+95", label: "+95 MMR" },
          { value: "+98", label: "+98 IRN" },
          { value: "+211", label: "+211 SSD" },
          { value: "+212", label: "+212 MAR" },
          { value: "+213", label: "+213 DZA" },
          { value: "+216", label: "+216 TUN" },
          { value: "+218", label: "+218 LBY" },
        ];
        
    const getAllUsers = () => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${id}`)
            .then((response) => {
                if(response.data.message == "Please Login"){
                    localStorage.removeItem('client');
                    navigate('/admin');
                    toast.error("Session Expired Please Login Again")
                  }  
                setName(response.data.user.name);
                setId(response.data.user._id);
                setCode(response.data.user.code);
                setEmail(response.data.user.email);
                setPhone(response.data.user.phone);
                setRoles(response.data.user.role);
            })
            .catch((error) => console.error(`Error: ${error}`));
    };

    const handleSubmitForm = async (data) => {
        //e.preventDefault();
        if (wait === false) {

            let { name, email, code, phone, role, user_id } = data;
            // console.table({ name, email, code, phone, role, user_id });
            if (phone !== realPhone) {
                phone = realPhone;
                }
            data.phone=phone
            try {
                // setIsLoading(true);
                var token;
                const user = JSON.parse(window.localStorage.getItem("client"));
                if (user) {
                    token = user.token;
                } else {
                    token = null;
                }
                const { data } = await axios.post(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/user/userbyadminandbusiness/create`,
                    {
                        name,
                        email,
                        code,
                        phone,
                        role,
                        user_id,
                        loginUserId :loginUserId
                    }, {
                    headers: {
                        'Authorization': `Bearer ${getNewToken()}`
                    }
                }
                );
                if (data.message === "Email already exists") {
                    setError(true);
                    // setSameEmail(true);
                    // setIsLoading(false);
                }
                //  setIsLoading(false);
                toast.success(data.message);
                if (data.status === "success") {
                    const dataToSave = {
                        "status": data?.status,
                        "data": data?.data
                    };
                    window.localStorage.setItem('user', JSON.stringify(dataToSave));
                    navigate("/admin/users-management");
                }
            } catch (err) {
                console.log(`Error : ${err}`);
                setError(true);
                // setIsLoading(false);
                err?.response?.data?.errors?.map((error) => {
                    toast.error(error);
                });
            }
        }
        setTimeout(() => {
            setWait(false);
        }, 2000)

    };


    function handlePaste(event) {

        const pastedText = event.clipboardData.getData('text');
        let names = pastedText.trim();

        if (names.includes(" ")) {
            const [firstName, lastName] = names.split(" ");
            names = `${firstName} ${lastName}`

            setPaste(names.length)
            if (paste < 2) {
                setErrorName(true);
            }
            else if (paste <= 21) {
                setErrorName(false);
            }
            setNameError(names);
            setNumber(names.length)
            return names;
        }
        else {
            setNumber(names.length)
            setNameError(paste);
            return paste;
        }
    }


    function nameFormat(input) {
        return input.trimStart();
        // if(input[0] == ""){
        //     input = input.trimStart().replace(/\s+/g, ' ');
        // }
        // input = input.trimStart().replace(/\s+/g, ' ');
        // if (input.includes(" ")) {
        //   const [firstName, lastName] = input.split(" ");
        //   input = `${firstName} ${lastName}`
        //   if(input.length <2){
        //     setErrorName(true);
        //   }
        //   else if(input.length <= 21){
        //     setErrorName(false);
        //   }
        //   setNumber(input.length)
        //   setNameError(input);
        //   return input;
        // } else {
        //     setNumber(input.length)
        //     setNameError(input);
        //   return input;
        // }
    }




    function keyDown(e) {
        var e = window.event || e;
        var key = e.keyCode;
        //space pressed
        if (key == 32) { //space
            e.preventDefault();
        }

    }

    function checkWhitespace(event) {
        var data = event.clipboardData.getData("text/plain");
        var isNullOrContainsWhitespace = (!data || data.length === 0 || /\s/g.test(data));

        if (isNullOrContainsWhitespace) {
            event.preventDefault();
        }

    }




    function phoneFormat(input) {
        input = input.replace(/\D/g, "");
        input = input.trim()
        input = input.substring(0, 10);

        var size = input.length;
        if (size === 0) {
            input = input;
        }
        else if (size < 4) {
            input = "(" + input;
        }
        else if (size < 7) {
            input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
        }
        else if (size <= 11)
            input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6) + "-" + input.substring(6, 11);

        if (input?.length > 10 && input?.length <= 14) {
            setErrorPhone(false);
        } else {
            setErrorPhone(true);
        }
        setRealPhone(input)
        return input;
    }
    const handleKeyPress = (e) => {
        const keyCode = e.keyCode || e.which;
        const char = String.fromCharCode(keyCode);
        const regex = /^[0-9]+$/;
        if (!regex.test(char)) {
          e.preventDefault();
        }
      };






    function formatPhoneExt(ext) {
        let extn = ext ? ext.replace(/[^0-9+]/, "") : '';
        return extn
    }

    function formatNameExt(ext) {
        let extnn = ext ? ext.replace(/[^a-zA-Z]/g, "") : '';
        return extnn
    }


    const userID = localStorage.getItem('client');
    const users = JSON.parse(userID);
    const userIds = users?.user._id;


    const userStatus = (userIds) => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${userIds}`)
            .then((response) => {
                let users = response.data.user?.status
                if (users === false) {
                    window.localStorage.removeItem('client');
                    navigate('/admin');
                    toast.error("Your account has been deactivated");
                }
            })
            .catch((error) => console.error(`Error: ${error}`));
    }

    useEffect(() => {
        userStatus(userIds);
    }, [])

    const useTouchedFields = () => {
        const [touched, setTouched] = useState({});

        const handleFieldBlur = (fieldName) => {
            setTouched({ ...touched, [fieldName]: true });
        };

        const isFieldTouched = (fieldName) => !!touched[fieldName];

        return { handleFieldBlur, isFieldTouched };
    };

    // Usage:
    const { handleFieldBlur, isFieldTouched } = useTouchedFields();



    return isLoading === true ? (
        <div className="d-flex justify-content-center mt-5 pt-5">
            <Bars
                height="80"
                width="100%"
                margin='auto'
                color="var(--newMainBlue)"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    ) : (
        <div className="p-3 p-lg-4 p-xl-5">
            <Container fluid className="bg-white p-4 rounded shadow">
                <Row className="align-items-center">
                    <Col md={5}>
                        <div>
                            <Link
                                to="/admin/users-management"
                                className="p-0 btn btnOrangeTransparent fw-medium"
                                variant="white"
                            >
                                <span className="me-2 fw-semibold">
                                    {/* <img
                                        src="/assets/images/back-org.png"
                                        className="img-fluid"
                                        alt=""
                                    /> */}
                                    <MdOutlineKeyboardBackspace  className="fs-4" style={{color: 'var(--newOrange)'}}/>
                                </span>
                                <span>Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col md={7}></Col>
                    <Col md={12} className="mt-4">
                        <h1 className="font20 mb-4 text-dark">
                            Users Management
                        </h1>
                        <Formik
                            enableReinitialize={true}
                            initialValues={formikInitialValue}
                            validationSchema={formSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                //setStillSubmitting(true);
                                setWait(true);
                                setTimeout(() => {

                                    handleSubmitForm(values);
                                    setSubmitting(false);
                                }, 400);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <Form
                                    noValidate
                                    className="row fw-medium"
                                    onSubmit={handleSubmit}
                                >
                                    <Col md={6}>
                                        <Form.Control
                                            type="hidden"
                                            value={user_id}
                                            onChange={(e) =>
                                                setId(e.target.value)
                                            }
                                            placeholder="Enter Name"
                                            className="font14"
                                        />

                                        <Row className="mb-4 align-items-center">
                                            <Col lg={2} md={3}>
                                                <div className="font12 text-dark">
                                                    Name
                                                </div>
                                            </Col>
                                            <Col md={9}>


                                                <Form.Control
                                                    name="name"
                                                    className="font12"
                                                    value={nameFormat(values.name.charAt(0).toUpperCase() + values.name.slice(1))}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="text"
                                                    placeholder="Enter Name"
                                                    isValid={touched.name && !errors.name}
                                                    isInvalid={!!errors.name}
                                                    onPaste={handlePaste}
                                                />
                                                {errors.name && touched.name ? (
                                                    <div className="text-danger font12">
                                                        {errors.name}
                                                    </div>
                                                ) : null}






                                            </Col>
                                        </Row>

                                        <Row className="mb-4 align-items-center">
                                            <Col md={3} lg={2}>
                                                <div className="font12 text-dark">
                                                    Email
                                                </div>
                                            </Col>
                                            <Col md={9}>
                                                {user_id ? (
                                                    <Form.Control
                                                        className="font12"
                                                        type="email"
                                                        value={email}
                                                        onChange={(e) =>
                                                            setEmail(
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Enter Email Address"
                                                        disabled
                                                    />
                                                ) : (
                                                    <Form.Control
                                                        style={{ border: error ? "1px solid red" : "" }}
                                                        className="font12"
                                                        type="email"
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder="Enter Email Address"
                                                        isValid={touched.email && !errors.email && !sameEmail && !error}
                                                        isInvalid={!!errors.email || sameEmail}
                                                        onKeyPress={(e) => keyDown(e)}
                                                        onPaste={(e) => checkWhitespace(e)}
                                                    />

                                                )}
                                                {errors.email && touched.email ? (
                                                    <div className="text-danger font12">{errors.email}</div>
                                                ) : null}
                                                {error === true ? <div className="text-danger font12 mt-1">Email Already Exists</div> : ""}
                                            </Col>







                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <Row className="mb-4 align-items-center">
                                            <Col md={3} lg={2}>
                                                <div className="font12 text-dark">
                                                    Phone
                                                </div>
                                            </Col>
                                            <Col md={9} >
                          <div className="d-flex align-items-center">
                            <div className="setect_code">
                              <Select
                                className={`font12 form-select${
                                  values.code.length > 10 ? "sdd" : "jk"
                                }`}
                                type="text"
                                name="code"
                                value={phoneCode.find(
                                  (option) => option.value === values.code
                                )}
                                onChange={(selectedOption) =>
                                  handleChange({
                                    target: {
                                      name: "code",
                                      value: selectedOption.value,
                                    },
                                  })
                                }
                                onBlur={handleBlur}
                                options={phoneCode}
                                isSearchable
                                placeholder="Code"
                              />
                            </div>
                           
                            &nbsp;
                                                    {values.code === '' ?
                                                        <Form.Control

                                                            name="phone"
                                                            maxLength={14}
                                                            value={phoneFormat(values.phone)}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="Enter Phone"
                                                            className="font14"
                                                            isValid={touched.phone && !errors.phone}
                                                            isInvalid={!!errors.phone}
                                                            onKeyPress={handleKeyPress}
                                                            disabled
                                                        />
                                                        :

                                                        <Form.Control
                                                            name="phone"
                                                            maxLength={14}
                                                            value={phoneFormat(values.phone)}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isValid={touched.phone && !errorPhone}
                                                            isInvalid={errorPhone}
                                                            placeholder="Enter Phone"
                                                            className="font14"
                                                         
                                                            onKeyPress={handleKeyPress}
                                                            onPaste={(e) => {
                                                                e.preventDefault();
                                                                const pastedText = e.clipboardData.getData('text/plain');
                                                                const formattedPhoneNumber = phoneFormat(pastedText);
                                                                handleChange({ target: { name: 'phone', value: formattedPhoneNumber } });
                                                            }}
                                                            required
                                                        />

                                                    }




                                                </div>
                                                {(phoneError && touched.phone) || (errors.phoneExt && touched.phoneExt) ? (
                                                    <div className="text-danger font12">{errors.phone || errors.phoneExt}</div>
                                                ) : null}
                                            </Col>
                                        </Row>
                                        <Row className="mb-4 align-items-center">
                                            <Col md={3} lg={2}>
                                                <div className="font12 text-dark">
                                                    Type of User
                                                </div>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Select
                                                    type="text"
                                                    name="role"
                                                    value={values.role}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="font14"
                                                    isValid={touched.role && !errors.role}
                                                    isInvalid={!!errors.role}
                                                >
                                                    <option value="" disabled>
                                                        Select Role
                                                    </option>
                                                    <option value="Admin">
                                                        Admin
                                                    </option>
                                                    <option value="Internal Manager">
                                                        Internal Manager
                                                    </option>
                                                    <option value="Product Manager">
                                                        Product Manager
                                                    </option>
                                                    <option value="Supplier Manager">
                                                        Supplier Manager
                                                    </option>
                                                    <option value="Commercial Manager">
                                                        Commercial Manager
                                                    </option>
                                                    <option value="Accountant Manager">
                                                        Accountant Manager
                                                    </option>
                                                    <option value="Customer Service">
                                                        Customer Service
                                                    </option>
                                                </Form.Select>
                                                {errors.role && touched.role ? (
                                                    <div className="text-danger font12">{errors.role}</div>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={12}>
                                        <div className="d-flex align-items-center mt-4">
                                            {wait ? <Button
                                                variant="primary"
                                                className="fullRounded px-4 py-2 font12 me-4 shadowPrimary"
                                                style={{
                                                    backgroundColor: 'var(--newOrange)',
                                                    borderColor: 'var(--newOrange)',
                                                }}
                                            >
                                                {id ? 'Save Changes' : 'Create User'}
                                            </Button> :
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                    className="fullRounded newCustomButton px-4 py-2 font12 me-4 shadowPrimary"
                                                    style={{
                                                        backgroundColor: 'var(--newOrange)',
                                                        borderColor: 'var(--newOrange)',
                                                    }}
                                                >
                                                    {id ? 'Save Changes' : 'Create User'}
                                                </Button>}
                                        </div>
                                    </Col>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
            {/* <ToastContainer position="top-center" /> */}
        </div>
    );
};

export default UsersManagementDetails;
