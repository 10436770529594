import React , {useState,useEffect} from 'react'
import BtnArea from './elements/BtnArea'
import SiteHeader from './elements/SiteHeader'
import ProductSlider from './ProductSlider'
import { Button } from 'react-bootstrap'
import ModalOpen from './elements/CategoryModalOpen'
import axios from 'axios'
import { useSelector } from 'react-redux';


const RedeemSec = (props) => {


    const nameDomain = ( window.location.host.split('.')); 
const [videoUrl,setVideoUrl] = useState("");
const [primaryColor,setPrimaryColor] = useState("");
const [secondaryColor,setSecondaryColor] = useState("")
const [checkColor,setCheckColor] = useState(true);
const [isVideo,setIsVideo] = useState(true);
const [btnColor,setBtnColor] = useState('');
const [btnText,setBtnText] = useState('');
const [homeBannerColor,setHomeBannerColor] = useState();
const [homeBannerColor1,setHomeBannerColor1] = useState();
const [isLoading,setIsLoading] = useState(true);
const [giftcards,setGiftcards] = useState("giftcards");
const [reedemLoding,setReedemLoding] = useState(true)

// const navigate = useNavigate();
// const categoryName = useSelector((state)=>state.sendCategorie_reducer);
const [search,setSearch] = useState('');
const backgroundStyle = {
  backgroundImage: `url(${videoUrl})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: `${100}% ${100}%`,
  boxShadow: `inset 0 0 0 2000px rgba(0,0,0,0.5)`,
  // height: '',
};
const backgroundColor = {
  backgroundImage:`linear-gradient(93.59deg, ${homeBannerColor} -0.51%, ${homeBannerColor1} 105.82%)`
}

const cmsData = useSelector((state) => state.cmsReducer);

useEffect(() => {
  if (cmsData?.data) {
    // Assuming you have received data from the API in the Redux store
    const data = cmsData.data?.cms;

    setGiftcards("giftcards");

    if (data.default_ncentiva === false) {
      setVideoUrl(data.home_image);
      setCheckColor(data.home_image_color);
      setPrimaryColor(data.primary_color);
      setHomeBannerColor(data.homeBannerColorCombination);
      setHomeBannerColor1(data.homeBannerColorCombination1);
      setSecondaryColor(data.secondary_color);
      setBtnColor(data.btn_color);
      setBtnText(data.btn_text);
    } else {
      setCheckColor(true);
      setBtnColor('#3D05B6');
      setBtnText('white');
      setHomeBannerColor("#FF7020");
      setHomeBannerColor1("#F5C400");
      setPrimaryColor("#FF7020");
      setSecondaryColor("#F5C400");
    }

    setIsLoading(false);
  }
}, [cmsData]);

useEffect(()=>{
  setTimeout(() => {
    setReedemLoding(false)
  }, 2000);
},[])




  return (
    <>
       { isLoading ? ""
       : 
        <section className="redeem bg-light py-5">
            <div className="container-fluid custom-gap">
                <div className="mb-2">
                    <SiteHeader title="Redeem all your favorite things"/>
                </div>    
                <ProductSlider/>
                {/* <BtnArea title="View all categories"/> */}
                <div className="text-center mt-5">

                  {isLoading=== false?
                <Button
                      variant="primary"
                      className="fullRounded fw-bold font12"

                      style={{backgroundColor:btnColor,color:btnText,border:`1px solid ${btnColor}`}}

                      >
                      <ModalOpen home={"true"} title={"View all categories"} cssStyle={'button'} num={0} cmss={true} data={props?.categories} btnColor={btnColor} btnText={btnText} />
                      </Button>
                        :""}
                </div>
            </div>
        </section>
}
        </>
  )
}

export default RedeemSec
