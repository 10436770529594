import React from 'react'

const Maintanance = () => {
  return (
        <div className='d-flex flex-column position-relative align-items-center justify-content-center pt-4' style={{"height":'100vh', paddingBottom: '100px'}}>
           
           <img src="../assets/images/logo_line.png" width='100%' alt="" className="mb-3" style={{maxWidth: '250px'}} />
          
           <img src="../assets/images/mid-img.png" width='90%' alt="" style={{maxWidth: '450px'}} />
    
           <img src="../assets/images/down-bar.png" alt=""  className='position-absolute bottom-0 w-100'/>
        </div>
  )
}

export default Maintanance