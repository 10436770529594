// import React from 'react'
import React, { useRef } from "react";
// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SlArrowRight,SlArrowLeft } from "react-icons/sl";
// import axios from "axios";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import styles from './favourite.module.css';
// import { Bars } from "react-loader-spinner";
// import required modules
// import { Autoplay, Navigation } from "swiper";
// import BrandBlock from "./elements/BrandBlock";

// import "./styles.css";
import { Link } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { ClearhandleCategories } from "../Redux/SendCategories/action";
import { globalSearch } from "../Redux/GlobalSearch/action";

const favorites = [
  {
    src: "assets/images/logos/xbox.png",
    percent: 5
  },
  {
    src: "assets/images/logos/the-home-depot.png",
    percent: 2
  },
  {
    src: "assets/images/logos/nike.png",
    percent: 10
  },
  {
    src: "assets/images/logos/ebay.png",
    percent: 3
  },
  {
    src: "assets/images/logos/american-eagle-outfitters.png",
    percent: 4
  },
];

const FavouriteSlider = (props) => {
  const sliderRef = useRef(null);
  const { couponData, giveClass } = props;
  const dispatch = useDispatch();

  // if(couponData.length < 7){
  //   couponData.push(...couponData);
  //  } 
  let dataArray = [];
  // if (couponData.length < 6) {
    for (var i = 0; i < 7; i++) {
      dataArray.push(couponData[i % couponData.length]);
    }
  // }
  const allDefined = dataArray.some((element) => typeof element === 'undefined')

  //   const [couponData, setCouponData] = useState([]);
  //   const customer = JSON.parse(window.localStorage.getItem('customer'));
  //   const [giveClass,setGiveClass] = useState(false);
  //   const navigate = useNavigate();
  //   var partner_id;
  // if (customer) {
  //  // var cust_id = customer.customer._id;
  //   partner_id = customer.customer.business_partner_id

  //   // name = customer.customer.first_name +" "+ customer.customer.last_name;
  // }
  // let token;
  // useEffect(()=>{
  //   document.title = "ncentiva || Products";
  //    getCouponsData();
 
  // },[])
  // const getCouponsData = () => {
  //     if (customer) {
  //         token = customer.token;
  //       } else {
  //         token = null;
  //       }
  //     // setIsLoading(true);
  //      axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/allcouponsforhome?partner_id=${partner_id}`, {
  //         headers: {
  //           'Authorization': `Bearer ${token}`
  //         }
  //       })
  //          .then((response) => {
  //             if(response.data.message === "Please Login"){
  //                 localStorage.removeItem('customer');
  //                 localStorage.removeItem('listItem');
  //                navigate('/');
  //               toast("Session Expired Please Login Again")
  //               // window.location.reload();
  //               }
  //              const allcoupons = response.data.coupons;
  //        //setIsLoading(false);
  //        setGiveClass(false);

  //             if(allcoupons.length<3){
  //                 setGiveClass(true);
  //             }
  //              setCouponData(allcoupons);
  //          })
  //          .catch(error => console.error(`Error: ${error}`));
  //      }


  const handleClick = (data) => {
    dispatch(ClearhandleCategories());
    dispatch(globalSearch(data.brand_name))
  }

  const CustomNextArrow = (props) => (
    <button {...props} className="carousel-button next-button">
      <SlArrowRight />
    </button>
 );

 const CustomPrevArrow = (props) => (
    <button {...props} className="carousel-button prev-button">
     <SlArrowLeft />
    </button>
 );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />,
  };


  return (
    <>


      <Slider {...settings} ref={sliderRef} className="mb-5">

        {couponData.length < 7 ? (allDefined === true ? "" : dataArray?.map((favorite, i) =>
          <div key={i} className={`carousel_slide ${giveClass ? styles.slides1 : styles.slides}`} >
            {/* <BrandBlock src={favorite.img_path} discount={favorite.discount} bonusType={favorite.bonusType} bonusFee={favorite.bonusFee}/> */}
            <Link onClick={() => handleClick(favorite)} to="/giftcards" className="brand-block">
              <div className="brand-image">
                <img
                  src={favorite?.img_path}
                  alt={favorite?.brand_name}
                  className="img-fluid"
                  width="100px"
                  height="20px"
                />
              </div>
              <div className="brand-footer">
                <span>
                  {(!favorite?.discount || !favorite?.bonusFee || !favorite?.bonusType) ? '0.00 %' :
                    (favorite?.bonusType === 'Percentage') ?
                      `${(favorite?.discount * (favorite?.bonusFee / 100)).toFixed(2)} %` :
                      `${(Number(favorite?.discount) - Number(favorite?.bonusFee)).toFixed(2)} %`

                  }
                </span>
              </div>
            </Link>
          </div>
        )) :
          (
            couponData.map((favorite, i) =>
              <div key={i} className={` carousel_slide ${giveClass ? styles.slides1 : styles.slides} `}>
                {/* <BrandBlock src={favorite.img_path} discount={favorite.discount} bonusType={favorite.bonusType} bonusFee={favorite.bonusFee}/> */}
                <Link onClick={() => handleClick(favorite)} to="/giftcards" className="brand-block">
                  <div className="brand-image">
                    <img
                      src={favorite?.img_path}
                      alt={favorite?.brand_name}
                      className="img-fluid"
                      width="100px"
                      height="20px"
                    />
                  </div>
                  <div className="brand-footer">
                    <span>
                      {(!favorite?.discount || !favorite?.bonusFee || !favorite?.bonusType) ? '0.00 %' :
                        (favorite?.bonusType === 'Percentage') ?
                          `${(favorite?.discount * (favorite?.bonusFee / 100)).toFixed(2)} %` :
                          `${(Number(favorite?.discount) - Number(favorite?.bonusFee)).toFixed(2)} %`

                      }
                    </span>
                  </div>
                </Link>
              </div>
            )
          )
        }


      </Slider>
    </>
  )
}

export default FavouriteSlider
