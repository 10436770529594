import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { FaSearch } from 'react-icons/fa'
//import FilterTypeOne from './elements/FilterTypeOne'
//import FilterTypeTwo from './elements/FiterTypeTwo'
import axios from "axios";
const ProductFilterArea = (props) => {
  const options = [
    // {label: "view: all", value: "view: all" },
    { value: "Price Low - High", label: "Price Low - High" },
    { value: "Price High - Low", label: "Price High - Low" },
    { value: "All Brands", label: "All Brands" },
    { value: "Most value", label: "Most value" },
    { value: "Order A-Z", label: "Order A-Z" },
    { value: "Order Z-A", label: "Order Z-A" }
  ]
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'var(--darkblue)' : 'var(--color)',
      fontSize: 14,
      padding: 10,
      marginBottom: 0,
      backgroundColor: state.isSelected ? 'rgba(215, 223, 233, 0.34);' : '#fff'
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 'auto',
      display: 'flex',
      border: '1px solid #ced4da',
      borderRadius: '.25rem'
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = 'var(--color)';
      const fontSize = '14px';
      const transition = 'opacity 300ms';


      return { ...provided, opacity, transition, color, fontSize };
    }
  }


  const handleeChange = (selectedOption) => {
  }




  const [getbrand, setGetBrand] = useState([]);
  const getbrands = () => {

    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getallbrands`)
      .then((response) => {


        setGetBrand(response.data.brands);

      })
      .catch((error) => console.error(`Error: ${error}`));
  };
  useEffect(() => {

    getbrands();

  }, []);
  const handleChange = (selectedOption) => {
  }

  return (
    <section className="product-filters py-3 border-bottom">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-7">
            <div className="filters-left mb-4 mb-lg-0">
              <div className="search-content">
                <div className="search-bar">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for a brand, product or service"
                  />
                  {/* <i className="far fa-search search-icon" /> */}
                  <FaSearch className="search-icon" />
                </div>
                <button type="button"
                  // style={{backgroundColor:`${props?.btnColor}80`,color:props?.btnText,border:`1px solid transparent`}}
                  style={props.defaultNcentiva
                    ? { backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent` }
                    : { backgroundColor: `${props?.btnColor}`, color: props?.btnText, border: `1px solid ${props?.btnColor}` }
                  }
                  className="btn btn-main px-4">
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5">
            <div className="filter-right">
              <div className="row">
                <div className="col-md-6">
                  <div className="custom-select mb-2 mb-md-0">
                    <Select
                      styles={customStyles}
                      onChange={handleChange}
                      options={options}
                      defaultValue={{ value: "View: all", label: "View: all" }}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="custom-select">
                    <Select
                      styles={customStyles}
                      onChange={handleChange}
                      options={getbrand}
                      defaultValue={{ value: "Filter by:All", label: "Filter by:All" }}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default ProductFilterArea
