// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 576px){

}

@media only screen and (max-width: 993px){
  .customers_break__5qKLA{
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 994px){
    .customers_break__5qKLA{
        /* border:1px solid red; */
        min-width: 450px;
        justify-content: end;
    }
}

@media only screen and (min-width: 1069px){
    .customers_break__5qKLA{
        /* border:1px solid black; */
        justify-content: end;
      }
}

/*==== Min & Max Width media query ====*/
@media only screen and (min-width: 767px) and (max-width: 1200px){
}

/*==== Max Width media query ====*/

@media only screen and (max-width: 1200px){

}`, "",{"version":3,"sources":["webpack://./src/components/customers.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;AACF;;AAEA;IACI;QACI,0BAA0B;QAC1B,gBAAgB;QAChB,oBAAoB;IACxB;AACJ;;AAEA;IACI;QACI,4BAA4B;QAC5B,oBAAoB;MACtB;AACN;;AAEA,wCAAwC;AACxC;AACA;;AAEA,kCAAkC;;AAElC;;AAEA","sourcesContent":["@media only screen and (min-width: 576px){\n\n}\n\n@media only screen and (max-width: 993px){\n  .break{\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n@media only screen and (min-width: 994px){\n    .break{\n        /* border:1px solid red; */\n        min-width: 450px;\n        justify-content: end;\n    }\n}\n\n@media only screen and (min-width: 1069px){\n    .break{\n        /* border:1px solid black; */\n        justify-content: end;\n      }\n}\n\n/*==== Min & Max Width media query ====*/\n@media only screen and (min-width: 767px) and (max-width: 1200px){\n}\n\n/*==== Max Width media query ====*/\n\n@media only screen and (max-width: 1200px){\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"break": `customers_break__5qKLA`
};
export default ___CSS_LOADER_EXPORT___;
