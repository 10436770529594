import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row, Form, Table } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Bars } from "react-loader-spinner";
import ModalOpen from '../components/elements/BusinessPartnercategories';
import Modal from "react-bootstrap/Modal";
import BuisnessForm from "../components/BuisnessForm";
import { useDispatch, useSelector } from 'react-redux';
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { BiPlusMedical } from "react-icons/bi";
import { ClearhandleCategories } from "../Redux/SendCategories/action";
import "../../src/App.scss";
import { FaPlus, FaTrash, FaSyncAlt } from 'react-icons/fa';
import { getNewToken } from "../config/cookie.config";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const BusinessPartnerBrands = () => {

    const { id } = useParams();
    const user = JSON.parse(window.localStorage.getItem("client"));
    const dispatch = useDispatch();

    let dataa;
    let userId;
    let navigate = useNavigate();

    useEffect(() => {
        setBtnColor('#3D05B6');
        setBtnText('white');
    }, []);

    if (user) {
        userId = user.user._id;
    } else {
        userId = null;
        navigate("/admin");
    }

    const showCategory = useSelector(state => state.sendCategorie_reducer.showCategory);
    const categoryName = useSelector((state) => state.sendCategorie_reducer.category);

    const [business_name, setBusinessName] = useState("");
    const [partner_id, setPartner_id] = useState(id);
    const [promotedCheck, setPromotedCheck] = useState(false);
    const [standoutCheck, setStandoutCheck] = useState(false);
    const [btnColor, setBtnColor] = useState('');
    const [btnText, setBtnText] = useState('');
    const [catergoryOption, setCategoryOption] = useState(true);
    const [brandData, setBrandData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [brandLoading, setBrandLoading] = useState(true);
    const [getbrand, setGetBrand] = useState([]);
    const [formValues, setFormValues] = useState([{ brand: "", bonus: "", internalPercentage: "", bonusType: "", bonusDiscount: "", id: partner_id, thresholdValue: "", thresholdQuantity: "" }])
    const [brandId, setBrandId] = useState("");
    const [brand, setBrand] = useState("");
    const [bonus, setBonus] = useState("");
    const [discount, setDiscount] = useState("");
    const [bonusType, setBonusType] = useState("");
    const [bonusValue, setBonusValue] = useState("");
    const [internalPercentage, setInternalPercentage] = useState("");
    const [thresholdValue, setThresholdValue] = useState("");
    const [thresholdQuantity, setThresholdQuantity] = useState("");
    const [customerData, setCustomerData] = useState([]);
    const [customerData1, setCustomerData1] = useState([]);
    const [delay, setDelay] = useState(false);
    const [length, setLength] = useState();
    const [deleteDelay, setDeleteDelay] = useState(false);
    const [noData, setNoData] = useState(false);
    const [searching, setSearching] = useState('');
    const [sorts, setSorts] = useState("ASC");
    const [order, setOrder] = useState("ASC");
    const [categoryss, setCategoryss] = useState("ASC")
    const [brandss, setBrandss] = useState("ASC")
    const [bonusDiscount, setBonusDiscount] = useState("ASC")
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [showPage, setShowPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [clickBrand, setClickBrand] = useState(false);
    const [showCsvModal, setshowCsvModal] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const [filename, setFilename] = useState('');
    const [previewModal, setPreviewModal] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [previewData, setPreviewData] = useState();
    const [isApi, setIsApi] = useState(false);
    const [categories, setCategories] = useState([]);
    const [businessCategories, setBusinessCategories] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [newBrands, setNewBrands] = useState([]);
    const [deletedBrands, setDeletedBrands] = useState([]);
    const [updatedBrands, setUpdatedBrands] = useState([]);
    const [openDrawerId, setOpenDrawerId] = useState(null);

    const [newBrandsOpen, setNewBrandsOpen] = useState(true);
    const [deletedBrandsOpen, setDeletedBrandsOpen] = useState(true);
    const [updatedBrandsOpen, setUpdatedBrandsOpen] = useState(true);
    const [addedBusinessType, setAddedBusinessType] =  useState("");
    const [businessPartnerType, setBusinessPartnerType] = useState("");

    const toggleNewBrands = () => {
        setNewBrandsOpen(!newBrandsOpen);
    };

    const toggleDeletedBrands = () => {
        setDeletedBrandsOpen(!deletedBrandsOpen);
    };

    const toggleUpdatedBrands = () => {
        setUpdatedBrandsOpen(!updatedBrandsOpen);
    };

    const sorting = (order) => {
        if (order === "DSC") {
            setSorts("dscName");
            setOrder("DSC");
        } else {
            setSorts("ascName");
            setOrder("ASC");
        }
    };

    const categorySorting = (categoryss) => {
        if (categoryss === "DSC") {
            setSorts('dscCategory')
            setCategoryss('DSC')
        } else {
            setSorts('ascCategory')
            setCategoryss('ASC')
        }
    }

    const bonusSorting = (brandss) => {
        if (brandss === "DSC") {
            setSorts('dscBonus')
            setBrandss('DSC')
        } else {
            setSorts('ascBonus')
            setBrandss('ASC')
        }
    }

    const bonusDiscountSorting = (discount) => {
        if (discount === "DSC") {
            setSorts('dscBonusDiscount')
            setBonusDiscount('DSC')
        } else {
            setSorts('ascBonusDiscount')
            setBonusDiscount('ASC')
        }
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/get-categories`)
            .then((res) => {
                const unsortedCategories = res.data.brands[0].categories;
                const sortedCategories = unsortedCategories.sort();
                setCategories(sortedCategories);
            }).catch((err) => {
                console.log(err);
            })
    }, []);

    let handleBonusChangeForm = (e, index) => {
        setBonusValue(e.target.value)
        let newFormValues = [...formValues];
        newFormValues[index][e.target.name] = e.target.value;
        if (e.target.name === 'bonusPercentage') {
            newFormValues[index]['bonusDiscount'] = e.target.value;
        }
        setFormValues(newFormValues);
    }
    let handleChangeForm = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }
    let addFormFields = () => {
        setFormValues([...formValues, { brand: "", bonus: "", bonusType: "", internalPercentage: "", bonusDiscount: "", id: partner_id }])
    }
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }
    let handleSubmit = async (event) => {
        setDelay(true);
        setBrandLoading(true);
        setTimeout(() => {
            setDelay(false);
        }, 1800)
        var token;
        const user = JSON.parse(window.localStorage.getItem("client"));
        if (user) {
            token = user.token;
        } else {
            token = null;
        }
        event.preventDefault();
        for (let i = 0; i < formValues.length; i++) {
            if (addedBusinessType === "Discount") {
                let newFormValues = [...formValues];
                newFormValues[i]['bonusType'] = "Discount percentage";
                setFormValues(newFormValues);
            }
            if (formValues[i].bonus === "" || formValues[i].bonusType === "" || formValues[i].bonusDiscount === "") {
                var ip = 0;
                toast.warning("All Fields  Required");
                setIsLoading(false)
                return false;
            }
            else if (formValues[i].bonus < 50) {
                toast.warning("Bonus cant be less than 50%");
                setIsLoading(false)
                setBrandLoading(false)
                return false;
            } else if (formValues[i].bonus > 100) {
                toast.warning("Maximum Bonus Must Be 100%");
                setIsLoading(false)
                setBrandLoading(false)
                return false;
            } else if (formValues[i].bonusDiscount > 100) {
                toast.warning("Maximum Discount Must Be 100%");
                setIsLoading(false)
                setBrandLoading(false)
                return false;
            }
            else {
                ip = 100 - (+formValues[i].bonus);
                formValues[i].internalPercentage = ip;
            }
        }
        if (formValues[0].bonus) {
            const { data } = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/bpbonus`,
                {
                    formValues

                }, {
                headers: {
                    'Authorization': `Bearer ${getNewToken()}`
                }
            },
            );
            toast.success(data.message)
            fetchData();
            getdiscountbrandsstatus();
            if (data.message === "Brand Added Successfully") {
                setFormValues([{ brand: "", bonus: "", internalPercentage: "", bonusType: "", bonusDiscount: "", id: partner_id, thresholdValue: "", thresholdQuantity: "" }])
                setBonus("")
                setBonusValue("")
                setBrand("All Brands")

            }
        }
    }

    useEffect(() => {
        getdiscountbrandsstatus(pageNumber);
    }, [sorts, dataa])

    let items = [];
    let leftSide = pageNumber - 2;

    if (leftSide <= 0) leftSide = 0;
    let rightSide = pageNumber + 2;

    if (rightSide > numberOfPages) rightSide = numberOfPages;
    for (let number = leftSide + 1; number <= rightSide; number++) {
        items.push(
            <li
                key={number}
                className={number === pageNumber ? "active" : ""}
                onClick={() => {
                    setPageNumber(number);
                    setShowPage(number);
                }}
            >
                {number}
            </li>
        );
    }

    const pagenumberSearch = (e) => {
        var pages = parseInt(e);
        var page = pages;
        if (pages < 1) {
            setPageNumber(1);
            setShowPage(1);
        } else if (pages > numberOfPages) {
            setPageNumber(numberOfPages);
            setShowPage(numberOfPages);
        } else if (pages >= 1 && pages <= numberOfPages) {
            setPageNumber(page);
            setShowPage(pages);
        }
        else {
            setPageNumber(1);
            setShowPage(pages);
        }
    };

    const nextPage = () => {
        if (pageNumber < numberOfPages) {
            setPageNumber(pageNumber + 1);
            setShowPage(pageNumber + 1);
        }
    }

    const prevPage = () => {
        if (pageNumber > 0) {
            setPageNumber(pageNumber - 1)
            setShowPage(pageNumber - 1);
        }
    }

    useEffect(() => {
        if (id) {
            getAllCustomers();
            getbrands();
        }
    }, [sorts]);

    const getAllCustomers = async (loadingfalse) => {
        var token;
        const user = JSON.parse(window.localStorage.getItem("client"));
        if (user) {
            token = user.token;
        } else {
            token = null;
        }
        console.log(loadingfalse)
        if (loadingfalse === 'loadingfalse' && loadingfalse !== undefined) {
            setIsLoading(false);
            setIsApi(false);
        } else {

            setIsLoading(true);
            setIsApi(true);
        }
        axios
            .get(` ${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getbusinesspartner/${id}`, {
                headers: {
                    'Authorization': `Bearer ${getNewToken()}`
                }
            })
            .then((response) => {
                setBusinessCategories(response?.data?.categories);
                setBrandData(response.data.businessPartnes.brands);
                setBusinessName(response.data.businessPartnes.business_name);
                setAddedBusinessType(response?.data?.businessPartnes?.business_type_option)
                setBusinessPartnerType(response?.data?.businessPartnes?.business_partner_type)
                setIsLoading(false);
                setTimeout(() => {
                    setIsApi(false);
                }, 100);
            })
            .catch((error) => { console.error(`Error: ${error}`); setIsLoading(false); setIsApi(false) });
    };


  const handleCheckboxChange = (index) => {
    const updatedCategories = [...businessCategories];
    updatedCategories[index].checked = !updatedCategories[index].checked;
    setBusinessCategories(updatedCategories);
  };

  const handleSave = () => {
    const checkedCategories = businessCategories.filter((item) => item.checked);
    if (checkedCategories.length > 8) {
        getAllCustomers('loadingfalse');
        return toast.warn("Please select maximum 8 categories");
    }
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/add-categories`,
        {
          id,
          categories: businessCategories,
        }
      )
      .then((response) => {
        toast.success(response.data.message ?? "Categories updated successfully");
      })
      .catch((error) => {
        toast.error("Error updating categories");
        console.error("There was an error updating the categories:", error);
      });
  };

    
    const getbrands = () => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getallbrands`)
            .then((response) => {
                setGetBrand(response.data.brands);
            })
            .catch((error) => console.error(`Error: ${error}`));
    };
    var token;
    const deleteBp = (bid) => {
        setDeleteDelay(true);
        setTimeout(() => {
            setDeleteDelay(false);
        }, 1500)
        const user = JSON.parse(window.localStorage.getItem("client"));
        if (user) {
            token = user.token;
        } else {
            token = null;
        }
        axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/branddelete/${id}/${bid}`, {
            headers: {
                'Authorization': `Bearer ${getNewToken()}`
            }
        })
            .then((response) => {
                toast.warning(response.data.message);
                getdiscountbrandsstatus();
            })
            .catch((error) => console.error(`Error: ${error}`));
    };

    const getdiscountbrandsstatus = (pageNumber, check) => {
        if (id) {
            const user = JSON.parse(window.localStorage.getItem('client'));
            if (user) {
                token = user.token;
            } else {
                token = null;
            }
            if (dataa || categoryName.length > 0) {
                if (categoryName.length > 0) {
                    dataa = "";
                }
                axios
                    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getbpbrandstatus/${id}?sort=${sorts}&search=${dataa}&category=${categoryName}`, {
                        headers: {
                            'Authorization': `Bearer ${getNewToken()}`
                        }
                    })
                    .then((response) => {
                        if (response.data.message == "Please Login") {
                            localStorage.removeItem('client');
                            navigate('/admin');
                            toast.error("Session Expired Please Login Again")
                        }
                        setSearching('')
                        if (response.data.brands.length >= 1) {
                            setNoData(false);
                        }
                        setBrandLoading(false);

                        let arr = response.data.brands;
                        arr.sort((a, b) => {
                            return (a.brandName - b.brandName)
                        });
                        setCustomerData(arr);
                        setLength(response.data.brands.length);
                        const totalPages = response.data.totalPages;
                        setNumberOfPages(totalPages);

                    })
                    .catch((error) => console.error(`Error: ${error}`));
            } else {
                axios
                    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getbpbrandstatus/${id}?sort=${sorts}`, {
                        headers: {
                            'Authorization': `Bearer ${getNewToken()}`
                        }
                    })
                    .then((response) => {
                        setBrandLoading(false);
                        if (response.data.message == "Please Login") {
                            localStorage.removeItem('client');
                            navigate('/admin');
                            toast.error("Session Expired Please Login Again")
                        }
                        if (response.data.brands.length >= 1) {
                            setNoData(false);
                        }
                        let arr = response.data.brands;
                        setCustomerData(arr);
                        if (check !== "check") {
                            setCustomerData1(arr);
                        }
                        setLength(response.data.brands.length);
                        const totalPages = response.data.totalPages;
                        setNumberOfPages(totalPages);

                    })
                    .catch((error) => console.error(`Error: ${error}`));
            }
        }
    };

    function handleStatus(status, bid) {
        var ip = 0;
        const user = JSON.parse(window.localStorage.getItem("client"));
        if (user) {
            token = user.token;
        } else {
            token = null;
        }
        if (status === true) {
            if (bonus < 50) {
                toast.warning("Bonus cant be less than 50%")
                return;
            } else if (bonus > 100) {
                toast.warning("Bonus Must Be Maximum 100%")
                return;
            }
            else {
                ip = 100 - (+bonus);
                setInternalPercentage(ip);
            }
            axios
                .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/bp/statusdata/${id}/${bid}`, {
                    status,
                    brandId,
                    bonus,
                    bonusType,
                    discount,
                    internalPercentage: ip,
                    standoutCheck,
                    promotedCheck,
                    thresholdValue,
                    thresholdQuantity

                }, {
                    headers: {
                        'Authorization': `Bearer ${getNewToken()}`
                    }
                },)
                .then((response) => {
                    if (response.data.message === "Bonus Must Be Maximum 100%" || response.data.message === "Maximum Bonus Upto Discount Value" || response.data.message === "Bonus Must Be Greater Than 1") {
                        toast.warning(response.data.message);
                        return false;
                    } else {
                        toast.success(response.data.message);
                        setBonus(response.data.brands.bonus);
                        setDiscount(response.data.brands.discount)
                        setBonusType(response.data.brands.bonusType);
                        setInternalPercentage(response.data.brands.internal_rebate);
                        setBrandId(response.data.brands.couponId);
                        setStandoutCheck(response.data.brands?.stand_out);
                        setPromotedCheck(response.data.brands?.promotion);
                        setThresholdValue(response.data.brands?.thresholdValue);
                        setThresholdQuantity(response.data.brands?.thresholdQuantity);
                        getdiscountbrandsstatus(1, "check");
                    }

                })
                .catch((error) => console.error(`Error: ${error}`));
        }
        else if (status === false) {
            axios
                .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/bp/status/${id}/${bid}`, {
                    status,
                }, {
                    headers: {
                        'Authorization': `Bearer ${getNewToken()}`
                    }
                },)
                .then((response) => {
                    if (searching.length > 0) {
                        toast.success(response.data.message);
                        const searchData = customerData.find(item => item.brandName === response.data.brands.brandName);

                        if (searchData) {
                            dataa = searchData.brandName;
                            setBonus(searchData.bonus);
                            setDiscount(searchData.discount);
                            setBonusType(searchData.bonusType);
                            setInternalPercentage(searchData.internal_rebate);
                            setBrandId(searchData.couponId);
                            getdiscountbrandsstatus();

                        }
                    } else {
                        toast.success(response.data.message);
                        setBonus(response.data.brands.bonus);
                        setDiscount(response.data.brands.discount)
                        setBonusType(response.data.brands.bonusType);
                        setInternalPercentage(response.data.brands.internal_rebate);
                        setBrandId(response.data.brands.couponId);
                        setStandoutCheck(response.data.brands.stand_out)
                        setPromotedCheck(response.data.brands.promotion)
                        setThresholdValue(response.data.brands.thresholdValue);
                        setThresholdQuantity(response.data.brands.thresholdQuantity);;
                        getdiscountbrandsstatus();
                    }
                })
                .catch((error) => console.error(`Error: ${error}`));
        }

    }

    const handleSearch = (e) => {
        setSearching(e);
        let arr = [];
        let input = e.toLowerCase();
        if (input.length >= 1) {
            customerData1?.filter((elem) => {
                if ((elem.brandName.toLowerCase().includes(input)) && input.length >= 1) {
                    arr.push(elem);
                }
            })
            if (arr?.length === 0) {
                setNoData(true);
            } else {
                setNoData(false);
            }
            setCustomerData(arr);
            const pageLength = Math.ceil(arr?.length / 10)
            setNumberOfPages(pageLength)
            setPageNumber(1);
            setShowPage(1);
        } else {
            const pageLength = Math.ceil(customerData1?.length / 10);
            setNumberOfPages(pageLength)
            setCustomerData(customerData1);
            if (customerData1.length >= 21) {
                setNoData(false);
            }
        }

        dispatch(ClearhandleCategories());
    }

    const handleDiscountChange = (e) => {
        const inputValue = e.target.value.trim();
        if (!isNaN(inputValue) && inputValue >= 0 && inputValue < 100) {
            setDiscount(inputValue);
        }
    };

    const handleClosePreviewModal = () => {
        setPreviewModal(false);
        setshowCsvModal(false);
        setLoadingCsv(false);
        setFilename('');
    };
    const handleClosehandleCsvModal = () => {
        setshowCsvModal(!showCsvModal);
        setFilename('');
        setLoadingCsv(false);
    };

    const setCustomers = (userDataInString) => {
        var userDataInJSON = [];
        var rows = userDataInString.split("\n");
        var er = rows[0].search("\r");
        if (er > 1) {
            rows[0] = rows[0].slice(0, -1);
        } else {
            rows[0] = rows[0].slice(0);
        }
        var fields = rows[0].split(",");
        if (fields[1] !== "Brand_Id") {
            toast.warning("Brand Id Is Required");
            return false;
        }
        if (fields[4] !== "Customer_Percentage") {
            toast.warning("Bonus Is Required");
            return false;
        }
        if (fields[2] !== "Bonus_Percentage") {
            toast.warning("Discount Is Required");
            return false;
        }
        if (fields[1] == "Brand_Id" && fields[4] == "Customer_Percentage" && fields[2] == "Bonus_Percentage") {
            for (var i = 1; i < rows?.length; i++) {
                var rowdata = rows[i].split(",");
                var bonusValue = parseFloat(rowdata[4]);

                if (bonusValue < 50) {
                    toast.warning("Bonus can't be less than 50%");
                    return false;
                } else if (bonusValue > 100) {
                    toast.warning("Bonus Must Be Maximum 100%")
                    return false;
                }
                if (i === 1 || i === 2) {

                    var discountValue = Number(rowdata[2]);

                    if (discountValue >= 100) {
                        toast.warning("Discount must be less than 100%");
                        return false;
                    } else if (isNaN(discountValue)) {
                        toast.warning("Discount must be in Number");
                        return false;
                    }
                }
                var flag = false;
                var singleUserObject = {};
                var ers = rows[i].search("\r");
                if (ers >= 0) {
                    rows[i] = rows[i].slice(0, -1);
                } else {
                    rows[i] = rows[i].slice(0);
                }
                var singleRow = rows[i].split(",");
                if (fields.length !== singleRow.length && singleRow.length > 1) {
                    toast.warning("There is some issue in csv file format.");
                    return false;
                }
                for (var j = 0; j < fields.length; j++) {
                    if (singleRow[j] === "") {
                        flag = true;
                    }
                    singleUserObject[fields[j]] = singleRow[j];
                }
                if (flag) {
                } else {
                    userDataInJSON.push(singleUserObject);
                }
            }

            setCsvData(userDataInJSON);
            const user = JSON.parse(window.localStorage.getItem("client"));
            var token;
            if (user) {
                token = user.token;
            } else {
                token = null;
            }
            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/brand-preview`,
                    userDataInJSON, {
                    headers: {
                        'Authorization': `Bearer ${getNewToken()}`
                    }
                },
                )
                .then((res) => {
                    if (res.data.status === "failed") {
                        toast.warning(res.data.message);
                        return false;
                    } else {
                        toast.success(res.data.message);
                        setPreviewData(res.data.brands);
                        setLoadingCsv(true)
                        setTimeout(() => {
                            setLoadingCsv(false)
                            setPreviewModal(true)
                        }, 800);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });


        } else {
            toast.warning("Invalid CSV File")
            setCsvData([]);

        }


    };
    const handleClearData = () => {
        setFilename('');
    };
    const handleSubmitCSV = async (e) => {
        var token;
        const user = JSON.parse(window.localStorage.getItem("client"));
        if (user) {
            token = user.token;
        } else {
            token = null;
        }
        setLoadingCsv(true);
        e.preventDefault();
        setDisable(true)
        setTimeout(() => {
            setDisable(false);
        }, 2000);
        if (csvData) {
            if (csvData.length === 0) {
                toast.warning("Please Upload A CSV File");
                setTimeout(() => {
                    setLoadingCsv(false)
                }, 1000);
                return false;
            }

        }
        setLoadingCsv(true)
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/addbrand/csv/${id}`,
                csvData, {
                headers: {
                    'Authorization': `Bearer ${getNewToken()}`
                }
            })
            .then((res) => {
                if (res.data.status === "failed") {
                    setTimeout(() => {
                        setLoadingCsv(false)
                        handleClosePreviewModal()
                    }, 1500);
                    toast.warning(res.data.message);
                    return false;
                }
                setPreviewModal(false)
                setTimeout(() => {
                    setLoadingCsv(false)
                    handleClosehandleCsvModal();
                    handleClearData();
                    getdiscountbrandsstatus();
                }, 1000);
                toast.success(res.data.message);

            })
            .catch((err) => {
                navigate("/admin/businesspartners");
                console.log(err);
                if (err.response.data.msg) {
                    toast.error(err.response.data.msg);
                }

            });
    };


    useEffect(() => {
        setSearching('');
        if (showCategory.length > 0) {
            const filteredData = customerData1?.filter(customer => showCategory.includes(customer.brandCategory));
            setCustomerData(filteredData);
            const pageLength = Math.ceil(filteredData.length / 10)
            setNumberOfPages(pageLength)
            setPageNumber(1);
            setShowPage(1);
        } else {
            setCustomerData(customerData1);
            const pageLength = Math.ceil(customerData1.length / 10)
            setNumberOfPages(pageLength)
            setPageNumber(1);
            setShowPage(1);
        }
    }, [clickBrand])


    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/update/coupons/${id}`);
            const data = response.data;
            setNewBrands(data.newBrands);
            setDeletedBrands(data.deletedBrands);
            setUpdatedBrands(data.updatedBrands);
            // setShowModal(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleClose = () => setShowModal(false);


    const openDrawer = (couponId) => {
        fetchData();
        setOpenDrawerId((prevId) => (prevId === couponId ? null : couponId));
        let newFormValues = [...formValues];
        newFormValues[0]['brand'] = couponId;
        setFormValues(newFormValues);
    };

    const handleRemove = async (couponId) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/remove/coupon/${id}/${couponId}`);

            if (response.status === 200) {
                toast.success(response.data.message);
                fetchData();
                getdiscountbrandsstatus();
            } else {
                toast.warn(response.data.message);
            }
        } catch (error) {
            console.error('Error removing coupon:', error);
            toast.error('Unable to remove brands');
        }
    };

    const handleUpdated = async (couponId) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/update/coupon/${id}/${couponId}`);

            if (response.status === 200) {
                toast.success(response.data.message);
                fetchData();
                getdiscountbrandsstatus();
            } else {
                toast.warn(response.data.message);
            }
        } catch (error) {
            console.error('Error removing coupon:', error);
            toast.error('Unable to update brands');
        }
    };


    return (
        <>
            {isApi ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Bars
                        height="80"
                        width="100%"
                        color="var(--newMainBlue)"
                        ariaLabel="bars-loading"
                        visible={true}
                    />
                </div> :
                <div className="p-3 p-lg-4 p-xl-5">
                    <div className="d-flex align-items-center">
                        <Link
                            to="/admin/businesspartners"
                            className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
                            variant="white"
                        >
                            <span className="me-2 fw-semibold">
                                {/* <img
                                    src="/assets/images/back-org.png"
                                    className="img-fluid"
                                    alt=""
                                /> */}
                                <MdOutlineKeyboardBackspace  className="fs-4" style={{color: 'var(--newOrange)'}}/>
                            </span>
                            <span>Back</span>
                        </Link>
                        <h1 className="font20  text-dark">{id ? "" : "Add Business Partner"}</h1>
                    </div>
                    <Container fluid className="mt-4">
                        <Row className="bg-white">
                            <Col lg={12} md={12}>
                                <div className="py-3">
                                    <h3 className="font16 mb-2 text-dark">
                                        Add brands
                                    </h3>
                                    <Form
                                        className="p-4 rounded containerShadow"
                                        onSubmit={handleSubmit}>
                                        {formValues.map((element, index) => (
                                            <div className="form-inline footer_dropdown" key={index}>
                                                <Row className="align-items-end mt-3">
                                                    <Col sm={6} lg={4} xl={2} >
                                                        <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center responsive_group">
                                                            <Form.Label className="font13 text-dark fw-medium mt-2 me-2 " >Brand</Form.Label>
                                                            <Form.Select style={{ width: '85%' }} value={brand} name='brand' onChange={e => setBrand(handleChangeForm(index, e))} className="Dropdown_responsive" >
                                                                {
                                                                    getbrand?.map((datatwo, index) => {
                                                                        return (
                                                                            <option key={index} value={datatwo.value} >
                                                                                {datatwo.label}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }

                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} lg={4} xl={2}  >
                                                        <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center responsive_group " >
                                                            <Form.Label style={{ whiteSpace: 'nowrap' }} className="font14 text-dark fw-medium mt-2 me-2" >Business Type</Form.Label>
                                                            <Form.Select
                                                                style={{ width: '85%' }}
                                                                value={
                                                                    addedBusinessType === "Discount"
                                                                        ? "Discount percentage"
                                                                        : bonus
                                                                }
                                                                name="bonusType"
                                                                onChange={e => setBonus(handleChangeForm(index, e))}
                                                                className="Dropdown_responsive"
                                                                disabled={addedBusinessType === "Discount"}
                                                            >
                                                                {businessPartnerType === "Marketplace" ? (
                                                                    <>
                                                                        <option value="">Select Business Type</option>
                                                                        <option value="Bonus percentage">Bonus percentage</option>
                                                                        <option value="Bonus flat">Bonus flat</option>
                                                                    </>
                                                                ) : addedBusinessType === "Discount" ? (
                                                                    <option value="Discount percentage">Discount percentage</option>
                                                                ) : addedBusinessType === undefined ? (
                                                                    <>
                                                                        <option value="">Select Business Type</option>
                                                                        <option value="Bonus percentage">Bonus percentage</option>
                                                                        <option value="Bonus flat">Bonus flat</option>
                                                                        <option value="Discount percentage">Discount percentage</option>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <option value="">Select Business Type</option>
                                                                        <option value="Bonus percentage">Bonus percentage</option>
                                                                        <option value="Bonus flat">Bonus flat</option>
                                                                    </>
                                                                )}
                                                            </Form.Select>

                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} lg={4} xl={2}  >
                                                        <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center responsive_group " >
                                                            <Form.Label style={{ whiteSpace: 'nowrap' }} className="font13 text-dark fw-medium mt-2 me-2" >{addedBusinessType === "Discount" ?"Discount percentage" : "Bonus percentage"}</Form.Label>
                                                            <Form.Control
                                                                style={{ width: '80%' }}
                                                                className="font14 Dropdown_responsive"
                                                                name="bonusPercentage"
                                                                value={bonusValue}
                                                                onChange={e => setBonusValue(handleBonusChangeForm(e, index))}
                                                                type="number"
                                                                step="0.01" // Allow two decimal places
                                                                placeholder="Enter Bonus"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} lg={4} xl={2}  >
                                                        <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center responsive_group " >
                                                            <div >
                                                                <Form.Label className="font13 text-dark fw-medium mt-2 me-2" style={{ display: "flex" }} >Customer <span style={{ display: "inline-block", marginLeft: "2px" }}> (%)</span></Form.Label>
                                                            </div>
                                                            <div className=" d-flex  align-items-center ">
                                                                <Form.Control style={{ width: '100%' }} className="font14 Dropdown_responsive" name="bonus" value={element.bonus || ""} onChange={e => handleChangeForm(index, e)} type="number" placeholder="Enter Bonus percentage" />
                                                                <Form.Control style={{ width: '100%' }} className="font14 Dropdown_responsive" name="id" value={partner_id} onChange={e => handleChangeForm(index, e)} type="hidden" placeholder="Enter Bonus percentage" />


                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} lg={4} xl={2}  >
                                                        <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center responsive_group " >
                                                            <Form.Label style={{ whiteSpace: 'nowrap' }} className="font13 text-dark fw-medium mt-2 me-2" >Threshold Value</Form.Label>
                                                            <Form.Control
                                                                style={{ width: '80%' }}
                                                                className="font14 Dropdown_responsive"
                                                                name="thresholdValue"
                                                                onChange={e => handleChangeForm(index, e)}
                                                                type="number"
                                                                step="0.01" // Allow two decimal places
                                                                placeholder="Enter Threshold Value"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} lg={4} xl={2} className="d-flex  align-items-center" >
                                                        <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center responsive_group " >
                                                            <Form.Label style={{ whiteSpace: 'nowrap' }} className="font13 text-dark fw-medium mt-2 me-2" >Threshold Quantity</Form.Label>
                                                            <Form.Control
                                                                style={{ width: '80%' }}
                                                                className="font14 Dropdown_responsive"
                                                                name="thresholdQuantity"
                                                                onChange={e => handleChangeForm(index, e)}
                                                                type="number"
                                                                step="0.01" // Allow two decimal places
                                                                placeholder="Enter Threshold Quantity"
                                                            />
                                                        </Form.Group>
                                                        {index ?
                                                            <button type="button" className="icon mt-1 ms-1" style={{ color: 'black', border: 'none' }}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                            </svg></button>
                                                            :
                                                            null

                                                        }
                                                        {
                                                            index ?
                                                                <button type="button" className="icon mt-1 ms-1" style={{ color: 'black', border: 'none' }} onClick={() => removeFormFields(index)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                                    </svg>
                                                                </button>
                                                                : null
                                                        }
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                        <div className="button-section">
                                            {delay ? <Button variant="primary" className="button newCustomButton add fullRounded px-4 py-2 fw-bold font12 responsive_group mt-3"  
                                            style={{
                                                    backgroundColor: 'var(--newOrange)',
                                                    borderColor: 'var(--newOrange)',
                                                }} >Save</Button>
                                                : <Button variant="primary" className="button newCustomButton add fullRounded px-4 py-2 fw-bold font12 mt-3" type="submit"  
                                                style={{
                                                    backgroundColor: 'var(--newOrange)',
                                                    borderColor: 'var(--newOrange)',
                                                }} >Save</Button>}
                                            <Button variant="primary" className="button newCustomButton submit fullRounded px-4 py-2 fw-bold font12 add_button mt-3" onClick={() => addFormFields()} type="button"
                                                 style={{
                                                    backgroundColor: 'var(--newOrange)',
                                                    borderColor: 'var(--newOrange)',
                                                }}>
                                                <span className="me-2">
                                                    <BiPlusMedical />
                                                </span>
                                                <span>Add More</span></Button>
                                        </div>
                                    </Form>
                                </div>

                                {/* <Col className="py-3">
                                    <h3 className="font16 mb-2 text-dark">Select Banner Categories</h3>
                                    <Form className="p-4 rounded containerShadow">
                                        {businessCategories.reduce((acc, element, index) => {
                                            const rowIndex = Math.floor(index / 6);
                                            if (!acc[rowIndex]) {
                                                acc[rowIndex] = [];
                                            }
                                            acc[rowIndex].push(
                                                <Col key={index} xxl= {2} xl={1.5} lg={4} md={6} sm={6} xs={12} >
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={element.checked}
                                                        onChange={() => handleCheckboxChange(index)}
                                                        label={<span className="font14 text-dark fw-medium category-label">{element.name}</span>}
                                                    />
                                                </Col>
                                            );
                                            return acc;
                                        }, []).map((row, rowIndex) => (
                                            <Row key={rowIndex} className="mb-1">
                                                {row}
                                            </Row>
                                        ))}
                                    </Form>
                                </Col> */}


                                <Col className="py-3">
                                    <h3 className="font16 mb-2 text-dark">Select Banner Categories</h3>
                                    <Form className="p-4 rounded containerShadow">
                                        {businessCategories
                                            .reduce((acc, element, index) => {
                                                const rowIndex = Math.floor(index / 6);
                                                if (!acc[rowIndex]) {
                                                    acc[rowIndex] = [];
                                                }
                                                acc[rowIndex].push(
                                                    <Col key={index} xxl={2} xl={1.5} lg={4} md={6} sm={6} xs={12}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            checked={element.checked}
                                                            onChange={() => handleCheckboxChange(index)}
                                                            label={
                                                                <span className="font14 text-dark fw-medium category-label">
                                                                    {element.name}
                                                                </span>
                                                            }
                                                             className="custom-checkbox"
                                                        />
                                                    </Col>
                                                );
                                                return acc;
                                            }, [])
                                            .map((row, rowIndex) => (
                                                <Row key={rowIndex} className="mb-1">
                                                    {row}
                                                </Row>
                                            ))}
                                        <Row className="justify-content-end">
                                            <Col xs="auto">
                                                <Button variant="primary" className="button newCustomButton add fullRounded px-4 py-2 fw-bold font12 mt-3" onClick={handleSave}
                                                 style={{
                                                    backgroundColor: 'var(--newOrange)',
                                                    borderColor: 'var(--newOrange)',
                                                }}>
                                                    Save Categories
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>


                                <Row className="mt-5">
                                    <h2 className="font16 mb-2 text-dark">Brand List</h2>
                                    <Col className="p-4 mt-2 rounded containerShadow">
                                        <div className="textGray font12 Accessbility_section"  >
                                            <div style={{ maxWidth: "214px" }} className="me-2" >
                                                <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                                                    <Form.Control
                                                        type="text"
                                                        value={searching}
                                                        className="border-0 searchInput"
                                                        placeholder="Search"
                                                        onChange={({ currentTarget: input }) =>
                                                            handleSearch(input.value)
                                                        }
                                                    />
                                                    <Button
                                                        variant="primary"
                                                        title="Click here to search"
                                                        className="searchBtn newCustomSearch d-flex justify-content-center align-items-center"
                                                        style={{
                                                            backgroundColor: 'var(--newMainBlue)',
                                                            borderColor: 'var(--newMainBlue)',
                                                        }}
                                                    >
                                                        <img
                                                            src="/assets/images/search.png"
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div style={{ maxWidth: "214px" }} className="csv_button me-2">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => setshowCsvModal(!showCsvModal)}
                                                    className="fullRounded newCustomButton px-4 py-2 fw-bold font12"
                                                    style={{ width: "139px", backgroundColor: "var(--newOrange)", borderColor: "var(--newOrange)" }}
                                                >
                                                    <span className="me-2">
                                                        <BiPlusMedical />
                                                    </span>
                                                    <span>Upload CSV</span>

                                                </Button>
                                            </div>
                                         
                                            <div style={{ display: 'flex' }} className="filter_category category_button me-2" >
                                                    <Button
                                                        variant="primary"
                                                        className="fullRounded newCustomButton px-4 py-2 fw-bold font12"
                                                        style={{ minWidth: "149px", backgroundColor: "var(--newOrange)", borderColor: "var(--newOrange)" }}
                                                        onClick={() => {
                                                            fetchData();
                                                            setShowModal(true);
                                                          }}
                                                    >
                                                        <span>Update Giftcards</span>
                                                    </Button>

                                            </div>
                                           
                                            <div>
                                                <div style={{ display: 'flex' }} className="filter_category category_button" >
                                                    <ModalOpen title={<Button variant="primary" style={{ borderRadius: '30px', backgroundColor: "var(--newOrange)", borderColor: "var(--newOrange)" }} title="Click here to search" className="fullRounded newCustomButton px-4 py-2 fw-bold font12">
                                                        Filter by Categories
                                                    </Button>
                                                    }
                                                        data={categories}
                                                        num={0}
                                                        home={"false"}
                                                        btnColor={btnColor}
                                                        btnText={btnText}
                                                        setCategoryOption={setCategoryOption}
                                                        colorCheck={'black'}
                                                        catergoryOption={catergoryOption}
                                                        clickBrand={clickBrand}
                                                        setClickBrand={setClickBrand}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <div >
                                        <Col md={12} className="mt-4">
                                            <div className="table-responsive">
                                                <table className="table  editpage_table" style={{ verticalAlign: "middle" }}>
                                                    <thead className="bgLightRed font14">
                                                        <tr className="text-center">

                                                            <th className="py-2">
                                                                {" "}
                                                                <div className="d-flex align-items-center justify-content-center ">
                                                                    {" "}
                                                                    <span className="me-1 ">Stand Out</span>{" "}

                                                                </div>{" "}
                                                            </th>
                                                            <th className="py-2">
                                                                {" "}
                                                                <div className="d-flex align-items-center justify-content-center ">
                                                                    {" "}
                                                                    <span>Promoted</span>{" "}

                                                                </div>{" "}
                                                            </th>
                                                            <th className="py-2">
                                                                {" "}
                                                                <div className="d-flex align-items-center justify-content-center ">
                                                                    {" "}
                                                                    <span className="me-1 "> Brand</span>{" "}
                                                                    {order === "ASC" ? (
                                                                        <FaSortDown onClick={() => sorting("DSC")} />
                                                                    ) : (
                                                                        <FaSortUp onClick={() => sorting("ASC")} />
                                                                    )}
                                                                </div>{" "}
                                                            </th>

                                                            <th className="py-2">
                                                                <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                                                                    <span className="me-1">Category</span>
                                                                    {categoryss === "ASC" ? (
                                                                        <FaSortDown onClick={() => categorySorting("DSC")} />
                                                                    ) : (
                                                                        <FaSortUp onClick={() => categorySorting("ASC")} />
                                                                    )}
                                                                </div>
                                                            </th>

                                                            <th className="py-2">
                                                                <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                                                                    <span className="me-1">{addedBusinessType === "Discount" ?"Discount percentage" : "Bonus percentage"}</span>
                                                                    {bonusDiscount === "ASC" ? (
                                                                        <FaSortDown onClick={() => bonusDiscountSorting("DSC")} />
                                                                    ) : (
                                                                        <FaSortUp onClick={() => bonusDiscountSorting("ASC")} />
                                                                    )}
                                                                </div>
                                                            </th>
                                                            <th className="py-2">
                                                                <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                                                                    <span className="me-1">Business Type</span>
                                                                </div>
                                                            </th>
                                                            <th className="py-2" tyle={{ width: '10%' }}>
                                                                {" "}
                                                                <div className="d-flex align-items-center justify-content-center " >
                                                                    {" "}
                                                                    <span className="me-1 ">Rebate</span><span>(%)</span>{" "}
                                                                </div>
                                                            </th>
                                                            <th className="py-2" tyle={{ width: '10%' }}>
                                                                {" "}
                                                                <div className="d-flex align-items-center justify-content-center " >
                                                                    {" "}
                                                                    <span className="ms-1 me-1">Customer</span><span>(%)</span>{" "}
                                                                    {brandss === "ASC" ? (
                                                                        <FaSortDown onClick={() => bonusSorting("DSC")} />
                                                                    ) : (
                                                                        <FaSortUp onClick={() => bonusSorting("ASC")} />
                                                                    )}
                                                                </div>
                                                            </th>

                                                            <th className="py-2" tyle={{ width: '10%' }}>
                                                                {" "}
                                                                <div className="d-flex align-items-center justify-content-center " >
                                                                    {" "}
                                                                    <span className="ms-1 me-1">Threshold Value</span>{" "}

                                                                </div>
                                                            </th>

                                                            <th className="py-2" tyle={{ width: '10%' }}>
                                                                {" "}
                                                                <div className="d-flex align-items-center justify-content-center " >
                                                                    {" "}
                                                                    <span className="ms-1 me-1">Threshold Quantity</span>{" "}

                                                                </div>
                                                            </th>

                                                            <th className="py-2" style={{ width: "50px" }}>

                                                            </th>
                                                            <th className="py-2">
                                                                {" "}
                                                                <div className="d-flex align-items-center justify-content-center ">
                                                                    {" "}
                                                                    <span className="me-1 ">Actions</span>{" "}
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="font14 fw-medium text-center">
                                                        {brandLoading === true ? (
                                                            <tr style={{ backgroundColor: "#eee" }}>
                                                                <td colSpan="12">
                                                                    <Bars
                                                                        height="80"
                                                                        width="100%"
                                                                        margin='auto'
                                                                        color="var(--newMainBlue)"
                                                                        ariaLabel="bars-loading"
                                                                        wrapperStyle={{}}
                                                                        wrapperClass=""
                                                                        visible={true}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ) : customerData.length > 0 ?
                                                            (
                                                                customerData
                                                                    ?.sort((a, b) => a.brandName - b.brandName)
                                                                    .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
                                                                    .map((elem) => (
                                                                        <tr key={elem._id}>
                                                                            <td className="py-2 font14 fw-medium ">
                                                                                <div className="d-flex justify-content-center">
                                                                                    {elem.brandEdit ?
                                                                                        <Form.Check
                                                                                            style={{ width: '85%' }}
                                                                                            type="checkbox"
                                                                                            name="brand"
                                                                                            onChange={(e) => {
                                                                                                setStandoutCheck(e.target.checked);
                                                                                            }}
                                                                                        />
                                                                                        :
                                                                                        <Form.Check
                                                                                            style={{ width: '85%' }}
                                                                                            type="checkbox"
                                                                                            name="brand"
                                                                                            checked={elem.stand_out}
                                                                                            readOnly
                                                                                        />
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td className="py-2 font14 fw-medium ">
                                                                                <div className="d-flex justify-content-center">
                                                                                    {elem.brandEdit ?
                                                                                        <Form.Check
                                                                                            style={{ width: '85%' }}
                                                                                            type="checkbox"
                                                                                            name="brand"
                                                                                            onChange={(e) => setPromotedCheck(e.target.checked)}
                                                                                        />
                                                                                        :
                                                                                        <Form.Check
                                                                                            style={{ width: '85%' }}
                                                                                            type="checkbox"
                                                                                            name="brand"
                                                                                            checked={elem.promotion}
                                                                                            readOnly
                                                                                        />
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td className="py-2 font14 fw-medium ">
                                                                                <div className="d-flex justify-content-center">

                                                                                    <Form.Control style={{ width: '120px' }} className="font14" name="bonus" value={elem.brandName} disabled />
                                                                                </div>
                                                                            </td>
                                                                            <td className="py-2 font14 fw-medium ">
                                                                                <div className="d-flex justify-content-center">
                                                                                    <Form.Control style={{ width: '120px' }} className="font14" name="bonusType" value={elem.brandCategory} disabled />
                                                                                </div>

                                                                            </td>
                                                                            <td className="py-2 font14 fw-medium ">
                                                                                <div className="d-flex justify-content-center">
                                                                                    {elem.brandEdit ? (
                                                                                        <Form.Control style={{ width: '68px' }} className="font14" name="bonusType" value={discount} onChange={handleDiscountChange} />
                                                                                    ) : (
                                                                                        elem.discount ? (
                                                                                            <Form.Control style={{ width: '68px' }} className="font14" name="bonusType" value={`${(elem?.discount)?.toFixed(2)}%`} disabled />
                                                                                        ) : (
                                                                                            <Form.Control style={{ width: '68px' }} className="font14" name="bonusType" value="0%" disabled />
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                            {elem.brandEdit ?
                                                                                <td >
                                                                                    <div className="d-flex justify-content-center">

                                                                                        <Form.Select
                                                                                            style={{ width: '85px', height: '90%' }}
                                                                                            value={
                                                                                                addedBusinessType === "Discount"
                                                                                                    ? "Discount percentage"
                                                                                                    : bonusType || elem.bonusType
                                                                                            }
                                                                                            name="bonusType"
                                                                                            onChange={e => setBonusType(e.target.value)}
                                                                                            className="Dropdown_responsive"
                                                                                            disabled={addedBusinessType === "Discount"}
                                                                                        >
                                                                                            {businessPartnerType === "Marketplace" ? (
                                                                                                <>
                                                                                                    <option value="">Select Business Type</option>
                                                                                                    <option value="Bonus percentage">Bonus percentage</option>
                                                                                                    <option value="Bonus flat">Bonus flat</option>
                                                                                                </>
                                                                                            ) : addedBusinessType === "Discount" ? (
                                                                                                <option value="Discount percentage">Discount percentage</option>
                                                                                            ) : addedBusinessType === undefined ? (
                                                                                                <>
                                                                                                    <option value="">Select Business Type</option>
                                                                                                    <option value="Bonus percentage">Bonus percentage</option>
                                                                                                    <option value="Bonus flat">Bonus flat</option>
                                                                                                    <option value="Discount percentage">Discount percentage</option>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <option value="">Select Business Type</option>
                                                                                                    <option value="Bonus percentage">Bonus percentage</option>
                                                                                                    <option value="Bonus flat">Bonus flat</option>
                                                                                                </>
                                                                                            )}
                                                                                        </Form.Select>


                                                                                    </div>
                                                                                </td>
                                                                                :
                                                                                <td>
                                                                                    <div className="d-flex justify-content-center">
                                                                                        <Form.Control style={{ width: '85px' }} className="font14" name="bonusType" value={elem.bonusType === "Bonus percentage" ? "Bonus Percentage" : elem.bonusType} disabled />
                                                                                    </div>
                                                                                </td>
                                                                            }
                                                                            <td className="py-2 font14 fw-medium" style={{ width: '10%' }}>
                                                                                <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                                                                                    <Form.Control style={{ width: '56px' }} className="font14" name="bonus" value={elem.internalPercentage} type="text" placeholder="Enter Bonus percentage" disabled />
                                                                                </div>
                                                                            </td>
                                                                            <td className="py-2 font14 fw-medium" style={{ width: '10%' }}>
                                                                                <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                                                                                    {elem.brandEdit ? <Form.Control style={{ width: '56px', marginRight: "12px" }} className="font14" name="bonus" value={bonus} onChange={e => setBonus(e.target.value)} type="text" placeholder="Enter Bonus percentage" /> :
                                                                                        <Form.Control style={{ width: '56px', marginRight: "12px" }} className="font14" name="bonus" value={elem.bonus} type="text" placeholder="Enter Bonus percentage" disabled />}

                                                                                    <Form.Control style={{ width: '56px', marginRight: "12px" }} name="id" value={partner_id} type="hidden" placeholder="Enter Bonus percentage" />

                                                                                </div>
                                                                            </td>
                                                                            <td className="py-2 font14 fw-medium" style={{ width: '10%' }}>
                                                                                <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                                                                                    {elem.brandEdit ? <Form.Control style={{ width: '80px' }} className="font14" name="thresholdValue" value={thresholdValue} onChange={e => setThresholdValue(e.target.value)} type="number" placeholder="Enter Threshold Value" /> :
                                                                                        <Form.Control style={{ width: '80px' }} className="font14" name="thresholdValue" value={elem.thresholdValue} type="number" placeholder="Enter Threshold Value" disabled />}
                                                                                    <Form.Control style={{ width: '80px' }} name="id" value={partner_id} type="hidden" placeholder="Enter Threshold Value" />
                                                                                </div>
                                                                            </td>
                                                                            <td className="py-2 font14 fw-medium" style={{ width: '10%' }}>
                                                                                <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                                                                                    {elem.brandEdit ? <Form.Control style={{ width: '80px' }} className="font14" name="thresholdQuantity" value={thresholdQuantity} onChange={e => setThresholdQuantity(e.target.value)} type="number" placeholder="Enter Threshold Quantity" /> :
                                                                                        <Form.Control style={{ width: '80px' }} className="font14" name="thresholdQuantity" value={elem.thresholdQuantity} type="number" placeholder="Enter Threshold Quantity" disabled />}
                                                                                    <Form.Control style={{ width: '80px' }} name="id" value={partner_id} type="hidden" placeholder="Enter Threshold Quantity" />
                                                                                </div>
                                                                            </td>
                                                                            <td className="py-2 font14 fw-medium ">
                                                                                <div className="d-flex justify-content-center">
                                                                                    <Button onClick={() => navigate(`/admin/businesspartners/${id}/${elem.couponId}/coupons`)} type="submit" variant="primary" style={{ width: "123px", backgroundColor: 'var(--newOrange)',borderColor: 'var(--newOrange)' }} className="px-4 py-2 newCustomButton fullRounded shadowPrimary  font12">Gift Cards ({elem.coupons})</Button>
                                                                                </div>

                                                                            </td>
                                                                            <td>
                                                                                <div className="d-flex justify-content-evenly">

                                                                                    <div className="action_button">
                                                                                        {elem.brandEdit ? <button type="button" onClick={() => handleStatus(elem.brandEdit, elem._id)} className="icon mt-1 ms-1" style={{ color: 'black', border: 'none' }}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-check-square" viewBox="0 0 16 16">
                                                                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                                <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                                                                                            </svg>
                                                                                        </button>
                                                                                            :

                                                                                            <button type="button" onClick={() => handleStatus(elem.brandEdit, elem._id)} className="icon mt-1 ms-1" style={{ color: 'black', border: 'none' }}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                                                            </svg></button>}

                                                                                    </div>

                                                                                    {/* edit button */}
                                                                                    <div>


                                                                                        {/* edit button */}

                                                                                        {deleteDelay ? <button type="button" className="icon mt-1 ms-1" style={{ color: 'black', border: 'none' }} >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                                                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                                                            </svg>
                                                                                        </button> : <button type="button" className="icon mt-1 ms-1" style={{ color: 'black', border: 'none' }} onClick={() => deleteBp(elem.couponId)}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                                                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                                                                            </svg>
                                                                                        </button>}


                                                                                    </div>

                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))) :
                                                            (
                                                                <tr>
                                                                    <td colSpan="12">No Data Available</td>
                                                                </tr>
                                                            )
                                                        }
                                                        {isLoading ? "" :
                                                            <>
                                                            </>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>

                                        {numberOfPages > 0 ? (
                                            <Col className="mt-5 mb-4">
                                                <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                                                    {pageNumber === 1 ? (
                                                        ""
                                                    ) : (
                                                        <li onClick={prevPage}>Previous</li>
                                                    )}
                                                    {items}
                                                    {numberOfPages === pageNumber ? (
                                                        ""
                                                    ) : (
                                                        <li onClick={nextPage}>Next</li>
                                                    )}
                                                    <li className="font14">
                                                        <input
                                                            style={{
                                                                width: "25px",
                                                                border: "1px solid var(--newMainBlue)",
                                                                borderRadius: "4px",
                                                                textAlign: "center"
                                                            }}
                                                            value={showPage ? showPage : ""}
                                                            type="text"
                                                            name="number"
                                                            onChange={(e) => pagenumberSearch(e.target.value)}
                                                        />{" "}
                                                        <span style={{ color: "var(--newMainBlue)" }}>/</span> {numberOfPages}
                                                    </li>
                                                </ul>
                                            </Col>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                    <Modal
                        show={showCsvModal}
                        onHide={handleClosehandleCsvModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title
                                    className="font20 text-dark mb-0"
                                    id="contained-modal-title-vcenter"
                                >
                                    File Upload
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {loadingCsv ? <Bars
                                    height="80"
                                    width="100%"
                                    margin='auto'
                                    color="var(--newMainBlue)"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                    :
                                    <>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="fs-6 text-dark mb-2">Select CSV File</h4>

                                        </div>
                                        <BuisnessForm variant="primary" setCustomerData={setCustomers} setFilename={setFilename} filename={filename} brandData={brandData} brandName={business_name} />
                                    </>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                {filename.length > 0 ? <Button
                                    variant="secondary"
                                    className="fullRounded newCustomButton px-4 py-2 fw-bold font12"
                                     style={{
                                            backgroundColor: 'var(--newOrange)',
                                            borderColor: 'var(--newOrange)',
                                        }}
                                    disabled
                                >
                                    Upload
                                </Button>
                                    :

                                    <Button
                                        variant="primary"
                                        type="submit"
                                        className="fullRounded newCustomButton px-4 py-2 fw-bold font12"
                                         style={{
                                            backgroundColor: 'var(--newOrange)',
                                            borderColor: 'var(--newOrange)',
                                        }}
                                        onClick={handleSubmitCSV}
                                    >
                                        Upload
                                    </Button>
                                }
                                <Button
                                    variant="secondary"
                                    className="fullRounded px-4 py-2 fw-bold font12"
                                    onClick={() => {
                                        handleClosePreviewModal();
                                        handleClearData();
                                    }}
                                >
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                    <Modal
                        scrollable={true}
                        show={previewModal}
                        onHide={handleClosePreviewModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title
                                className="font20 text-dark mb-0"
                                id="contained-modal-title-vcenter"
                            >
                                CSV Preview
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {loadingCsv ? <Bars
                                height="80"
                                width="100%"
                                margin='auto'
                                color="var(--newMainBlue)"
                                ariaLabel="bars-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            /> :
                                <>
                                    <div className="table-responsive">
                                        <table
                                            className="table fontGilroy"
                                            style={{ verticalAlign: "middle" }}
                                        >
                                            <thead className="bgLightRed font14">
                                                <tr>
                                                    <th className="text-center">
                                                        <div className="d-flex align-items-center">

                                                            <span className="me-1">BrandName</span>

                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                                            <span className="me-1">Brand Id</span>

                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                                            <span className="me-1">Brand Category

                                                            </span>

                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                                            <span className="me-1">{addedBusinessType === "Discount" ?"Discount percentage" : "Bonus percentage"}

                                                            </span>

                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                                            <span className="me-1">Rebate (%)</span>

                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                                            <span className="me-1">Customer(%)

                                                            </span>

                                                        </div>
                                                    </th>
                                                    {/* <th>
                                                        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                                            <span className="me-1">Total Value

                                                            </span>

                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                                            <span className="me-1">Total Quantity
                                                            </span>

                                                        </div>
                                                    </th> */}

                                                </tr>
                                            </thead>
                                            <tbody className="font12 fw-medium">
                                                {
                                                    previewData?.map((cust, i) => (
                                                        <tr key={i}>
                                                            <td className="font14">

                                                                <span>
                                                                    <Link
                                                                        className="link"

                                                                    >
                                                                        {cust.brandname}
                                                                    </Link>
                                                                </span>
                                                            </td>
                                                            <td className="font14 fw-medium">{cust.Brand_Id}</td>
                                                            <td className="font14 fw-medium">{cust.brandCategory}</td>
                                                            <td className="font14 fw-medium">{Number(cust?.Bonus_Percentage)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%</td>
                                                            <td className="font14 fw-medium">{Number(cust?.internaPercentage)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%</td>
                                                            <td className="font14 fw-medium">{Number(cust?.Customer_Percentage)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%</td>
                                                        </tr>
                                                    ))

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                className="fullRounded px-4 py-2 fw-bold font12"
                                onClick={handleSubmitCSV}
                                disabled={loadingCsv}
                            >
                                Confirm
                            </Button>
                            <Button
                                variant="secondary"
                                className="fullRounded px-4 py-2 fw-bold font12"
                                onClick={() => handleClosePreviewModal()}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* for update brands */}

                    <Modal
                        show={showModal}
                        onHide={handleClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        scrollable
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Giftcards Update</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className="d-flex align-items-center">
                                    <h5 className="me-auto">New Giftcards</h5>
                                    <button className={`brands_toggle text-dark ${newBrandsOpen ? "rotate" : ""}`} onClick={() => toggleNewBrands()} >
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>
                                    </button>
                                </div>
                                {newBrandsOpen ?
                                    <>
                                        {newBrands.length > 0 ?
                                            <Table striped bordered hover className="mt-2">
                                                <thead>
                                                    <tr className="custom-table ">
                                                        <th className="ustom-table table-col-productname">Product Name</th>
                                                        <th className="table-col-brandcategory">Denomination Type</th>
                                                        <th className="table-col-denomination">Available Denomination</th>
                                                        <th className="table-col-action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {newBrands.map((coupon) => (
                                                        <React.Fragment key={coupon._id}>

                                                            <tr style={{ cursor: 'pointer' }} >
                                                                <td className="table-col-productname">{coupon.productname}</td>
                                                                <td className="table-col-brandcategory">{coupon.denominationtype}</td>
                                                                <td className="table-col-denomination">
                                                                    {coupon.faceValue === 0.0
                                                                        ? `$ ${Number(coupon.minValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} - $ ${Number(coupon.maxValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                                                                        : coupon.fixed.map((fix, index, array) => (
                                                                            `${index === array.length - 1 ? `$ ${Number(fix.faceValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : `$ ${Number(fix.faceValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ,`}`
                                                                        ))
                                                                    }
                                                                </td>
                                                                <td className="table-col-action">
                                                                    <Button
                                                                        variant="primary"
                                                                        className="fullRounded newCustomButton px-4 py-2 fw-bold font12"
                                                                        style={{ width: "109px", backgroundColor: 'var(--newOrange)',
                                                                            borderColor: 'var(--newOrange)' }}
                                                                        onClick={() => openDrawer(coupon.brand_id)}>
                                                                        <span className="me-2">
                                                                            <BiPlusMedical />
                                                                        </span>
                                                                        <span>Add</span>
                                                                    </Button>
                                                                </td>
                                                            </tr>

                                                            {openDrawerId === coupon.brand_id && (
                                                                <tr>
                                                                    <td colSpan="4">
                                                                        <div className={`transaction-drawer ${openDrawerId === coupon.brand_id ? 'drawer-open' : ''}`}>
                                                                            <Form className="p-1 rounded containerShadow" onSubmit={handleSubmit}>
                                                                                {formValues.map((element, index) => (
                                                                                    <div className="form-inline footer_dropdown" key={index}>
                                                                                        <Row className="align-items-end mt-3">
                                                                                            <Col md={3} className="ps-3">
                                                                                                <Form.Group className="mb-3 d-block d-xxl-flex align-items-center responsive_group">
                                                                                                    <Form.Label className="font12 text-dark fw-medium mt-2 me-2">Business Type</Form.Label>
                                                                                                    <Form.Select
                                                                                                        style={{ width: '85%' }}
                                                                                                        value={addedBusinessType === "Discount" ? "Discount percentage" : element.bonusType}
                                                                                                        name="bonusType"
                                                                                                        onChange={(e) => handleChangeForm(index, e)}
                                                                                                        className="Dropdown_responsive"
                                                                                                        disabled={addedBusinessType === "Discount"}
                                                                                                    >
                                                                                                        {businessPartnerType === "Marketplace" ? (
                                                                                                            <>
                                                                                                                <option value="">Select Business Type</option>
                                                                                                                <option value="Bonus percentage">Bonus percentage</option>
                                                                                                                <option value="Bonus flat">Bonus flat</option>
                                                                                                            </>
                                                                                                        ) : addedBusinessType === "Discount" ? (
                                                                                                            <option value="Discount percentage">Discount percentage</option>
                                                                                                        ) : addedBusinessType === undefined ? (
                                                                                                            <>
                                                                                                                <option value="">Select Business Type</option>
                                                                                                                <option value="Bonus percentage">Bonus percentage</option>
                                                                                                                <option value="Bonus flat">Bonus flat</option>
                                                                                                                <option value="Discount percentage">Discount percentage</option>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <option value="">Select Business Type</option>
                                                                                                                <option value="Bonus percentage">Bonus percentage</option>
                                                                                                                <option value="Bonus flat">Bonus flat</option>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </Form.Select>

                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            <Col md={3}>
                                                                                                <Form.Group className="mb-3 d-block d-xxl-flex align-items-center responsive_group">
                                                                                                    <Form.Label className="font12 text-dark fw-medium mt-2 me-2">{addedBusinessType === "Discount" ?"Discount percentage" : "Bonus percentage"}</Form.Label>
                                                                                                    <Form.Control
                                                                                                        // style={{ width: '80%' }}
                                                                                                        className="font14 Dropdown_responsive"
                                                                                                        name="bonusPercentage"
                                                                                                        value={bonusValue}
                                                                                                        onChange={e => setBonusValue(handleBonusChangeForm(e, index))}
                                                                                                        type="number"
                                                                                                        step="0.01" // Allow two decimal places
                                                                                                        placeholder="Enter Bonus"
                                                                                                    />
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            <Col md={3}>
                                                                                                <Form.Group className="mb-3 d-block d-xxl-flex align-items-center responsive_group">
                                                                                                    <Form.Label className="font12 text-dark fw-medium mt-2 me-2">Customer (%)</Form.Label>
                                                                                                    <Form.Control
                                                                                                        className="font14 Dropdown_responsive"
                                                                                                        name="bonus"
                                                                                                        value={element.bonus || ""}
                                                                                                        onChange={e => handleChangeForm(index, e)}
                                                                                                        type="number"
                                                                                                        placeholder="Enter Bonus percentage"
                                                                                                    />
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            <Col md={3} className="mb-4 ps-4">
                                                                                                <Button variant="primary" type="submit" className="fullRounded newCustomButton px-4 py-2 fw-bold font12"  style={{
                                                                                                            backgroundColor: 'var(--newOrange)',
                                                                                                            borderColor: 'var(--newOrange)',
                                                                                                        }}>
                                                                                                    Save Brands
                                                                                                </Button>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                ))}
                                                                            </Form>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </React.Fragment>

                                                    ))}
                                                </tbody>
                                            </Table>
                                            : <p>No new giftcards</p>}
                                    </>

                                    : null}
                            </div>
                            <hr />
                            <div>
                                <div className="d-flex align-items-center">
                                    <h5 className="me-auto">Deleted Giftcards</h5>
                                    <button className={`brands_toggle text-dark ${deletedBrandsOpen ? "rotate" : ""}`} onClick={() => toggleDeletedBrands()} >
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>
                                    </button>
                                </div>
                                {deletedBrandsOpen ?
                                    <>
                                        {deletedBrands.length > 0 ?
                                            <Table striped bordered hover className="mt-2">
                                                <thead>
                                                    <tr className="custom-table">
                                                        <th className="table-col-productname">Product Name</th>
                                                        <th className="table-col-brandcategory">Denomination Type</th>
                                                        <th className="table-col-denomination">Available Denomination</th>
                                                        <th className="table-col-action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {deletedBrands.map((coupon) => (
                                                        <tr key={coupon._id}>
                                                            <td className="table-col-productname">{coupon.productname}</td>
                                                            <td className="table-col-brandcategory">{coupon.denominationtype}</td>
                                                            <td className="table-col-denomination">
                                                                {coupon.faceValue === 0.0
                                                                    ? `$ ${Number(coupon.minValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} - $ ${Number(coupon.maxValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                                                                    : coupon.fixed.map((fix, index, array) => (
                                                                        `${index === array.length - 1 ? `$ ${Number(fix.faceValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : `$ ${Number(fix.faceValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ,`}`
                                                                    ))
                                                                }
                                                            </td>
                                                            <td className="table-col-action">
                                                                <Button
                                                                    variant="primary"
                                                                    className="fullRounded newCustomButton px-4 py-2 fw-bold font12"
                                                                    style={{ width: "120px", backgroundColor: 'var(--newOrange)', borderColor: 'var(--newOrange)' }}
                                                                    onClick={() => handleRemove(coupon.brand_id)}>
                                                                    <span className="me-2">
                                                                        <FaTrash />
                                                                    </span>
                                                                    <span>Remove</span>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            :
                                            <p>No deleted giftcards</p>}
                                    </>
                                    :
                                    null}
                            </div>
                            <hr />
                            <div>
                                <div className="d-flex align-items-center">
                                    <h5 className="me-auto">Updated Giftcards</h5>
                                    <button className={`brands_toggle text-dark ${updatedBrandsOpen ? "rotate" : ""}`} onClick={() => toggleUpdatedBrands()} >
                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>
                                    </button>
                                </div>
                                {updatedBrandsOpen ?
                                    <>
                                        {updatedBrands.length > 0 ?
                                            <Table striped bordered hover className="mt-2">
                                                <thead>
                                                    <tr className="custom-table ">
                                                        <th className="table-col-productname">Product Name</th>
                                                        <th className="table-col-brandcategory">Denomination Type</th>
                                                        <th className="table-col-denomination">Available Denomination</th>
                                                        <th className="table-col-action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {updatedBrands.map((coupon) => (
                                                        <tr key={coupon._id}>
                                                            <td className="table-col-productname">{coupon.productname}</td>
                                                            <td className="table-col-brandcategory">{coupon.denominationtype}</td>
                                                            <td className="table-col-denomination">
                                                                {coupon.faceValue === 0.0
                                                                    ? `$ ${Number(coupon.minValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} - $ ${Number(coupon.maxValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                                                                    : coupon.fixed.map((fix, index, array) => (
                                                                        `${index === array.length - 1 ? `$ ${Number(fix.faceValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : `$ ${Number(fix.faceValue).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ,`}`
                                                                    ))
                                                                }
                                                            </td>
                                                            <td className="table-col-action">
                                                                <Button
                                                                    variant="primary"
                                                                    className="fullRounded newCustomButton px-4 py-2 fw-bold font12"
                                                                    style={{ width: "110px", backgroundColor: 'var(--newOrange)', borderColor: 'var(--newOrange)' }}
                                                                    onClick={() => handleUpdated(coupon.brand_id)}>
                                                                    <span className="me-2">
                                                                        <FaSyncAlt />
                                                                    </span>
                                                                    <span>Update</span>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table> : <p>No updated giftcards</p>}
                                    </>

                                    :
                                    null}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* for update brands */}
                </div>

            }
        </>
    )
};

export default BusinessPartnerBrands;