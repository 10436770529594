import React, { useState ,useEffect} from 'react';
import PropTypes from 'prop-types';
import '../../styles/category.css'
import { useDispatch, useSelector } from 'react-redux';
import { ClearhandleCategories, handleCategory_name, handleCategory_show } from '../../Redux/SendCategories/action';
import { useNavigate } from 'react-router-dom';
import axios from "axios";


const BusinessBrandCategoryss = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categories,setCategories] = useState([]) 
  const showCategory = useSelector(state => state.sendCategorie_reducer.showCategory);
    const handleClick = (event,title) => {
        event.currentTarget.classList.toggle('active');
        props.setClickBrand(!props.clickBrand)
        if(props?.home === "false"){
          props?.setCategoryOption(!props?.catergoryOption);
          dispatch(handleCategory_name(encodeURIComponent(title)));
          dispatch(handleCategory_show(title));
        }else{
        //   navigate('/giftcards');
          dispatch(ClearhandleCategories())
          dispatch(handleCategory_name(encodeURIComponent(title)));
          dispatch(handleCategory_show(title));
        }
      };


  return (
    <>

   {showCategory?.includes(props?.title) && props?.home === "false"  ?  <button className="category-block" style={{backgroundColor: "var(--newOrange)",color:props?.btnText}} onClick={(event)=>handleClick(event,props.title)}>{props.title}</button>
  :<> <button className="category-block" onClick={(event)=>handleClick(event,props.title)}>{props?.title}</button> </>}
  </>
  )
}

BusinessBrandCategoryss.propTypes = {
    title: PropTypes.string
}



BusinessBrandCategoryss.defaultProps = {
    title: 'category'
};


export default BusinessBrandCategoryss
