// reducer.js
import {SET_CMS_DATA_REQUEST,SET_CMS_DATA_SUCCESS,SET_CMS_DATA_FAILURE} from './actionTypes';

const initialState = {
  data: null, 
  loading: false, 
  error: null, 
};

export const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CMS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SET_CMS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SET_CMS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
