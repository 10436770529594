import { useEffect,useState } from 'react';
import { Container } from "react-bootstrap";
import {Outlet, useNavigate} from "react-router-dom"
import DashTopBar from "../../parts/DashTopBar";
import CustomerLeftSidebar from '../../parts/CustomerLeftSidebar';
const CustomerDashboardLayout = () =>{
   
    return (
        <>
        <CustomerLeftSidebar/>
        <div className={"mainContent"}>
            <Container
                fluid
                className="p-0"
                style={{
                    backgroundColor: "#F5F5F5",
                    minHeight: "100vh",
                }}
            >
                <DashTopBar />
                <Outlet />
            </Container>
        </div>
        </>
    )
}

export default CustomerDashboardLayout;