import { SET_CUS_DATA_REQUEST,SET_CUS_DATA_SUCCESS,SET_CUS_DATA_FAILURE } from "./actionType";


const initialState = {
    data: null, 
    loading: false, 
    error: null, 
  };

  export const getcustomer = (state =initialState ,action) => {
    switch(action.type){
    case SET_CUS_DATA_REQUEST:
    return{
        ...state,
        loading:true,
        error:null,
    };
    case SET_CUS_DATA_SUCCESS:
        return{
            ...state,
            loading:false,
            data:action.payload,
        };
    case SET_CUS_DATA_FAILURE:
        return{
            ...state,
            loading:false,
            error:action.payload,
        };
        default:
        return state;
    }
  }