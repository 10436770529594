// actions.js
import axios from 'axios';
import { SET_CMS_DATA_REQUEST, SET_CMS_DATA_SUCCESS, SET_CMS_DATA_FAILURE } from './actionTypes';

export const cms_req = () => ({
  type: SET_CMS_DATA_REQUEST
});

export const cms_succ = (data) => ({
  type: SET_CMS_DATA_SUCCESS,
  payload: data
});

export const cms_fail = (err) => ({
  type: SET_CMS_DATA_FAILURE,
  payload: err
});

export const getCms = (nameDomain) => (dispatch) => {
  // if (!nameDomain) {
  //   dispatch(cms_fail("Name domain is not provided."));
  //   return;
  // }
  dispatch(cms_req());
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain}`
  })
    .then((res) => {
      dispatch(cms_succ(res.data));
    })
    .catch((err) => {
      dispatch(cms_fail(err));
    });
};
