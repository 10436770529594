import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import axios from "axios";
import { useEffect } from "react";
import logo from "../../assets/images/Ncentiva.png";
import "jspdf-autotable";
import "../../styles/transaction.css"
// import "../styles/transaction.css"
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { FcDownload, FcUpload } from 'react-icons/fc';
import { Bars } from 'react-loader-spinner';
import {
    Button,
    Col,
    Container,
    Row,
    Form,
    DropdownButton,
    Dropdown,
    Modal
} from "react-bootstrap";
import { toast } from "react-toastify";
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Select from "react-select";
// import "../styles/monetray.scss"
import { number } from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessContact } from '../../Redux/BusinessContacts/action';
import { Field, Formik } from "formik";
import { getNewPartnerToken } from "../../config/cookie.config";

const BusinessAcountHistory = (props) => {
    let navigate = useNavigate();
    var token;
    var userrole;
    var usersid;
    const user = JSON.parse(window.localStorage.getItem("client"));
    let id;
    const businessPartner = JSON.parse(window.localStorage.getItem('business-client'));
    const params = useParams();
    // if (props?.admin === true) {
        const userss = JSON.parse(window.localStorage.getItem("business-client"));
        // if (userss) {
        //     userrole = userss.users.role;
        //     usersid = userss.users.business_partner_id
        // } else {
        //     userrole = null;
        //     usersid = null;
        //     navigate("/admin");
        // }
        id = userss.user.business_partner_id
    // } else {
    //     id = businessPartner?.user?._id;
    // }


    const [search, setSearch] = useState("");
    const [role] = useState(userrole);
    const [userid] = useState(usersid);
    const [customerData, setCustomerData] = useState([]);
    const [transactionData, setTransactionData] = useState([]);
    const [customerMaxValue, setCustomerMaxValue] = useState([]);
    const [customerUpdateAtMaxValue, setCustomerUpdateAtMaxValue] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState("ASC");
    const [emailorder, setEmailorder] = useState("ASC");
    const [valueorder, setValueorder] = useState("ASC");
    const [updateorder, setUpdateorder] = useState("ASC");
    const [noData, setNoData] = useState(false);
    const [sort, setSort] = useState("");
    const [sendMail, setSendMail] = useState([]);
    const [totalFund, setTotalFund] = useState([]);
    const [usedFund, setUsedFund] = useState([]);
    const [allproducts, setAllProducts] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [show, setShow] = useState(false);
    const [showPage, setShowPage] = useState(1);
    const [monetaryData, setMonetaryData] = useState();
    const [custFilter, setCustFilter] = useState("");
    const [apiResponse, setApiResponse] = useState(false);
    const [invoiceData, setInvoiceData] = useState([])
    const [invoiceLink, setInvoiceLink ] = useState();
    const redirectToLogin = () => {
        const user = JSON.parse(window.localStorage.getItem("business-client"));
        if (user) {
            token = user.token;
        } else {
            token = null;
        }

        if (token === null) {
            navigate("/business-login");
        }
    };


    const dispatch = useDispatch();
  
    useEffect(() => {
      const user = JSON.parse(window.localStorage.getItem("business-client"));
      const id = user?.userData?._id;
      const token = user?.token;
    
      dispatch(getBusinessContact(id, token, navigate, toast));
    
    }, [dispatch, navigate, toast]);




    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "var(--darkblue)" : "var(--color)",
            fontSize: 14,
            padding: 10,
            marginBottom: 0,
            backgroundColor: state.isSelected ? "rgba(215, 223, 233, 0.34);" : "#fff",
        }),

        control: () => ({
            // none of react-select's styles are passed to <Control />
            width: "auto",
            display: "flex",
            border: "1px solid #ced4da",
            borderRadius: ".25rem",
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const color = "var(--color)";
            const fontSize = "14px";
            const transition = "opacity 300ms";

            return { ...provided, opacity, transition, color, fontSize };
        },
    };


    const [getCustomer, setCustomer] = useState([]);


    // name
    const sorting = (order) => {
        if (order === "DSC") {
            setSort("dscName");

            setOrder("DSC");

        } else {
            setSort("ascName");

            setOrder("ASC");

        }
    };

    const emailsorting = (emailorder) => {
        if (emailorder === "DSC") {
            setSort("dscEmail");

            setEmailorder("DSC");
        } else {
            setSort("ascEmail");

            setEmailorder("ASC");
        }
    };

    const valuesorting = (valueorder) => {
        if (valueorder === "DSC") {
            setSort("dscName");

            setValueorder("DSC");
        } else {
            setSort("ascName");

            setValueorder("ASC");
        }
    };

    const updatesorting = (updateorder) => {
        if (updateorder === "DSC") {
            setSort("dscCreate");

            setUpdateorder("DSC");
        } else {
            setSort("ascCreate");

            setUpdateorder("ASC");
        }
    };

    const [businessPartner_data, setBusinesspartner_data] = useState('');


    const pages = new Array(numberOfPages).fill(null).map((v, i) => i);
    const getTransactionData = (pageNumber, start, end) => {
        setIsLoading(true)
        const user = JSON.parse(window.localStorage.getItem("business-client"));
        if (user) {
            token = user.token;
        } else {
            token = null;
        }
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getbusinessmonetarytransaction/${id}?page=${pageNumber}&search=${search}&sort=${sort}&startDate=${start}&endDate=${end}&custFilter=${custFilter}`, {
            headers: {
                'Authorization': `Bearer ${getNewPartnerToken()}`
            }
        })
            .then((response) => {
                if(response.data.message == "Please Login"){
                    localStorage.removeItem('business-client');
                    navigate('/business-login');
                    toast.error("Session Expired Please Login Again")
                  }
                setApiResponse(true);
                setCustomer(response.data.customerFilter);
                setInvoiceData(response.data.invoicedata);
                if (response.data.getFund === "NaN" || "0") {
                    setTotalFund(0.00);
                } else {
                    setTotalFund(response.data.getFund);
                }
                if (response.data.usedFund === "NaN" || "0") {
                    setUsedFund(0.00);
                } else {
                    setUsedFund(response.data.usedFund);
                }
                setMonetaryData(response.data);
                setBusinesspartner_data(response?.data?.partnerdata);
                const alltransaction = response.data.monetary;
                const totalPages = response.data.totalPages;
                setCustomerData(response?.data?.monetary);
                setNumberOfPages(totalPages);
                if (alltransaction.length === 0) {
                    setNoData(true);
                }

                setIsLoading(false);
            })
            .catch((error) =>{
                console.log(error);
                if (error.response.data.message === "Unauthorized User" ){
                    localStorage.removeItem('business-client');
                    navigate('/business-login');
                    toast.error("Session Expired Please Login Again")
                }
            }
            )
    };
    //for PDF

    const handleChange = (selectedOption) => {
        if (selectedOption.value === "All Customers") {
            setCustFilter("");
            // getTransactionData(pageNumber,startDate,endDate,custFilter);
            setIsLoading(false);
        } else {
            // setSearch(selectedOption.value);
            const promise = new Promise((resolve, reject) => {
                // Perform your asynchronous operation here
                // For example, fetch data from an API
                setCustFilter(selectedOption.value);
            });
            promise.then(data => {
                getTransactionData(pageNumber, startDate, endDate, custFilter);
                setIsLoading(false);
            }).catch(error => {
                console.error(error);
                setIsLoading(false);
            });
        }
    };


    let obj = {};
    if (invoiceData) {
        const invoice = invoiceData[0];
        obj = {
            buisnessId: invoice?.business_partner_id,
            business: invoice?.business_partner_name,
            // balance: invoice.total
        };
    }




    const downloadInvoice = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const doc = new jsPDF(orientation, unit, size);
        // doc.addImage(logo, "png", 30, 40, 150, 30);
        var lMargin2 = 330;
        var rMargin2 = 5;
        var pdfInMM2 = 690;

        var lMargin3 = 330;
        var rMargin3 = 5;
        var pdfInMM3 = 565;

        var lMargin4 = 330;
        var rMargin4 = 5;
        var pdfInMM4 = 690;


        var buisnessPartner = `Business Partner ID:${obj.buisnessId}`
        var buisnessName = `Buisness Partner Name:${obj.business}`
        var balance = `Total Balance in Account :$ ${Number(monetaryData?.totalBonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        doc.setFontSize(12)
        var lines2 = doc.splitTextToSize(buisnessPartner, (pdfInMM2 - lMargin2 - rMargin2));
        doc.text(lMargin2, 100, lines2);
        doc.setFontSize(12)
        var lines3 = doc.splitTextToSize(buisnessName, (pdfInMM3 - lMargin3 - rMargin3));
        doc.text(lMargin3, 120, lines3);

        doc.setFontSize(12)
        var lines4 = doc.splitTextToSize(balance, (pdfInMM4 - lMargin4 - rMargin4));
        doc.text(lMargin4, 150, lines4);
        doc.setFontSize(15);

        function addImageToEveryPage() {
            var totalPages = doc.internal.getNumberOfPages();

            for (var i = 1; i <= totalPages; i++) {

                doc.setPage(i);


                doc.addImage(logo, 'PNG', 30, 40, 150, 50);
            }
        }
        var pdfstyles = {
            textColor: [12, 12, 12],
            fontSize: 10,
            cellPadding: { top: 10, right: 10, bottom: 10, left: 10 },
        }
        const headers = [
            ["Date", "Transaction Type", "Amount"]];

        let bodyInfo = [];
        if (invoiceData) {
            invoiceData?.map((bal, i) => {
                var date = formatDate(bal.createdAt).slice(0, 16).replace("T", "      ")
                var transactionType = bal.transaction_type
                var amount = `$ ${Number(bal.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                bodyInfo.push([date, transactionType, amount])
            })
        }
        let content = {
            startY: 180,
            head: headers,
            headStyles: { fillColor: "#fff3ed" },
            styles: pdfstyles,
            body: bodyInfo,
            addPageContent: addImageToEveryPage,
            margin: { top: 100, right: 30, bottom: 10, left: 30 }

        }
        doc.autoTable(content);
        doc.save(`${obj.business}.pdf`);
    }

    let items = [];
    let leftSide = pageNumber - 1;

    if (leftSide <= 0) leftSide = 0;
    let rightSide = pageNumber + 2;

    if (rightSide > numberOfPages) rightSide = numberOfPages;
    for (let number = leftSide; number < rightSide; number++) {

        items.push(
            <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number) }}>
                {number + 1}
            </li>,
        );
    }


    // const pagenumberSearch = (e)=>{
    //   var pages =parseInt(e) ;
    //   var page= pages -1;
    //   if(pages<=numberOfPages){
    //     setPageNumber(page);
    //   }else if(pages>numberOfPages){
    //     setPageNumber(0);
    //   }else{
    //     setPageNumber(0);
    //   }
    //   }
    const pagenumberSearch = (e) => {
        var pages = parseInt(e);
        var page = pages - 1;
        if (pages < 1) {
            setPageNumber(0);
            setShowPage(1);
        } else if (pages > numberOfPages) {
            setPageNumber(numberOfPages - 1);
            setShowPage(numberOfPages);
        } else if (pages >= 1 && pages <= numberOfPages) {
            setPageNumber(page);
            setShowPage(pages);
        }
        else {
            setPageNumber(0);
            setShowPage(pages);
        }
    }


    const nextPage = () => {
        if (pageNumber < numberOfPages) {
            setPageNumber(pageNumber + 1);
            setShowPage(pageNumber + 2);
        }
    }

    const prevPage = () => {
        if (pageNumber > 0) {
            setPageNumber(pageNumber - 1);
            setShowPage(pageNumber);
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        setSearch(e.target.value);
        setPageNumber(0);
    };

    useEffect(() => {
        setIsLoading(true);
        getTransactionData(pageNumber, startDate, endDate, custFilter);
        redirectToLogin();
        setIsLoading(false);
    }, [pageNumber, search, sort, custFilter]);

    
    const downloadHistory = (idd, invoice) => {

        axios
          .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/download-invoice/${id}/${idd}/${invoice}`)
          .then((res)=>{
            if (res.data.status == "success") {
              toast.success(res.data.message);
              const url =res.data.data
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                `FileName.pdf`,
              );
          
              document.body.appendChild(link);
          
              link.click();
          
              link.parentNode.removeChild(link);
            } else {
              toast.warning(res.data.message);
            }
          })
          .catch((err)=>{
            console.log(`Error ${err}`);
          })
    
      }
    
    
    const userID = localStorage.getItem('client');
    const users = JSON.parse(userID);
    const userIds = users?.user._id;


    const formatDate = (date) => {
        // Convert the date to EST time zone
        const estDate = new Date(date);
        const estOptions = {
            timeZone: "America/New_York",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
        };

        // Format the date as per the EST time zone, without seconds
        return estDate.toLocaleString("en-US", estOptions).replace(",", "");
    };


    const handleSelect = (date) => {
        let filtered = allproducts.filter((product) => {
            let productDate = new Date(product["createdAt"]);
            return (
                productDate >= date.selection.startDate &&
                productDate <= date.selection.endDate
            );
        })
        setStartDate(date.selection.startDate);
        setEndDate(date.selection.endDate);
        setCustomerData(filtered);
        getTransactionData(pageNumber, date?.selection?.startDate, date?.selection?.endDate);
        setNumberOfPages(0);
        setPageNumber(0);
        setShowPage(1);
    }


    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [credit_amount, setCredit_amount] = useState();
    const [debit_amount, setDebit_amount] = useState();
    const [payment_type, setPayment_type] = useState("");
    const [amountShow, setAmountShow] = useState(false);
    const [availableBalance, setAvailableBalance] = useState('');
    const [availableBalance1, setAvailableBalance1] = useState('');
    const [valueType, setValueType] = useState('');
    const [amount, setAmount] = useState();
    const [buttonClicked, setButtonClicked] = useState(false);
    const handleamt = (value, valueType) => {
        if (valueType === "Deduct") {
            if (payment_type === "Credit" && parseFloat(availableBalance) >= Number(value)) {
                setButtonClicked(false)
            } else if (payment_type === "Funds" && Number(availableBalance1) <= Number(value)) {
                setButtonClicked(false)
            }
        }
        if (value === "" || value === undefined) {
            setButtonClicked(false)
        }
        let input = value?.replace(/[eE-]/g, '');
        const parts = input?.split('.');
        if (parts?.length > 1) {
            const decimalPart = parts[1]?.slice(0, 2);
            input = parts[0] + (decimalPart?.length > 0 ? '.' + decimalPart : '');
        }
        setAmount(input);
    }
    const [showModal, setShowModal] = useState(false);
    const [formValues, setFormValues] = useState({
        payment_type: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getPaymentData = () => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getfunds/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                if (res.data.business.payment_type === "Credit") {
                    let roundedBal = Number(res.data.business.available_balance)?.toFixed(2);
                    setAvailableBalance(roundedBal);
                }
                if (res.data.business.payment_type === "Funds") {
                    let roundedBal1 = Number(res.data.business.available_balance)?.toFixed(2);
                    setAvailableBalance1(roundedBal1);
                }
                const roundedDebitamt = Number(res.data.business.debit_amount)?.toFixed(2);
                const roundedCreditamt = Number(res.data.business.credit_amount)?.toFixed(2);
                setCredit_amount(roundedCreditamt);
                setDebit_amount(roundedDebitamt);
                setPayment_type(res.data.business.payment_type);
            })
            .catch((err) => {
                console.error(err);
            });
    }
    useEffect(() => {
        getPaymentData()
    }, [])


    const handleSubmit = (values) => {
        // if (values.payment_type === "") {
        //     toast.warning("Please Select Payment Type");
        //     return false;
        // }
        if (payment_type !== "Invoice" && amount === undefined) {
            toast.warning("Please Add An Amount");
            return false;
        }

        let payload;
        if (payment_type === "Funds") {
            payload = {
                amount: amount,
                value_type: "Add",
                business_partner_id: id,
                credit_amount: "0.00",
                debit_amount: amount,
                payment_type: payment_type,
                reference_id:  values.reference_id === undefined ? "" : values.reference_id
            }
        } else if (payment_type === "Credit") {
            payload = {
                amount: amount,
                value_type: "Add",
                business_partner_id: id,
                credit_amount: amount,
                debit_amount: "0.00",
                payment_type: payment_type,
                reference_id: values.reference_id === undefined ? "" : values.reference_id
            }
        } else {
            payload = {
                amount: amount,
                value_type: "Add",
                business_partner_id: id,
                payment_type: payment_type,
                reference_id:  values.reference_id === undefined ? "" : values.reference_id
            }
        }
        axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/addfunds`, payload, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            setIsSubmitting(true);
            toast.success(res.data.message);
            setTimeout(() => {
                setAmount();
                // setPayment_type('');
                setIsSubmitting(false);
                setShowModal(false);
                getTransactionData(pageNumber, startDate, endDate, custFilter);
            }, 100);
        }).catch((err) => {
            console.error(err);
        });

    };

    const handleCloseModal = () => {
        setShowModal(false);
        setAmount();
        // setPayment_type('');
    };

    return (
        <>
            <div className="p-3 p-lg-5">
                {props?.admin ? <div className="d-flex mb-4 align-items-center">
                    <Link
                        to="/admin/businesspartners"
                        className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
                        variant="white"
                    >
                        <span className="me-2 fw-semibold">
                            <img
                                src="/assets/images/back-org.png"
                                className="img-fluid"
                                alt=""
                            />
                        </span>
                        <span>Back</span>
                    </Link>
                    <h1 className="font20 text-dark">Funding History</h1>
                </div> : <div className="d-flex mb-4 align-items-center">
                    <h1 className="font20 text-dark">Business partner Funding History </h1>
                </div>}
                <Container fluid className="bg-white p-4 rounded shadow">
                    <Row className="g-3 mb-3 justify-content-between">
                        <Col xs="auto" xxl="auto">
                            <Row className="g-3">
                                <Col xs="12" sm="auto">
                                    <div className="search-btn">
                                        <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                                            <Form.Control
                                                type="text"
                                                className="border-0 searchInput"
                                                placeholder="Search"
                                                onChange={handleSearch}
                                            />
                                            <Button
                                                variant="primary"
                                                title="Click here to search"
                                                className="searchBtn newCustomButton d-flex justify-content-center align-items-center"
                                                style={{
                                                    backgroundColor: 'var(--newMainBlue)',
                                                    borderColor: 'var(--newMainBlue)',
                                                }}
                                            >
                                                <img
                                                    src="/assets/images/search.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="12" sm="auto">
                                    <div className="custom-select select-item">
                                        <Select
                                            styles={customStyles}
                                            onChange={handleChange}
                                            options={getCustomer}
                                            defaultValue={{
                                                value: "Filter by:All",
                                                label: "Filter by:All",
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="12" sm="auto" xxl="auto">
                            <Row className="g-2 g-sm-3"> 
                                <Col xs="12" sm="auto">
                                    <div className="date-range-modal">
                                        <Button variant="primary"
                                            type="button"
                                            className="fullRounded newCustomButton fw-bold font14 dateRangeBtn"
                                            style={{
                                                backgroundColor: 'var(--newOrange)',
                                                borderColor: 'var(--newOrange)',
                                            }}
                                            onClick={handleShow}>
                                            <span>Search With Date</span>
                                        </Button>
                                        <Modal
                                            show={show}
                                            onHide={handleClose}
                                            size="lg"
                                            centered
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title>Date Range</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} 
                                                rangeColors={['var(--newMainBlue)']}
                                                color="var(--newMainBlue)"
                                                />
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </Col>
                                <Col xs="12" sm="auto">
                                    <div className="date-range-modal">
                                        <Button variant="primary" type="button" className="fullRounded newCustomButton fw-bold font14 dateRangeBtn " style={{
                                            backgroundColor: 'var(--newOrange)',
                                            borderColor: 'var(--newOrange)',
                                        }} onClick={() => setShowModal(true)}>
                                            <span>Add Payment</span>
                                        </Button>
                                        {/* Modal for payment form */}
                                        <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Payment & Invoice</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Formik
                                                    enableReinitialize={true}
                                                    initialValues={formValues}
                                                    onSubmit={handleSubmit}
                                                >
                                                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                                        <Form onSubmit={handleSubmit}>
                                                            <h2 className="font16 mb-2 text-dark">Add Payment</h2>
                                                            <Row className='mt-2 py-2'>
                                                                <Col md={6}>
                                                                    <Form.Group className="mb-3">
                                                                        <Form.Label className="font12 text-dark fw-medium">
                                                                            Payment Type{' '}
                                                                            <span className="text-danger">*</span>
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            className="font12 w-100"
                                                                            name="payment_type"
                                                                            value={payment_type}
                                                                            disabled
                                                                            style={{
                                                                                border: errors.payment_type && touched.payment_type ? '1px solid red' : '1px solid #cfc6c6',
                                                                                height: '31px',
                                                                                borderRadius: '5px'
                                                                            }}
                                                                            isValid={touched.payment_type && !errors.payment_type}
                                                                            isInvalid={!!errors.payment_type}
                                                                        />
                                                                        {errors.payment_type && touched.payment_type ? (
                                                                            <div className="text-danger font12">
                                                                                {errors.payment_type}
                                                                            </div>
                                                                        ) : null}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={6}></Col>
                                                                {/* <Col md={6}></Col> */}
                                                                {payment_type === 'Credit' ? (
                                                                    <Col md={4}>
                                                                        <Form.Group className="mb-3" controlId="formBasicText7">
                                                                            {payment_type ? (
                                                                                <Form.Label className="font12 text-dark fw-medium">
                                                                                    {payment_type}{' '}
                                                                                </Form.Label>
                                                                            ) : (
                                                                                <Form.Label className="font12 text-dark fw-medium">
                                                                                    Credit{' '}
                                                                                </Form.Label>
                                                                            )}
                                                                            <Form.Control
                                                                                className="font12"
                                                                                name="credit_amount"
                                                                                value={amountShow
                                                                                    ? Number(availableBalance)?.toString()?.replace(
                                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                                        ','
                                                                                    )
                                                                                    : Number(availableBalance)?.toString()?.replace(
                                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                                        ','
                                                                                    )
                                                                                }
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                type="text"
                                                                                placeholder="Enter credit amount"
                                                                                isValid={touched.credit_amount && !errors.credit_amount}
                                                                                isInvalid={!!errors.credit_amount}
                                                                                disabled
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                ) : (
                                                                    <>
                                                                        {payment_type === 'Funds' ? (
                                                                            <Col md={4}>
                                                                                <Form.Group className="mb-3" controlId="formBasicText7">
                                                                                    {payment_type ? (
                                                                                        <Form.Label className="font12 text-dark fw-medium">
                                                                                            {payment_type}{' '}
                                                                                        </Form.Label>
                                                                                    ) : (
                                                                                        <Form.Label className="font12 text-dark fw-medium">
                                                                                            Funds{' '}
                                                                                        </Form.Label>
                                                                                    )}
                                                                                    <Form.Control
                                                                                        className="font12"
                                                                                        name="debit_amount"
                                                                                        disabled
                                                                                        value={amountShow
                                                                                            ? parseFloat(availableBalance1)
                                                                                                .toFixed(2)
                                                                                                .toString()
                                                                                            : parseFloat(availableBalance1)
                                                                                                .toFixed(2)
                                                                                                .toString()
                                                                                        }
                                                                                        onChange={handleChange}
                                                                                        onBlur={handleBlur}
                                                                                        type="number"
                                                                                        placeholder="Enter funds amount"
                                                                                        isValid={touched.debit_amount && !errors.debit_amount}
                                                                                        isInvalid={!!errors.debit_amount}
                                                                                    />
                                                                                    {errors.debit_amount && touched.debit_amount ? (
                                                                                        <div className="text-danger font12">
                                                                                            {errors.debit_amount}
                                                                                        </div>
                                                                                    ) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <Col md={6}></Col>
                                                                        )}
                                                                    </>
                                                                )}
                                                                <Col md={6}></Col>
                                                                {payment_type !== "Invoice" && payment_type !== "" ?
                                                                    <Col md={4}>
                                                                        <Form.Group className="mb-3" controlId="formBasicText7">
                                                                            <Form.Label className="font12 text-dark fw-medium">
                                                                                Add {payment_type}{' '}
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className="font12"
                                                                                name="credit_amount"
                                                                                value={amount}
                                                                                onChange={(e) => { handleamt(e.target.value, valueType); }}
                                                                                type="number"
                                                                                placeholder="Enter amount"
                                                                            />
                                                                        </Form.Group>
                                                                    </Col> : ''}

                                                            </Row>
                                                            {payment_type && payment_type !== "Invoice" ? (
                                                                <Col md={4}>
                                                                    <Form.Group className="mb-3" controlId="formReferenceId">
                                                                        <Form.Label className="font12 text-dark fw-medium">Reference Id</Form.Label>
                                                                        <Form.Control
                                                                            className="font12"
                                                                            name="reference_id"
                                                                            value={values.reference_id}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="text"
                                                                            placeholder="Enter reference ID"
                                                                            isValid={touched.reference_id && !errors.reference_id}
                                                                            isInvalid={!!errors.reference_id}
                                                                        />
                                                                        {errors.reference_id && touched.reference_id ? (
                                                                            <div className="text-danger font12">
                                                                                {errors.reference_id}
                                                                            </div>
                                                                        ) : null}
                                                                    </Form.Group>
                                                                </Col>
                                                            ) : null}

                                                            <Row className="mt-4">
                                                                <Col>
                                                                    <Button
                                                                        type="submit"
                                                                        variant="primary"
                                                                        className="px-4 py-2 fullRounded newCustomButton fw-bold shadowPrimary font12"
                                                                        style={{
                                                                            backgroundColor: 'var(--newOrange)',
                                                                            borderColor: 'var(--newOrange)',
                                                                        }}
                                                                        disabled={isSubmitting}
                                                                    >
                                                                        {isSubmitting ? 'Submitting...' : 'Save Changes'}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </Col>
                            </Row>  
                        </Col>
                    
                    </Row>
                    <Row className="align-items-center">
                        <Container>
                            <Row>
                                {/* <Col xl={7} xxl={8} lg={7} className="mt-1" > */}
                                <Col lg={12} className="mt-1" >
                                    <div className="text-dark font12">
                                        <div className="d-flex align-items-center w-100 ms-0"> {
                                            apiResponse ?
                                                <h3 className="font16 text-dark">Business Partner ID : {businessPartner_data?.business_partner_id}</h3>
                                                :
                                                ""
                                        }
                                        </div>
                                        <div className="d-flex align-items-center w-100 ms-0">
                                            {
                                                apiResponse ?
                                                    <h3 className="font16 text-dark">Business Partner Name : {businessPartner_data?.business_partner_name}</h3>
                                                    : ""
                                            }
                                        </div>
                                        <div className="d-flex align-items-center w-100 ms-0">
                                            {
                                                apiResponse ?
                                                    <h3 className="font16 text-dark">Total Balance in Account :  ${Number(monetaryData?.totalBonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h3>
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </Col>
                                

                            </Row>
                        </Container>
                        <Col md={12} className="mt-4">
                            <div className="table-responsive">
                                <table className="table " style={{ verticalAlign: "middle" }}>
                                    <thead className="bgLightRed font14">
                                        <tr className="text-center">

                                            <th className="py-3">
                                                {" "}
                                                <div className="d-flex align-items-center justify-content-center ">
                                                    {" "}
                                                    <span className="me-1 "> Date</span>{" "}
                                                    {updateorder === "ASC" ? (
                                                        <FaSortDown onClick={() => updatesorting("DSC")} />
                                                    ) : (
                                                        <FaSortUp onClick={() => updatesorting("ASC")} />
                                                    )}
                                                </div>{" "}
                                            </th>
                                            <th className="py-3">
                                                <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                                                    <span className="me-1">Transaction Type</span>
                                                </div>
                                            </th>
                                            <th className="py-3">
                                                <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                                                    <span className="me-1">Customer Name</span>
                                                </div>
                                            </th>
                                            <th className="py-3">
                                                {" "}
                                                <div className="d-flex align-items-center justify-content-center ">
                                                    {" "}
                                                    <span className="me-1 ">Amount</span>{" "}
                                                </div>
                                            </th>
                                            <th className="py-3">
                                                {" "}
                                                <div className="d-flex align-items-center justify-content-center ">
                                                    {" "}
                                                    <span className="me-1 ">Payment Status</span>{" "}
                                                </div>
                                            </th>
                                            <th className="py-3">
                                                {" "}
                                                <div className="d-flex align-items-center justify-content-center ">
                                                    {" "}
                                                    <span className="me-1 ">Invoice Name</span>{" "}
                                                </div>
                                            </th>
                                            <th className="py-3">
                                                {" "}
                                                <div className="d-flex align-items-center justify-content-center ">
                                                    {" "}
                                                    <span className="me-1 ">Action</span>{" "}
                                                </div>
                                            </th>



                                        </tr>
                                    </thead>
                                    <tbody className="font14 fw-medium text-center">

                                        {isLoading === true ? (
                                            <tr style={{ backgroundColor: "#eee" }}>
                                                <td colSpan="8">
                                                    <Bars
                                                        height="80"
                                                        width="100%"
                                                        margin='auto'
                                                        color="var(--newMainBlue)"
                                                        ariaLabel="bars-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                    />
                                                </td>
                                            </tr>
                                        ) :(<>
                                        
                                        {noData ? 
                                            <>
                                                 <tr >
                                                    <td colSpan="8">
                                                        <h1 className="font16 text-dark">No Transactions</h1>
                                                    </td>
                                                </tr> 
                                            </>:   (
                                            customerData?.map((cust) => (
                                                <tr
                                                    key={cust._id}
                                                >
                                                    <td className="py-3 font14 fw-medium ">
                                                        {/* {cust.createdAt.slice(0, 16).replace("T", "      ")} */}
                                                        {formatDate(cust.createdAt).slice(0, 16).replace("T", "      ")}
                                                    </td>

                                                    <td className="py-3 font14 fw-medium">
                                                        {cust.transaction_type}
                                                    </td>
                                                    <td className="py-3 font14 fw-medium">
                                                        {cust.customer_name}
                                                    </td>
                                                    <td className="py-3 font14 fw-medium ">
                                                        {/* {cust.transaction_type === "Funds Updated" || "Created"? ( */}
                                                        {cust.deduct ? (
                                                            <span style={{ color: "red" }}>
                                                                -
                                                            </span>
                                                        ) : (
                                                            <span style={{ color: "green" }}>
                                                                +
                                                            </span>
                                                        )}
                                                        {/* ) : ""} */}

                                                        ${Number(cust.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    </td>

                                                    <td className="py-3 font14 fw-medium ">{cust.payment_status}</td>
                                                    <td className="py-3 font14 fw-medium ">{cust.invoice}</td>

                                                    <td className="py-3 font14 fw-medium">
                                                            {cust.invoice?
                                                        <span className="ms-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Download" style={{ fontSize: '18px' }}
                                                            onClick={() => downloadHistory(cust._id, cust.invoice)}
                                                        >
                                                            <FcDownload />
                                                        </span>:""}
                                                    </td>

                                                </tr>
                                            )))
                                        } </>)
                                         }
                                    </tbody>
                                </table>
                            </div>
                        </Col>

                        {/* {startDate? '':<> */}

                        {
                            numberOfPages === 0 ? "" :
                                <Col className="mt-5 mb-4">
                                    {
                                        <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                                            {pageNumber === 0 ? (
                                                ""
                                            ) : (

                                                <li onClick={prevPage}>Previous </li>

                                            )}


                                            {items}


                                            {numberOfPages - 1 === pageNumber ? (
                                                ""
                                            ) : (
                                                <li onClick={nextPage}>Next</li>
                                            )}
                                            <li className="font14"><input style={{ width: "25px", border: '1px solid var(--newMainBlue)', borderRadius: '4px', textAlign: 'center' }} value={showPage ? showPage : ''} type="text" name="number" id="" onChange={(e) => {
                                                pagenumberSearch(e.target.value)
                                            }}  ></input> <span style={{ color: 'var(--newMainBlue)' }}>/</span> {numberOfPages}</li>
                                        </ul>
                                    }
                                </Col>
                        }
                        {/* </>
  } */}
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default BusinessAcountHistory
