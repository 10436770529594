// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new_layer{
    z-index: 999999999!important;
}
.new_layer::before, .new_layer::before  {
    content: "";
    background: #00000087;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
}`, "",{"version":3,"sources":["webpack://./src/pages/onboard.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;AACA;IACI,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,MAAM;IACN,OAAO;IACP,UAAU;AACd","sourcesContent":[".new_layer{\n    z-index: 999999999!important;\n}\n.new_layer::before, .new_layer::before  {\n    content: \"\";\n    background: #00000087;\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    z-index: 9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
