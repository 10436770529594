import React, { useState } from 'react';
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

const PaymentConfirmationModal = ({ handleClose, hasSufficientFunds, value, btnColor, btnText, rFund, onChangeFunction, paymentOption, onConfirm, reaminingBonus, isFixed,handleOpen}) => {
    // const [paymentOption, setPaymentOption] = useState('cash'); 
    const [showInsufficientFunds, setShowInsufficientFunds] = useState(false);   
    const defaultNcentiva = localStorage.getItem("defaultNcentivaCms");
    const handleConfirm = () => {
        // if (!hasSufficientFunds) {
        //     setShowInsufficientFunds(true);
        // } else {
            onConfirm(paymentOption, isFixed);
            handleClose();
        // }
    };
    if (value == "") {
        value = 0.00
    }

if ( typeof reaminingBonus === Number) {
    reaminingBonus.toString();
}
    return (
        <>
            <Modal show={handleOpen} onHide={handleClose} backdrop="static" keyboard={false} size="md" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Payment Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col>
                        <Row className="modal-row">
                            <Col md={6} style={{margin: "-10px"}}>
                                <Form.Check
                                    className="radio-right selectPaymentType" 
                                    type="radio"
                                    label="Balance"
                                    name="paymentOption"
                                    id="cashRadio"
                                    value="cash"
                                    checked={paymentOption === 'cash'}
                                    onChange={(e) => onChangeFunction(e)}
                                    disabled={parseFloat(rFund) < parseFloat(value)}
                                />
                                <div>
                                    {
                                        parseFloat(rFund) >= 0 ? 
                                        <p className="mb-0 balanceAvailblity">Available Balance: ${rFund}</p>
                                    : <p className="mb-0 balanceAvailblity">Available Balance: $0.00    </p>}
                                        </div>{parseFloat(rFund) >= parseFloat(value)
                                            ? "" :
                                            <div>
                                            <p className="mb-0 insufficientCheck">Insufficient Balance</p>
                                            </div>}
                                <Form.Check
                                    className="radio-right selectPaymentType"
                                    type="radio"
                                    label="Bonus Cash"
                                    name="paymentOption"
                                    id="walletRadio"
                                    value="wallet"
                                    checked={paymentOption === 'wallet'}
                                    onChange={(e) => onChangeFunction(e)}
                                    disabled={parseFloat(reaminingBonus) < parseFloat(value)}
                                />
                                <div>
                            <p className="mb-0 balanceAvailblity">Available Bonus Cash: ${Number(reaminingBonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                        </div>
                                {Number(reaminingBonus) >= Number(value) ? "" :
                                    <div>
                                        <p className="mb-3 insufficientCheck">Insufficient Bonus Cash</p>
                                    </div>}
                            </Col>
                            {isFixed?<Col md={6}>
                                <p style={{marginBottom:'10px', fontSize:'small'}} className="mt-4 mt-md-0 "> 
                                     <span style={{font:'message-box'}}>**</span>The "Bonus Cash" generated for the fixed-value gift cards in this transaction will be accumulated on the "Bonus Cash" account after checkout.
                                </p>
                            </Col>: null}
                        </Row>
                        <Row className="modal-row">
                            {/* <Col md={12}>
                                {showInsufficientFunds && <p className="text-danger">Insufficient Bonus</p>}
                            </Col> */}
                        </Row>
                        <Row className=" paymentConfirmRow">
                            {/* {isFixed?<Col>
                                <p style={{marginBottom:'10px', fontSize:'small'}}> 
                                     <span style={{font:'message-box'}}>**</span>The "Bonus Cash" generated for the fixed-value gift cards in this transaction will be accumulated on the "Bonus Cash" account after checkout.
                                </p>
                            </Col>: null} */}
                            <Col>
                            <div className=''>
                                {paymentOption == "" ?
                                    <Button 
                                    // style={{ color: btnText, background: btnColor, border: `1px solid ${btnColor}` }} 
                                    style={defaultNcentiva === "true"
                                        ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                        : defaultNcentiva === "false" ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` } : null
                                      }
                                    className="procedBtn" disabled>
                                        <span><b>Proceed</b></span>
                                    </Button>
                                    :
                                    <Button 
                                    // style={{ color: btnText, background: btnColor, border: `1px solid ${btnColor}` }} 
                                    style={defaultNcentiva === "true"
                                        ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                        : defaultNcentiva === "false" ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` } : null
                                      }
                                    className="procedBtn button_hover" onClick={handleConfirm}>
                                        <span><b>Proceed</b></span>
                                    </Button>
                                }
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PaymentConfirmationModal;
