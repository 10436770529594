import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FaLongArrowAltLeft } from 'react-icons/fa'

const ReturnBtn = props => {
  return (
    <Link replace to={props.to} className="return-btn">
        <FaLongArrowAltLeft className="me-1"/> Return
    </Link>
  )
}

ReturnBtn.propTypes = {
    return: PropTypes.string
}

export default ReturnBtn
