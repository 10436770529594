import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./viewcardTab.scss"
import { useEffect, useState } from 'react';

function ViewCardTab(props) {
  const [activeKey, setActiveKey] = useState('home');
  function hexToRgba(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  useEffect(() => {
    const applyActiveTabStyle = () => {
      const allTabs = document.querySelectorAll('.nav-link');
      const activeTab = document.querySelector(`.nav-link[data-rr-ui-event-key="${activeKey}"]`);
    
        allTabs.forEach(tab => {
          tab.style.backgroundColor = '';
          tab.style.color = '';
          tab.style.border = '';
        });
    
        if (activeTab) {
          const rgbaColor = props.defaultNcentiva ==="true" ? hexToRgba(props.btnColor, 1) :props.defaultNcentiva ==="false" ? props.btnColor : null;
          activeTab.style.backgroundColor = rgbaColor;
          activeTab.style.color = props.btnText;
          activeTab.style.border = `1px solid transparent`;
        }
      
    };
    

    applyActiveTabStyle();
    window.addEventListener('resize', applyActiveTabStyle);

    return () => {
      window.removeEventListener('resize', applyActiveTabStyle);
    };
  }, [activeKey, props.btnColor, props.btnText]);


  return (
    
    <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3 d-flex custom-tabs-destails flex-column "
      onSelect={(k) => setActiveKey(k)}
    >
      <Tab eventKey="home" title="Description" >
        <div className="mt-2">
          <p style={{ whiteSpace: 'pre-line' }}>{props.description}</p>
        </div>
      </Tab>
      <Tab eventKey="profile" title="Redemption Instructions" >
        <div className="mt-2 mb-5">
          <p style={{ whiteSpace: 'pre-line' }}>{props.redemptionInstructions}</p>
        </div>
      </Tab>
      <Tab eventKey="terms" title="Terms and Conditions">
        <div>
          <p style={{ whiteSpace: 'pre-line' }}>{props.termsAndConditions}</p>
        </div>
      </Tab>
      {props?.availableDenominations?.length > 0 ? <Tab eventKey="denomination" title="Available Denominations">
        <div className="mt-2 d-flex flex-wrap">
          {props?.availableDenominations?.map((elem) => (
            <div key={elem._id} style={{ border: '1px solid #dee2e6' }} className='me-1 mt-1'>
              <p className='p-2'>${(+elem.faceValue).toFixed(2)}</p>
            </div>
          ))}
        </div>
      </Tab> : ''}
    </Tabs>
  );
}

export default ViewCardTab;