import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import "../styles/page/Login.scss";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CryptoJS from 'crypto-js';
import * as yup from "yup";

const Login = () => {
  useEffect(() => {
    document.title = "ncentiva || Admin Login";
  }, []);

  const [stillSendingOtp, setStillSendingOtp] = useState(false)
  const [validated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const navigate = useNavigate();
  var token;
  useEffect(() => {
    redirectToDashboard();
  });

  const FormSchema = yup.object().shape({
    pass: yup
      .string()
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
  });

  const redirectToDashboard = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token !== null) {
      navigate("/admin/dashboard");
    }
  };




  const [isSms, setIsSms] = useState(false);

  const handleInnerCircleClick = () => {
    setIsSms(!isSms);
  };
  const [showRadio, setShowRadio] = useState(false);
  const handleCaretClick = () => {
    setShowRadio(!showRadio);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const handleSubmit = async (e) => {
    setTimeout(() => setStillSendingOtp(true), 100)
    e.preventDefault();
    e.stopPropagation();
    if (!email.trim()) {
      toast.warning("Email is required");
      setTimeout(() => setStillSendingOtp(false), 2000)
      return;
    }
    if (!isValidEmail(email)) {
      toast.error("Invalid email format");
      setTimeout(() => setStillSendingOtp(false), 2000)
      return;
    }
    if (!password.trim()) {
      toast.warning("Password is required");
      setTimeout(() => setStillSendingOtp(false), 2000)
      return;
    }
    const credentials = {
      email: email,
      password: password,
      timestamp: Date.now()
    };
    const jsonString = JSON.stringify(credentials);
    const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();
    

    try {

      const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/user/otp`, {
        securedLogin,
        isSms
      });


      if (data.status === "success") {
        if (data?.user?.new === true) {
          window.localStorage.setItem('admin_email', email)
          navigate(`/admin/setpassword/${data?.user?._id}`)
          setStillSendingOtp(false)
        }
        else {
          const dataToSave = {
            "status": data?.status,
            "data": data?.data
          };
          window.localStorage.setItem('user', JSON.stringify(dataToSave));
          navigate(`/admin/verify?isSms=${isSms}`)
          setStillSendingOtp(false)
          toast.success(data.message);
        }
      } else if (data.status === "failed") {
        setTimeout(() => setStillSendingOtp(false), 2000)
        toast.error(data.message)
      }
    } catch (err) {
      console.log(`Error : ${err}`);
      toast.error(err?.response?.data?.message || 'An error occurred');
      setTimeout(() => setStillSendingOtp(false), 2000)
    }

  }
  function keyDown(e) {
    var e = window.event || e;
    var key = e.keyCode;
    //space pressed
    if (key == 32) { //space
      e.preventDefault();
    }

  }

  function checkWhitespace(event) {
    event.preventDefault();
    var data = event.clipboardData.getData("text/plain");
    var trimmedData = data.trim();
    
    if (trimmedData.length > 0) {
      document.execCommand('insertText', false, trimmedData);
    }
  }




  //   if(password === '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/'){
  //     console.log('error')
  //   }

  return (
    <>
      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ height: "100vh", backgroundColor: "var(--newLightBlue)" }}
      >
        <Container
          className="bg-white loginContainer p-0 rounded"
          style={{ maxWidth: "1065px" }}
        >
          <div className="d-flex align-items-center justify-content-center">
           <Link to="/admin">
                <img
                    // src="/assets/images/logo.png"
                    src="https://ncentiva-development.s3.eu-west-1.amazonaws.com/ncentiva_new_logo.png"
                    className="imgLogo img-fluid mt-4"
                    alt=""
                    style={{
                      width: '200px',
                      height: '100px',
                      objectFit: 'contain'
                    }}
                  />  
           </Link>
          </div>
          <Row className="align-items-center g-0">
            <Col
              md="6"
              style={{ height: "100%", borderRight: "1px solid #ccc" }}
              className="loginLeft j_login_left"
            >
            <h1 className="loginH1Title text-dark mb-3">Login</h1>
              <div className="pt-3">
                {/* <h1 className="loginH1Title text-dark mb-3">Login</h1> */}
                <Form
                  className="form "
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="text-dark">Email</Form.Label>
                    <Form.Control
                      type="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Email"
                      onKeyPress={(e) => keyDown(e)}
                      onPaste={(e) => checkWhitespace(e)}
                      // value={email}




                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className=" text-dark">Password</Form.Label>




                    <div className="position-relative">
                      <Form.Control type={passwordType} 
                      onChange={(e) => {
                        let trimValue = e.target.value; 
                        trimValue = trimValue.replace(/\s/g, '')
                        setPassword(trimValue)}}
                        //  value={password} 
                         name="password" placeholder="Enter Password" 
                        onPaste={(e) => checkWhitespace(e)}
                        />
                      {/* {password.length > 0 && ( */}
                      <button
                        type="button"
                        className="bg-transparent border-0 position-absolute eyBtn"
                        onClick={togglePassword}
                      >
                        {passwordType === "password" ? <FaEyeSlash /> : <FaEye />}


                      </button>
                      {/* )} */}

                    </div>








                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Link
                      className="text-end font14 fw-medium"
                      style={{color:"var(--newMainBlue)"}}
                      to={`/send-password-reset-email`}
                    >
                      Forgot Password ?
                    </Link>
                  </Form.Group>

                  <Form.Group className="mb-3">

                    {
                      stillSendingOtp ?
                        <Button
                          type="submit"
                          variant="primary"
                          // style={{backgroundColor:btnColor,color:btnText,border:`1px solid ${btnColor}`}}
                          className="w-100 fullRounded newCustomButton font12 fw-bold py-3 shadowPrimary"
                           style={{
                                  backgroundColor: 'var(--newOrange)',
                                  // borderColor: 'var(--newOrange)',
                                  border: '1px solid var(--newOrange)'
                                }}
                          disabled>Please wait ....</Button> :
                        <>
                          <Button
                            type="submit"
                            variant="primary"
                            className="w-100 fullRounded newCustomButton font12 fw-bold py-3 shadowPrimary"
                            style={{
                                  backgroundColor: 'var(--newOrange)',
                                  // borderColor: 'var(--newOrange)',
                                  border: '1px solid var(--newOrange)',
                                  }}
                          >Login</Button>
                        </>
                    }
                  </Form.Group>
                </Form>




              </div>
              <Link style={{ color: "var(--newMainBlue)" }} target="_blank" to="/api/docs">API Documentation</Link>
            </Col>
            <Col
              md="6"
              className="loginRight j_login_right  align-items-center"
              style={{ height: "100%" }}
            >
              <div>
                <p className="verification-text text-dark">
                  <span className="verification-title" style={{fontWeight:'500'}}>One time password verification.</span> <br /><br />
                  We will send an OTP code to your email.<br />
                  Please use it before it expires within 5 minutes.<br />
                  <br />
                  <span
                    style={{ fontWeight: 'lighter', cursor: 'pointer' }} onClick={handleCaretClick}
                    className={`use-another-method ${showRadio ? 'visible' : 'hidden'}`}
                  >
                    Use another method

                    <span className="ms-2" >
                      {!showRadio ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                          <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                        </svg>}
                    </span>
                  </span>
                  <br />
                  {showRadio ?
                    <span className="iconsss" style={{ position: 'absolute' }}>
                      <div
                        className="round-icon"
                        style={{
                          display: 'inline-block',
                          position: 'relative',
                        }}
                      >
                        {/* <input
                          type="radio"
                          id="html"
                          name="fav_language"
                          value="HTML"
                          checked={isSms}
                          onChange={handleInnerCircleClick}
                        /> */}
                        <div className="round">
                          <input
                            type="checkbox"
                            id="html"
                            name="fav_language"
                            value="HTML"
                            checked={isSms}
                            onChange={handleInnerCircleClick}
                          />
                          <label htmlFor="html"></label>
                        </div>

                      </div>
                      <span className="ms-2">
                        SMS
                      </span>
                    </span> : ""}
                </p>
              </div>
            </Col>

          </Row>
        </Container>
      </Container>
      {/* <ToastContainer position="top-center" /> */}
    </>
  );
};

export default Login;
