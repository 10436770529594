import React, { useState } from "react";
import { FaMinus, FaPlus } from 'react-icons/fa';

export default function ProductInput() {
  let [count, setCount] = useState(0);

  function incrementCount() {
    setCount(count+1);
  }
  function decrementCount() {
    if(count >= 1){
      setCount(count-1);
    }
  }
  return (
    <>
        <button
            type="button"
            className="btn listing-input-btn btn-minus me-1"
            onClick={decrementCount}
        >
            <FaMinus/>
        </button>
        <div className="inputwithunit mb-2">
            <span>$</span>
            <input
            type="number"
            className="form-control"
            value={Number(count).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            onChange={(e)=>setCount( e.target.value )}
            />
        </div>
        <button
            type="button"
            className="btn listing-input-btn btn-plus ms-1"
            onClick={incrementCount}
        >
            <FaPlus/>
        </button>
    </>
  );
}