import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import PayoutConfirm from '../components/PayoutConfirm';
const PayOut = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "ncentiva || Payout";
    const handlePopState = () => {
      navigate("/");
    };
  var data=  window.addEventListener("popstate", handlePopState);
  }, [navigate]);

  return (
    <>
      <PayoutConfirm />
    </>
  );
}

export default PayOut;
