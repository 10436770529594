import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react';
import { useEffect } from 'react';

const FooterBrand = props => {
  let img=props.src;
  let data = img.split(".");
  const [image,setImage] = useState();

  useEffect(()=>{
     if(data[data.length-1] === "undefined"){
      setImage('')
     }else{
      setImage(props.src);
     }
  },[])
  
  const FooterStyle ={
    width: '80px',
    height: '35px',
}
  return (
    <>
    {
      image?<img src={props.src} alt={props.alt} style={FooterStyle} className="me-3"/>:''
    }
    </>
  )
}

FooterBrand.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string
}
FooterBrand.defaultProps = {
    src: 'assets/images/footer-logos/gdpr.png',
    alt: 'Footer brand'
}

export default FooterBrand
