import React from 'react'
import DelightSlider from './DelightSlider'
import SiteHeader from './elements/SiteHeader'

const DelightArea = () => {
  return (
    <section className="delight py-5 overflow-hidden">
        <div className="container">
            <div className="row justify-content-center">
                <div className="delight-header text-center">
                    <div className="mb-2">
                        <SiteHeader title="Designed to delight multi-store digital gift cards"/>
                    </div>
                    <p className="text-dark fs-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                </div>
            </div>
        </div>
        <div className="my-5">
            <DelightSlider/> 
        </div>   
    </section>
  )
}

export default DelightArea
