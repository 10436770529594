import React from 'react';
import { Card } from 'react-bootstrap';
import "./businessAddCustomer.css";
import { useNavigate } from 'react-router-dom';
import { BsPlus, BsFileEarmark } from "react-icons/bs";

function BusinessAddCustomer() {
  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path);
  };

  return (
    <div className='mt-5 mx-3'>
      <div className="card-container">
        <div className="custom-card" onClick={() => handleCardClick("/partner/customers/add")}>
          <div className="icon-circle orange-circle">
            <BsPlus className="icon-style" style={{fontSize:"80px"}}/>
          </div>
          <p className="card-title">Create customer one by one</p>
        </div>
        <div className="custom-card" onClick={() => handleCardClick("/partner/Upload")}>
          <div className="icon-circle orange-circle">
            <BsFileEarmark className="icon-style" style={{fontSize:"45px"}} />
          </div>
          <p className="card-title">Create customer by file (CSV)</p>
        </div>
      </div>
    </div>
  );
}

export default BusinessAddCustomer;
