import React from 'react'
import Category from './elements/Category'
import ModalOpen from './elements/CategoryModalOpen';

const pcategories = ['Clothing & Accesories', 'Department Stores', 'Food & Restaurants', 'Streaming & Gaming', 'Travel & Experiences']

const ProductCategories = () => {
  return (
    <section className="product-categories">
        <div className="container">
            <div className="d-flex flex-wrap align-items-end">
            {pcategories.map((pcategory,i) => 
                    <Category key={i} title={pcategory} customclass="iproduct-block w-auto"/>
            )}
            <ModalOpen customclass="category-btn text-dark mt-2 mb-1 pb-0"/>
            </div>
        </div>
        </section>

  )
}

export default ProductCategories
