import React, { useState, useEffect } from "react";
import { FaChevronUp, FaTrash } from 'react-icons/fa';
import { Link, useNavigate } from "react-router-dom";
import RedeemProgress from "./elements/RedeemProgress";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getcart } from "../Redux/CartType/actions";
import WebSocketClient from 'websocket';
// import axios from "axios";
const CheckoutBar = (props) => {

    const [usedFund, setUsedFund] = useState(0);
    const [bonus, setBonus] = useState(0);
    const [rFund, setrFund] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const cartData = useSelector((state) => state.cartReducer);
    const cmsData = props.cmsData

    const defaultNcentiva = cmsData?.data?.cms?.default_ncentiva;
    // const [customerData, setCustomerData] = useState([]);

    const dispatch = useDispatch();

    let lists = cartData?.data?.cart;

    const customer = JSON.parse(window.localStorage.getItem('customer'));
    let cusId = customer?.customer?._id
    if (lists) {
        lists = lists.filter((item) => item.customer_id === cusId);
    }
    const cartList = lists
    var balance = 0;
    let customerBal = Number(customer?.customer?.balance);
    let listBalance = Number(0);
    let nBal = Number(0);
    let remaingWallet = Number(0);
    let walletUsed = Number(0);
    let walletBalance = Number(0);
    let navigate = useNavigate();
    const { ws } = props

    useEffect(() => {
        // Ensure ws exists
        if (ws) {
            // Handle incoming messages
            ws.onmessage = (event) => {
                console.log('Received message from server:', event.data);
                const message = JSON.parse(event.data);
                console.log('message', message.action);
                if (message.action === 'addItemToCart' || message.action === 'deleteItemToCart' || message.action === 'redemItemFromCart') {
                    dispatch(getcart(token));
                }
            };
        }

        // Cleanup function
        return () => {
            // No need to close the WebSocket here
        };
    }, [ws]); // Add ws as a dependency

    for (let i = 0; i < cartList?.length; i++) {
        listBalance = listBalance + parseFloat(cartList[i].cost);
        walletBalance = walletBalance + parseFloat(cartList[i].ncentiva_wallet);
        if (cartList[i].wallet === true) {
            remaingWallet += parseFloat(cartList[i].cost);
            walletUsed += parseFloat(cartList[i].cost);
        }
    }
    let reaminingBonuscash = Number(0);
    reaminingBonuscash = parseFloat(props?.wallet_balance) - parseFloat(walletUsed);
    remaingWallet = parseFloat(walletBalance) - parseFloat(remaingWallet);

    remaingWallet = parseFloat(remaingWallet) + parseFloat(props.wallet_balance)

    const [allCartData, setAllCartData] = useState([])
    let token;
    if (customer) {
        token = customer.token;
    } else {
        token = null;
    }
    // const getAllData = () => {
    //     token = customer.token;
    //     axios
    //         .get(
    //             `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/getholdcart/${cusId}`, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`
    //             }

    //         }
    //         )
    //         .then((response) => {
    //             if (response.data.message === "Please Login") {
    //                 localStorage.removeItem('customer');
    //                 navigate('/login');
    //                 toast.error("Session Expired Please Login Again")

    //             }
    //             if (response.data.status === "success") {
    //                 setAllCartData(response.data.cart);
    //             }
    //         })
    //         .catch((error) => console.error(`Error: ${error}`));
    // };

    useEffect(() => {
        // getAllData()
    }, [])


    function removeData(id, objId) {
        const localId = Number(id);
        setDisabled(true);
        setTimeout(() => {
            setDisabled(false);
        }, 3000);
        //get data from localstorage and store to contaclist array
        //we must to use JSON.parse, because data as string, we need convert to array
        var contactList;
        let carts = cartData?.data?.cart;
        if (carts) {
            carts = carts.filter((item) => item.customer_id === cusId);
        }
        const cartList = carts;
        contactList = cartList;
        let usedBalance = parseInt(0);
        for (let i = 0; i < contactList?.length; i++) {
            usedBalance = usedBalance + parseFloat(contactList[i].cost)
        }

        let deletedItem = contactList.filter(function (item) { return item.id === localId; });
        contactList = contactList.filter(function (value) {
            return value.id !== localId;
        });

        // let ncentivaBal = parseFloat(0.00);
        // let itemToRemove = contactList.filter(function (item) { return item.id !== id; });

        let localWallet = window.localStorage.getItem('ncentiva_wallet');
        let walletAmount = Number(0);
        let usedWallet = Number(0);
        for (let i = 0; i < cartList?.length; i++) {
            walletAmount = walletAmount + parseFloat(cartList[i].ncentiva_wallet)
            if (cartList[i].wallet) {
                usedWallet += parseFloat(cartList[i].cost);
            }
        }
        if (customerBal < usedBalance) {
            if (deletedItem[0]?.wallet === true) {
                nBal = parseFloat(usedWallet) - parseFloat(deletedItem[0]?.cost)
                window.localStorage.setItem('ncentiva_wallet', nBal)
                axios
                    .delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/deletecart/${objId}`)
                    .then(() => {
                        localStorage.setItem('listItem', JSON.stringify(contactList));
                        toast.success(`Deleted Successfully `);
                        if (ws) {
                            ws.send(JSON.stringify({ action: 'deleteItemToCart' }));
                        } else {
                            console.error('WebSocket connection is not established');
                        }
                        dispatch(getcart(token));
                        setTimeout(() => {
                            // window.location.reload();    
                        }, 2000);
                        return false;
                    })
                    .catch((err) => console.log(`Error ${err}`));
            } else {

                let walletBal = parseFloat(walletAmount) - parseFloat(deletedItem[0]?.ncentiva_wallet);
                walletBal.toFixed(2);
                const walletData = contactList.filter((cust) => cust.wallet === false);

                axios
                    .delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/deletecart/${objId}`)
                    .then(() => {
                        localStorage.setItem('listItem', JSON.stringify(walletData))
                        window.localStorage.setItem('ncentiva_wallet', walletBal)
                        if (ws) {
                            ws.send(JSON.stringify({ action: 'deleteItemToCart' }));
                        } else {
                            console.error('WebSocket connection is not established');
                        }
                        toast.success(`Deleted Successfully `);
                        dispatch(getcart(token));
                        setTimeout(() => {
                            // window.location.reload();
                        }, 2000);
                        return false;
                    })
                    .catch((err) => console.log(`Error ${err}`));
            }
        } else {
            for (let i = 0; i < contactList?.length; i++) {
                nBal += contactList[i].ncentiva_wallet
            }
            window.localStorage.setItem('ncentiva_wallet', nBal)

            axios
                .delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/deletecart/${objId}`)
                .then(() => {
                    localStorage.setItem('listItem', JSON.stringify(contactList))
                    if (ws) {
                        ws.send(JSON.stringify({ action: 'deleteItemToCart' }));
                    } else {
                        console.error('WebSocket connection is not established');
                    }
                    toast.success(`Deleted Successfully `);
                    dispatch(getcart(token));
                    setTimeout(() => {
                        // window.location.reload();
                    }, 2000);
                    return false;
                })
                .catch((err) => console.log(`Error ${err}`));
        }
    }
    var name;
    var rbalance;
    var id;
    const getcustomer = (id) => {
        // axios
        //     .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcustomer/${id}`)
        //     .then((response) => {


        if (cartList) {
            var sbalance = props.rbalance_redux;
            let sum = 0;
            let bonus = 0;
            let costValue = 0;
            for (var i = 0; i < cartList.length; i++) {
                if (cartList[i].wallet == false) {
                    sum += parseFloat(cartList[i].cost);
                    bonus += parseFloat(cartList[i].bonus);
                    costValue += parseFloat(cartList[i].costValue);
                }
            }

            rbalance = parseFloat(sbalance) - parseFloat(sum);
            setUsedFund(sum);
            setBonus(bonus);
            setrFund(rbalance);
            setTotalValue(costValue);
        } else {
            // setrFund(response.data.customer.balance);
            setrFund(props.rbalance_redux);
        }


        // })
        // .catch((error) => console.error(`Error: ${error}`));
    };
    if (customer) {
        id = customer?.customer?._id;

        name = customer?.customer?.first_name + " " + customer?.customer?.last_name;
    } else {
        name = null;
        balance = null;
    }

    // console.log('object',cartData.data)
    // useEffect(() => {
    //     setTimeout(() => {
    //         getcustomer(id); 
    //     }, 1000);
    // }, [usedFund, rFund, id]);

    useEffect(() => {
        if (cartData.data !== null) {
            getcustomer(id, token);
        }
    }, [cartData.data, usedFund, rFund, id]);

    const [isActive, setActive] = useState("false");
    const toggleClick = event => {
        setActive(!isActive);
    };

    let localWallet = window.localStorage.getItem('ncentiva_wallet');




    const handleLinkClick = () => {
        // setTimeout(() => {
        // window.location.href = '/checkout';
        navigate('/checkout');
        // }, 200); 
    };

    useEffect(() => {
        if (cartData?.data?.cart && cartData?.data?.cart?.length > 0) {
            setActive(false);
        } else {
            setActive(true);
        }
    }, [cartData?.data?.cart]);


    return (<section
        className={`go-checkout fixed-bottom w-100 bg-white border-top py-4 paddingBottom ${isActive ? '' : 'active'
            } ${cartData?.data?.cart && cartData?.data?.cart?.length === 1
                ? 'go-checkout-length-1'
                : cartData?.data?.cart && cartData?.data?.cart?.length > 1
                    ? 'go-checkout-length-2'
                    : 'go-checkout-default'
            }`}
    >
        {/* <a href="javascript:void(0);" className="chekout-toggle text-dark">
            <i className="far fa-chevron-up" />
        </a> */}
        <button className={isActive ? "chekout-toggle text-dark" : "chekout-toggle text-dark rotate"} onClick={toggleClick}><FaChevronUp /></button>
        <div className="container">
            <div className="row">
                <div className="col-6 col-lg-3 order-1 order-lg-1">
                    {props.primaryColor ? <div className="checkout-left border-end h-100">
                        <h5 style={{ color: props.primaryColor }}>
                            Funds Used <span>${usedFund === 0 ? 0 : Number(usedFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                        </h5>
                        <h5>
                            {/* {listBalance < customerBal && rFund >= 0.00 ? (
                                    <> */}
                            Remaining <span>${Number(rFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                            {/* </>
                                 ) : (
                                     <>
                                         Remaining <span>$0.00</span>
                                     </>
                                 )

                                 } */}
                            {/* {rFund > 0 ? (
                      <>
                        Remaining <span>${Number(rFund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                       </>
                      ) : (
                       <>
                    Remaining <span>${Math.abs(localWallet).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                    </>
                    )} */}
                        </h5>
                        <h5 >
                            Bonus <span>${Number(bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                        </h5>
                        {/* {walletUsed > 0 ? (
                                rFund > 0 ? (
                                    <h5 >
                                        Bonus Cash <span>${Number(walletUsed).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                    </h5>
                                ) :<h5 className="text-yellow">
                                Bonus Cash <span>${Number(walletUsed).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                            </h5>
                            ) : null} */}

                        {remaingWallet > 0 ? (
                            listBalance >= customerBal ?
                                <h5 className="text-yellow" style={{ color: 'rgb(245, 196, 0)' }}>
                                    Remaing Bonus Cash <span>${Number(reaminingBonuscash).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                </h5> :
                                <h5 style={{ color: 'rgb(245, 196, 0)' }}>
                                    Remaing Bonus Cash <span>${Number(reaminingBonuscash).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                </h5>) : null}
                    </div> : ''}
                </div>
                <div className="col-lg-6 col-12 order-3 order-lg-2">
                    <div className="checkout-middle h-100">
                        {listBalance < customerBal ?
                            <RedeemProgress btnColor={props.btnColor} btnText={props.btnText} primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} walletColor={"#f5c400"} lwidth="80%" rwidth="20.00%" amount={usedFund} rFund={rFund} bonus={bonus} wallet={walletUsed.toFixed(2)} />
                            :
                            <RedeemProgress btnColor={props.btnColor} btnText={props.btnText} primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} walletColor={"#f5c400"} lwidth="80%" rwidth="20.00%" amount={usedFund} rFund="0.00" bonus={bonus} wallet={walletUsed.toFixed(2)} />
                        }
                        <div className="chekout-middle-table">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        {props.btnColor ? <tr>
                                            <th />
                                            <th className="text-dark">Gift Card Cost</th>
                                            <th className="text-main">Gift Card Value</th>
                                            <th style={{ color: 'rgb(245, 196, 0)' }}>Bonus Cash</th>
                                            {/* <th /> */}
                                        </tr> : ''}
                                    </thead>
                                    <tbody>
                                        {cartData?.data?.cart && props.btnColor ? cartData?.data?.cart?.map((cust, index) => (
                                            <tr key={index}>
                                                <td className="text-uppercase">{cust.productname}</td>
                                                <td className="text-dark">${cust.cost}</td>
                                                <td className="text-main">${cust.costValue}</td>
                                                <td className="text-yellow">${Number(cust.ncentiva_wallet).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                                                <td>
                                                    {disabled ? <button type="button" className="btn delete-check p-0">
                                                        <FaTrash />
                                                    </button> : <button type="button" className="btn delete-check p-0">
                                                        <FaTrash onClick={() => {
                                                            removeData(cust.local_id, cust._id);
                                                        }} />
                                                    </button>}

                                                </td>
                                            </tr>
                                        )) : <tr><td className="text-dark"> Cart Empty</td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-6 order-2 order-lg-3 d-flex justify-content-center">
                    <div className="checkout-right h-100">
                        {cartList ?
                            <>
                                {cartList.length > 0 && props?.btnColor ?
                                    <li onClick={handleLinkClick}
                                        //  style={{backgroundColor: `${props?.btnColor}80`,color:props?.btnText,border:`1px solid transparent`}} 
                                        style={defaultNcentiva
                                            ? { backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent` }
                                            : { backgroundColor: `${props?.btnColor}`, color: props?.btnText, border: `1px solid ${props?.btnColor}` }
                                        }
                                        className="btn btn-main w-100 mt-2">
                                        Go to Checkout
                                    </li>
                                    :
                                    <>
                                        {props.btnColor ? <Link to="/checkout"
                                            //    style={{backgroundColor: `${props?.btnColor}80`,color:props?.btnText,border:`1px solid transparent`,pointerEvents:'none'}} 
                                            style={defaultNcentiva
                                                ? { backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent`, pointerEvents: 'none' }
                                                : { backgroundColor: `${props?.btnColor}`, color: props?.btnText, border: `1px solid ${props?.btnColor}`, pointerEvents: 'none' }
                                            }
                                            className="btn btn-main w-100 mt-2">
                                            Go to Checkout
                                        </Link> : ''}
                                    </>
                                }
                            </>
                            :
                            <>
                                {props.btnColor ? <Link to="/checkout"
                                    // style={{backgroundColor: `${props?.btnColor}80`,color:props?.btnText,border:`1px solid transparent`,pointerEvents:'none'}}
                                    style={defaultNcentiva
                                        ? { backgroundColor: `${props?.btnColor}80`, color: props?.btnText, border: `1px solid transparent`, pointerEvents: 'none' }
                                        : { backgroundColor: `${props?.btnColor}`, color: props?.btnText, border: `1px solid ${props?.btnColor}`, pointerEvents: 'none' }
                                    }
                                    className="btn btn-main w-100 mt-2">
                                    Go to Checkout
                                </Link> : ''}
                            </>}
                    </div>
                </div>
            </div>
        </div>
    </section>

    )
}

export default CheckoutBar
