  import { useRef, useState, useEffect } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { Bars } from "react-loader-spinner";
import Select from 'react-select';
import ModalOpen from '../components/elements/BusinessPartnercategories';
import Modal from "react-bootstrap/Modal";
import BuisnessForm from "../components/BuisnessForm";
import BrandCategorys from '../components/elements/BusinessCategorys';
import { useDispatch, useSelector } from 'react-redux';
import { FaSortUp, FaSortDown } from "react-icons/fa";
import {BiPlusMedical} from "react-icons/bi";
import { ClearhandleCategories } from "../Redux/SendCategories/action";
import csvSVG from "../assets/images/icons8-csv-64.png"
import "../../src/App.scss";
import { getNewToken } from "../config/cookie.config";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const BusinessPartner = () => {
  const btnAble ={
    width:'133px',
    height:'36px'
  }
  const btnDisable = {
    width:'133px',
    height:'36px',
    backgroundColor:'whitesmoke',
    color:'gray',
    cursor:'none',
    border:'none'}
  const inputFileRef = useRef(null);
  const { id } = useParams();
  
  // const { bid } = useParams();
  const [name, setName] = useState("");
  const [checkAdd,setCheckAdd] = useState(false);
  const [business_name, setBusinessName] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);
  const [brandEdit,setBrandEdit] = useState(false);
  const [main_code, setMain_code] = useState("");
  const [user_id, setId] = useState("");
  const [partner_id, setPartner_id] = useState(id);
  const [brand_id, setBarnd_id] = useState('');
  const [email, setEmail] = useState("");
  const [main_phone, setMain_phone] = useState("");
  const [website, setWebsite] = useState("");
  const [phone,setPhone] = useState("");
  const [street_number,setStreet_Number] = useState("");
  const [city,setCity] = useState("");
  // const [internal_percentage,setInternalPercentage] = useState("");
  const [country,setCountry] = useState("");
  const [contact_type,setContact_type] = useState("");
  const [postal,setPostal] = useState("");
  // const [contactInformation,setContactInformation] = useState([{contact_type:"",name:"",email:"",code:"",phone:""}]);
  // const [credit, setCredit] = useState("");
  const [credit_amount, setCredit_amount] = useState();
  const [debit_amount, setDebit_amount] = useState();
  const [payment_type, setPayment_type] = useState("");
  const [business_partner_type, setBusinessPartnerType] = useState("");
  // const [address, setAddress] = useState("");
  const [fundsShow,setFundsShow] = useState();
  const [creditShow,setCreditShow] = useState();
  const [amountShow,setAmountShow] = useState(false);
  const [availableBalance,setAvailableBalance] = useState(0)
  const [availableBalance1,setAvailableBalance1] = useState(0)
  const [isError,setIsError] = useState(false);
  const [marginAmount, setMarginAmount] = useState("");
  const [allThresholdValue, setAllThresholdValue] = useState("");
  const [allThresholdQuantity, setAllThresholdQuantity] = useState("");
  const [isApiThreshold, setIsApiThreshold] = useState(false);
  const [exchangeName, setExchangeName] = useState("");
  const [exchangeCurrency, setExchangeCurrency] = useState("");
  const [exchangeRate, setExchangeRate] = useState("");
  const user = JSON.parse(window.localStorage.getItem("client"));
  const dispatch = useDispatch();

  const [errorTrue,seterrorTrue] = useState(false);

  const [promotedCheck,setPromotedCheck] =useState(false);
  const [standoutCheck,setStandoutCheck] =useState(false);



  let dataa ;
  let paages ;

  const [btnColor,setBtnColor] = useState('');
  const [btnText,setBtnText] = useState('');
  const [catergoryOption,setCategoryOption] = useState(true);
  const [brandData, setBrandData] = useState([]);

  let userId;

  useEffect(()=>{
    setBtnColor('#3D05B6');
    setBtnText('white');
  },[]);

  let navigate = useNavigate();
  if (user) {
    userId = user.user._id;
  } else {
    userId = null;
    navigate("/admin");
  }
   const [userid] = useState(userId);
    const showCategory = useSelector(state => state.sendCategorie_reducer.showCategory);
    const categoryName = useSelector((state)=>state.sendCategorie_reducer.category);

  const [isLoading, setIsLoading] = useState(false);
  const [brandLoading,setBrandLoading] = useState(true);
  const [getbrand, setGetBrand] = useState([]);
  const [formValues, setFormValues] = useState([{ brand: "", bonus : "", internalPercentage : "", bonusType : "", bonusDiscount : "", id: partner_id, thresholdValue: "", thresholdQuantity: ""}])
  // const [cards, setCards] = useState("");
  const [brandId,setBrandId] =useState("");
  const [brand, setBrand] = useState("");
  const [bonus, setBonus] = useState("");
  const [discount, setDiscount] = useState("");
  const [bonusType,setBonusType] = useState("");
  const [bonusValue,setBonusValue] = useState("");
  const [internalPercentage,setInternalPercentage] = useState("");
  const [thresholdValue,setThresholdValue] = useState("");
  const [thresholdQuantity,setThresholdQuantity] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [customerData1, setCustomerData1] = useState([]);
  const [disabled,setDisabled] = useState(false);
  const [countryData,setCountryData] = useState([]);
  const [delay,setDelay] = useState(false);
  const [length,setLength] = useState();
  // const [loadMore,setLoadMore] = useState(false);
  const [deleteDelay,setDeleteDelay] = useState(false);
  const [noData,setNoData] = useState(false);
  const [searching,setSearching] = useState('');
  const [errorPhone,setErrorPhone] = useState();
  // const [value, setValue] = useState("");
  // const [number,setNumber] =useState(0);
  const [amount, setAmount] = useState();
  
  const [sorts,setSorts] = useState("ASC");
  const [order,setOrder] = useState("ASC");
  const [categoryss,setCategoryss] = useState("ASC")
  const [brandss,setBrandss] = useState("ASC")
  const [bonusDiscount, setBonusDiscount] = useState("ASC")
  const [pageNumber, setPageNumber] = useState(1); // Start from page 1
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [showPage, setShowPage] = useState(1);

  const [pageSize, setPageSize] = useState(10);
  const [clickBrand, setClickBrand] = useState(false);
  const [showCsvModal, setshowCsvModal] = useState(false);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [filename, setFilename] = useState('');
  const [previewModal, setPreviewModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [previewData, setPreviewData] = useState();

  const [valueType,setValueType]= useState('');
  const [funds_type , setFundType] = useState(null)
  const [fullfillmentType,setFullfillmentType] = useState(null);
  const [primary_partner_id,setAssociatePartner] = useState("");
  const [primaryBusinessName,setPrimaryBusinessName] = useState("")
  const [isApi, setIsApi] = useState(false);
  const [apiFundsType, setApiFundsType] = useState("");
  const [validate,setValidate] = useState(false);
  
  const [taxId, setTaxId]=useState("");
  const [clientProgramNumber, setClientProgramNumber ]=useState("")
  const [company, setCompany]=useState("")
  const [contact, setContact]=useState("")
  const [line1, setLine1]=useState("")
  const [line2, setLine2]=useState("")
  const [line3, setLine3]=useState("")
  const [tpCity, setTpCity]=useState("")
  const [region, setregion]=useState("")
  const [postalCode, setPostalCode]=useState("")
  const [postalCodeExt, setPostalCodeExt]=useState("")
  const [tpCountry, settpCountry]=useState("")
  
  const [address, setAddress]=useState(
    {
       
     },
  )

  const [thirdPartyInfo, setThirdPartyInfo]=useState( 
    { }
  )

  
  // const bonusTypeRef = useRef(null);
  // const brandType = useRef(null);
    // name
    const sorting = (order) => {
      if (order === "DSC") {
        setSorts("dscName");
        setOrder("DSC");
      } else {
        setSorts("ascName");
  
        setOrder("ASC");
      }
    };

    const categorySorting = (categoryss) => {
      if (categoryss === "DSC") {
        setSorts('dscCategory')
  
        setCategoryss('DSC')
  
      } else {
        setSorts('ascCategory')
  
        setCategoryss('ASC')
  
  
      }
    }

    const bonusSorting = (brandss) => {
      if (brandss === "DSC") {
        setSorts('dscBonus')
  
        setBrandss('DSC')
  
      } else {
        setSorts('ascBonus')
  
        setBrandss('ASC')
  
  
      }
    }

    const bonusDiscountSorting = (discount) => {
      if (discount === "DSC") {
        setSorts('dscBonusDiscount')
  
        setBonusDiscount('DSC')
  
      } else {
        setSorts('ascBonusDiscount')
  
        setBonusDiscount('ASC')
  
  
      }
    }





const getCountryData = () => {
  axios
    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcountries`)
    .then((response) => {
      const customers = response.data.countries;
      const uniqueIds = [];
      const unique = customers.filter(element => {
        const isDuplicate = uniqueIds.includes(element.value);
        if (!isDuplicate) {
          uniqueIds.push(element.value);
          return uniqueIds;
        }
        return false;
      });
      setCountryData(unique);
    })
    .catch((error) => console.error(`Error: ${error}`));
};
 


const [categories,setCategories] = useState([]);
// useEffect(()=>{
//     axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/get-categories`)
//     .then((res)=>{
//       const unsortedCategories = res.data.brands[0].categories;
//       const sortedCategories = unsortedCategories.sort();
//       setCategories(sortedCategories);
//     }).catch((err)=>{
//       console.log(err);
//     })
// },[]);
const [businessPartnerFilter, setbusinessPartnersFilter] = useState([])

function businessPartnerOptions () {
  axios
    .get(` ${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getallcustomersbrand`, {
        headers: {
          'Authorization' : `Bearer ${getNewToken()}`
        }
      })
    .then((response) => {
      
      const businessFilter = response.data.allPartnersFilter
      setbusinessPartnersFilter(businessFilter);
    })
    .catch((error) => {
      console.error(`Error : ${error}`);
    })
}

useEffect(()=>{
  businessPartnerOptions()
},[])

// var trueArray =[];

 useEffect(()=>{
   getCountryData();
 },[])
 let handleBonusChangeForm = (e, index) => {
   setBonusValue(e.target.value)
  let newFormValues = [...formValues];
  newFormValues[index][e.target.name] = e.target.value;

  if (e.target.name === 'bonusPercentage') {
    newFormValues[index]['bonusDiscount'] = e.target.value;
  }

  setFormValues(newFormValues);
}

  let handleChangeForm = (i, e) => {
      let newFormValues = [...formValues];
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);  
    }
  
  let addFormFields = () => {
      setFormValues([...formValues, { brand: "", bonus: "", bonusType: "", internalPercentage:"", bonusDiscount:"", id: partner_id}])
    }
  
  let removeFormFields = (i) => {
      let newFormValues = [...formValues];
      newFormValues.splice(i, 1);
      setFormValues(newFormValues)
  }
  let handleSubmit = async(event) => {
    setDelay(true);
    setBrandLoading(true);
    setTimeout(()=>{
     setDelay(false);
     
    },1800)
    var token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
      event.preventDefault();
      
     for(let i=0;i<formValues.length;i++){
      if( formValues[i].bonus === "" || formValues[i].bonusType === "" || formValues[i].bonusDiscount === "" ){
        var ip=0; 
        toast.warning("All Fields  Required");
        setIsLoading(false)
          return false;
        }
        else if(formValues[i].bonus < 50){
          toast.warning("Bonus cant be less than 50%");
          setIsLoading(false)
          setBrandLoading(false)
          return false;
  
      }else if(formValues[i].bonus > 100){
        toast.warning("Maximum Bonus Must Be 100%");
        setIsLoading(false)
        setBrandLoading(false)
        return false;
      }else if(formValues[i].bonusDiscount > 100){
        toast.warning("Maximum Discount Must Be 100%");
        setIsLoading(false)
        setBrandLoading(false)
        return false;
      }
      else{
        ip=100-(+formValues[i].bonus);
        formValues[i].internalPercentage=ip;
      }
     }
      
      if(formValues[0].bonus ){      
        setBarnd_id(formValues[0].brand)
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/bpbonus`,
          {
            formValues
  
          },{
            headers: {
              'Authorization': `Bearer ${getNewToken()}`
            }
          },
      );

        toast.success(data.message) 
       
        getdiscountbrandsstatus();  
      if(data.message === "Brand Added Successfully"){
        // setTimeout(()=>{
        //   window.location.reload();
        //   },1000)  
        setFormValues([{ brand: "", bonus : "", internalPercentage : "", bonusType : "", bonusDiscount : "", id: partner_id, thresholdValue: "", thresholdQuantity: ""}])
        setBonus("")
        setBonusValue("")
        setBrand("All Brands")
      //   if (bonusTypeRef.current) {
      //     bonusTypeRef.current.value = ""; 
      // }
      // if(brandType.current){
      //   brandType.current.value = "All Brands";
      // }
        
      }
  }
}

  const formikInitialValue = {
    business_name,
    main_code,
    main_phone,
    website,
    marginAmount,
    allThresholdValue,
    allThresholdQuantity,
    email,
    street_number,
    city,
    postal,
    country,
    contact_type,
    name,
    phone,
    email,
    payment_type,
    // primary_partner_id,
    credit_amount,
    debit_amount,
    // internal_percentage,
    partner_id,
    user_id,
    checkEmail,
    exchangeName,
    exchangeCurrency,
    exchangeRate,
    taxId,
    tpCountry,
    company,
    contact,
    line1,
    line2,
    line3,
    tpCity,
    region,
    postalCode,
    postalCodeExt,
    clientProgramNumber
  
  };

  const formSchema = Yup.object().shape({
     city: Yup.string()
      .min(3, "Too Short!")
      .max(24, "Maximum 24 characters allowed for City")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("City cannot be empty"),
     country: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Maximum 50 characters allowed for Country")
      .required("Country cannot be empty"),
    business_name: Yup.string()
      .min(3, "Too Short!")
      .matches(/^[a-zA-Z0-9\s]+\.?$/, "Invalid business partner name")
      .required("Business Name cannot be empty"),
      exchangeName: Yup.string()
      .min(3, "Too Short!")
      .matches(/^[a-zA-Z0-9\s]+\.?$/, "Invalid exchange name"),  
    checkEmail: Yup.boolean(),
      website: Yup.string()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter correct url!'
        ),
        email:Yup.string()
        .email("Invaild email")
        .test("email-format", "Invalid email format", (value) => {
          return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value);
        })
        .required("Business Email cannot be empty"),
        // .matches(/^(\b\D+\b)?\s*(\b.*?\d.*?\b)\s*(\b\D+\b)?$/,'Enter correct street number')

      street_number: Yup.string()
      .min(2, "Specify correct address")
      .max(64, "Maximum 64 characters allowed for address information")
      .matches(/^[aA-zZ0-9\s-&-/,]+$/, "Alphabet and numbers are allowed for address information")
       .required('street and number cannot be empty'),
       postal: Yup.string()
      .min(5, "Minimum 5 digits required for credit Amount")
      .matches(/^[aA-zZ0-9\s]+$/, "Alphabet and numbers are allowed for postal code")
      .required("postal cannot be empty"),
      main_code: Yup.string()
      .min(1, "minimum 1 digit require")
      .required("Code cannot be empty"),
    main_phone: Yup.string()
      .min(10, "Minimum 7 digits required for Phone number")
      .max(15, "Maximum 10 digits allowed for Phone number")
      .required("Phone Number cannot be empty"),
    
    payment_type: Yup.string()
      .min(3, "Minimum 3 digits required for payment_type Amount")
      .max(10, "Maximum 10 digits allowed for payment_type Amount"),
      // .required("Payment type cannot be empty"),

      credit_amount: Yup.string().when('partner_id', {
        is: (partner_id, credit_amount) => partner_id === undefined, // Apply validation when partner_id is empty
        then: Yup.string()
          .matches(/^[0-9]+(\.[0-9]{1,2})?$/, 'Invalid format'),
        otherwise: Yup.string().notRequired(),
      }),
    
      debit_amount: Yup.string().when('partner_id', {
        is: (partner_id, debit_amount) => partner_id === undefined, // Apply validation when partner_id is empty
        then: Yup.string()
          .matches(/^[0-9]+(\.[0-9]{1,2})?$/, 'Invalid format'),
        otherwise: Yup.string().notRequired(),
      }),
      exchangeCurrency: Yup.string()
      .min(3, "Too Short!")
      .matches(/^[a-zA-Z0-9\s]+\.?$/, "Invalid business partner name"),
      exchangeRate: Yup.string().when('partner_id', {
        is: (partner_id, exchangeRate) => partner_id === undefined, // Apply validation when partner_id is empty
        then: Yup.string()
          .matches(/^[0-9]+(\.[0-9]{1,2})?$/, 'Invalid format'),
        otherwise: Yup.string().notRequired(),
      }),
      marginAmount: Yup.string()
      .matches(/^\d+(\.\d{2})?$/, 'Invalid Threshold format'),
      allThresholdQuantity: Yup.string()
      .matches(/^\d+$/, 'Invalid Threshold Quantity format'),
      allThresholdValue: Yup.string()
      .matches(/^\d+(\.\d{2})?$/, 'Invalid Threshold Value format'),
      company: Yup.string()
      .max(60, 'Company must be 60 characters or less'),
      contact: Yup.string()
      .max(35, 'Contact must be 35 characters or less'),
      line1: Yup.string()
      .max(35, 'Line1 must be 35 characters or less'),
      line2: Yup.string()
      .max(35, 'Line2 must be 35 characters or less'),
      line3: Yup.string()
      .max(35, 'Line3 must be 35 characters or less'),
      tpCity: Yup.string()
      .max(30, 'City must be 30 characters or less'),
      region: Yup.string()
    .matches(
      /^(?:[A-Z]{2}|.{1,35})$/,
      'Address region must be a valid 2-character uppercase abbreviation for a U.S state or a Canadian province or contain an international region less than 36 characters'
    ),
      postalCode: Yup.string()
      .max(10, 'Postal code must be 10 characters or less'),
      postalCodeExt: Yup.string()
      .max(4, 'Postal code must be 4 characters or less'),
      tpCountry: Yup.string()
      .matches(/^[A-Za-z]{3}$/, 'Country must be the 3 alphabetic ISO code'),
      taxId: Yup.string()
      .matches(/^\d{9}$/, 'Tax ID must be exactly 9 digits'),
      // .required('Tax ID is required'),
      clientProgramNumber: Yup.string()
      .test(
            'is-integer',
            'Client Program Number must be a digit',
            function(value) {
                // If the field is not empty, check if it's an integer
                if (value && value.trim()) {
                    return /^\d+$/.test(value);
                }
                // If the field is empty, consider it valid
                return true;
            }
        )      
        .matches(/^\d{8}$/, 'Client Program Number must be exactly 8 digits'),
      // .required('Client Program Number is required'),
  });


  useEffect(()=>{
    // getdiscountbrandsstatus(pageNumber);
  },[sorts,dataa])


  
  // pagination

  let items = [];
  let leftSide = pageNumber - 2;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide+1; number <= rightSide; number++) {

    items.push(
      <li
      key={number}
      className={number === pageNumber ? "active" : ""}
      onClick={() => {
        setPageNumber(number);
        setShowPage(number);
      }}
    >
      {number}
    </li>
    );
  }

  const pagenumberSearch = (e) => {
    var pages = parseInt(e);
    var page = pages;
    if (pages < 1) {
      setPageNumber(1);
      setShowPage(1);
    }else if (pages > numberOfPages) {
        setPageNumber(numberOfPages);
      setShowPage(numberOfPages);
    } else if (pages >= 1 && pages <= numberOfPages) {
      setPageNumber(page);
      setShowPage(pages);
    }
    else{
      setPageNumber(1);
      setShowPage(pages);
    }
    };




  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
      setShowPage(pageNumber+1);
    }
  }

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1)
      setShowPage(pageNumber-1);
    }
  }

  // pagination


  useEffect(() => {
    if (id) {
      // getdiscountbrandsstatus();
      getAllCustomers();
      // getbrands();
     

    }
  }, [sorts]);

  //funds
  const getAllCustomers =async () => {
    var token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    setIsLoading(true);
    setIsApi(true);
    axios
      .get(` ${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getbusinesspartner/${id}`, {
          headers: {
            'Authorization' : `Bearer ${getNewToken()}`
          }
        })
      .then((response) => {
         const roundedDebitamt =  Number(response.data.businessPartnes.debit_amount)?.toFixed(2);
         const roundedCreditamt = Number(response.data.businessPartnes.credit_amount)?.toFixed(2); 
        setBrandData(response.data.businessPartnes.brands)
        setBusinessName(response.data.businessPartnes.business_name);
        setCity(response.data.businessPartnes.city);
        setExchangeName(response.data.businessPartnes.exchange_name);
        setExchangeCurrency(response.data.businessPartnes.exchange_currency);
        setExchangeRate(response.data.businessPartnes.exchange_rate)



        setTaxId(response?.data?.businessPartnes?.thirdPartyInfo?.taxId)
        setClientProgramNumber(response?.data?.businessPartnes?.thirdPartyInfo?.clientProgramNumber)
        setCompany(response?.data?.businessPartnes?.thirdPartyInfo?.address?.company)
        setContact(response?.data?.businessPartnes?.thirdPartyInfo?.address?.contact)
        setLine1(response?.data?.businessPartnes?.thirdPartyInfo?.address?.line1)
        setLine2(response?.data?.businessPartnes?.thirdPartyInfo?.address?.line2)
        setLine3(response?.data?.businessPartnes?.thirdPartyInfo?.address?.line3)
        setregion(response?.data?.businessPartnes?.thirdPartyInfo?.address?.region)
        setPostalCode(response?.data?.businessPartnes?.thirdPartyInfo?.address?.postalCode)
        setPostalCodeExt(response?.data?.businessPartnes?.thirdPartyInfo?.address?.postalCodeExt)
        settpCountry(response?.data?.businessPartnes?.thirdPartyInfo?.address?.country)
        setTpCity(response?.data?.businessPartnes?.thirdPartyInfo?.address?.city)




        setCountry(response.data.businessPartnes.country);
        setCredit_amount(roundedCreditamt);
        setDebit_amount(roundedDebitamt);
        if(response.data.businessPartnes.payment_type === "Credit"){
          let roundedBal = Number(response.data.businessPartnes.available_balance)?.toFixed(2);
          setAvailableBalance(roundedBal);
        }
        if(response.data.businessPartnes.payment_type === "Funds"){
          let roundedBal1 = Number(response.data.businessPartnes.available_balance)?.toFixed(2);
          setAvailableBalance1(roundedBal1);
        }
        setApiFundsType(response.data.businessPartnes.funds_type);
        setPayment_type(response.data.businessPartnes.payment_type);
        setBusinessPartnerType(response.data.businessPartnes.business_partner_type);
        setFundType(response.data.businessPartnes.funds_type);
        setPrimaryBusinessName(response.data.primaryBusinessName);
        setMain_code(response.data.businessPartnes.main_code);
        setMain_phone(response.data.businessPartnes.main_phone);
        setPostal(response.data.businessPartnes.postal);
        setStreet_Number(response.data.businessPartnes.street_number);
        setWebsite(response.data.businessPartnes.website);
        setEmail(response.data.businessPartnes.email);
        let roundAmount = Number(response.data.businessPartnes.funds_reminder)?.toFixed(2);
        setAllThresholdQuantity(response.data.businessPartnes.allThresholdQuantity)
        setAllThresholdValue(response.data.businessPartnes.allThresholdValue)

        if (response.data.businessPartnes.business_partner_type === 'Direct Order') {
          setShowConfirmationSelect(true);
          setConfirmationOption(response.data.businessPartnes.direct_order_notification)
          setConfirmationBusinessTypeOption(response.data.businessPartnes.business_type_option)
        } else {
          setShowConfirmationSelect(false);
          // setConfirmationOption('No');
         
        }
        // setInternalPercentage(response.data.businessPartnes.internal_percentage);
        if (response.data.businessPartnes.funds_reminder.length > 0 && response.data.businessPartnes.funds_reminder !== "0") {
          setIsApiThreshold(true);
          setMarginAmount(roundAmount);
        } else {
          setIsApiThreshold(false);
          setMarginAmount(response.data.businessPartnes.funds_reminder);
        }
        setIsLoading(false);
        setTimeout(() => {
          setIsApi(false);
        }, 100);
      })
      .catch((error) => {console.error(`Error: ${error}`);setIsLoading(false); setIsApi(false)});

  };


  const getbrands = () => {

    axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getallbrands`)
        .then((response) => {            
         setGetBrand(response.data.brands);            
        })
        .catch((error) => console.error(`Error: ${error}`));
  };


var token;
  const deleteBp = (bid) => {
    setDeleteDelay(true);
    setTimeout(()=>{
      setDeleteDelay(false);
    },1500)
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/branddelete/${id}/${bid}`, {
      headers: {
        'Authorization' : `Bearer ${getNewToken()}`
      }
    })
      .then((response) => {
        toast.warning(response.data.message);
        getdiscountbrandsstatus();
        getAllCustomers()
           })
      .catch((error) => console.error(`Error: ${error}`));
      // getdiscountbrandsstatus();
      // toast.warning("Brand Deleted")
  };




// update bussiness partner with id and bid
  const updateBp = (bid) => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/brandupdate/${id}/${bid}`)
      .then((response) => {
        // toast(response.data.message);
           })
      .catch((error) => console.error(`Error: ${error}`));
  };



  const getdiscountbrandsstatus = (pageNumber,check) => {
    
    if (id){
    const user = JSON.parse( window.localStorage.getItem('client'));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    if(dataa || categoryName.length>0 ){
      if(categoryName.length>0){
        dataa="";
      }
  axios
  .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getbpbrandstatus/${id}?sort=${sorts}&search=${dataa}&category=${categoryName}`,{
    headers: {
      'Authorization' : `Bearer ${getNewToken()}`
    }
  })
  .then((response) => { 

    if(response.data.message == "Please Login"){
      localStorage.removeItem('client');
      navigate('/admin');
      toast.error("Session Expired Please Login Again")
    }
   setSearching('')
  //  setFilters('')
   if(response.data.brands.length>=1){
    setNoData(false);
    
   }
   setBrandLoading(false);
  
   let arr = response.data.brands;
   arr.sort((a,b)=>{
    return (a.brandName-b.brandName)});
   setCustomerData(arr);
  //  setCustomerData1(arr);
   setLength(response.data.brands.length);
   const totalPages = response.data.totalPages;
   setNumberOfPages(totalPages);

})
.catch((error) => console.error(`Error: ${error}`));

}else{
  axios
  .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getbpbrandstatus/${id}?sort=${sorts}`,{
    headers: {
      'Authorization' : `Bearer ${getNewToken()}`
    }
  })
  .then((response) => { 
    setBrandLoading(false);
    if(response.data.message == "Please Login"){
      localStorage.removeItem('client');
      navigate('/admin');
      toast.error("Session Expired Please Login Again")
    } 
  //  setSearching('')
   if(response.data.brands.length>=1){
    setNoData(false);
   }
   let arr = response.data.brands;
  //  arr.sort((a,b)=>{
  //   return (a.brandName-b.brandName)});
   setCustomerData(arr);
   if(check !== "check"){
    setCustomerData1(arr);
  }
   setLength(response.data.brands.length);
   const totalPages = response.data.totalPages;
   setNumberOfPages(totalPages);
   
  })
  .catch((error) => console.error(`Error: ${error}`));
  }
      
  }
};

  

  function handleBrandCheckboxChange (standoutCheck) {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/standout/${id}`,{
        couponId:brandId,
        ischeck:standoutCheck
      }, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }
      },)
      .then((response) => {
        if(response.data.status === "success") {
          toast.success(response.data.message);
        } else {
          toast.warning(response.data.message);
        }
        // getdiscountbrandsstatus()
      })
      .catch((err)=>{
        console.log(`Error :${err}`);
      })

  }

  function handlePromotionBrandCheckboxChange (promotedCheck) {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/promotion/${id}`,{
        couponId:brandId,
        ischeck:promotedCheck
      }, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }
      },)
      .then((response) => {
        if(response.data.status === "success") {
          toast.success(response.data.message);
        } else {
          toast.warning(response.data.message);
        }
        // getdiscountbrandsstatus()
      })
      .catch((err)=>{
        console.log(`Error :${err}`);
      })

  }
  
  function handleStatus(status,bid) {
     var ip=0;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    
    if(status===true){
      // handleBrandCheckboxChange(standoutCheck,brandId)
      // handlePromotionBrandCheckboxChange(promotedCheck,brandId)
      if(bonus<50){
        toast.warning("Bonus cant be less than 50%")
        return;
      }else if(bonus>100){
        toast.warning("Bonus Must Be Maximum 100%")
        return;
      }
      else{
        ip=100-(+bonus);
        setInternalPercentage(ip);
      }
      axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/bp/statusdata/${id}/${bid}`, {  
        status,  
        brandId,
        bonus,
        bonusType,
        discount,
        internalPercentage:ip,
        standoutCheck,
        promotedCheck,
        thresholdValue,
        thresholdQuantity
        
      }, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }
      },)
      .then((response) => {
       if(response.data.message === "Bonus Must Be Maximum 100%" || response.data.message === "Maximum Bonus Upto Discount Value" || response.data.message === "Bonus Must Be Greater Than 1"){
        toast.warning(response.data.message);
        return false;
       }else{
        toast.success(response.data.message);
        setBonus(response.data.brands.bonus);
        setDiscount(response.data.brands.discount)
        setBonusType(response.data.brands.bonusType);
        setInternalPercentage(response.data.brands.internal_rebate);
        setBrandId(response.data.brands.couponId);       
        setStandoutCheck(response.data.brands?.stand_out);
        setPromotedCheck(response.data.brands?.promotion);
        setThresholdValue(response.data.brands?.thresholdValue);
        setThresholdQuantity(response.data.brands?.thresholdQuantity);
        // dispatch(ClearhandleCategories());
        getdiscountbrandsstatus(1,"check"); 
        // getdiscountbrandsstatus();
        window.location.reload();
       }

      })
      .catch((error) => console.error(`Error: ${error}`));
    }
   else if(status===false){
    // handleBrandCheckboxChange(standoutCheck,brandId)
    // handlePromotionBrandCheckboxChange(promotedCheck,brandId)
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/bp/status/${id}/${bid}`, {    
       status,
      }, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }
      },)
      .then((response) => {
        if(searching.length>0){
          toast.success(response.data.message);
          const searchData = customerData.find(item => item.brandName === response.data.brands.brandName);

          if (searchData) {
            dataa =searchData.brandName;
            setBonus(searchData.bonus);
            setDiscount(searchData.discount);
            setBonusType(searchData.bonusType);
            setInternalPercentage(searchData.internal_rebate);
            setBrandId(searchData.couponId);
            getdiscountbrandsstatus();
            
          }
        }else{
          toast.success(response.data.message);
          setBonus(response.data.brands.bonus);
          setDiscount(response.data.brands.discount)
          setBonusType(response.data.brands.bonusType);
          setInternalPercentage(response.data.brands.internal_rebate);
          setBrandId(response.data.brands.couponId); 
          setStandoutCheck(response.data.brands.stand_out)
          setPromotedCheck(response.data.brands.promotion)
          setThresholdValue(response.data.brands.thresholdValue);
          setThresholdQuantity(response.data.brands.thresholdQuantity);;
          getdiscountbrandsstatus();
        }

      })
      .catch((error) => console.error(`Error: ${error}`));
   }
      
  }

  const handleSubmitForm = async (data) => {
    setDisabled(true)
    let {
      business_name,
      main_code,
      main_phone,
      website,
      marginAmount,
      allThresholdValue,
      allThresholdQuantity,
      street_number,
      city,
      postal,
      country,
      contact_type,
      name,
      phone,
      email,
      payment_type,
      // primary_partner_id,
      // internal_percentage,
      credit_amount,
      debit_amount,
      user_id,
      exchangeName,
      exchangeCurrency,
      exchangeRate,
      taxId,
    tpCountry,
    company,
    contact,
    line1,
    line2,
    line3,
    tpCity,
    region,
    postalCode,
    postalCodeExt,
    clientProgramNumber,
    
    } = data;

    // internal_percentage = internal_percentage.replace(/[^\w\s]/gi, '');
    // console.table({
    //   business_name,
    //   main_code,
    //   main_phone,
    //   website,
    //   street_number,
    //   city,
    //   postal,
    //   country,
    //   contact_type,
    //   name,     
    //   phone,
    //   email,
    //   // credit,
    //   payment_type,
    //   credit_amount,
    //   debit_amount,
    //   user_id,
    // });
    try {
      // setIsLoading(true);
      const user = JSON.parse(window.localStorage.getItem("client"));
      var token;
      if (user) {
        token = user.token;
      } else {
        token = null;
      }
      if(payment_type === "Invoice"){
        credit_amount = "0";
        debit_amount = "0";
      }else if(payment_type === "Credit"){
        debit_amount = "0";
        if(creditShow > credit_amount){
            credit_amount=creditShow;
        }
      }else if(payment_type === "Funds"){
        credit_amount = "0";
        if(fundsShow > debit_amount){
          debit_amount=fundsShow;
      }
      }

      if (!funds_type) {
        toast.warning("Buisness Partner Funds Type Is Required")
        setDisabled(false)
        return
      }
      if (!business_partner_type) {
        toast.warning("Buisness Partner Fulfilment Type Is Required")
        setDisabled(false)
        return
      }

      if(marginAmount < 0 && payment_type === "Credit"){
        toast.warning("Threshold Amount can not be more than Credit amount")
        setDisabled(false)
        setValidate(true)
        return
      }

       if(marginAmount < 0 && payment_type === "Funds"){
        toast.warning("Threshold Amount can not be more than Debit amount")
        setDisabled(false)
        setValidate(true)
        return
      }
    
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/businesspartner/create`,
        {
          business_name,
          main_code,
          main_phone,
          website,
          email,
          street_number,
          city,
          postal,
          country,
          payment_type,
          business_partner_type,
          funds_type ,
          primary_partner_id,
          // internal_percentage,
          credit_amount,
          debit_amount,
          user_id,
          partner_id,
          valueType,
          marginAmount,
          allThresholdValue,
          allThresholdQuantity,
          exchangeName,
          exchangeCurrency,
          exchangeRate,
          taxId,
          tpCountry,
          company,
          contact,
          line1,
          line2,
          line3,
          tpCity,
          region,
          postalCode,
          postalCodeExt,
          clientProgramNumber,
          directOrderNotification : confirmationOption,
          businessTypeOption : confirmationBusinessTypeOption,
          loginUserId: userId
        },{
          headers: {
            'Authorization': `Bearer ${getNewToken()}`
          }
        },
      );
      toast.success(data.message);
       setValidate(false);
      if (data.status === "success") {
      setIsLoading(true);
        navigate("/admin/businesspartners");
      } else {
        if (data.message === "Email Already Exists") {
          setIsLoading(false);
          // setIsError(true);
        } else {
          // setIsError(false);
          navigate("/admin/businesspartners");
        }
      }
      setTimeout(() => {
        setDisabled(false);
      }, 2000);
    } catch (err) {
      console.log(`Error : ${err}`);
      toast.error(err.response?.data?.message);
    }
  };




  function phoneFormat(input) {
    if(input?.length>0){
    input = input?.replace(/\D/g, "");
    input = input?.trim()
    input = input?.substring(0, 10);

    var size = input?.length;
    if (size === 0) {
      input = input;
    }
     else if (size < 4) {
      input = "(" + input;
    } 
    else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } 
    else if(size <= 11)
    input= "("+input.substring(0, 3)+") "+input.substring(3, 6)+"-" +input.substring(6, 11);
    
    if (input?.length > 10 && input?.length <= 14){
      setErrorPhone(false);
    }else{
      setErrorPhone(true);
    }
    return input;
  }
  }

  function nameFormat(input) {
    // if (input == " ") {
    //   input = input.replace(/\s/g, "");
    // }
  
    return input.trimStart();
  }




  const handleSearch = (e)=>{
    setSearching(e);
    let arr=[];
    let input = e.toLowerCase();
    if(input.length>0){
      customerData1?.filter((elem)=>{
        if( (elem.brandName.toLowerCase().includes(input))&&input.length>0){
          arr.push(elem);
        }
      })
      if(arr.length===0){
        setNoData(true);
      }else{
        setNoData(false);
      }
     
      setCustomerData(arr);
      const pageLength = Math.ceil(arr.length/10)
      setNumberOfPages(pageLength)
  setPageNumber(1);
  setShowPage(1);
  
    }else{
      const pageLength = Math.ceil(customerData1.length/10);
      setNumberOfPages(pageLength)
      setCustomerData(customerData1);
      // setpaginate(20);
      if(customerData1.length>20){
        setNoData(false);
        // setLoadMore(true);
      }
    }
    dispatch(ClearhandleCategories());
  }


  const userID = localStorage.getItem('client');
  const users = JSON.parse(userID);
  const userIds = users?.user._id;


  // const userStatus = (userIds) => {
  //     axios
  //         .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${userIds}`)
  //         .then((response)=>{
  //             let users = response.data.user?.status
  //             if(users === false){
  //                 window.localStorage.removeItem('client');
  //                 navigate('/admin');
  //                 toast.warning("Your account has been deactivated");
  //         }
  //         })
  //         .catch((error) => console.error(`Error: ${error}`));
  //     }

  // useEffect(()=>{
  //     userStatus(userIds);
  // } ,[userIds])

  const handleDiscountChange = (e) => {
    const inputValue = e.target.value.trim();
    if (!isNaN(inputValue) && inputValue >= 0 && inputValue <100 ) {
      setDiscount(inputValue);
    }
  };

  
  const useTouchedFields = () => {
    const [touched, setTouched] = useState({});
  
    const handleFieldBlur = (fieldName) => {
      setTouched({ ...touched, [fieldName]: true });
    };
  
    const isFieldTouched = (fieldName) => !!touched[fieldName];
  
    return { handleFieldBlur, isFieldTouched };
  };
  
  const handleClosePreviewModal = () => {
    setPreviewModal(false);
    setshowCsvModal(false);
    setLoadingCsv(false);
    setFilename('');
  };
  const handleClosehandleCsvModal = () => {
    setshowCsvModal(!showCsvModal);
    setFilename('');
    setLoadingCsv(false);
  };

  const setCustomers = (userDataInString) => {
   

    var userDataInJSON = [];
    var rows = userDataInString.split("\n");
    var er = rows[0].search("\r");
    if (er > 1) {
      rows[0] = rows[0].slice(0, -1);
    } else {
      rows[0] = rows[0].slice(0);
    }
    var fields = rows[0].split(",");
    if(fields[1] !== "Brand_Id") {
      toast.warning("Brand Id Is Required");
      return false;
    }
    if(fields[4] !== "Customer_Percentage") {
      toast.warning("Bonus Is Required");
      return false;
    }
    
    if(fields[2] !== "Bonus_Percentage") {
      toast.warning("Discount Is Required");
      return false;
    }
   
    if (fields[1] == "Brand_Id" && fields[4] == "Customer_Percentage" && fields[2] == "Bonus_Percentage" ) {
      
      
      

      for (var i = 1; i < rows.length; i++) {

        var rowData = rows[i].split(",");
        var bonusValue = parseFloat(rowData[4]);
        
     if (bonusValue < 50) {
    toast.warning( "Bonus can't be less than 50%");
     return false;
     } else if (bonusValue > 100) {
      toast.warning( "Bonus Must Be Maximum 100%" )
      return false;
     }
      
     if (i === 1 || i === 2) {
      
      var discountValue = Number(rowData[2]);
     
        if(discountValue >= 100 ){
         toast.warning( "Discount must be less than 100%");
         return false;
        }else if(isNaN(discountValue)){
          toast.warning( "Discount must be in Number");
          return false;
        }
      }
    
        var flag = false;

        var singleUserObject = {};

        var ers = rows[i].search("\r");
        if (ers >= 0) {
          rows[i] = rows[i].slice(0, -1);

        } else {
          rows[i] = rows[i].slice(0);
        }

        var singleRow = rows[i].split(",");
        if (fields.length !== singleRow.length && singleRow.length > 1) {
          toast.warning("There is some issue in csv file format.");
          return false;
        }
        for (var j = 0; j < fields.length; j++) {
          if (singleRow[j] === "") {
            flag = true;
          }

          // Key-Value Pairs
          singleUserObject[fields[j]] = singleRow[j];
         
        }

        if (flag) {
          // To be added to Invalid Users
        } else {
          // To be sent to backend
          userDataInJSON.push(singleUserObject);
        }
      }

      setCsvData(userDataInJSON);
      const user = JSON.parse(window.localStorage.getItem("client"));
      var token;
      if (user) {
        token = user.token;
      } else {
        token = null;
      }
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/brand-preview`,
          userDataInJSON, {
          headers: {
            'Authorization': `Bearer ${getNewToken()}`
          }
        },
        )
        .then((res) => {
          if (res.data.status === "failed") {
            toast.warning(res.data.message);
            return false;
          }else {
            toast.success(res.data.message);
            setPreviewData(res.data.brands);
            // setPreviewData1(res.data);
            // setBusinessName(res.data.businessName)
            setLoadingCsv(true)
            setTimeout(() => {
              setLoadingCsv(false)
              setPreviewModal(true)
            }, 800);
          }
        })
        .catch((err) => {
          console.log(err);
          // if (err.response.data.msg) {
          //   toast.error(err.response.data.msg);
          // }
        });


    } else {
      toast.warning("Invalid CSV File")
      setCsvData([]);

    }


  };
  const handleClearData =()=>{
    setFilename('')
  };
  const handleSubmitCSV = async (e) => {
    var token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    setLoadingCsv(true);
    e.preventDefault();
    setDisable(true)
    setTimeout(() => {
      setDisable(false);
    }, 2000);
    if (csvData) {
      if (csvData.length === 0) {
        toast.warning("Please Upload A CSV File");
        setTimeout(() => {
          setLoadingCsv(false)
        }, 1000);
        return false;
      }

    }

    
    setLoadingCsv(true)
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/addbrand/csv/${id}`,
        csvData, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }
      },
      )
      .then((res) => {
        if (res.data.status === "failed") {
          setTimeout(() => {
            // getAllCustomers();
            setLoadingCsv(false)
            handleClosePreviewModal()
            // window.location.reload();
            // getAllCustomers();
          }, 1500);
          toast.warning(res.data.message);
          return false;
        }
        setPreviewModal(false)
        setTimeout(() => {
          getAllCustomers();
          setLoadingCsv(false)
          handleClosehandleCsvModal();
          handleClearData(); 
          window.location.reload();
          getAllCustomers();
          // navigate("/admin/customers");
        }, 1000);
        toast.success(res.data.message);
        
      })
      .catch((err) => {
        navigate("/admin/businesspartners");
        console.log(err);
        if (err.response.data.msg) {
          toast.error(err.response.data.msg);
        }

      });
  };
   const handleamt =(e)=>{
    let input = e.target.value.replace(/[eE-]/g, '');
    const parts = input.split('.');
    if (parts.length > 1) {
      const decimalPart = parts[1].slice(0, 2);
      input = parts[0] + (decimalPart.length > 0 ? '.' + decimalPart : '');
    }
    setAmount(input);
   }

  // Usage:
  const { handleFieldBlur, isFieldTouched } = useTouchedFields();


//  const handleBonus =(e)=>{
//   if(e.target.value<50){
//     toast("Bonus cant be less than 50%")
//   }else if(e.target.value>95){
//     toast("Bonus cannot be more than 95%")
//   }
//   else{
//     setBonus(e.target.value);
//     setInternalPercentage(100-e.target.value);
//   }
//  }

  function deleteReminder() {
    axios.delete(` ${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/delete-reminder/${partner_id}`, {
      headers: {
        'Authorization': `Bearer ${getNewToken()}`
      }
    }).then((response) => {
      if (response.data.status === "success") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      getAllCustomers()
    }).catch((error) => {
      console.log(`Error : ${error}`);
    })
  }

useEffect(()=>{
  setSearching('');
  if(showCategory.length>0){
    const filteredData = customerData1?.filter(customer => showCategory.includes(customer.brandCategory));
    setCustomerData(filteredData);
    const pageLength = Math.ceil(filteredData.length/10)
        setNumberOfPages(pageLength)
    setPageNumber(1);
    setShowPage(1);
  }else{
  setCustomerData(customerData1);
  const pageLength = Math.ceil(customerData1.length/10)
        setNumberOfPages(pageLength)
    setPageNumber(1);
    setShowPage(1);
  }
  },[clickBrand])


  const handleFundTypeChange = (e) => {
    setFundType(e.target.value);
  };

  const handleFullfillmentType = (e) =>{
    setBusinessPartnerType(e.target.value)
  };

  const handleAssociteparterType = (event) =>{
    const selectedValue = event.target.value;
    setAssociatePartner(selectedValue)
    const selectedIndex = event.target.selectedIndex;
    // const selectedLabel = event.target.options[selectedIndex].text;
    ;

  }

  const [showConfirmationSelect, setShowConfirmationSelect] = useState(false);
  const [confirmationOption, setConfirmationOption] = useState('Yes');

  const handleFullfillmentTypeChange = (e) => {
    handleFullfillmentType(e);
    if (e.target.value === 'Direct Order') {
      setShowConfirmationSelect(true);
    } else {
      setShowConfirmationSelect(false);
      setConfirmationOption('No');
    }
  };

  const handleConfirmationChange = (e) => {
    setConfirmationOption(e.target.value);
  };

  const [confirmationBusinessTypeOption, setConfirmationBusinessTypeOption] = useState('Bonus');
  
  const handleBusinessTypeChange = (e) => {
    setConfirmationBusinessTypeOption(e.target.value);
  };

  return (

  
  
    <>
    {isApi?
    
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <Bars
      height="80"
      width="100%"
      color="var(--newMainBlue)"
      ariaLabel="bars-loading"
      visible={true}
    />
  </div>:

      <div className="p-3 p-lg-4 p-xl-5">
        <div className="d-flex align-items-center">
          <Link
            to="/admin/businesspartners"
            className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
            variant="white"
          >
            <span className="me-2 fw-semibold">
              {/* <img
                src="/assets/images/back-org.png"
                className="img-fluid"
                alt=""
              /> */}
              <MdOutlineKeyboardBackspace  className="fs-4" style={{color: 'var(--newOrange)'}}/>
            </span>
            <span>Back</span>
          </Link>
          <h1 className="font20  text-dark">{id ? "" : "Add Business Partner"}</h1>
        </div>

        <Container fluid className="mt-4">
          <Row  className="bg-white">
            <Col lg={12} md={12}>
                <Row className="mt-4 mb-4" style={{ margin: "10px" }}>
                  <Col lg={6} md={6}>
                    <h2 className="font16 mb-2 text-dark">Business Partner Funds Type<span className="text-danger">*</span></h2>
                    {id ? (
                      <div className="d-flex align-items-center responsiveRadioBP" style={{ gap: "10px" }}>
                        <div className="d-flex align-items-center" style={{ columnGap: "3px" }}>
                          <input type="radio" id="primary" name="funds_type" className="mt-0" value="Primary" checked={funds_type === 'Primary'} onChange={handleFundTypeChange} disabled />
                          <label htmlFor="primary">Primary</label>
                        </div>
                        <div className="d-flex align-items-center" style={{ columnGap: "3px" }}>
                          <input type="radio" id="Associate" name="funds_type" value="Associate" checked={funds_type === 'Associate'} onChange={handleFundTypeChange} className="mb-0" disabled />
                          <label htmlFor="Associate">Associate</label>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center responsiveRadioBP" style={{ gap: "10px" }}>
                        <div className="d-flex align-items-center" style={{ columnGap: "3px" }}>
                          <input type="radio" id="primary" name="funds_type" className="mt-0" value="Primary" checked={funds_type === 'Primary'} onChange={handleFundTypeChange} />
                          <label htmlFor="primary">Primary</label>
                        </div>
                        <div className="d-flex align-items-center" style={{ columnGap: "3px" }}>
                          <input type="radio" id="Associate" name="funds_type" value="Associate" checked={funds_type === 'Associate'} onChange={handleFundTypeChange} className="mb-0" />
                          <label htmlFor="Associate">Associate</label>
                        </div>
                      </div>
                    )}
                    {funds_type === 'Associate' && (
                      <div style={{ width: "100%", maxWidth: "300px", marginTop: "20px" }}>
                        {id ? (
                          <select
                            style={{ height: '31px', width: '100%', borderWidth: '1px', borderRadius: '5px' }}
                            className="partner_select font12"
                            placeholder="Select Primary Partner"
                            onChange={handleAssociteparterType}
                            disabled
                          >
                            <option value={primaryBusinessName}>{primaryBusinessName}</option>
                            {businessPartnerFilter.map((partner) => (
                              <option key={partner.id} value={partner.value}>{partner.label}</option>
                            ))}
                          </select>
                        ) : (
                          <select
                            style={{ height: '31px', width: '100%', borderWidth: '1px', borderRadius: '5px' }}
                            value={primary_partner_id}
                            className="partner_select font12"
                            placeholder="Select Primary Partner"
                            onChange={handleAssociteparterType}
                          >
                            <option value="default">Select Primary partner</option>
                            {businessPartnerFilter.map((partner) => (
                              <option key={partner.id} value={partner.value}>{partner.label}</option>
                            ))}
                          </select>
                        )}
                      </div>
                    )}
                  </Col>
                  <Col lg={6} md={6}>
                    <h2 className="font16 mb-2 text-dark">Business Partner Fulfilment Type<span className="text-danger">*</span></h2>
                    {id ? (
                      <div>
                        <div className="d-flex align-items-center responsiveRadioBP" style={{ gap: "10px" }}>
                          <div style={{ columnGap: "3px" }} className="d-flex align-items-center">
                            <input type="radio" id="marketplace" name="Fullfillment" value="Marketplace" onChange={handleFullfillmentTypeChange} disabled className="mb-0" checked={business_partner_type === "Marketplace"} />
                            <label htmlFor="marketplace">Marketplace</label>
                          </div>
                          <div style={{ columnGap: "3px" }} className="d-flex align-items-center">
                            <input type="radio" id="Direct" name="Fullfillment" value="Direct Order" onChange={handleFullfillmentTypeChange} disabled className="mb-0" checked={business_partner_type === "Direct Order"} />
                            <label htmlFor="Direct">Direct Order</label>
                          </div>
                        </div>
                        {showConfirmationSelect && ( 
                          <div className="">
                            <div className="mt-2">
                              <h2 className="font16 mb-2 text-dark">Business Type</h2>
                              <div className="d-flex align-items-center" style={{ columnGap: "10px" }}>
                                <input type="radio" id="businessTypeDiscount" name="businessType" value="Bonus" onChange={handleBusinessTypeChange} disabled checked={confirmationBusinessTypeOption === 'Bonus'} />
                                <label htmlFor="businessTypeBonus">Bonus</label>
                                <input type="radio" id="businessTypeDiscount" name="businessType" value="Discount" onChange={handleBusinessTypeChange} disabled checked={confirmationBusinessTypeOption === 'Discount'} />
                                <label htmlFor="businessTypeBonus">Discount</label>
                              </div>
                            </div>
                            <div className="mt-2">
                              <h2 className="font16 mb-2 text-dark">Sent order confirmation notifications</h2>
                              <div className="d-flex align-items-center" style={{ columnGap: "10px" }}>
                                <input type="radio" id="confirmationYes" name="confirmation" value="Yes" onChange={handleConfirmationChange} disabled checked={confirmationOption === 'Yes'} />
                                <label htmlFor="confirmationYes">Yes</label>
                                <input type="radio" id="confirmationNo" name="confirmation" value="No" onChange={handleConfirmationChange} disabled checked={confirmationOption === 'No'} />
                                <label htmlFor="confirmationNo">No</label>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div className="d-flex align-items-center responsiveRadioBP" style={{ gap: "10px" }}>
                          <div style={{ columnGap: "3px" }} className="d-flex align-items-center">
                            <input type="radio" id="marketplace" name="Fullfillment" value="Marketplace" onChange={handleFullfillmentTypeChange} className="mb-0" checked={business_partner_type === "Marketplace"} />
                            <label htmlFor="marketplace">Marketplace</label>
                          </div>
                          <div style={{ columnGap: "3px" }} className="d-flex align-items-center">
                            <input type="radio" id="Direct" name="Fullfillment" value="Direct Order" onChange={handleFullfillmentTypeChange} className="mb-0" checked={business_partner_type === "Direct Order"} />
                            <label htmlFor="Direct">Direct Order</label>
                          </div>
                        </div>
                        {showConfirmationSelect && (
                          
                         <div>
                              <div className="mt-2">
                                <h2 className="font16 mb-2 text-dark">Business Type</h2>
                                <div className="d-flex align-items-center" style={{ columnGap: "10px" }}>
                                  <input type="radio" id="businessTypeBonus" name="businessType" value="Bonus" onChange={handleBusinessTypeChange} checked={confirmationBusinessTypeOption === 'Bonus'} />
                                  <label htmlFor="businessTypeBonus">Bonus</label>
                                  <input type="radio" id="businessTypeDiscount" name="businessType" value="Discount" onChange={handleBusinessTypeChange} checked={confirmationBusinessTypeOption === 'Discount'} />
                                  <label htmlFor="businessTypeDiscount">Discount</label>
                                </div>
                              </div>
                              <div className="mt-2">
                                <h2 className="font16 mb-2 text-dark">Sent order confirmation notifications</h2>
                                <div className="d-flex align-items-center" style={{ columnGap: "10px" }}>
                                  <input type="radio" id="confirmationYes" name="confirmation" value="Yes" onChange={handleConfirmationChange} checked={confirmationOption === 'Yes'} />
                                  <label htmlFor="confirmationYes">Yes</label>
                                  <input type="radio" id="confirmationNo" name="confirmation" value="No" onChange={handleConfirmationChange} checked={confirmationOption === 'No'} />
                                  <label htmlFor="confirmationNo">No</label>
                                </div>
                              </div>
                         </div>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
             
              <Formik
                enableReinitialize={true}
                initialValues={formikInitialValue}
                validationSchema={formSchema}
                onSubmit={(values, { setSubmitting }) => {
                  //setStillSubmitting(true);
                  setDisabled(true);
                  setTimeout(()=>{
                    setDisabled(false);
                  },1500)
                  setTimeout(() => {
                    handleSubmitForm(values);
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting /* and other goodies */,
                }) => (
                  <Form
                    className="p-4 rounded containerShadow"
                    onSubmit={handleSubmit}
                  >
               
                    <h2 className="font16 mb-2 text-dark">General Information</h2>

                    <Row>
                      {/* Business Name */}
                      <Col md={6}>
                      <Form.Control
                          type="hidden"
                          value={partner_id}
                          onChange={(e) => setPartner_id(e.target.value)}
                          placeholder="Enter Name"
                          className="font14"
                        />
                        <Form.Control
                          type="hidden"
                          value={userId}
                          onChange={(e) => setId(e.target.value)}
                          placeholder="Enter Name"
                          className="font14"
                        />
                        <Form.Group className="mb-3 mb-lg-0" controlId="formBasicText1" >
                          <Form.Label className="font12 text-dark fw-medium">
                            Business Name{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="business_name"
                            value={nameFormat(values.business_name.charAt(0).toUpperCase() + values.business_name.slice(1))}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // pattern="^(?!.*\s).*$"
                            // title="Please avoid leading spaces."
                            type="text"
                            placeholder="Enter Business Name"
                            isValid={touched.business_name && !errors.business_name}
                            isInvalid={!!errors.business_name}
                          />
                          {errors.business_name && touched.business_name ? (
                            <div className="text-danger font12">
                              {errors.business_name}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3 mb-lg-0">
                          <Form.Label className="font12 text-dark fw-medium">
                            Phone Number{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <div className='d-flex justify-content-center align-items-center'>

                            <Field as='select' className="font12 form-select" name='main_code' value={(values.main_code)} onChange={handleChange}
                              onBlur={handleBlur} style={{ border: '1px solid #cfc6c6', height: '31px', borderRadius: '5px', width: '6rem' }}
                              isValid={touched.main_code && !errors.main_code}
                              isInvalid={!!errors.main_code} type='text'>
                              <option value="" label="Code" disabled/>
                              <option value="+1" label="+1 USA" />
                              <option value="+7" label="+7 RUS" />
                              <option value="+20" label="+20 EGY" />
                              <option value="+27" label="+27 ZAF" />
                              <option value="+30" label="+30 GRC" />
                              <option value="+31" label="+31 NLD" />
                              <option value="+32" label="+32 BEL" />
                              <option value="+33" label="+33 FRA" />
                              <option value="+34" label="+34 ESP" />
                              <option value="+36" label="+36 HUN" />
                              <option value="+39" label="+39 ITA" />
                              <option value="+40" label="+40 ROU" />
                              <option value="+41" label="+41 SWZ" />
                              <option value="+43" label="+43 AUT" />
                              <option value="+44" label="+44 GBR" />
                              <option value="+45" label="+45 DNK" />
                              <option value="+46" label="+46 SWE" />
                              <option value="+47" label="+47 NOR" />
                              <option value="+48" label="+48 POL" />
                              <option value="+49" label="+49 DEU" />
                              <option value="+51" label="+51 PER" />
                              <option value="+52" label="+52 MEX" />
                              <option value="+53" label="+53 CUB" />
                              <option value="+54" label="+54 ARG" />
                              <option value="+55" label="+55 BRA" />
                              <option value="+56" label="+56 CHL" />
                              <option value="+57" label="+57 COL" />
                              <option value="+58" label="+58 VEN" />
                              <option value="+60" label="+60 MYS" />
                              <option value="+61" label="+61 AUS" />
                              <option value="+62" label="+62 IDN" />
                              <option value="+63" label="+63 PHL" />
                              <option value="+64" label="+64 NZL" />
                              <option value="+65" label="+65 SGP" />
                              <option value="+66" label="+66 THA" />
                              <option value="+81" label="+81 JPN" />
                              <option value="+82" label="+82 KOR" />
                              <option value="+84" label="+84 VNM" />
                              <option value="+86" label="+86 CHN" />
                              <option value="+90" label="+90 TUR" />
                              <option value="+91" label="+91 IND" />
                              <option value="+92" label="+92 PAK" />
                              <option value="+93" label="+93 AFG" />
                              <option value="+94" label="+94 LKA " />
                              <option value="+95" label="+95 MMR" />
                              <option value="+98" label="+98 IRN" />
                              <option value="+211" label="+211 SSD" />
                              <option value="+212" label="+212 MAR" />
                              <option value="+213" label="+213 DZA" />
                              <option value="+216" label="+216 TUN" />
                              <option value="+218" label="+218 LBY" />
                            </Field>
                            &nbsp;
                            {values.main_code === '' ?
                              <Form.Control
                                type="text"
                                name="main_phone"
                                value={phoneFormat(values.main_phone)?.trim()}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                // isValid={touched.main_phone && !errorPhone}
                                // isInvalid={errorPhone === true}
                                isValid={touched.main_phone && !errors.main_phone}
                                isInvalid={!!errors.main_phone}
                                placeholder="Enter Phone"
                                className="font12 "
                                disabled
                             />
                              :
                              <Form.Control
                              type="text"
                              name="main_phone"
                              value={values.main_phone || ""}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const formattedPhoneNumber = phoneFormat(inputValue);
                                handleChange({ target: { name: 'main_phone', value: formattedPhoneNumber } });
                              }}
                              onBlur={handleBlur}
                              isValid={touched.main_phone && !errorPhone}
                              isInvalid={errorPhone}
                              placeholder="Enter Phone"
                              className="font12"
                              required
                            />
                            }
                          </div>
                          <div className="d-flex">
                            {values.main_phone === '' ? '' : (errors.main_code && touched.main_code ? (
                              <div className="text-danger font12">
                                {errors.main_code}
                              </div>
                            ) : null)
                            } 
                            {errorPhone && touched.main_phone ? (
                              <div className="text-danger font12">
                                {errors.main_phone}
                              </div>
                            ) : null}
                          </div>

                        </Form.Group>
                      </Col>
                      
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText2">
                          <Form.Label className="font12 text-dark fw-medium">
                            Website{" "}
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="website"
                            value={values.website}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Website"
                            isValid={
                              touched.website &&
                              !errors.website
                            }
                            isInvalid={!!errors.website}
                          />
                          {errors.website &&
                            touched.website ? (
                            <div className="text-danger font12">
                              {errors.website}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText2">
                          <Form.Label className="font12 text-dark fw-medium">
                            Email{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="email"
                            placeholder="Enter Email"
                            isValid={
                              touched.email &&
                              !errors.email
                            }
                            isInvalid={!!errors.email || isError}
                          />
                          {errors.email &&
                           touched.email &&
                            !isError ? (
                              <div className="text-danger font12">{errors.email}</div>
                            ) : null}
                            {isError ? (
                              <div className="text-danger font12">Email Already Exists</div>
                            ) : null}
                        </Form.Group>
                      </Col>
                    </Row>

                    <h2 className="font16 mb-2 mt-2 text-dark">Address Information</h2>

                    <Row>
                     
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          Number and Street{" "}
                          <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="street_number"
                            value={nameFormat(values.street_number)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Number and Street"
                            isValid={touched.street_number && !errors.street_number}
                            isInvalid={!!errors.street_number}
                          />
                          {errors.street_number && touched.street_number ? (
                            <div className="text-danger font12">
                              {errors.street_number}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" >
                          <Form.Label className="font12 text-dark fw-medium">
                          Country{" "}
                          <span className="text-danger">*</span>
                          </Form.Label>
                          <Field as='select' style={{ height: '31px', width: '100%',borderWidth:'1px',borderRadius:'5px',borderColor:errors.country && touched.country ? 'red' : '#cccccc' }} className="font12" name="country"   
                          onChange={handleChange} onBlur={handleBlur}  isValid={
                              touched.country &&
                              !errors.country
                            }
                            isInvalid={!!errors.country} 
                            placeholder="Enter Business Partner"
                            >
                            {countryData?.map((partner,index)=> {
                              return(
                                <option key={index} value={partner.value} >
                                  {partner.label}
                                </option>
                              )
                            })}
                          </Field>
                          {/* <Form.Control
                            className="font12"
                            name="country"
                            value={nameFormat(values.country)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Country"
                            isValid={touched.country && !errors.country}
                            isInvalid={!!errors.country}
                          /> */}
                          {errors.country && touched.country ? (
                            <div className="text-danger font12">
                              {errors.country}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                       <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText4">
                          <Form.Label className="font12 text-dark fw-medium">
                            Postal or Zip Code{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="postal"
                            value={values.postal.trim()}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Zip Code"
                            isValid={touched.postal && !errors.postal}
                            isInvalid={!!errors.postal}
                          />
                          {errors.postal && touched.postal ? (
                            <div className="text-danger font12">
                              {errors.postal}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicText5">
                          <Form.Label className="font12 text-dark fw-medium">
                          City{" "}
                          <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="city"
                            value={nameFormat(values.city)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter City"
                            isValid={touched.city && !errors.city}
                            isInvalid={!!errors.city}
                          />
                          {errors.city && touched.city ? (
                            <div className="text-danger font12">
                              {errors.city}
                            </div>
                          ) : null}
                        </Form.Group>
                        </Col>
                      </Row>
                      <h2 className="font16 mb-2 mt-2 text-dark">Third Party Info</h2>

                    <Row>
                     
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          Tax Id{" "}
                          {/* <span className="text-danger">*</span> */}
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="taxId"
                            value={values?.taxId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Tax Id"
                            isValid={touched.taxId && !errors.taxId}
                            isInvalid={!!errors.taxId}
                          />
                          {errors.taxId && touched.taxId ? (
                            <div className="text-danger font12">
                              {errors.taxId}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          Client Program Number{" "}
                          {/* <span className="text-danger">*</span> */}
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="clientProgramNumber"
                            value={(values?.clientProgramNumber)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Client Program Number"
                            isValid={touched.clientProgramNumber && !errors.clientProgramNumber}
                            isInvalid={!!errors.clientProgramNumber}
                          />
                          {errors.clientProgramNumber && touched.clientProgramNumber ? (
                            <div className="text-danger font12">
                              {errors.clientProgramNumber}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          Company{" "}
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="company"
                            value={values?.company}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Company"
                            isValid={touched.company && !errors.company}
                            isInvalid={!!errors.company}
                          />
                          {errors.company && touched.company ? (
                            <div className="text-danger font12">
                              {errors.company}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          Contact{" "}
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="contact"
                            value={values.contact}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Contact"
                            isValid={touched.contact && !errors.contact}
                            isInvalid={!!errors.contact}
                          />
                          {errors.contact && touched.contact ? (
                            <div className="text-danger font12">
                              {errors.contact}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          Line 1{" "}
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="line1"
                            value={values.line1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Line 1 Address"
                            isValid={touched.line1 && !errors.line1}
                            isInvalid={!!errors.line1}
                          />
                          {errors.line1 && touched.line1 ? (
                            <div className="text-danger font12">
                              {errors.line1}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          Line 2{" "}
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="line2"
                            value={values.line2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Line 2 Address"
                            isValid={touched.line2 && !errors.line2}
                            isInvalid={!!errors.line2}
                          />
                          {errors.line2 && touched.line2 ? (
                            <div className="text-danger font12">
                              {errors.line2}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          Line 3{" "}
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="line3"
                            value={values.line3}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Line 3 Address"
                            isValid={touched.line3 && !errors.line3}
                            isInvalid={!!errors.line3}
                          />
                          {errors.line3 && touched.line3 ? (
                            <div className="text-danger font12">
                              {errors.line3}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          City{" "}
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="tpCity"
                            value={values?.tpCity}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter City"
                            isValid={touched.tpCity && !errors.tpCity}
                            isInvalid={!!errors.tpCity}
                          />
                          {errors.tpCity && touched.tpCity ? (
                            <div className="text-danger font12">
                              {errors.tpCity}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          Region{" "}
                          </Form.Label>
                            <Field
                              as='select'
                              className="font12 form-select"
                              name='region'
                              value={values.region}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.region && !errors.region}
                              isInvalid={!!errors.region}
                              type='text'
                            >
                              {id === undefined ? <option value="" label="Select Region" disabled /> : <option value="" label="Select Region" />}
                              <optgroup label="United States">
                                <option value="AL" label="AL"></option>
                              <option value="AK" label="AK"></option>
                              <option value="AZ" label="AZ"></option>
                              <option value="AR" label="AR"></option>
                              <option value="CA" label="CA"></option>
                              <option value="CO" label="CO"></option>
                              <option value="CT" label="CT"></option>
                              <option value="DE" label="DE"></option>
                              <option value="FL" label="FL"></option>
                              <option value="GA" label="GA"></option>
                              <option value="HI" label="HI"></option>
                              <option value="ID" label="ID"></option>
                              <option value="IL" label="IL"></option>
                              <option value="IN" label="IN"></option>
                              <option value="IA" label="IA"></option>
                              <option value="KS" label="KS"></option>
                              <option value="KY" label="KY"></option>
                              <option value="LA" label="LA"></option>
                              <option value="ME" label="ME"></option>
                              <option value="MD" label="MD"></option>
                              <option value="MA" label="MA"></option>
                              <option value="MI" label="MI"></option>
                              <option value="MN" label="MN"></option>
                              <option value="MS" label="MS"></option>
                              <option value="MO" label="MO"></option>
                              <option value="MT" label="MT"></option>
                              <option value="NE" label="NE"></option>
                              <option value="NV" label="NV"></option>
                              <option value="NH" label="NH"></option>
                              <option value="NJ" label="NJ"></option>
                              <option value="NM" label="NM"></option>
                              <option value="NY" label="NY"></option>
                              <option value="NC" label="NC"></option>
                              <option value="ND" label="ND"></option>
                              <option value="OH" label="OH"></option>
                              <option value="OK" label="OK"></option>
                              <option value="OR" label="OR"></option>
                              <option value="PA" label="PA"></option>
                              <option value="RI" label="RI"></option>
                              <option value="SC" label="SC"></option>
                              <option value="SD" label="SD"></option>
                              <option value="TN" label="TN"></option>
                              <option value="TX" label="TX"></option>
                              <option value="UT" label="UT"></option>
                              <option value="VT" label="VT"></option>
                              <option value="VA" label="VA"></option>
                              <option value="WA" label="WA"></option>
                              <option value="WV" label="WV"></option>
                              <option value="WI" label="WI"></option>
                              <option value="WY" label="WY"></option>

                              </optgroup>
                              <optgroup label="Canada">
                                <option value="AB" label="AB"></option>
                                <option value="BC" label="BC"></option>
                                <option value="MB" label="MB"></option>
                                <option value="NB" label="NB"></option>
                                <option value="NL" label="NL"></option>
                                <option value="NS" label="NS"></option>
                                <option value="ON" label="ON"></option>
                                <option value="PE" label="PE"></option>
                                <option value="QC" label="QC"></option>
                                <option value="SK" label="SK"></option>
                                <option value="NT" label="NT"></option>
                                <option value="NU" label="NU"></option>
                                <option value="YT" label="YT"></option>

                              </optgroup>
                            </Field>

                          {errors.region && touched.region ? (
                            <div className="text-danger font12">
                              {errors.region}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          Postal Code{" "}
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="postalCode"
                            value={values.postalCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Postal Code"
                            isValid={touched.postalCode && !errors.postalCode}
                            isInvalid={!!errors.postalCode}
                          />
                          {errors.postalCode && touched.postalCode ? (
                            <div className="text-danger font12">
                              {errors.postalCode}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          Postal Code Ext{" "}
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="postalCodeExt"
                            value={values.postalCodeExt}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter  Postal Code Ext"
                            isValid={touched.postalCodeExt && !errors.postalCodeExt}
                            isInvalid={!!errors.postalCodeExt}
                          />
                          {errors.postalCodeExt && touched.postalCodeExt ? (
                            <div className="text-danger font12">
                              {errors.postalCodeExt}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          Country{" "}
                          </Form.Label>
                            <Field
                              as='select'
                              className="font12 form-select"
                              name='tpCountry'
                              value={values.tpCountry}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.tpCountry && !errors.tpCountry}
                              isInvalid={!!errors.tpCountry}
                              type='text'
                            >

                              {id === undefined ? <option value="" label="Select Country" disabled /> : <option value="" label="Select Country" />}
                                  <option value="AFG" label="AFG"></option>
                                  <option value="ALA" label="ALA"></option>
                                  <option value="ALB" label="ALB"></option>
                                  <option value="DZA" label="DZA"></option>
                                  <option value="ASM" label="ASM"></option>
                                  <option value="AND" label="AND"></option>
                                  <option value="AGO" label="AGO"></option>
                                  <option value="AIA" label="AIA"></option>
                                  <option value="ATA" label="ATA"></option>
                                  <option value="ATG" label="ATG"></option>
                                  <option value="ARG" label="ARG"></option>
                                  <option value="ARM" label="ARM"></option>
                                  <option value="ABW" label="ABW"></option>
                                  <option value="AUS" label="AUS"></option>
                                  <option value="AUT" label="AUT"></option>
                                  <option value="AZE" label="AZE"></option>
                                  <option value="BHS" label="BHS"></option>
                                  <option value="BHR" label="BHR"></option>
                                  <option value="BGD" label="BGD"></option>
                                  <option value="BRB" label="BRB"></option>
                                  <option value="BLR" label="BLR"></option>
                                  <option value="BEL" label="BEL"></option>
                                  <option value="BLZ" label="BLZ"></option>
                                  <option value="BEN" label="BEN"></option>
                                  <option value="BMU" label="BMU"></option>
                                  <option value="BTN" label="BTN"></option>
                                  <option value="BOL" label="BOL"></option>
                                  <option value="BES" label="BES"></option>
                                  <option value="BIH" label="BIH"></option>
                                  <option value="BWA" label="BWA"></option>
                                  <option value="BVT" label="BVT"></option>
                                  <option value="BRA" label="BRA"></option>
                                  <option value="IOT" label="IOT"></option>
                                  <option value="BRN" label="BRN"></option>
                                  <option value="BGR" label="BGR"></option>
                                  <option value="BFA" label="BFA"></option>
                                  <option value="BDI" label="BDI"></option>
                                  <option value="KHM" label="KHM"></option>
                                  <option value="CMR" label="CMR"></option>
                                  <option value="CAN" label="CAN"></option>
                                  <option value="CPV" label="CPV"></option>
                                  <option value="CYM" label="CYM"></option>
                                  <option value="CAF" label="CAF"></option>
                                  <option value="TCD" label="TCD"></option>
                                  <option value="CHL" label="CHL"></option>
                                  <option value="CHN" label="CHN"></option>
                                  <option value="CXR" label="CXR"></option>
                                  <option value="CCK" label="CCK"></option>
                                  <option value="COL" label="COL"></option>
                                  <option value="COM" label="COM"></option>
                                  <option value="COG" label="COG"></option>
                                  <option value="COD" label="COD"></option>
                                  <option value="COK" label="COK"></option>
                                  <option value="CRI" label="CRI"></option>
                                  <option value="CIV" label="CIV"></option>
                                  <option value="HRV" label="HRV"></option>
                                  <option value="CUB" label="CUB"></option>
                                  <option value="CUW" label="CUW"></option>
                                  <option value="CYP" label="CYP"></option>
                                  <option value="CZE" label="CZE"></option>
                                  <option value="DNK" label="DNK"></option>
                                  <option value="DJI" label="DJI"></option>
                                  <option value="DMA" label="DMA"></option>
                                  <option value="DOM" label="DOM"></option>
                                  <option value="ECU" label="ECU"></option>
                                  <option value="EGY" label="EGY"></option>
                                  <option value="SLV" label="SLV"></option>
                                  <option value="GNQ" label="GNQ"></option>
                                  <option value="ERI" label="ERI"></option>
                                  <option value="EST" label="EST"></option>
                                  <option value="ETH" label="ETH"></option>
                                  <option value="FLK" label="FLK"></option>
                                  <option value="FRO" label="FRO"></option>
                                  <option value="FJI" label="FJI"></option>
                                  <option value="FIN" label="FIN"></option>
                                  <option value="FRA" label="FRA"></option>
                                  <option value="GUF" label="GUF"></option>
                                  <option value="PYF" label="PYF"></option>
                                  <option value="ATF" label="ATF"></option>
                                  <option value="GAB" label="GAB"></option>
                                  <option value="GMB" label="GMB"></option>
                                  <option value="GEO" label="GEO"></option>
                                  <option value="DEU" label="DEU"></option>
                                  <option value="GHA" label="GHA"></option>
                                  <option value="GIB" label="GIB"></option>
                                  <option value="GRC" label="GRC"></option>
                                  <option value="GRL" label="GRL"></option>
                                  <option value="GRD" label="GRD"></option>
                                  <option value="GLP" label="GLP"></option>
                                  <option value="GUM" label="GUM"></option>
                                  <option value="GTM" label="GTM"></option>
                                  <option value="GGY" label="GGY"></option>
                                  <option value="GIN" label="GIN"></option>
                                  <option value="GNB" label="GNB"></option>
                                  <option value="GUY" label="GUY"></option>
                                  <option value="HTI" label="HTI"></option>
                                  <option value="HMD" label="HMD"></option>
                                  <option value="VAT" label="VAT"></option>
                                  <option value="HND" label="HND"></option>
                                  <option value="HKG" label="HKG"></option>
                                  <option value="HUN" label="HUN"></option>
                                  <option value="ISL" label="ISL"></option>
                                  <option value="IND" label="IND"></option>
                                  <option value="IDN" label="IDN"></option>
                                  <option value="IRN" label="IRN"></option>
                                  <option value="IRQ" label="IRQ"></option>
                                  <option value="IRL" label="IRL"></option>
                                  <option value="IMN" label="IMN"></option>
                                  <option value="ISR" label="ISR"></option>
                                  <option value="ITA" label="ITA"></option>
                                  <option value="JAM" label="JAM"></option>
                                  <option value="JPN" label="JPN"></option>
                                  <option value="JEY" label="JEY"></option>
                                  <option value="JOR" label="JOR"></option>
                                  <option value="KAZ" label="KAZ"></option>
                                  <option value="KEN" label="KEN"></option>
                                  <option value="KIR" label="KIR"></option>
                                  <option value="PRK" label="PRK"></option>
                                  <option value="KOR" label="KOR"></option>
                                  <option value="KWT" label="KWT"></option>
                                  <option value="KGZ" label="KGZ"></option>
                                  <option value="LAO" label="LAO"></option>
                                  <option value="LVA" label="LVA"></option>
                                  <option value="LBN" label="LBN"></option>
                                  <option value="LSO" label="LSO"></option>
                                  <option value="LBR" label="LBR"></option>
                                  <option value="LBY" label="LBY"></option>
                                  <option value="LIE" label="LIE"></option>
                                  <option value="LTU" label="LTU"></option>
                                  <option value="LUX" label="LUX"></option>
                                  <option value="MAC" label="MAC"></option>
                                  <option value="MKD" label="MKD"></option>
                                  <option value="MDG" label="MDG"></option>
                                  <option value="MWI" label="MWI"></option>
                                  <option value="MYS" label="MYS"></option>
                                  <option value="MDV" label="MDV"></option>
                                  <option value="MLI" label="MLI"></option>
                                  <option value="MLT" label="MLT"></option>
                                  <option value="MHL" label="MHL"></option>
                                  <option value="MTQ" label="MTQ"></option>
                                  <option value="MRT" label="MRT"></option>
                                  <option value="MUS" label="MUS"></option>
                                  <option value="MYT" label="MYT"></option>
                                  <option value="MEX" label="MEX"></option>
                                  <option value="FSM" label="FSM"></option>
                                  <option value="MDA" label="MDA"></option>
                                  <option value="MCO" label="MCO"></option>
                                  <option value="MNG" label="MNG"></option>
                                  <option value="MNE" label="MNE"></option>
                                  <option value="MSR" label="MSR"></option>
                                  <option value="MAR" label="MAR"></option>
                                  <option value="MOZ" label="MOZ"></option>
                                  <option value="MMR" label="MMR"></option>
                                  <option value="NAM" label="NAM"></option>
                                  <option value="NRU" label="NRU"></option>
                                  <option value="NPL" label="NPL"></option>
                                  <option value="NLD" label="NLD"></option>
                                  <option value="NCL" label="NCL"></option>
                                  <option value="NZL" label="NZL"></option>
                                  <option value="NIC" label="NIC"></option>
                                  <option value="NER" label="NER"></option>
                                  <option value="NGA" label="NGA"></option>
                                  <option value="NIU" label="NIU"></option>
                                  <option value="NFK" label="NFK"></option>
                                  <option value="MNP" label="MNP"></option>
                                  <option value="NOR" label="NOR"></option>
                                  <option value="OMN" label="OMN"></option>
                                  <option value="PAK" label="PAK"></option>
                                  <option value="PLW" label="PLW"></option>
                                  <option value="PSE" label="PSE"></option>
                                  <option value="PAN" label="PAN"></option>
                                  <option value="PNG" label="PNG"></option>
                                  <option value="PRY" label="PRY"></option>
                                  <option value="PER" label="PER"></option>
                                  <option value="PHL" label="PHL"></option>
                                  <option value="PCN" label="PCN"></option>
                                  <option value="POL" label="POL"></option>
                                  <option value="PRT" label="PRT"></option>
                                  <option value="PRI" label="PRI"></option>
                                  <option value="QAT" label="QAT"></option>
                                  <option value="REU" label="REU"></option>
                                  <option value="ROU" label="ROU"></option>
                                  <option value="RUS" label="RUS"></option>
                                  <option value="RWA" label="RWA"></option>
                                  <option value="BLM" label="BLM"></option>
                                  <option value="SHN" label="SHN"></option>
                                  <option value="KNA" label="KNA"></option>
                                  <option value="LCA" label="LCA"></option>
                                  <option value="MAF" label="MAF"></option>
                                  <option value="SPM" label="SPM"></option>
                                  <option value="VCT" label="VCT"></option>
                                  <option value="WSM" label="WSM"></option>
                                  <option value="SMR" label="SMR"></option>
                                  <option value="STP" label="STP"></option>
                                  <option value="SAU" label="SAU"></option>
                                  <option value="SEN" label="SEN"></option>
                                  <option value="SRB" label="SRB"></option>
                                  <option value="SYC" label="SYC"></option>
                                  <option value="SLE" label="SLE"></option>
                                  <option value="SGP" label="SGP"></option>
                                  <option value="SXM" label="SXM"></option>
                                  <option value="SVK" label="SVK"></option>
                                  <option value="SVN" label="SVN"></option>
                                  <option value="SLB" label="SLB"></option>
                                  <option value="SOM" label="SOM"></option>
                                  <option value="ZAF" label="ZAF"></option>
                                  <option value="SGS" label="SGS"></option>
                                  <option value="SSD" label="SSD"></option>
                                  <option value="ESP" label="ESP"></option>
                                  <option value="LKA" label="LKA"></option>
                                  <option value="SDN" label="SDN"></option>
                                  <option value="SUR" label="SUR"></option>
                                  <option value="SJM" label="SJM"></option>
                                  <option value="SWE" label="SWE"></option>
                                  <option value="CHE" label="CHE"></option>
                                  <option value="SYR" label="SYR"></option>
                                  <option value="TWN" label="TWN"></option>
                                  <option value="TJK" label="TJK"></option>
                                  <option value="TZA" label="TZA"></option>
                                  <option value="THA" label="THA"></option>
                                  <option value="TLS" label="TLS"></option>
                                  <option value="TGO" label="TGO"></option>
                                  <option value="TKL" label="TKL"></option>
                                  <option value="TON" label="TON"></option>
                                  <option value="TTO" label="TTO"></option>
                                  <option value="TUN" label="TUN"></option>
                                  <option value="TUR" label="TUR"></option>
                                  <option value="TKM" label="TKM"></option>
                                  <option value="TCA" label="TCA"></option>
                                  <option value="TUV" label="TUV"></option>
                                  <option value="UGA" label="UGA"></option>
                                  <option value="UKR" label="UKR"></option>
                                  <option value="ARE" label="ARE"></option>
                                  <option value="GBR" label="GBR"></option>
                                  <option value="USA" label="USA"></option>
                                  <option value="UMI" label="UMI"></option>
                                  <option value="URY" label="URY"></option>
                                  <option value="UZB" label="UZB"></option>
                                  <option value="VUT" label="VUT"></option>
                                  <option value="VEN" label="VEN"></option>
                                  <option value="VNM" label="VNM"></option>
                                  <option value="VGB" label="VGB"></option>
                                  <option value="VIR" label="VIR"></option>
                                  <option value="WLF" label="WLF"></option>
                                  <option value="ESH" label="ESH"></option>
                                  <option value="YEM" label="YEM"></option>
                                  <option value="ZMB" label="ZMB"></option>
                                  <option value="ZWE" label="ZWE"></option>


                            </Field>
                          {errors.tpCountry && touched.tpCountry ? (
                            <div className="text-danger font12">
                              {errors.tpCountry}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      
                      </Row>

                      {/* <h2 className="font16 mb-2 mt-2 text-dark">Business Partner  Type</h2>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label className="font12 text-dark fw-medium">
                            Business Partner Type{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Field as='select' className="font12 form-select" name='business_partner_type' value={(values.business_partner_type)} onChange={handleChange}
                              onBlur={handleBlur} style={{ border: errors.business_partner_type && touched.business_partner_type ? '1px solid red' : '1px solid #cfc6c6', height: '31px', borderRadius: '5px' }}
                              isValid={touched.business_partner_type && !errors.business_partner_type}
                              isInvalid={!!errors.business_partner_type} type='text'>
                              <option value="" label="Select" />
                              <option value="Marketplace" label="Marketplace" />
                              <option value="Direct Order" label="Direct Order" />
                            </Field>
                            {errors.business_partner_type && touched.business_partner_type ? (
                              <div className="text-danger font12">
                                {errors.business_partner_type}
                              </div>
                            ) : null}
                          </Form.Group>
                        </Col>
                      </Row> */}
                                             <h2 className="font16 mb-2 mt-2 text-dark">Exchange Currency</h2>
                      
                      <Row>

                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicText7">
                            <Form.Label className="font12 text-dark fw-medium">
                            Name
                            </Form.Label>
                            <div className="position-relative" style={{ minWidth: "180px" }}>
                              <Form.Control
                                type="text"
                                placeholder="Enter The Name of Currency"
                                className={`font12 form-control ${errors.exchangeName && touched.exchangeName ? "is-invalid" : ""
                                  }`}
                                name="exchangeName"
                                value={values.exchangeName}
                                // defaultValue={(thersholdQuantity)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.exchangeName && !errors.exchangeName}
                                isInvalid={!!errors.exchangeName}
                                style={{
                                  borderRadius: "5px",
                                }}
                              />
                              {errors.exchangeName && touched.exchangeName && (
                                <div className="invalid-feedback font12">
                                  {errors.exchangeName}
                                </div>
                              )}
                            </div>
                          </Form.Group>
                        </Col>

                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicText7">
                            <Form.Label className="font12 text-dark fw-medium">
                            Currency
                            </Form.Label>
                            <div className="position-relative" style={{ minWidth: "180px" }}>
                              <Form.Control
                                type="text"
                                placeholder="Enter Currency"
                                className={`font12 form-control ${errors.exchangeCurrency && touched.exchangeCurrency ? "is-invalid" : ""
                                  }`}
                                name="exchangeCurrency"
                                value={values.exchangeCurrency}
                                // defaultValue={(thersholdValue)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.exchangeCurrency && !errors.exchangeCurrency}
                                isInvalid={!!errors.exchangeCurrency}
                                style={{
                                  borderRadius: "5px",
                                }}
                              />
                              {errors.exchangeCurrency && touched.exchangeCurrency && (
                                <div className="invalid-feedback font12">
                                  {errors.exchangeCurrency}
                                </div>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicText7">
                            <Form.Label className="font12 text-dark fw-medium">
                            Exchange rate
                            </Form.Label>
                            <div className="position-relative" style={{ minWidth: "180px" }}>
                              <Form.Control
                                type="number"
                                placeholder="Enter Exchange rate"
                                className={`font12 form-control ${errors.exchangeRate && touched.exchangeRate ? "is-invalid" : ""
                                  }`}
                                name="exchangeRate"
                                value={values.exchangeRate}
                                // defaultValue={(thersholdValue)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.exchangeRate && !errors.exchangeRate}
                                isInvalid={!!errors.exchangeRate}
                                style={{
                                  borderRadius: "5px",
                                }}
                              />
                              {errors.exchangeRate && touched.exchangeRate && (
                                <div className="invalid-feedback font12">
                                  {errors.exchangeRate}
                                </div>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                        {/* <h2 className="font16 mb-2 mt-2 text-dark">Funds Threshold Reminder</h2>
                      <Row>

                        <Col md={6}>

                          <Form.Group className="mb-3" controlId="formBasicText7">
                            <Form.Label className="font12 text-dark fw-medium">
                              Threshold Amount
                            </Form.Label>
                            <div className="position-relative" style={{ minWidth: "180px" }}>
                              <Form.Control
                                type="number"
                                placeholder="Enter Threshold Amount"
                                className={`font12 form-control ${validate ? "field_validate" : ""    
                                  }`}
                                name="marginAmount"
                                value={values.marginAmount}
                                // defaultValue={(marginAmount)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.marginAmount && !errors.marginAmount}
                                isInvalid={!!errors.marginAmount}
                                style={{
                                  borderRadius: "5px",
                                }}
                              />
                              {errors.marginAmount && touched.marginAmount && (
                                <div className="invalid-feedback font12">
                                  {errors.marginAmount}
                                </div>
                              )}
                            </div>
                          </Form.Group>

                          {isApiThreshold ? <div className="d-flex justify-content-md-end">
                            <Link className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font14 " style={{ minWidth: "140px", marginTop: "-10px" }} onClick={deleteReminder}>
                              Withdraw Reminder
                            </Link>
                          </div> : ""}
                        </Col>


                      </Row> */}

                      <h2 className="font16 mb-2 mt-2 text-dark">Threshold Limit Per Order</h2>
                      
                      <Row>

                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicText7">
                            <Form.Label className="font12 text-dark fw-medium">
                              Number of Giftcards{" "}
                            </Form.Label>
                            <div className="position-relative" style={{ minWidth: "180px" }}>
                              <Form.Control
                                type="number"
                                placeholder="Enter Number of Giftcards"
                                className={`font12 form-control ${errors.allThresholdQuantity && touched.allThresholdQuantity ? "is-invalid" : ""
                                  }`}
                                name="allThresholdQuantity"
                                value={values.allThresholdQuantity}
                                // defaultValue={(thersholdQuantity)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.allThresholdQuantity && !errors.allThresholdQuantity}
                                isInvalid={!!errors.allThresholdQuantity}
                                style={{
                                  borderRadius: "5px",
                                }}
                              />
                              {errors.allThresholdQuantity && touched.allThresholdQuantity && (
                                <div className="invalid-feedback font12">
                                  {errors.allThresholdQuantity}
                                </div>
                              )}
                            </div>
                          </Form.Group>
                        </Col>

                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicText7">
                            <Form.Label className="font12 text-dark fw-medium">
                              Total Threshold Value{" "}
                            </Form.Label>
                            <div className="position-relative" style={{ minWidth: "180px" }}>
                              <Form.Control
                                type="number"
                                placeholder="Enter Total Threshold Value"
                                className={`font12 form-control ${errors.allThresholdValue && touched.allThresholdValue ? "is-invalid" : ""
                                  }`}
                                name="allThresholdValue"
                                value={values.allThresholdValue}
                                // defaultValue={(thersholdValue)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.allThresholdValue && !errors.allThresholdValue}
                                isInvalid={!!errors.allThresholdValue}
                                style={{
                                  borderRadius: "5px",
                                }}
                              />
                              {errors.allThresholdValue && touched.allThresholdValue && (
                                <div className="invalid-feedback font12">
                                  {errors.allThresholdValue}
                                </div>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>


                      {/* {
                        apiFundsType === "Associate" || funds_type === "Associate" ? ""
                          : <>
                            <h2 className="font16 mb-2 mt-2 text-dark">Payment Type</h2>

                            <Row>

                              <Col md={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label className="font12 text-dark fw-medium">
                                    Payment Type{" "}
                                    <span className="text-danger">*</span>
                                  </Form.Label>

                                  <Field as='select' className="font12 w-100" name='payment_type' value={(values.payment_type)} onChange={handleChange}
                                    onBlur={handleBlur} style={{ border: errors.payment_type && touched.payment_type ? '1px solid red' : '1px solid #cfc6c6', height: '31px', borderRadius: '5px' }}
                                    isValid={touched.payment_type && !errors.payment_type}
                                    isInvalid={!!errors.payment_type} >
                                    <option value="" label="Select" />
                                    <option value="Invoice" label="Invoice" />
                                    <option value="Credit" label="Credit" />
                                    <option value="Funds" label="Funds" />
                                  </Field>
                                  {errors.payment_type && touched.payment_type ? (
                                    <div className="text-danger font12">
                                      {errors.payment_type}
                                    </div>
                                  ) : null}
                                </Form.Group>

                              </Col>

                              <Col md={6}>

                              </Col>





                              {values.payment_type === "Credit" ?
                                <Col md={4}>
                                  <Form.Group className="mb-3" controlId="formBasicText7">
                                    {values.payment_type ? <Form.Label className="font12 text-dark fw-medium">
                                      {values.payment_type} {" "}

                                    </Form.Label> : <Form.Label className="font12 text-dark fw-medium">
                                      Credit {" "}


                                    </Form.Label>}

                                    {id ? <Form.Control
                                      className="font12"
                                      name="credit_amount"
                                      value={
                                        amountShow
                                          ? Number(availableBalance)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                          : Number(availableBalance)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      type="text"
                                      placeholder="Enter credit amount"
                                      isValid={touched.credit_amount && !errors.credit_amount}
                                      isInvalid={!!errors.credit_amount}
                                      disabled
                                    />


                                      : <Form.Control
                                        className="font12"
                                        name="credit_amount"
                                        value={amountShow ? creditShow : values.credit_amount}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="number"
                                        placeholder="Enter credit amount"
                                        isValid={touched.credit_amount && !errors.credit_amount}
                                        isInvalid={!!errors.credit_amount}
                                      />}
                                    {errors.credit_amount && touched.credit_amount ? (
                                      <div className="text-danger font12">
                                        {errors.credit_amount}
                                      </div>
                                    ) : null}

                                  </Form.Group>
                                </Col> :
                                <>
                                  {values.payment_type === "Funds" ? <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formBasicText7">
                                      {values.payment_type ? <Form.Label className="font12 text-dark fw-medium">
                                        {values.payment_type} {" "}

                                      </Form.Label> : <Form.Label className="font12 text-dark fw-medium">
                                        Funds {" "}

                                      </Form.Label>}
                                      {id ? <Form.Control
                                        className="font12"
                                        name="debit_amount"
                                        disabled
                                        value={
                                          amountShow
                                            ? parseFloat(availableBalance1).toFixed(2).toString()
                                            : parseFloat(availableBalance1).toFixed(2).toString()
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="number"
                                        placeholder="Enter funds amount"
                                        isValid={touched.debit_amount && !errors.debit_amount}
                                        isInvalid={!!errors.debit_amount}
                                      />
                                        : <Form.Control
                                          className="font12"
                                          name="debit_amount"
                                          value={amountShow ? fundsShow : values.debit_amount}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          type="number"
                                          placeholder="Enter funds amount"
                                          isValid={touched.debit_amount && !errors.debit_amount}
                                          isInvalid={!!errors.debit_amount}
                                        />}
                                      {errors.debit_amount && touched.debit_amount ? (
                                        <div className="text-danger font12">
                                          {errors.debit_amount}
                                        </div>
                                      ) : null}

                                    </Form.Group>
                                  </Col> : ''}
                                </>
                              }

                              {id ? <Col md={6} className="mt-4">
                                {values.payment_type === "Invoice" || values.payment_type === "" ? <div onClick={setCheckAdd(false)}></div> : <Button
                                  // type="submit"
                                  onClick={() => setCheckAdd(true)}
                                  style={checkAdd ? btnDisable : btnAble}
                                  variant={"primary"}
                                  className="fullRounded fw-bold shadowPrimary font12"
                                >
                                  {checkAdd ? '' : ''}
                                  <span>Update Balance</span>
                                </Button>}
                              </Col> : ''}


                              {checkAdd && id ?
                                <>
                                  <Col md={6}>
                                    <Form.Check
                                      inline
                                      label="Add"
                                      value="Add"
                                      name="path"
                                      checked={valueType === "Add"}
                                      onChange={(e) => setValueType(e.target.value)}
                                      type="radio"
                                      className="text-dark labelControl"
                                    />
                                    <Form.Check
                                      inline
                                      label="Deduct"
                                      value="Deduct"
                                      name="path"
                                      checked={valueType === "Deduct"}
                                      onChange={(e) => setValueType(e.target.value)}

                                      type="radio"
                                      className="text-dark labelControl"
                                    />
                                  </Col>
                                  <Col md={6}>

                                  </Col>
                                </>
                                : ''}




                              {checkAdd && id && valueType !== '' ?
                                <>
                                  <Col md={4}>
                                    <Form.Group className="mb-3" controlId="formBasicText7">
                                      <Form.Label className="font12 text-dark fw-medium">
                                        {valueType} {values.payment_type} {" "}

                                      </Form.Label>

                                      <Form.Control
                                        className="font12"
                                        name="credit_amount"
                                        value={amount}
                                        onChange={handleamt}
                                        type="number"
                                        placeholder="Enter amount"

                                      />


                                    </Form.Group>
                                  </Col>
                                  <Col md={3} className="mt-4">
                                    {values.payment_type === "Invoice" || values.payment_type === "" ? <div></div> : <Button
                                      onClick={() => {
                                        setAmountShow(true);
                                        if (valueType == 'Add') {
                                          if (values.payment_type === "Credit") {
                                            if (+amount > 0) {
                                              setPayment_type("Credit");
                                              let a = (+availableBalance) + (+amount);
                                              values.credit_amount = (+values.credit_amount) + (+amount);
                                              setAvailableBalance(a);
                                              setCreditShow(a);
                                              setCredit_amount(values.credit_amount);
                                            }
                                          } else if (values.payment_type === "Funds") {
                                            if (+amount > 0) {
                                              setPayment_type("Funds");
                                              let a = (+availableBalance1) + (+amount);
                                              values.debit_amount = (+values.debit_amount) + (+amount);
                                              setAvailableBalance1(a);
                                              setFundsShow(a);
                                              setDebit_amount(values.debit_amount);
                                            }
                                          }
                                        } else if (valueType == 'Deduct') {
                                          if (values.payment_type === "Credit") {
                                            if (+amount > 0) {

                                              setPayment_type("Credit");
                                              let a = (+availableBalance) - (+amount);
                                              values.credit_amount = (+values.credit_amount) - (+amount);
                                              if (a >= 0.00) {
                                                setAvailableBalance(a);
                                                setCreditShow(a);
                                                setCredit_amount(values.credit_amount);
                                              } else {
                                                toast.warning("Invalid balance")
                                                return false;
                                              }

                                            }
                                          } else if (values.payment_type === "Funds") {
                                            if (+amount > 0) {

                                              setPayment_type("Funds");
                                              let a = (+availableBalance1) - (+amount);
                                              values.debit_amount = (+values.debit_amount) - (+amount);
                                              if (a >= 0.00) {
                                                setAvailableBalance1(a);
                                                setFundsShow(a);
                                                setDebit_amount(values.debit_amount);
                                              } else {
                                                toast.warning("Invalid balance")
                                                return false;
                                              }
                                            }
                                          }
                                        }

                                        setCheckAdd(false);
                                        setAmount();
                                      }}

                                      style={{ width: '133px', height: '36px' }}
                                      variant="primary"
                                      className="px-4 py-2 fullRounded fw-bold shadowPrimary font12"
                                    >
                                      
                                      <span>{valueType}</span>
                                    </Button>}
                                  </Col>
                                </>
                                : ''}




                            </Row>
                          </>
                      } */}

                    <Row className="mt-4">
                      <Col>
                        {disabled?<Button
                          variant="primary"
                          className="px-4 py-2 fullRounded shadowPrimary font12"
                          style={{
                            backgroundColor: 'var(--newOrange)',
                            borderColor: 'var(--newOrange)',
                        }}
                          disabled
                        >
                          {id ? 'Save Changes' : 'Create Business Partner'}
                        </Button>:<Button
                         type="submit"
                          variant="primary"
                          className="px-4 py-2 newCustomButton fullRounded fw-bold shadowPrimary font12"
                          style={{
                            backgroundColor: 'var(--newOrange)',
                            borderColor: 'var(--newOrange)',
                        }}
                        >
                          {id ? 'Save Changes' : 'Create Business Partner'}
                        </Button>}
                      </Col>
                    </Row>

                  </Form>
                )}
              </Formik>

                {/* {id ? <Row className="mt-5">
                  <h2 className="font16 mb-2 text-dark">Accessbility</h2>
                  <Col className="p-4 mt-2 rounded containerShadow">
                    <div className="textGray font12 Accessbility_section"  >

                      <div style={{ maxWidth: "214px" }}  >
                        <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                          <Form.Control
                            type="text"
                            value={searching}
                            className="border-0 searchInput"
                            placeholder="Search"
                            onChange={({ currentTarget: input }) =>
                              handleSearch(input.value)
                            }
                          />
                          <Button
                            variant="primary"
                            title="Click here to search"
                            className="searchBtn d-flex justify-content-center align-items-center"
                          >
                            <img
                              src="/assets/images/search.png"
                              className="img-fluid"
                              alt=""
                            />
                          </Button>
                        </div>
                      </div>
                      <div style={{ maxWidth: "214px" }} className="csv_button">

                        <Button
                          variant="primary"
                          onClick={() => setshowCsvModal(!showCsvModal)}
                          className="fullRounded px-4 py-2 fw-bold font12"
                          style={{ width: "139px" }}
                        >
                          <span className="me-2">

                            <BiPlusMedical />
                          </span>
                          <span>Upload CSV</span>

                        </Button>
                      </div>
                      <div>
                        <div style={{ display: 'flex' }} className="filter_category category_button" >
                          <ModalOpen title={<Button variant="primary" style={{ borderRadius: '30px' }} title="Click here to search" className="fullRounded px-4 py-2 fw-bold font12">
                            Filter by Categories
                          </Button>
                          }
                            data={categories}
                            num={0}
                            home={"false"}
                            btnColor={btnColor}
                            btnText={btnText}
                            setCategoryOption={setCategoryOption}
                            colorCheck={'black'}
                            catergoryOption={catergoryOption}
                            clickBrand={clickBrand}
                            setClickBrand={setClickBrand}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <div >
                    <Col md={12} className="mt-4">
                      <div className="table-responsive">
                        <table className="table  editpage_table" style={{ verticalAlign: "middle" }}>
                          <thead className="bgLightRed font14">
                            <tr className="text-center">
                              <th className="py-2">
                                {" "}
                                <div className="d-flex align-items-center justify-content-center ">
                                  {" "}
                                  <span className="me-1 ">Stand Out</span>{" "}
                                </div>{" "}
                              </th>
                              <th className="py-2">
                                {" "}
                                <div className="d-flex align-items-center justify-content-center ">
                                  {" "}
                                  <span>Promoted</span>{" "}
                                </div>{" "}
                              </th>
                              <th className="py-2">
                                {" "}
                                <div className="d-flex align-items-center justify-content-center ">
                                  {" "}
                                  <span className="me-1 "> Brand</span>{" "}
                                  {order === "ASC" ? (
                                    <FaSortDown onClick={() => sorting("DSC")} />
                                  ) : (
                                    <FaSortUp onClick={() => sorting("ASC")} />
                                  )}
                                </div>{" "}
                              </th>
                              <th className="py-2">
                                <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                                  <span className="me-1">Category</span>
                                  {categoryss === "ASC" ? (
                                    <FaSortDown onClick={() => categorySorting("DSC")} />
                                  ) : (
                                    <FaSortUp onClick={() => categorySorting("ASC")} />
                                  )}
                                </div>
                              </th>
                              <th className="py-2">
                                <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                                  <span className="me-1">Bonus</span><span>(%)</span>
                                  {bonusDiscount === "ASC" ? (
                                    <FaSortDown onClick={() => bonusDiscountSorting("DSC")} />
                                  ) : (
                                    <FaSortUp onClick={() => bonusDiscountSorting("ASC")} />
                                  )}
                                </div>
                              </th>
                              <th className="py-2">
                                <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                                  <span className="me-1">Bonus Type</span>
                                </div>
                              </th>
                              <th className="py-2" tyle={{ width: '10%' }}>
                                {" "}
                                <div className="d-flex align-items-center justify-content-center " >
                                  {" "}
                                  <span className="me-1 ">Rebate</span><span>(%)</span>{" "}
                                </div>
                              </th>
                              <th className="py-2" tyle={{ width: '10%' }}>
                                {" "}
                                <div className="d-flex align-items-center justify-content-center " >
                                  {" "}
                                  <span className="ms-1 me-1">Customer</span><span>(%)</span>{" "}
                                  {brandss === "ASC" ? (
                                    <FaSortDown onClick={() => bonusSorting("DSC")} />
                                  ) : (
                                    <FaSortUp onClick={() => bonusSorting("ASC")} />
                                  )}
                                </div>
                              </th>
                              <th className="py-2" tyle={{ width: '10%' }}>
                                {" "}
                                <div className="d-flex align-items-center justify-content-center " >
                                  {" "}
                                  <span className="ms-1 me-1">Threshold Value</span>{" "}
                                </div>
                              </th>
                              <th className="py-2" tyle={{ width: '10%' }}>
                                {" "}
                                <div className="d-flex align-items-center justify-content-center " >
                                  {" "}
                                  <span className="ms-1 me-1">Threshold Quantity</span>{" "}
                                </div>
                              </th>
                              <th className="py-2" style={{ width: "50px" }}>
                              </th>
                              <th className="py-2">
                                {" "}
                                <div className="d-flex align-items-center justify-content-center ">
                                  {" "}
                                  <span className="me-1 ">Actions</span>{" "}
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="font14 fw-medium text-center">
                            {brandLoading === true ? (
                              <tr style={{ backgroundColor: "#eee" }}>
                                <td colSpan="12">
                                  <Bars
                                    height="80"
                                    width="100%"
                                    margin='auto'
                                    color="#FF7020"
                                    ariaLabel="bars-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                  />
                                </td>
                              </tr>
                            ) : customerData.length > 0 ?
                              (
                                customerData
                                  ?.sort((a, b) => a.brandName - b.brandName)
                                  .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
                                  .map((elem) => (
                                    <tr key={elem._id}>
                                      <td className="py-2 font14 fw-medium ">
                                        <div className="d-flex justify-content-center">
                                          {elem.brandEdit ?
                                            <Form.Check
                                              style={{ width: '85%' }}
                                              type="checkbox"
                                              name="brand"
                                              onChange={(e) => {
                                                setStandoutCheck(e.target.checked);
                                              }}
                                            />
                                            :
                                            <Form.Check
                                              style={{ width: '85%' }}
                                              type="checkbox"
                                              name="brand"
                                              checked={elem.stand_out}
                                              readOnly
                                            />
                                          }
                                        </div>
                                      </td>
                                      <td className="py-2 font14 fw-medium ">
                                        <div className="d-flex justify-content-center">
                                          {elem.brandEdit ?
                                            <Form.Check
                                              style={{ width: '85%' }}
                                              type="checkbox"
                                              name="brand"
                                              onChange={(e) => setPromotedCheck(e.target.checked)}
                                            />
                                            :
                                            <Form.Check
                                              style={{ width: '85%' }}
                                              type="checkbox"
                                              name="brand"
                                              checked={elem.promotion}
                                              readOnly
                                            />
                                          }
                                        </div>
                                      </td>
                                      <td className="py-2 font14 fw-medium ">
                                        <div className="d-flex justify-content-center">
                                          <Form.Control style={{ width: '120px' }} className="font14" name="bonus" value={elem.brandName} disabled />
                                        </div>
                                      </td>
                                      <td className="py-2 font14 fw-medium ">
                                        <div className="d-flex justify-content-center">
                                          <Form.Control style={{ width: '120px' }} className="font14" name="bonusType" value={elem.brandCategory} disabled />
                                        </div>
                                      </td>
                                      <td className="py-2 font14 fw-medium ">
                                        <div className="d-flex justify-content-center">
                                          {elem.brandEdit ? (
                                            <Form.Control style={{ width: '68px' }} className="font14" name="bonusType" value={discount} onChange={handleDiscountChange} />
                                          ) : (
                                            elem.discount ? (
                                              <Form.Control style={{ width: '68px' }} className="font14" name="bonusType" value={`${(elem?.discount)?.toFixed(2)}%`} disabled />
                                            ) : (
                                              <Form.Control style={{ width: '68px' }} className="font14" name="bonusType" value="0%" disabled />
                                            )
                                          )}
                                        </div>
                                      </td>
                                      {elem.brandEdit ? <td >
                                        <div className="d-flex justify-content-center">
                                          <Form.Select style={{ width: '85px', height: '90%' }} name='bonusType' value={bonusType || elem.bonusType} onChange={e => setBonusType(e.target.value)}>
                                            <option value="">Select Bonus type</option>
                                            <option value="Percentage">Percentage</option>
                                            <option value="Flat">Flat</option>
                                          </Form.Select>
                                        </div>
                                      </td> :
                                        <td>
                                          <div className="d-flex justify-content-center">
                                            <Form.Control style={{ width: '85px' }} className="font14" name="bonusType" value={elem.bonusType} disabled />
                                          </div>
                                        </td>}
                                      <td className="py-2 font14 fw-medium" style={{ width: '10%' }}>
                                        <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                                          <Form.Control style={{ width: '56px' }} className="font14" name="bonus" value={elem.internalPercentage} type="text" placeholder="Enter Percentage" disabled />
                                        </div>
                                      </td>
                                      <td className="py-2 font14 fw-medium" style={{ width: '10%' }}>
                                        <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                                          {elem.brandEdit ? <Form.Control style={{ width: '56px', marginRight: "12px" }} className="font14" name="bonus" value={bonus} onChange={e => setBonus(e.target.value)} type="text" placeholder="Enter Percentage" /> :
                                            <Form.Control style={{ width: '56px', marginRight: "12px" }} className="font14" name="bonus" value={elem.bonus} type="text" placeholder="Enter Percentage" disabled />}
                                          <Form.Control style={{ width: '56px', marginRight: "12px" }} name="id" value={partner_id} type="hidden" placeholder="Enter Percentage" />
                                        </div>
                                      </td>
                                      <td className="py-2 font14 fw-medium" style={{ width: '10%' }}>
                                        <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                                          {elem.brandEdit ? <Form.Control style={{ width: '80px' }} className="font14" name="thresholdValue" value={thresholdValue} onChange={e => setThresholdValue(e.target.value)} type="number" placeholder="Enter Threshold Value" /> :
                                            <Form.Control style={{ width: '80px' }} className="font14" name="thresholdValue" value={elem.thresholdValue} type="number" placeholder="Enter Threshold Value" disabled />}
                                          <Form.Control style={{ width: '80px' }} name="id" value={partner_id} type="hidden" placeholder="Enter Threshold Value" />
                                        </div>
                                      </td>
                                      <td className="py-2 font14 fw-medium" style={{ width: '10%' }}>
                                        <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                                          {elem.brandEdit ? <Form.Control style={{ width: '80px' }} className="font14" name="thresholdQuantity" value={thresholdQuantity} onChange={e => setThresholdQuantity(e.target.value)} type="number" placeholder="Enter Threshold Quantity" /> :
                                            <Form.Control style={{ width: '80px' }} className="font14" name="thresholdQuantity" value={elem.thresholdQuantity} type="number" placeholder="Enter Threshold Quantity" disabled />}
                                          <Form.Control style={{ width: '80px' }} name="id" value={partner_id} type="hidden" placeholder="Enter Threshold Quantity" />
                                        </div>
                                      </td>
                                      <td className="py-2 font14 fw-medium ">
                                        <div className="d-flex justify-content-center">
                                          <Button onClick={() => navigate(`/admin/businesspartners/${id}/${elem.couponId}/coupons`)} type="submit" variant="primary" style={{ width: "123px" }} className="px-4 py-2 fullRounded shadowPrimary  font12">Gift Cards ({elem.coupons})</Button>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="d-flex justify-content-evenly">
                                          <div className="action_button">
                                            {elem.brandEdit ? <button type="button" onClick={() => handleStatus(elem.brandEdit, elem._id)} className="icon mt-1 ms-1" style={{ color: 'black', border: 'none' }}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-check-square" viewBox="0 0 16 16">
                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                                              </svg>
                                            </button>
                                              :
                                              <button type="button" onClick={() => handleStatus(elem.brandEdit, elem._id)} className="icon mt-1 ms-1" style={{ color: 'black', border: 'none' }}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                              </svg></button>}
                                          </div>
                                          <div>
                                            {deleteDelay ? <button type="button" className="icon mt-1 ms-1" style={{ color: 'black', border: 'none' }} >
                                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                              </svg>
                                            </button> : <button type="button" className="icon mt-1 ms-1" style={{ color: 'black', border: 'none' }} onClick={() => deleteBp(elem.couponId)}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                              </svg>
                                            </button>}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))) :
                              (
                                <tr>
                                  <td colSpan="12">No Data Available</td>
                                </tr>
                              )
                            }
                            {isLoading ? "" :
                              <>
                              </>
                            }
                          </tbody>
                        </table>
                      </div>
                    </Col>
                    {numberOfPages > 0 ? (
                      <Col className="mt-5 mb-4">
                        <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                          {pageNumber === 1 ? (
                            ""
                          ) : (
                            <li onClick={prevPage}>Previous</li>
                          )}
                          {items}
                          {numberOfPages === pageNumber ? (
                            ""
                          ) : (
                            <li onClick={nextPage}>Next</li>
                          )}
                          <li className="font14">
                            <input
                              style={{
                                width: "25px",
                                border: "1px solid #4C00EF",
                                borderRadius: "4px",
                                textAlign: "center"
                              }}
                              value={showPage ? showPage : ""}
                              type="text"
                              name="number"
                              onChange={(e) => pagenumberSearch(e.target.value)}
                            />{" "}
                            <span style={{ color: "#4C00EF" }}>/</span> {numberOfPages}
                          </li>
                        </ul>
                      </Col>
                    ) : (
                      ""
                    )}
                  </div>
                  {noData ? <h2 className="allbrands-head mb-4">No Brands Found</h2> : ''}
                  <div style={{ display: "flex", alignItems: "center" }}>
                  </div>
                  <Form
                    className="p-4 rounded containerShadow"
                    onSubmit={handleSubmit}>
                    {formValues.map((element, index) => (
                      <div className="form-inline footer_dropdown" key={index}>
                        <Row className="align-items-end mt-3">
                          <Col sm={6} lg={4} xl={2} >
                            <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center responsive_group">
                              <Form.Label className="font13 text-dark fw-medium mt-2 me-2 " >Brand</Form.Label>
                              <Form.Select style={{ width: '85%' }} value={brand} name='brand' onChange={e => setBrand(handleChangeForm(index, e))} className="Dropdown_responsive" >
                                {
                                  getbrand?.map((datatwo, index) => {
                                    return (
                                      <option key={index} value={datatwo.value} >
                                        {datatwo.label}
                                      </option>
                                    )
                                  })
                                }
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={6} lg={4} xl={2}  >
                            <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center responsive_group " >
                              <Form.Label style={{ whiteSpace: 'nowrap' }} className="font14 text-dark fw-medium mt-2 me-2" >Bonus Type</Form.Label>
                              <Form.Select style={{ width: '85%' }} value={bonus} name='bonusType' onChange={e => setBonus(handleChangeForm(index, e))} className="Dropdown_responsive" >
                                <option value="">Select Bonus type</option>
                                <option value="Percentage">Percentage</option>
                                <option value="Flat">Flat</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={6} lg={4} xl={2}  >
                            <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center responsive_group " >
                              <Form.Label style={{ whiteSpace: 'nowrap' }} className="font13 text-dark fw-medium mt-2 me-2" >Bonus (%)</Form.Label>
                              <Form.Control
                                style={{ width: '80%' }}
                                className="font14 Dropdown_responsive"
                                name="bonusPercentage"
                                value={bonusValue}
                                onChange={e => setBonusValue(handleBonusChangeForm(e, index))}
                                type="number"
                                step="0.01"
                                placeholder="Enter Bonus"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6} lg={4} xl={2}  >
                            <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center responsive_group " >
                              <div >
                                <Form.Label className="font13 text-dark fw-medium mt-2 me-2" style={{ display: "flex" }} >Customer <span style={{ display: "inline-block", marginLeft: "2px" }}> (%)</span></Form.Label>
                              </div>
                              <div className=" d-flex  align-items-center ">
                                <Form.Control style={{ width: '100%' }} className="font14 Dropdown_responsive" name="bonus" value={element.bonus || ""} onChange={e => handleChangeForm(index, e)} type="number" placeholder="Enter Percentage" />
                                <Form.Control style={{ width: '100%' }} className="font14 Dropdown_responsive" name="id" value={partner_id} onChange={e => handleChangeForm(index, e)} type="hidden" placeholder="Enter Percentage" />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col sm={6} lg={4} xl={2}  >
                            <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center responsive_group " >
                              <Form.Label style={{ whiteSpace: 'nowrap' }} className="font13 text-dark fw-medium mt-2 me-2" >Threshold Value</Form.Label>
                              <Form.Control
                                style={{ width: '80%' }}
                                className="font14 Dropdown_responsive"
                                name="thresholdValue"
                                onChange={e => handleChangeForm(index, e)}
                                type="number"
                                step="0.01"
                                placeholder="Enter Threshold Value"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6} lg={4} xl={2} className="d-flex  align-items-center" >
                            <Form.Group className="mb-3  mb-sm-0 d-block d-xxl-flex  align-items-center responsive_group " >
                              <Form.Label style={{ whiteSpace: 'nowrap' }} className="font13 text-dark fw-medium mt-2 me-2" >Threshold Quantity</Form.Label>
                              <Form.Control
                                style={{ width: '80%' }}
                                className="font14 Dropdown_responsive"
                                name="thresholdQuantity"
                                onChange={e => handleChangeForm(index, e)}
                                type="number"
                                step="0.01"
                                placeholder="Enter Threshold Quantity"
                              />
                            </Form.Group>
                            {index ?
                              <button type="button" className="icon mt-1 ms-1" style={{ color: 'black', border: 'none' }}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                              </svg></button>
                              :
                              null
                            }
                            {
                              index ?
                                <button type="button" className="icon mt-1 ms-1" style={{ color: 'black', border: 'none' }} onClick={() => removeFormFields(index)}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                  </svg>
                                </button>
                                
                                : null
                            }
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <div className="button-section">
                      {delay ? <Button variant="primary" className="button add fullRounded px-4 py-2 fw-bold font12 responsive_group mt-3" >Save</Button>
                        : <Button variant="primary" className="button add fullRounded px-4 py-2 fw-bold font12 mt-3" type="submit" >Save</Button>}
                      <Button variant="primary" className="button submit fullRounded px-4 py-2 fw-bold font12 add_button mt-3" onClick={() => addFormFields()} type="button">
                        <span className="me-2">
                          <BiPlusMedical />
                        </span>
                        <span>Add More</span></Button>
                    </div>
                  </Form>
                </Row> : ''} */}
            </Col>
          </Row>
        </Container>
        <Modal
        show={showCsvModal}
        onHide={handleClosehandleCsvModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title
              className="font20 text-dark mb-0"
              id="contained-modal-title-vcenter"
            >
              File Upload
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loadingCsv ? <Bars
              height="80"
              width="100%"
              margin='auto'
              color="var(--newMainBlue)"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
              :
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="fs-6 text-dark mb-2">Select CSV File</h4>

                </div>
                <BuisnessForm variant="primary" setCustomerData={setCustomers} setFilename={setFilename} filename={filename} brandData={brandData} brandName={business_name} />
              </>
            }
          </Modal.Body>
          <Modal.Footer>
      
             {filename.length > 0 ? <Button
              variant="secondary"
              className="fullRounded px-4 py-2 fw-bold font12"
              disabled
            >
              Upload
            </Button>
              :
           
              <Button
                variant="primary"
                type="submit"
                className="fullRounded px-4 py-2 fw-bold font12"
                onClick={handleSubmitCSV}
              >
                Upload
              </Button>
              
            }

            <Button
              variant="secondary"
              className="fullRounded px-4 py-2 fw-bold font12"
              onClick={() => {
                handleClosePreviewModal();
                handleClearData(); 
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        scrollable={true}
        show={previewModal}
        onHide={handleClosePreviewModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="font20 text-dark mb-0"
            id="contained-modal-title-vcenter"
          >
            CSV Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {loadingCsv ? <Bars
              height="80"
              width="100%"
              margin='auto'
              color="var(--newMainBlue)"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            /> :
          <>
            
            <div className="table-responsive">
              <table
                className="table fontGilroy"
                style={{ verticalAlign: "middle" }}
              >
                <thead className="bgLightRed font14">
                  <tr>
                    <th className="text-center">
                      <div className="d-flex align-items-center">

                        <span className="me-1">BrandName</span>

                      </div>
                    </th>

                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Brand Id</span>

                      </div>
                    </th>

                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Brand Category

                        </span>

                      </div>
                    </th>

                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Bonus(%)

                        </span>

                      </div>
                    </th>

                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Rebate (%)</span>

                      </div>
                    </th>


                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Customer(%)

                        </span>

                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Total Value

                        </span>

                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Total Quantity
                        </span>

                      </div>
                    </th>

                  </tr>
                </thead>
                <tbody className="font12 fw-medium">



                  {

                      previewData?.map((cust,i) => (
                      <tr key={i}>
                        <td className="font14">

                          <span>
                            <Link
                              className="link"

                            >
                              {cust.brandname} 
                            </Link>
                          </span>
                        </td>
                        <td className="font14 fw-medium">{cust.Brand_Id}</td>
                        <td className="font14 fw-medium">{cust.brandCategory}</td>
                        <td className="font14 fw-medium">{Number(cust?.Bonus_Percentage)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%</td>
                        <td className="font14 fw-medium">{Number(cust?.internaPercentage)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%</td>
                        <td className="font14 fw-medium">{Number(cust?.Customer_Percentage)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}%</td>
                        {/* <td className="font14 fw-medium">
                          $ 1,000.00
                        </td>
                        <td className="font14 fw-medium">
                          20
                        </td> */}
                      </tr>
                    ))

                  }
                </tbody>
              </table>
            </div>
          </>
          }
        </Modal.Body>
        <Modal.Footer>

         
          <Button
            variant="primary"
            className="fullRounded px-4 py-2 fw-bold font12"
            onClick={handleSubmitCSV}
            disabled={loadingCsv} 
          >
            Confirm
          </Button>
          <Button
            variant="secondary"
            className="fullRounded px-4 py-2 fw-bold font12"
            onClick={() => handleClosePreviewModal()}
          >
            Close
          </Button>
        </Modal.Footer>

      </Modal>
      </div>
}
    </>
  )
  // );
};

export default BusinessPartner;