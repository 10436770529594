import { useRef, useState, useEffect } from "react";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { Bars } from "react-loader-spinner";
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessContact } from '../../Redux/BusinessContacts/action';
import { getNewPartnerToken } from "../../config/cookie.config";

const Profile = () => {
  const inputFileRef = useRef(null);
  const businessPartner = JSON.parse( window.localStorage.getItem('business-client'));
  let userrole;
  let usersid;
  let loginUserId
  let navigate = useNavigate();
  if (businessPartner) {
    userrole = businessPartner?.user?.role;
    usersid = businessPartner?.user?._id;
    loginUserId = businessPartner?.userData?._id
  } else {
    userrole = null;
    usersid = null;
    localStorage = null;
    localStorage.removeItem('business-client')
    navigate("/business-login");
  }
    const id = businessPartner?.user?._id;
//   const { id } = useParams();
//   const { bid } = useParams();
  const [name, setName] = useState("");
  const [business_name, setBusinessName] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);
  const [brandEdit,setBrandEdit] = useState(false);
  const [main_code, setMain_code] = useState("");
  const [user_id, setId] = useState("");
  const [partner_id, setPartner_id] = useState(id);
  const [brand_id, setBarnd_id] = useState('');
  const [email, setEmail] = useState("");
  const [main_phone, setMain_phone] = useState("");
  const [website, setWebsite] = useState("");
  const [phone,setPhone] = useState("");
  const [street_number,setStreet_Number] = useState("");
  const [city,setCity] = useState("");
  const [internal_percentage,setInternalPercentage] = useState("");
  const [country,setCountry] = useState("");
  const [contact_type,setContact_type] = useState("");
  const [postal,setPostal] = useState("");
  const [contactInformation,setContactInformation] = useState([{contact_type:"",name:"",email:"",code:"",phone:""}]);
  // const [credit, setCredit] = useState("");
  const [credit_amount, setCredit_amount] = useState("");
  const [debit_amount, setDebit_amount] = useState("");
  const [available_balance, setAvailable_balance] = useState("");
  const [payment_type, setPayment_type] = useState("");
  const [address, setAddress] = useState("");
  const user = JSON.parse(window.localStorage.getItem("client"));


  const [btnColor,setBtnColor] = useState('');
  const [loading,setLoading] = useState(true);
  const [primaryColor,setPrimaryColor]= useState('');
  const [secondaryColor,setSecondaryColor] = useState('');
  const [funds_type , setFundType] = useState(null);
  const [primary_partner_id,setAssociatePartner] = useState("");
  const [primaryBusinessName,setPrimaryBusinessName] = useState("")
  const [business_partner_type, setBusinessPartnerType] = useState("");
  const [marginAmount, setMarginAmount] = useState("");
  const [isApiThreshold, setIsApiThreshold] = useState(false);
  const [directOrderNotification, setDirectOrderNotification] = useState(false);
  const [businessTypeOption, setBusinessTypeOption] = useState(false);
  
  const [businessPartnerFilter, setbusinessPartnersFilter] = useState([])

  function businessPartnerOptions () {
    axios
      .get(` ${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getallcustomersbrand`)
      .then((response) => {
        
        const businessFilter = response.data.allPartnersFilter
        setbusinessPartnersFilter(businessFilter);
      })
      .catch((error) => {
        console.error(`Error : ${error}`);
      })
  }
  
  useEffect(()=>{
    businessPartnerOptions()
  },[])

  let userId;

//   let navigate = useNavigate();
//   if (user) {
//     userId = user.user._id;
//   } else {
//     userId = null;
//     navigate("/admin");
//   }
const handleKeyPress = (e) => {
  const keyCode = e.keyCode || e.which;
  const char = String.fromCharCode(keyCode);
  const regex = /^[0-9]+$/;
  if (!regex.test(char)) {
    e.preventDefault();
  }
};

  const [isLoading, setIsLoading] = useState(false);
  const [disabled,setDisabled] = useState(false);
  const [countryData,setCountryData] = useState([]);
  const [errorPhone,setErrorPhone] = useState();

  const dispatch = useDispatch();
  
  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    const id = user?.userData?._id;
    const token = user?.token;
  
    dispatch(getBusinessContact(id, token, navigate, toast));
  
  }, [dispatch, navigate, toast]);
  

const getCountryData = () => {
  axios
    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcountries`)
    .then((response) => {
      if(response.data.message == "Please Login"){
        localStorage.removeItem('business-client');
        navigate('/business-login');
        toast.error("Session Expired Please Login Again")
      }            
      const customers = response.data.countries;
      const uniqueIds = [];
      const unique = customers.filter(element => {
        const isDuplicate = uniqueIds.includes(element.value);
        if (!isDuplicate) {
          uniqueIds.push(element.value);
          return uniqueIds;
        }
        return false;
      });
      setCountryData(unique);
    })
    .catch((error) => console.error(`Error: ${error}`));
};
 
 useEffect(()=>{
   getCountryData();
 },[])

 

//  useEffect(()=>{
//      setLoading(true);
//     axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getcmsbusiness/${id}`)
//     .then((res)=>{
//       if(res.data?.cms?.default_ncentiva === false){ 
//       setBtnColor(res.data?.cms?.btn_color);
//       setPrimaryColor(res.data?.cms?.primary_color);
//       setSecondaryColor(res.data?.cms?.secondary_color);
//     }else{
//         setBtnColor('#3D05B6');
//         setPrimaryColor("#FF7020");
//         setSecondaryColor("#F5C400");
//       }
//       setLoading(false);
//     }).catch((err)=>{
//       console.log(err);
//     })
// },[])


 const phoneCode = [
   { value: "+1", label: "+1 USA" },
   { value: "+7", label: "+7 RUS" },
   { value: "+20", label: "+20 EGY" },
   { value: "+27", label: "+27 ZAF" },
   { value: "+30", label: "+30 GRC" },
   { value: "+31", label: "+31 NLD" },
   { value: "+32", label: "+32 BEL" },
   { value: "+33", label: "+33 FRA" },
   { value: "+34", label: "+34 ESP" },
   { value: "+36", label: "+36 HUN" },
   { value: "+39", label: "+39 ITA" },
   { value: "+40", label: "+40 ROU" },
   { value: "+41", label: "+41 SWZ" },
   { value: "+43", label: "+43 AUT" },
   { value: "+44", label: "+44 GBR" },
   { value: "+45", label: "+45 DNK" },
   { value: "+46", label: "+46 SWE" },
   { value: "+47", label: "+47 NOR" },
   { value: "+48", label: "+48 POL" },
   { value: "+49", label: "+49 DEU" },
   { value: "+51", label: "+51 PER" },
   { value: "+52", label: "+52 MEX" },
   { value: "+53", label: "+53 CUB" },
   { value: "+54", label: "+54 ARG" },
   { value: "+55", label: "+55 BRA" },
   { value: "+56", label: "+56 CHL" },
   { value: "+57", label: "+57 COL" },
   { value: "+58", label: "+58 VEN" },
   { value: "+60", label: "+60 MYS" },
   { value: "+61", label: "+61 AUS" },
   { value: "+62", label: "+62 IDN" },
   { value: "+63", label: "+63 PHL" },
   { value: "+64", label: "+64 NZL" },
   { value: "+65", label: "+65 SGP" },
   { value: "+66", label: "+66 THA" },
   { value: "+81", label: "+81 JPN" },
   { value: "+82", label: "+82 KOR" },
   { value: "+84", label: "+84 VNM" },
   { value: "+86", label: "+86 CHN" },
   { value: "+90", label: "+90 TUR" },
   { value: "+91", label: "+91 IND" },
   { value: "+92", label: "+92 PAK" },
   { value: "+93", label: "+93 AFG" },
   { value: "+94", label: "+94 LKA" },
   { value: "+95", label: "+95 MMR" },
   { value: "+98", label: "+98 IRN" },
   { value: "+211", label: "+211 SSD" },
   { value: "+212", label: "+212 MAR" },
   { value: "+213", label: "+213 DZA" },
   { value: "+216", label: "+216 TUN" },
   { value: "+218", label: "+218 LBY" },
 ];



 const handleFundTypeChange = (e) => {
  setFundType(e.target.value);
};

const handleFullfillmentType = (e) =>{
  setBusinessPartnerType(e.target.value)
};

const handleAssociteparterType = (event) =>{
  const selectedValue = event.target.value;
  setAssociatePartner(selectedValue)
  const selectedIndex = event.target.selectedIndex;
  const selectedLabel = event.target.options[selectedIndex].text;
  ;

}
  

  const formikInitialValue = {
    business_name,
    main_code,
    main_phone,
    website,
    email,
    street_number,
    city,
    postal,
    country,
    contact_type,
    name,
    phone,
    email,
    payment_type,
    credit_amount,
    debit_amount,
    available_balance,
    internal_percentage,
    partner_id,
    user_id,
    checkEmail,
    marginAmount
  };


  const phoneTransform = (value, originalValue) => {
    if (!originalValue) {
      return originalValue;
    }
  
    // Remove non-numeric characters
    const numericValue = originalValue.replace(/\D/g, '');
  
    // Apply formatting logic here
    // ...
  
    return numericValue;
  };
  
  const formSchema = Yup.object().shape({
     city: Yup.string()
      .min(3, "Too Short!")
      .max(24, "Maximum 24 characters allowed for City")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .required("City cannot be empty"),
     country: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Maximum 50 characters allowed for Country")
      .required("Country cannot be empty"),
    business_name: Yup.string()
      .min(3, "Too Short!")
      .max(64, "Maximum 64 characters allowed for business name")
      .required("Business Name cannot be empty"),
    checkEmail: Yup.boolean(),
      website: Yup.string()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter correct url!'
        ),
        email:Yup.string()
        .required("Business Email cannot be empty"),
        // .matches(/^(\b\D+\b)?\s*(\b.*?\d.*?\b)\s*(\b\D+\b)?$/,'Enter correct street number')

      street_number: Yup.string()
      .min(2, "Specify correct address")
      .max(64, "Maximum 64 characters allowed for address information")
       .required('street and number cannot be empty'),
       postal: Yup.string()
      .min(5, "Minimum 5 digits required for credit Amount")
      .required("postal cannot be empty"),
      main_code: Yup.string()
      .min(1, "minimum 1 digit require")
      .required("Code cannot be empty"),
    main_phone: Yup.string()
    .transform(phoneTransform)
    .min(10, "Minimum 7 digits required for Phone number")
    .max(14, "Maximum 10 digits allowed for Phone number")
    .required('Phone Number cannot be empty'),
    // credit_amount: Yup.string()
    //   .min(3, "Minimum 3 digits required for credit_amount Amount")
    //   .max(5, "Maximum 5 digits allowed for credit_amount Amount")
    //   .matches(
    //     /^\d+(?:\.\d{0,2})?$/,
    //     "Invalid credit_amount format"
    //   )
    //   .required("Amount cannot be empty"),
    payment_type: Yup.string()
      .min(3, "Minimum 3 digits required for payment_type Amount")
      .max(10, "Maximum 10 digits allowed for payment_type Amount")
      .required("Payment type cannot be empty"),
      marginAmount: Yup.string()
      .matches(/^\d+(\.\d{2})?$/, 'Invalid Threshold format')
  });

  useEffect(() => {
    if (id) {
    //   getdiscountbrandsstatus();
      getAllCustomers();
    //   getbrands();
     

    }
  }, []);
  const getAllCustomers =async () => {
    setIsLoading(true)
    var token;
    const user = JSON.parse( window.localStorage.getItem('business-client'));;
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(` ${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getbusinesspartners/${id}`, {
          headers: {
            'Authorization' : `Bearer ${getNewPartnerToken()}`
          }
        })
      .then((response) => {
        if(response.data.message == "Please Login"){
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
        }
        let availBalance = Number(response.data.businessPartnes.available_balance)?.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
      });
      
        setIsLoading(false)
        setBusinessName(response.data.businessPartnes.business_name);
        setCity(response.data.businessPartnes.city);
        setCountry(response.data.businessPartnes.country);
        setCredit_amount(response.data.businessPartnes.credit_amount);
        setDebit_amount(response.data.businessPartnes.debit_amount);
        
        setAvailable_balance(availBalance);
        setPayment_type(response.data.businessPartnes.payment_type);
        setMain_code(response.data.businessPartnes.main_code);
        setMain_phone(response.data.businessPartnes.main_phone);
        setPostal(response.data.businessPartnes.postal);
        setStreet_Number(response.data.businessPartnes.street_number);
        setWebsite(response.data.businessPartnes.website);
        setEmail(response.data.businessPartnes.email);
        setInternalPercentage(response.data.businessPartnes.internal_percentage);
        setBusinessPartnerType(response.data.businessPartnes.business_partner_type);
        setFundType(response.data.businessPartnes.funds_type);
        setPrimaryBusinessName(response.data.primaryBusinessName);
        setDirectOrderNotification(response.data.businessPartnes.direct_order_notification);
        setBusinessTypeOption(response.data.businessPartnes.business_type_option)
        let amountBalance = Number(response.data.businessPartnes.funds_reminder)?.toFixed(2);
       
        if (response.data.businessPartnes.funds_reminder.length > 0 && response.data.businessPartnes.funds_reminder !== "0") {
          setIsApiThreshold(true);
          setMarginAmount(amountBalance);
        } else {
          setIsApiThreshold(false);
          setMarginAmount(response.data.businessPartnes.funds_reminder);
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Unauthorized User" ){
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
      }
        console.error(`Error: ${error}`)
      });
  };


  const handleSubmitForm = async (data) => {

    let {
      business_name,
      main_code,
      main_phone,
      website,
      street_number,
      city,
      postal,
      country,
      contact_type,
      name,
      phone,
      email,
      payment_type,
      internal_percentage,
      credit_amount,
      debit_amount,
      available_balance,
      marginAmount,
      user_id,
    } = data;
    if(data.payment_type === "Invoice"){
      credit_amount = "0";
      debit_amount = "0";
    }else if(data.payment_type === "Credit"){
      debit_amount = "0";
    }else if(data.payment_type === "Funds"){
      credit_amount = "0"
    }
    // internal_percentage = internal_percentage.replace(/[^\w\s]/gi, '');
    // console.table({
    //   business_name,
    //   main_code,
    //   main_phone,
    //   website,
    //   street_number,
    //   city,
    //   postal,
    //   country,
    //   contact_type,
    //   name,     
    //   phone,
    //   email,
    //   // credit,
    //   payment_type,
    //   credit_amount,
    //   debit_amount,
    //   user_id,
    // });
    try {
      setIsLoading(true);
      const user = JSON.parse( window.localStorage.getItem('business-client'));
      var token;
      if (user) {
        token = user.token;
      } else {
        token = null;
      }
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/businesspartner/create`,
        {
          business_name,
          main_code,
          main_phone,
          website,
          email,
          street_number,
          city,
          postal,
          country,
          payment_type,
          business_partner_type,
          credit_amount,
          debit_amount,
          user_id,
          partner_id,
          marginAmount,
          loginUserId
        },{
          headers: {
            'Authorization': `Bearer ${getNewPartnerToken()}`
          }
        },
      );

      toast.success(data.message);
      window.location.reload();
    } catch (err) {
      console.log(`Error : ${err}`);
      toast.error(err.response.data.message);
    }
  };




  function phoneFormat(input) {
    input = input.replace(/\D/g, "");
    input = input.trim()
    input = input.substring(0, 10);

    var size = input.length;
    if (size === 0) {
      input = input;
    }
     else if (size < 4) {
      input = "(" + input;
    } 
    else if (size < 7) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } 
    else if(size <= 11)
    input= "("+input.substring(0, 3)+") "+input.substring(3, 6)+"-" +input.substring(6, 11);
    
    if (input?.length > 10 && input?.length <= 14){
      setErrorPhone(false);
    }else{
      setErrorPhone(true);
    }
    return input;
  }

  function nameFormat(input) {
    // if (input == " ") {
    //   input = input.replace(/\s/g, "");
    // }
  
    return input.trimStart();
  }




  
  const useTouchedFields = () => {
    const [touched, setTouched] = useState({});
  
    const handleFieldBlur = (fieldName) => {
      setTouched({ ...touched, [fieldName]: true });
    };
  
    const isFieldTouched = (fieldName) => !!touched[fieldName];
  
    return { handleFieldBlur, isFieldTouched };
  };
  
  // Usage:
  const { handleFieldBlur, isFieldTouched } = useTouchedFields();

  function deleteReminder() {
    const user = JSON.parse(window.localStorage.getItem('business-client'));
    let token;
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.delete(` ${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/delete-reminder/${partner_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((response) => {
      if (response.data.status === "success") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
      getAllCustomers()
    }).catch((error) => {
      console.log(`Error : ${error}`);
    })
  }



  return isLoading === true ? (
    <div className="d-flex justify-content-center mt-5 pt-5">
      <Bars
  height="80"
  width="80"
  color="var(--newMainBlue)"
  ariaLabel="bars-loading"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
/>
    </div>
  ) : (
    <>
      <div className="p-3 p-lg-4 p-xl-5">
        <div className="d-flex align-items-center">
          <h1 className="font20  text-dark">Profile</h1>
        </div>

        <Container fluid className="mt-4">
          <Row  className="bg-white">
            <Col lg={12} md={12}>
            <Row className="mt-4 mb-4" style={{margin:"10px"}}>
                <Col lg={6} md={6}>
                  <h2 className="font16 mb-2 text-dark">Buisness Partner Funds Type{" "}<span className="text-danger">*</span></h2>
                  {id ? 
                   <div className="d-flex align-items-center " style={{gap:"20px"}}>
                   <div className="d-flex align-items-center" style={{columnGap:"3px"}}>
                 <input type="radio" id="primary" name="funds_type " className="mt-0"  value="Primary" checked={funds_type  === 'Primary'}
                   onChange={handleFundTypeChange}  disabled />
                  <label htmlFor="primary">Primary</label>
                 </div>
                 <div className="d-flex align-items-center" style={{columnGap:"3px"}}>
                 <input type="radio" id="Associate" name="funds_type " value="Associate"
                 checked={funds_type  === 'Associate'}
                  onChange={handleFundTypeChange} className="mb-0" disabled  />
                 <label htmlFor="Associate">Associate</label>
                 </div>
                 
                 </div>
                 :
                
                <div className="d-flex align-items-center " style={{gap:"20px"}}>
                    <div className="d-flex align-items-center" style={{columnGap:"3px"}}>
                  <input type="radio" id="primary" name="funds_type " className="mt-0"  value="Primary" checked={funds_type  === 'Primary'}
                    onChange={handleFundTypeChange} />
                  <label htmlFor="primary">Primary</label>
                  </div>
                  <div className="d-flex align-items-center" style={{columnGap:"3px"}}>
                  <input type="radio" id="Associate" name="funds_type " value="Associate"
                  checked={funds_type  === 'Associate'}
                   onChange={handleFundTypeChange} className="mb-0" />
                 <label htmlFor="Associate">Associate</label>
                  </div>
                  
                  </div>
                  }
                  {funds_type  === 'Associate' ? 
                  <div style={{width:"100%",maxWidth:"300px", marginTop:"20px"}}>
                    {id ?
                    <select
                  
                    // value={primaryBusinessName}
                    className="partner_select"
                    placeholder="Select Primary Partner"
                     onChange={handleAssociteparterType}
                     disabled
                     >
                      <option value={primaryBusinessName}>{primaryBusinessName}</option>
                      {businessPartnerFilter.map((partner) => (
                          <option key={partner.id} value={partner.value}>{partner.label}</option>
                        ))}
                      </select>
                      :
                  
                   <select
                  
                  value={primary_partner_id}
                  className="partner_select"
                  placeholder="Select Primary Partner"
                   onChange={handleAssociteparterType}
                   >
                    <option value="default">Select Primary partner</option>
                    {businessPartnerFilter.map((partner) => (
                        <option key={partner.id} value={partner.value}>{partner.label}</option>
                      ))}
                    </select>
                       }
                  </div>
                  

                   
                  : ""}
                </Col>
                <Col lg={6} md={6}>
                <h2 className="font16 mb-2 text-dark">Buisness Partner Fulfilment Type{" "}<span className="text-danger">*</span></h2>
                    {id ? (
                      <div>
                        <div className="d-flex align-items-center customGap" >
                          <div style={{ columnGap: "10px" }} className="d-flex align-items-center">
                            <input type="radio" id="marketplace" name="Fullfillment" value="Marketplace" onChange={handleFullfillmentType} disabled className="mb-0" checked={business_partner_type === "Marketplace"} />
                            <label htmlFor="marketplace">Marketplace</label>
                          </div>
                          <div style={{ columnGap: "10px" }} className="d-flex align-items-center">
                            <input type="radio" id="Direct" name="Fullfillment" value="Direct Order" onChange={handleFullfillmentType} disabled className="mb-0" checked={business_partner_type === "Direct Order"} />
                            <label htmlFor="Direct">Direct Order</label>
                          </div>

                        </div>
                        {business_partner_type === "Direct Order" ? (
                          <div className="">
                            <div className="mt-2">
                              <h2 className="font16 mb-2 text-dark">Business Type</h2>
                              <div className="d-flex align-items-center" style={{ columnGap: "10px" }}>
                                <input type="radio" id="businessTypeDiscount" name="businessType" value="Bonus" disabled checked={businessTypeOption === "Bonus"} />
                                <label htmlFor="businessTypeBonus">Bonus</label>
                                <input type="radio" id="businessTypeDiscount" name="businessType" value="Discount" disabled checked={businessTypeOption === "Discount"} />
                                <label htmlFor="businessTypeBonus">Discount</label>
                              </div>
                            </div>
                            <div className="mt-2">
                              <h2 className="font16 mb-2 text-dark">Sent order confirmation notifications</h2>
                              <div className="d-flex align-items-center" style={{ columnGap: "10px" }}>
                                <input type="radio" id="confirmationYes" name="confirmation" value="Yes" disabled checked={directOrderNotification === "Yes"} />
                                <label htmlFor="confirmationYes">Yes</label>
                                <input type="radio" id="confirmationNo" name="confirmation" value="No" disabled checked={directOrderNotification === "No"} />
                                <label htmlFor="confirmationNo">No</label>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )
                      : (
                        <div className="d-flex align-items-center customGap"  >
                          <div style={{ columnGap: "10px" }} className="d-flex align-items-center">
                            <input type="radio" id="marketplace" name="Fullfillment" value="Marketplace" onChange={handleFullfillmentType} className="mb-0" checked={business_partner_type === "Marketplace"} />
                            <label htmlFor="marketplace">Marketplace</label>
                          </div>
                          <div style={{ columnGap: "10px" }} className="d-flex align-items-center">
                            <input type="radio" id="Direct" name="Fullfillment" value="Direct Order" onChange={handleFullfillmentType} className="mb-0" checked={business_partner_type === "Direct Order"} />
                            <label htmlFor="Direct">Direct Order</label>
                          </div>

                        </div>

                      )}
                </Col>
              </Row>
              <Formik
                enableReinitialize={true}
                initialValues={formikInitialValue}
                validationSchema={formSchema}
                onSubmit={(values, { setSubmitting }) => {
                  //setStillSubmitting(true);
                  setDisabled(true);
                  setTimeout(()=>{
                    setDisabled(false);
                  },1500)
                  setTimeout(() => {
                    handleSubmitForm(values);
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting /* and other goodies */,
                }) => (
                  <Form
                    className="p-4 rounded containerShadow"
                    onSubmit={handleSubmit}
                  >
               
                    <h2 className="font16 mb-2 text-dark">General Information</h2>

                    <Row>
                      {/* Business Name */}
                      <Col md={6}>
                      <Form.Control
                          type="hidden"
                          value={partner_id}
                          onChange={(e) => setPartner_id(e.target.value)}
                          placeholder="Enter Name"
                          className="font14"
                        />
                        <Form.Control
                          type="hidden"
                          value={userId}
                          onChange={(e) => setId(e.target.value)}
                          placeholder="Enter Name"
                          className="font14"
                        />
                        <Form.Group className="mb-3 mb-lg-0" controlId="formBasicText1" >
                          <Form.Label className="font12 text-dark fw-medium">
                            Business Name{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="business_name"
                            value={nameFormat(values.business_name.charAt(0).toUpperCase() + values.business_name.slice(1))}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // pattern="^(?!.*\s).*$"
                            // title="Please avoid leading spaces."
                            type="text"
                            placeholder="Enter Business Name"
                            isValid={touched.business_name && !errors.business_name}
                            isInvalid={!!errors.business_name}
                          />
                          {errors.business_name && touched.business_name ? (
                            <div className="text-danger font12">
                              {errors.business_name}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3 mb-lg-0">
                          <Form.Label className="font12 text-dark fw-medium">
                            Phone Number{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="setect_code">
                              <Select
                                className={`font12 form-select${
                                  values.main_code.length > 10 ? "sdd" : "jk"
                                }`}
                                type="text"
                                name="main_code"
                                value={phoneCode.find(
                                  (option) => option.value === values.main_code
                                )}
                                onChange={(selectedOption) =>
                                  handleChange({
                                    target: {
                                      name: "main_code",
                                      value: selectedOption.value,
                                    },
                                  })
                                }
                                onBlur={handleBlur}
                                options={phoneCode}
                                isSearchable
                                placeholder="Code"
                                isValid={touched.main_code && !errors.main_code}
                                isInvalid={!!errors.main_code}
                              />
                            </div>
                     
                            &nbsp;
                            {values.main_code === '' ?
                              <Form.Control
                                type="text"
                                name="main_phone"
                                value={phoneFormat(values.main_phone).trim()}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                // isValid={touched.main_phone && !errorPhone}
                                // isInvalid={errorPhone === true}
                                isValid={touched.main_phone && !errors.main_phone}
                                isInvalid={!!errors.main_phone}
                                placeholder="Enter Phone"
                                className="font12 "
                                onKeyPress={handleKeyPress}
                                disabled
                             />
                              :
                              <Form.Control
                                type="text"
                                name="main_phone"
                                value={phoneFormat(values.main_phone?.trim())}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.main_phone && !errors.main_phone} 
                                isInvalid={!!errors.main_phone}
                                placeholder="Enter Phone"
                                className="font12 "
                                onKeyPress={handleKeyPress}
                                onPaste={(e) => {
                                  e.preventDefault();
                                  const pastedText = e.clipboardData.getData('text/plain');
                                  const formattedPhoneNumber = phoneFormat(pastedText);
                                  handleChange({ target: { name: 'main_phone', value: formattedPhoneNumber } });
                                }}
                                required
                             />
                            }
                          </div>
                          <div className="d-flex">
                            {values.main_phone === '' ? '' : (errors.main_code && touched.main_code ? (
                              <div className="text-danger font12">
                                {errors.main_code}
                              </div>
                            ) : null)
                            } 
                            {errorPhone && touched.main_phone ? (
                              <div className="text-danger font12">
                                {errors.main_phone}
                              </div>
                            ) : null}
                          </div>

                        </Form.Group>
                      </Col>
                      
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText2">
                          <Form.Label className="font12 text-dark fw-medium">
                            Website{" "}
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="website"
                            value={values.website}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Website"
                            isValid={
                              touched.website &&
                              !errors.website
                            }
                            isInvalid={!!errors.website}
                          />
                          {errors.website &&
                            touched.website ? (
                            <div className="text-danger font12">
                              {errors.website}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText2">
                          <Form.Label className="font12 text-dark fw-medium">
                            Email
                          </Form.Label>
                          <Form.Control
                          disabled
                            className="font12"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="email"
                            placeholder="Enter Email"
                            isValid={
                              touched.email &&
                              !errors.email
                            }
                            isInvalid={!!errors.email}
                          />
                          {errors.email &&
                            touched.email ? (
                            <div className="text-danger font12">
                              {errors.email}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>

                    <h2 className="font16 mb-2 mt-2 text-dark">Address Information</h2>

                    <Row>
                     
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText3">
                          <Form.Label className="font12 text-dark fw-medium">
                          Number and Street{" "}
                          <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="street_number"
                            value={nameFormat(values.street_number)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Number and Street"
                            isValid={touched.street_number && !errors.street_number}
                            isInvalid={!!errors.street_number}
                          />
                          {errors.street_number && touched.street_number ? (
                            <div className="text-danger font12">
                              {errors.street_number}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" >
                          <Form.Label className="font12 text-dark fw-medium">
                          Country{" "}
                          <span className="text-danger">*</span>
                          </Form.Label>
                          <Field as='select' style={{ height: '31px', width: '100%',borderWidth:'1px',borderRadius:'5px',borderColor:errors.country && touched.country ? 'red' : '#cccccc' }} className="font12" name="country"   
                          onChange={handleChange} onBlur={handleBlur}  isValid={
                              touched.country &&
                              !errors.country
                            }
                            isInvalid={!!errors.country} 
                            placeholder="Enter Business Partner"
                            >
                            {countryData?.map(partner => {
                              return(
                                <option key={partner.value} value={partner.value} >
                                  {partner.label}
                                </option>
                              )
                            })}
                          </Field>
                          {/* <Form.Control
                            className="font12"
                            name="country"
                            value={nameFormat(values.country)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Country"
                            isValid={touched.country && !errors.country}
                            isInvalid={!!errors.country}
                          /> */}
                          {errors.country && touched.country ? (
                            <div className="text-danger font12">
                              {errors.country}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                       <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicText4">
                          <Form.Label className="font12 text-dark fw-medium">
                            Postal or Zip Code{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="postal"
                            value={values.postal.trim()}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter Zip Code"
                            isValid={touched.postal && !errors.postal}
                            isInvalid={!!errors.postal}
                          />
                          {errors.postal && touched.postal ? (
                            <div className="text-danger font12">
                              {errors.postal}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                      <Form.Group className="mb-3" controlId="formBasicText5">
                          <Form.Label className="font12 text-dark fw-medium">
                          City{" "}
                          <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            className="font12"
                            name="city"
                            value={nameFormat(values.city)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                            placeholder="Enter City"
                            isValid={touched.city && !errors.city}
                            isInvalid={!!errors.city}
                          />
                          {errors.city && touched.city ? (
                            <div className="text-danger font12">
                              {errors.city}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>             
                    </Row>


                    <h2 className="font16 mb-2 mt-2 text-dark">Payment Type</h2>
{funds_type === "Associate" ? <></> :
<Row>

<Col md={6}>
    <Form.Group className="mb-3">
      <Form.Label className="font12 text-dark fw-medium">
        Payment Type{" "}
        <span className="text-danger">*</span>
      </Form.Label>
      
      <Field as='select' className="font12 w-100" name='payment_type' value={(values.payment_type)} onChange={handleChange} disabled
          onBlur={handleBlur} style={{ border:errors.payment_type && touched.payment_type ?'1px solid red' : '1px solid #cfc6c6', height: '31px', borderRadius: '5px' }}
          isValid={touched.payment_type && !errors.payment_type}
          isInvalid={!!errors.payment_type} type='text'>
          <option value="" label="Select" />
          <option value="Invoice" label="Invoice" />
          <option value="Credit" label="Credit" />
          <option value="Funds" label="Funds" />
        </Field>
        {errors.payment_type && touched.payment_type ? (
        <div className="text-danger font12">
          {errors.payment_type}
        </div>
      ) : null}
    </Form.Group>
  </Col>
  {values.payment_type === "Invoice" ? "" :
   <>
   {values.payment_type === "Credit" ? <Col md={6}>
    <Form.Group className="mb-3" controlId="formBasicText7">
      {values.payment_type?<Form.Label className="font12 text-dark fw-medium">
        {values.payment_type} {" "}
        
      </Form.Label>:<Form.Label className="font12 text-dark fw-medium">
        Credit {" "}
       
      </Form.Label>}

            <Form.Control
            disabled
        className="font12"
        name="credit_amount"
        value={values.available_balance}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        placeholder="Enter credit amount"
        isValid={touched.available_balance && !errors.available_balance}
        isInvalid={!!errors.available_balance}
      />
      {errors.available_balance && touched.available_balance ? (
        <div className="text-danger font12">
          {errors.available_balance}
        </div>
      ) : null} 
            
    </Form.Group>
  </Col>:<Col md={6}>
    <Form.Group className="mb-3" controlId="formBasicText7">
      {values.payment_type?<Form.Label className="font12 text-dark fw-medium">
        {values.payment_type} {" "}
        
      </Form.Label>:<Form.Label className="font12 text-dark fw-medium">
        Funds {" "}
       
      </Form.Label>}

            <Form.Control
            disabled
        className="font12"
        name="debit_amount"
        value={values.available_balance}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        placeholder="Enter funds amount"
        isValid={touched.available_balance && !errors.available_balance}
        isInvalid={!!errors.available_balance}
      />
      {errors.available_balance && touched.available_balance ? (
        <div className="text-danger font12">
          {errors.available_balance}
        </div>
      ) : null} 
            
    </Form.Group>
  </Col>}
   </>
  
  }
 
</Row>}

                      <h2 className="font16 mb-2 mt-2 text-dark">Funds Threshold Reminder</h2>
                      <Row>

                        <Col md={6}>
                          <Form.Group className="mb-3" controlId="formBasicText7">
                            <Form.Label className="font12 text-dark fw-medium">
                              Threshold Amount
                            </Form.Label>
                            <div className="position-relative" style={{ minWidth: "180px" }}>
                              <input
                                type="number"
                                placeholder="Enter Threshold Amount"
                                className={`font12 form-control ${errors.marginAmount && touched.marginAmount ? "is-invalid" : ""
                                  }`}
                                name="marginAmount"
                                value={values.marginAmount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isValid={touched.marginAmount && !errors.marginAmount}
                                isInvalid={!!errors.marginAmount}
                                style={{
                                  borderRadius: "5px",
                                }}
                              />
                              {errors.marginAmount && touched.marginAmount && (
                                <div className="invalid-feedback font12">
                                  {errors.marginAmount}
                                </div>
                              )}
                            </div>
                          </Form.Group>

                          {isApiThreshold ? <div className="d-flex justify-content-md-end">
                            <Link className="fullRounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font14 " style={{ minWidth: "140px", marginTop: "-10px" }} onClick={deleteReminder}>
                              Withdraw Reminder
                            </Link>
                          </div> : ""}
                        </Col>


                      </Row>
                    <Row className="mt-4">
                      <Col>
                        {disabled?<Button
                          variant="primary"
                          className="px-4 py-2 fullRounded newCustomButton shadowPrimary font12"
                           style={{
                                backgroundColor: 'var(--newOrange)',
                                borderColor: 'var(--newOrange)',
                            }}
                        >
                          {id ? 'Save Changes' : 'Create Business Partner'}
                        </Button>:<Button
                          type="submit"
                          variant="primary"
                          className="px-4 py-2 fullRounded newCustomButton shadowPrimary font12"
                           style={{
                                backgroundColor: 'var(--newOrange)',
                                borderColor: 'var(--newOrange)',
                            }}
                        >
                          {id ? 'Save Changes' : 'Create Business Partner'}
                        </Button>}
                      </Col>
                    </Row>

                  </Form>
                )}
              </Formik>
              
            </Col>
          </Row>
        </Container>
       
        {/* <ToastContainer position="top-center" /> */}
      </div>
    </>
  );
};

export default Profile;