import React, { useState } from 'react';
import '../styles/header.css';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { BsCart4 } from "react-icons/bs";
import { FaSearch, FaUserCircle } from "react-icons/fa"
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import { FaSortUp, FaSortDown } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { Bars } from 'react-loader-spinner';
import {
    Button,

    Col,
    Container,
    Row,
    Form,
    DropdownButton,
    Dropdown,

} from "react-bootstrap";
import { useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {getcart} from '../Redux/CartType/actions';
import { deleteCookie, getNewCustomerToken } from '../config/cookie.config';

const NavbarComponent = (props) => {
    const nameDomain = (window.location.host.split('.'));
    const navigate = useNavigate();
    // const user = JSON.parse( window.localStorage.getItem('user'));
    
    let ncentivaBal = parseFloat(0.00);
    // const loaddata = props.checkdata
    const [balance, setBalance] = useState("");
    const [ncentivaWalletBalance, setNcentivaWalletBalance] = useState("");
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [showNav, setShowNav] = useState(false);
    const [btnColor, setBtnColor] = useState('');
    const [btnText, setBtnText] = useState('');
    const [image, setImage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [customerWallet, setCustomerWallet] = useState(0);
    const [show, setShow] = useState(false);
    const [mobilelogo,setMobileLogo] = useState(false);
    const cmsData = useSelector((state) => state.cmsReducer);
    const cartData = useSelector((state)=>state.cartReducer);
    const cusData = useSelector((state)=>state.customer_balance_Reducer);
    const dispatch = useDispatch();

    let lists = cartData?.data?.cart
    const customer = JSON.parse(window.localStorage.getItem('customer'));
    let cusId = customer?.customer?._id
    if(lists){
        lists = lists.filter((item) => item?.customer_id === cusId);
    } else {
        lists = ""
    }
    const cartList = lists;

    var name;
    var rbalance;
    //var balance;
    let token;
    if (customer) {
        token = customer.token;
    } else {
        token = null;
    }
    let localWallet = window.localStorage.getItem('ncentiva_wallet');
    var id;
    let wallet = Number(0);
    let usedWalletBalance = Number(0);
    const cartDatalength = cartData?.data?.cart?.length
    for (let i = 0; i < cartList?.length; i++) {
        wallet += parseFloat(cartList[i]?.ncentiva_wallet)
        if (cartList[i]?.wallet === true) {
            usedWalletBalance += parseFloat(cartList[i].cost)
        }
    }

   


    useEffect(()=>{
        // setIsLoading(true);
      if(cusData){
        if(cusData?.data?.message === "Please Login"){
          localStorage.removeItem('customer');
          // localStorage.removeItem('listItem');
          navigate('/login');
          toast.error("Session Expired Please Login Again")
        }
        let status = cusData?.data?.status;
        // setFirstName(cusData?.data?.customer?.first_name);
        // setLastName(cusData?.data?.customer?.last_name);
        // setCustomerWallet(cusData?.data?.customer?.wallet_balance);
        // setBalance(cusData?.data?.customer?.balance);

        if (status === false) {
          window.localStorage.removeItem('customer');
          navigate('/login');
          toast.warning("Your account has been deactivated");
      }
      if(cusData?.isError?.response?.status === 404){
        localStorage.removeItem('customer');
        navigate('/login');
        toast.error("Your Account Doesn't Exist")
      }else if (cusData?.isError?.response?.status === 401){
        localStorage.removeItem('customer');
        navigate('/login');
        toast.error("Session Expired Please Login Again")
      }else if (cusData?.isError?.response?.status === 400){
        localStorage.removeItem('customer');
        navigate('/login');
        toast.error("Your Account Has Been Deactivated")
      }
    //   setIsLoading(false);
      }
      },[cusData])

    let r = parseInt(0);
    let bal;
    var contactList ;
    let customerList = cartData?.data?.cart
    if(customerList){
        customerList = customerList.filter((item) => item?.customer_id === cusId);
    }
    contactList = customerList;
    if (ncentivaBal >= 0.00) {
        for (let i = 0; i < contactList?.length; i++) {
            ncentivaBal += parseFloat(contactList[i]?.ncentiva_wallet);
            r += parseFloat(contactList[i]?.cost);
        }
    }
    else {
        for (let i = 0; i < cartList?.length; i++) {
            ncentivaBal += parseFloat(cartList[i]?.ncentiva_wallet);
            r += parseFloat(cartList[i]?.cost);
        }
    }
    bal = cusData?.data?.balance - r;

    if (bal >= 0.0) {
        ncentivaBal = ncentivaBal.toFixed(2);
        // window.localStorage.setItem('ncentiva_wallet', ncentivaBal);
    }

    if (customer) {
        id = customer?.customer?._id;
        
        name = customer?.customer?.first_name + " " + customer?.customer?.last_name;
    } else {
        name = null;
        //setBalance(null);
    }

    const gotoTransaction = () => {
        navigate("/customers/transaction")
    }

    const gotoMonetaryTransaction = () => {
        navigate(`/customers/monetarytransaction`)
    }

    const customerEmail = JSON.parse(window.localStorage.getItem('customer'));
    let email = customerEmail?.customer?.email;

    const gotoResetPassword = async (e) => {

        try {
            const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/send-reset-password-email`, {
                email,
            });

            if (data && data.message) {
                if (data.message === "Email doesn't exist") {
                    toast.warning(data.message);
                } else if (data.message === "Email Field is Required") {
                    toast.warning(data.message);
                } else {
                    toast.success(data.message);
                    localStorage.removeItem('customer_data')
                    localStorage.removeItem('customer');
                    navigate('/')
                }

            } else {
                console.error("Unexpected response format:", data);
            }
        } catch (err) {
            console.error("Error:", err);
            if (err.response && err.response.data) {
                toast.error(err.response.data);
            } else {
                toast.error("An error occurred while processing your request.");
            }
        }

    }



    const gotoProfile = () => {
        navigate("/customers/profile")
    }
    useEffect(() => {
        const data = cmsData.data?.cms; 
          
        if (data?.default_ncentiva === false) {
            setBtnColor(data?.btn_color);
            setBtnText(data?.btn_text);
            if (data?.brand_logo) {
                setImage(data?.brand_logo)
                setMobileLogo(true)

            }
        } else if(data?.default_ncentiva === true){
            // setImage("https://dev.ncentiva.com/assets/images/logo.png");
            setBtnColor('#3D05B6');
            setBtnText('white');
        }
        setIsLoading(false);
    }, [cmsData])

  


    const logout = async () => {
  
        // toast.success("You are Successfully Logged Out")
        // setTimeout(() => {
        //     localStorage.removeItem('customer');
        //     localStorage.removeItem('customer_data');
        //     // localStorage.removeItem('listItem');
        //     navigate('/login');
     
        // }, 1000);


        await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/logout`, {
            headers: {
              'Authorization': `Bearer ${getNewCustomerToken()}`
            }
          }).then((res)=> {
            toast.success("You are Successfully Logged Out")
            setTimeout(() => {
                // Delete cookies
                deleteCookie('customer_OAC-01');
                deleteCookie('customer_AAC-02');

                // Remove token from localStorage
                window.localStorage.removeItem("customer_CAACAO-012");
                localStorage.removeItem('customer');
                localStorage.removeItem('customer_data');
                localStorage.removeItem('listItem');
                localStorage.removeItem('ncentiva_wallet');
                localStorage.removeItem('payoutItem');
                navigate('/login');
        
            }, 1000);
          }).catch((err)=> {
            console.log('Error :',err);
          })
        

    }

    const handleCartClick = () => {
        if (cartList?.length > 0) {
            navigate('/checkout');
        }
    };

    const LogoStyle = {
        width: "170px",
        // height: "110px",
        objectFit: "scale-down",
    }
    let bals = Number(0);

    const balances = customerWallet
    //   if( Number(balances) > 0 ) {
    bals = (parseFloat(cusData?.data?.wallet_balance) + parseFloat(wallet));
    bals = parseFloat(bals) - parseFloat(usedWalletBalance)

    //   } 
    if (bals < "0.00") {
        bals = "0.00"
    }

    const handleClose = () => {
        setShow(false);
      } 
      
    const handleShow = () => {
      setShow(true);
      
    }


// Implementing auto logout by inactivity
//   useEffect(() => {
//     let timeoutId;
//     function onUserActivity() {
//       if (timeoutId) clearTimeout(timeoutId);
//       timeoutId = setTimeout(() => {
//         localStorage.removeItem('customer');
//         navigate('/login');
//         toast.error("Session Expired Please Login Again")
//       }, 900000);
//     }
//     window.addEventListener('mousemove', onUserActivity);
//     window.addEventListener('keydown', onUserActivity);
//     return () => {
//       window.removeEventListener('mousemove', onUserActivity);
//       window.removeEventListener('keydown', onUserActivity);
//     };
//   }, [])
  useEffect(() => {
    let timeoutId;
      function onUserActivity() {
          if (localStorage.getItem('customer')) {
              clearTimeout(timeoutId);
              timeoutId = setTimeout(() => {
                  localStorage.removeItem('customer');
                  // Delete cookies
                  deleteCookie('customer_OAC-01');
                  deleteCookie('customer_AAC-02');

                  // Remove token from localStorage
                  window.localStorage.removeItem("customer_CAACAO-012");
                  navigate('/login');
                  toast.error("Session Expired. Please Login Again");
              }, 900000); // 15 minutes in milliseconds
          }
      }
    
    function resetTimeout() {
      onUserActivity();
    }

    // Events for desktop
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keydown', resetTimeout);

    // Events for mobile
    window.addEventListener('touchstart', resetTimeout);
    window.addEventListener('touchmove', resetTimeout);
    window.addEventListener('touchend', resetTimeout);

    // Initialize timeout on component mount
    onUserActivity();

    // Cleanup event listeners and timeout on component unmount
    return () => {
        // Cleanup
        window.removeEventListener('mousemove', resetTimeout);
        window.removeEventListener('keydown', resetTimeout);
        window.removeEventListener('touchstart', resetTimeout);
        window.removeEventListener('touchmove', resetTimeout);
        window.removeEventListener('touchend', resetTimeout);
        clearTimeout(timeoutId);
    };
  }, [navigate]);



    return (
        <>
            {(cmsData?.loading || cusData?.loading ) ? "" :
                <header className={`header  bg-white ${props.position ? props.position : 'sticky-top' }`}>
                    <div className="container-fluid">
                        <div className={`header-content ${ name === null ? 'nologin_navbar reponsive_nologin' : ''}`}>
                            <div className=" d-flex align-items-center col-6 ">
                                <Link to="/" className={`${ mobilelogo ? "mobile_View_logo" : "header-logo"}`}>
                                  {cmsData.data?.cms?.default_ncentiva === false ? (
                                    image && <img
                                    src={image}
                                    alt="Site Logo"
                                    
                                    style={LogoStyle}
                                    />
                                  )
                                  
                                  :
                                  
                                  <img
                                        
                                        src="https://ncentiva-development.s3.eu-west-1.amazonaws.com/ncentiva_new_logo.png"
                                        alt="Site Logossss"
                                        style={{width:"170px"}}
                                    
                                    />}
                                </Link>
                                 <div className="search-content w-100 mx-3">
                                <div className="search-bar">
                                  <input
                                    type="text"
                                    className={`form-control responsive_width`}
                                    placeholder="Search for a brand, product or service"
                                   
                                  />
                                  <FaSearch className="search-icon" />
                                </div>
                              </div>
                            </div>
                            <div className="">
                                <ul className="header-ul">
                                    {
                                        name == null ?
                                            <li className="account-balance nav-item">
                                              
                                                {btnColor ? <Link className="btn btn-main d-lg-inline" style={{ backgroundColor: btnColor, color: btnText, border: `1px solid ${btnColor}` }} to="/login">Login</Link> : ''}
                                            </li> :
                                            <>
                                                <li className='balanace_seriel'>

                                                    <Dropdown>
                                                        <Dropdown.Toggle id="dropdown-basic" className='dropdown_header'>
                                                            Balance                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                            </svg>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item >   <li>
                                                                <li className="account-balance  nav-item">
                                                                    <span className="d-lg-inline" >Bonus Cash</span> </li>
                                                                <li className="account-balance  nav-item">
                                                                    <span className="d-lg-inline" >$ {Number(bals).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}   </span> </li>
                                                            </li></Dropdown.Item>
                                                            <Dropdown.Item >  <li>
                                                                <li className="account-balance  nav-item">
                                                                    <span className="d-lg-inline " >Balance</span> </li>
                                                                <li className="account-balance nav-item">
                                                                   {cusData ? <span className="d-lg-inline" >$ {Number(cusData?.data?.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> : ""}  </li>
                                                            </li></Dropdown.Item>

                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </li>
                                                <li className="account-balance bonus_cash nav-item">
                                                    <span className="d-lg-inline" >Bonus Cash</span> </li>
                                                <li className="account-balance bonus_cash nav-item">
                                                    <span className="d-lg-inline" >$ {Number(cusData?.data?.wallet_balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}   </span> </li>
                                                <li className="account-balance bonus_cash nav-item">
                                                    <span className="d-lg-inline bonus_cash" >Balance</span> </li>
                                                <li className="account-balance bonus_cash nav-item">
                                                    <span className="d-lg-inline" >$ {Number(cusData?.data?.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span> </li>
                                                <li className="account-balance nav-item nav-cart" onClick={handleCartClick}>
                                                    {cartDatalength > 0 ? <span className="cart-count">{cartDatalength}</span> : ''}
                                                    <BsCart4 className="fs-4" />
                                                </li>
                                                <NavDropdown title={<span> <FaUserCircle className="fs-4" /> <span className='user-pname ms-1'>{cusData?.data?.first_name} {cusData?.data?.last_name}</span></span>} id="basic-nav-dropdown" >
                                                    {
                                                        // <img src="/assets/images/user-photo.png" /> 
                                                    }
                                                    <NavDropdown.Item onClick={() => gotoProfile()}>
                                                        My Profile

                                                    </NavDropdown.Item >
                                                    <NavDropdown.Item onClick={() => gotoTransaction()}>
                                                        My Giftcards

                                                    </NavDropdown.Item >
                                                    <NavDropdown.Item onClick={() => gotoMonetaryTransaction()} >
                                                        Monetary Transactions
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item onClick={() => gotoResetPassword()} >
                                                        Reset Password
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Item  onClick={ ()=>handleShow() } >
                                                            Logout
                                                    </NavDropdown.Item>

                                                </NavDropdown>
                                                <Modal
                                                        show={show}
                                                        onHide={handleClose}
                                                        backdrop="static"
                                                        keyboard={false}
                                                        size="md"
                                                        centered
                                                    >

                                                                <Modal.Body>
                                                                <Col>
                                                                <img className="modal-img"
                                                                    src="/assets/images/exclamation.svg"
                                                                    // className="img-fluid"
                                                                    alt=""
                                                                />
                                                                <br />
                                                                <br />
                                                                <h3 className="modal-text">Are you sure, you want to Log out?</h3>
                                                                <br />
                                                                <Row className="modal-row">
                                                                    <Col md ={1}></Col>
                                                                    <Col md={4}>
                                                                    <Button className="btn btn-danger success-btn" onClick={handleClose} >
                                                                            <span><b>Cancel</b></span>
                                                                        </Button>
                                                                    </Col>
                                                                    <Col className="middle-col" md ={2}></Col>
                                                                    <Col md={4}>
                                                                        <Button className="btn btn-success primary-btn" onClick={logout}>
                                                                            <span><b>Confirm</b></span>
                                                                        </Button>
                                                                    </Col>
                                                                    <Col md ={1}></Col>

                                                                </Row>
                                                                <br />
                                                                </Col>
                                                                </Modal.Body>

                                                        </Modal> 
                                                
                                            </>

                                    }







                                </ul>
                            </div>
                        </div>
                    </div>
                </header>}
        </>

    )
}

export default NavbarComponent