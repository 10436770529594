import React, { useState } from "react";

import jsPDF from "jspdf";
import logo from "../../assets/images/logo.png";
import "jspdf-autotable";

import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { FaSortUp, FaSortDown } from "react-icons/fa";

import { Bars } from "react-loader-spinner";
import { Col, Container, Row, DropdownButton, Dropdown } from "react-bootstrap";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getNewCustomerToken } from "../../config/cookie.config";

const CustomerTransaction = () => {
  useEffect(() => {
    document.title = "ncentiva || Customer Historical Transactions";
  }, []);
  const [transactionData, setTransactionData] = useState([]);
  const [modalloading, setModalLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [showPage, setShowPage] = useState(1);
  const [noshow, setNoShow] = useState(false);
  const [valueorder, setValueorder] = useState("ASC");
  const [updateorder, setUpdateorder] = useState("ASC");
  const [sort, setSort] = useState("");
  const nameDomain = window.location.host.split(".");
  const cmsData = useSelector((state) => state.cmsReducer);
  const defaultNcentiva = cmsData?.data?.cms?.default_ncentiva;
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponses, setApiResponses] = useState(false);
  const [image, setImage] = useState("");
  const [btnColor, setBtnColor] = useState('');
  const [btnText, setBtnText] = useState('');
  const [primaryColor, setPrimaryColor] = useState("");
  let navigate = useNavigate();
  let token;
  let customer_id;
  let business_partner;
  const getAllData = (click) => {
    if (click !== undefined && click) {
      setModalLoading(false);
    } else {
      setModalLoading(true);
    }
    // setModalLoading(true);
    const user = JSON.parse(window.localStorage.getItem("customer"));

    if (user) {
      token = user?.token;
      customer_id = user.customer._id;
      business_partner = user.customer.business_partner_id;
    } else {
      token = null;
      customer_id = null;
      business_partner = null;
    }

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customertransaction?customer_id=${customer_id}&partner_id=${business_partner}&page=${pageNumber}&sort=${sort}`,
        {
          headers: {
            Authorization: `Bearer ${getNewCustomerToken()}`,
          },
        }
      )
      .then((response) => {
        if (response.data.message === "Please Login") {
          localStorage.removeItem("customer");
          localStorage.removeItem("listItem");
          navigate("/login");
          toast.error("Session Expired Please Login Again");
          // window.location.reload();
        }
        const allData = response?.data?.customer_transaction;
        const totalpage = response?.data?.totalPages;
        if (allData.length < 1) {
          setNoShow(true);
        } else {
          setNoShow(false);
        }
        setTransactionData(allData);
        setNumberOfPages(totalpage);
        setModalLoading(false);
      })

      .catch((error) => {
        console.log(error);

        if (error.message === "Request failed with status code 401") {
          localStorage.removeItem("customer");
          navigate("/login");
          toast.warning("Session Expired Please Login Again");
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    getAllData();
  }, [pageNumber]);

  const valuesorting = (valueorder) => {
    if (valueorder === "DSC") {
      setSort("dscValue");

      setValueorder("DSC");
    } else {
      setSort("ascValue");

      setValueorder("ASC");
    }
  };

  const updatesorting = (updateorder) => {
    if (updateorder === "DSC") {
      setSort("dscUpdate");

      setUpdateorder("DSC");
    } else {
      setSort("ascUpdate");

      setUpdateorder("ASC");
    }
  };
  useEffect(() => {
    setIsLoading(true)
    if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
      axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
        .then((res) => {
          setApiResponses(true)
          if (res.data?.cms?.default_ncentiva === false) {
            setBtnColor(res.data?.cms?.btn_color);
            setBtnText(res.data?.cms?.btn_text);
            setPrimaryColor(res.data?.cms?.primary_color);
            if (res.data?.cms?.brand_logo) {
              setImage(res.data?.cms?.brand_logo)
            }
          } else {
            setImage("https://dev.ncentiva.com/assets/images/logo.png");
            setBtnColor('#FFAD31');
            setBtnText('white');
            setPrimaryColor("#2665F2");
          }
          setIsLoading(false);
        }).catch((err) => {
          console.log(err);
        })
    } else {
      setIsLoading(false);
    }
  }, []);
  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {
    items.push(
      <li
        key={number}
        className={number === pageNumber ? "active" : ""}
        style={defaultNcentiva
          ? {
            backgroundColor: number === pageNumber ? `${primaryColor}` : "",
            color: number !== pageNumber ? "black" : "white",
            border: `1px solid transparent`,
          }
          : {
            backgroundColor: number === pageNumber ? `${primaryColor}` : "",
            color: number !== pageNumber ? "black" : "white",
            border: `1px solid transparent`
          }
        }
        onClick={() => {
          setPageNumber(number);
          setShowPage(number + 1);
        }}
      >
        {number + 1}
      </li>
    );
  }

  const pagenumberSearch = (e) => {
    var pages = parseInt(e);
    var page = pages - 1;
    if (pages < 1) {
      setPageNumber(0);
      setShowPage(1);
    } else if (pages > numberOfPages) {
      setPageNumber(numberOfPages - 1);
      setShowPage(numberOfPages);
    } else if (pages >= 1 && pages <= numberOfPages) {
      setPageNumber(page);
      setShowPage(pages);
    } else {
      setPageNumber(0);
      setShowPage(pages);
    }
  };

  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
      setShowPage(pageNumber + 2);
    }
  };

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setShowPage(pageNumber);
    }
  };

  const formatDate = (date) => {
    // Convert the date to EST time zone
    const estDate = new Date(date);
    const estOptions = {
      timeZone: "America/New_York",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    };

    // Format the date as per the EST time zone, without seconds
    return estDate.toLocaleString("en-US", estOptions).replace(",", "");
  };

  const pdfDownlod = (pdfid) => {
    const user = JSON.parse(window.localStorage.getItem("customer"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/gettransactiondetails/${pdfid}`,
        {
          headers: {
            Authorization: `Bearer ${getNewCustomerToken()}`,
          },
        }
      )
      .then((response) => {
        if (response.data.message === "Please Login") {
          localStorage.removeItem("customer");

          navigate("/login");
          toast.error("Session Expired Please Login Again");
        }
        const totalAmount =
          parseFloat(response.data.transaction.fund) +
          parseFloat(response.data.transaction.bonus);
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const doc = new jsPDF(orientation, unit, size);
        var paragraphSecond = "6860 Dallas Pkwy";
        var paragraphThird = "Suite 200";
        var paragraphFourth = "Plano, TX 75024,";
        var paragraphFifth = "United States.";

        var lMargin2 = 450;
        var rMargin2 = 5;
        var pdfInMM2 = 690;

        var lMargin3 = 450;
        var rMargin3 = 5;
        var pdfInMM3 = 690;

        var lMargin4 = 450;
        var rMargin4 = 5;
        var pdfInMM4 = 690;

        var lMargin5 = 450;
        var rMargin5 = 5;
        var pdfInMM5 = 690;

        doc.setFontSize(12);

        doc.setFont("Roboto-Regular", "bold");
        var lines2 = doc.splitTextToSize(
          paragraphSecond,
          pdfInMM2 - lMargin2 - rMargin2
        );
        doc.text(lMargin2, 100, lines2);
        doc.setFontSize(12);
        var lines3 = doc.splitTextToSize(
          paragraphThird,
          pdfInMM3 - lMargin3 - rMargin3
        );
        doc.text(lMargin3, 115, lines3);
        doc.setFontSize(12);
        var lines4 = doc.splitTextToSize(
          paragraphFourth,
          pdfInMM4 - lMargin4 - rMargin4
        );
        doc.text(lMargin4, 130, lines4);
        doc.setFontSize(12);
        var lines5 = doc.splitTextToSize(
          paragraphFifth,
          pdfInMM5 - lMargin5 - rMargin5
        );
        doc.text(lMargin5, 145, lines5);

        doc.addImage(logo, "png", 30, 40, 150, 30);

        doc.setFontSize(18);
        doc.text(30, 200, "RECIPIENT:");
        doc.setFontSize(12);
        doc.text(
          30,
          220,
          `${response.data.transaction.customer_first_name} ${response.data.transaction.customer_last_name}`
        );
        doc.setFontSize(10);
        doc.text(30, 235, `${response.data.transaction.customer_email}`);

        var total = `$ ${Number(response.data.transaction.costValue)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        var datass = [
          { name: "Process", amount: `${response.data.transaction.status}` },
          {
            name: "Transaction Date",
            amount: `${response.data.transaction.createdAt.slice(0, 10)}`,
          },
          {
            name: "Transaction Time",
            amount: `${response.data.transaction.createdAt.slice(11, 16)}`,
          },
        ];

        var body = [
          ...datass?.map((el) => [el.name, el.amount]),
          [
            {
              content: `Total                                     ${totalAmount}`,
              colSpan: 2,
              styles: { fillColor: "#fff3ed" },
            },
          ],
        ];

        doc.autoTable({
          startY: 160,
          headStyles: { fillColor: "#fff3ed" },
          head: [
            ["Transaction ID", `${response.data.transaction.transaction_id}`],
          ],
          styles: { textColor: [10, 10, 10] },
          body: body,
          margin: { right: 30, left: 320 },
        });

        doc.setFontSize(15);
        const headers = [
          ["Product Name", "Cost", "Bonus", "Cost Value", "Purchase Order"],
        ];

        let info = [];
        response.data.transaction?.giftcards?.map((element) => {
          var costs = `$ ${Number(element.cost)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          var bonuss = `$ ${Number(element.bonus)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          var costvalues = `$ ${Number(element.costValue)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
          info.push([
            element.productName || element.productname,
            costs,
            bonuss,
            costvalues,
            element.purchaseOrderName,
          ]);
        });

        let content = {
          startY: 350,
          head: headers,
          headStyles: { fillColor: "#fff3ed" },
          styles: { textColor: [10, 10, 10] },
          body: info,
          margin: { top: 10, right: 30, bottom: 0, left: 30 },
        };

        doc.setFontSize(16);
        doc.text(340, 600, "Subtotal");
        doc.text(
          420,
          600,
          `$ ${Number(response.data.transaction.fund)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
        );
        doc.line(335, 605, 525, 605);

        doc.text(340, 620, "Bonus");
        doc.text(
          420,
          620,
          `$ ${Number(response.data.transaction.bonus)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
        );
        doc.line(335, 625, 525, 625);

        doc.text(340, 640, "Amount");
        doc.text(420, 640, `$ ${totalAmount}`);
        doc.line(335, 645, 525, 645);

        doc.autoTable(content);
        doc.save(`${response.data.transaction.customer_first_name}.pdf`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

 

  const [openDrawerId, setOpenDrawerId] = useState(null);

  const openDrawer = (transactionId) => {
    getAllData("click");
    setOpenDrawerId((prevId) =>
      prevId === transactionId ? null : transactionId
    );
  };

  return (
    <div className="px-3 p-3 p-lg-5">
      <Container fluid>
        <h3 className="mt-5 fs-5 text-dark">My Giftcards</h3>
      </Container>

      <Container fluid className="bg-white p-4 rounded shadow mt-4 d-none d-md-block">
        <Row className="align-items-center">
          <Col md={12} className="mt-4">
            <div className="table-responsive">
              <table
                className="table transaction_table "
                style={{ verticalAlign: "middle" }}
              >
                <thead className="bgLightRed font14">
                  <tr className="text-center">
                    <th className="text-start py-3 ">
                      {" "}
                      <div className="d-flex align-items-center justify-content-center ">
                        {" "}
                        <span className="me-1 "> Transaction ID</span>{" "}
                      </div>{" "}
                    </th>
                    <th className="py-3">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ cursor: "pointer" }}
                      >
                        <span className="me-1">Funds</span>
                        {valueorder === "ASC" ? (
                          <FaSortDown onClick={() => valuesorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => valuesorting("ASC")} />
                        )}
                      </div>
                    </th>
                    <th className="py-3">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ cursor: "pointer" }}
                      >
                        <span className="me-1">Bonus / Discount</span>
                      </div>
                    </th>
                    <th className="py-3">
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="me-1">Date</span>
                        {updateorder === "ASC" ? (
                          <FaSortDown onClick={() => updatesorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => updatesorting("ASC")} />
                        )}
                      </div>
                    </th>
                    <th className="py-3">Gift Cards</th>
                    <th className="py-3">Status</th>
                    <th className="py-3">Action</th>
                  </tr>
                </thead>
                <tbody className="font14 fw-medium text-center">
                  {modalloading === true ? (
                    <tr style={{ backgroundColor: "#eee" }}>
                      <td colSpan="9">
                        <Bars
                          height="80"
                          width="100%"
                          margin="auto"
                          color={primaryColor}
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {noshow ? (
                        <tr>
                          <td colSpan="9" className="text-center">
                            <h1 className="font16 text-dark">
                              No Transactions
                            </h1>
                          </td>
                        </tr>
                      ) : (
                        transactionData?.map((cust) => (
                          <React.Fragment key={cust._id}>
                            {console.log(cust.business_type_option)}
                            <tr
                              onClick={() => openDrawer(cust._id)}
                              style={{ cursor: "pointer" }}
                            >
                              <td className="py-3 font14 fw-medium ">
                                {cust.transaction_id}
                              </td>
                              <td className="py-3 font14 fw-medium ">
                                {
                                  cust.business_type_option === "Bonus" ? (
                                    <>
                                      ${Number(cust.fund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </>
                                  ) : (
                                    <>
                                      ${Number(cust.fund - cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </>
                                  )
                                }
                              </td>
                              <td>
                                <span className="me-1">
                                  {cust.business_type_option === "Bonus" ? '+' : '-'}
                                </span>
                                $
                                {Number(cust.bonus)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td className="py-3 font14 fw-medium">
                                {" "}
                                {formatDate(cust.updatedAt)
                                  .slice(0, 16)
                                  .replace("T", "      ")}
                              </td>

                              <td className="py-3 font14 fw-medium">
                                <Link
                                  to={`/customers/transaction/${cust._id}/giftcard`}
                                >
                                  {cust.giftcards.length} Gift Cards
                                  <br />
                                </Link>
                              </td>
                              <td className="py-3  font14 fw-medium">
                                {cust.status}
                              </td>
                              <td>
                                <DropdownButton
                                  variant="white"
                                  className="p-0  tableActionDropDown text-center"
                                  title={
                                    <div className="actionBtn">
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  }
                                  drop="start"
                                >
                                  <Dropdown.Item
                                    className="font14 fw-medium"
                                    onClick={() =>
                                      navigate(
                                        `/customers/transaction/${cust._id}/giftcard`
                                      )
                                    }
                                  >
                                    Details
                                  </Dropdown.Item>
                                  {cust.status === "Success" ||
                                    cust.status === "Refund" ? (
                                    <>
                                      {cust.giftcards.some(
                                        (giftcard) =>
                                          giftcard.status === "Success"
                                      ) ? (
                                        <>
                                          <Dropdown.Item
                                            className="font14 fw-medium"
                                            onClick={(e) =>
                                              pdfDownlod(cust._id)
                                            }
                                          >
                                            Download PDF
                                          </Dropdown.Item>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </DropdownButton>
                              </td>
                            </tr>

                            {openDrawerId === cust._id && (
                              <tr>
                                <td colSpan="12">
                                  <div
                                    className={`transaction-drawer ${openDrawerId === cust._id
                                        ? "drawer-open"
                                        : ""
                                      }`}
                                  >
                                    {cust.giftcards.length > 0 && (
                                      <div>
                                        <table
                                          className="table drawerTable"
                                          style={{
                                            verticalAlign: "middle",
                                            textAlign: "center",
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th className="py-1">
                                                Product Name
                                              </th>
                                              <th className="py-1">Order ID</th>
                                              <th className="py-1">Cost</th>
                                              <th className="py-1">Bonus / Discount</th>
                                              <th className="py-1">
                                                Card Value
                                              </th>
                                              <th className="py-1">Status</th>
                                              <th className="py-1">
                                                Status Code
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {cust.giftcards.map((giftcard) => (
                                              <tr key={giftcard._id}>
                                                <td className="font14 fw-medium  py-1">
                                                  {giftcard.productName}
                                                </td>
                                                <td className="font14 fw-medium  py-1">
                                                  {giftcard.purchaseOrderName}
                                                </td>
                                                <td className="font14 fw-medium  py-1">
                                                  {
                                                    cust.business_type_option === "Bonus" ? (
                                                      <>
                                                        ${Number(giftcard.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      </>
                                                    ) : (
                                                      <>
                                                        ${Number(giftcard.cost - giftcard.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                      </>
                                                    )
                                                  }
                                                </td>
                                                <td className="font14 fw-medium  py-1">
                                                  <span className="me-1">
                                                    {cust.business_type_option === "Bonus" ? '+' : '-'}
                                                  </span>
                                                  $
                                                  {Number(
                                                    giftcard.bonus
                                                  ).toFixed(2)}
                                                </td>
                                                <td className="font14 fw-medium  py-1">
                                                  {giftcard.business_type === "Bonus" ?
                                                    <>
                                                      ${Number(giftcard.costValue).toFixed(2)}
                                                    </>
                                                    :
                                                    <>
                                                      ${Number(giftcard.cost).toFixed(2)}
                                                    </>
                                                  }
                                                </td>
                                                <td className="font14 fw-medium  py-1">
                                                  {giftcard.status}
                                                </td>
                                                <td className="font14 fw-medium  py-1">
                                                  {giftcard.gcipStatusCode}
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      )}
                    </>
                  )}
                </tbody>
              </table>
              
              {numberOfPages === 0 ? (
                ""
              ) : (
                <Col className="mt-5 mb-4">
                  {
                    <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                      {pageNumber === 0 ? (
                        ""
                      ) : (
                        <li onClick={prevPage}>Previous </li>
                      )}

                      {items}

                      {numberOfPages - 1 === pageNumber ? (
                        ""
                      ) : (
                        <li onClick={nextPage}>Next</li>
                      )}
                      <li className="font14">
                        <input
                          style={{
                            width: "25px",
                            border: `1px solid ${primaryColor}`,
                            borderRadius: "4px",
                            textAlign: "center",
                          }}
                          value={showPage ? showPage : ""}
                          type="text"
                          name="number"
                          id=""
                          onChange={(e) => {
                            pagenumberSearch(e.target.value);
                          }}
                        ></input>{" "}
                        <span style={{ color: `${primaryColor}` }}>/</span>{" "}
                        {numberOfPages}
                      </li>
                    </ul>
                  }
                </Col>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="p-0 d-block d-md-none">
        {modalloading === true ? (
          <div >
            <div colSpan="9">
              <Bars
                height="80"
                width="100%"
                margin="auto"
                color={primaryColor}
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </div>
        ) :
          (
            <>
              {noshow ? (
                <div>
                  <div colSpan="9" className="text-center">
                    <h1 className="font16 text-dark">
                      No Transactions
                    </h1>
                  </div>
                </div>
              ) :
                transactionData?.map((cust) =>
                (
                  <div class="container-fluid d-block d-md-none p-0">
                    <div class="border card_container_transaction_list position-relative bg-white ">
                      <div class="position-absolute custom_status_tag" style={{
                        backgroundColor: cust.status.trim() === "Success" ? "#20A76E" :
                          cust.status.trim() === "Pending" ? "#F5C400" :
                            "Red"
                      }}>{cust.status}
                      </div>
                      <div class="d-flex  justify-content-between flex-row mb-2">
                        <div class="col-10 p-0">
                          <h5>Transaction ID:</h5>
                          <p>{cust.transaction_id}</p>
                        </div>
                        <DropdownButton
                          variant="white"
                          className="p-0  tableActionDropDown text-center"
                          title={
                            <div className="actionBtn">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          }
                          drop="start"
                        >
                          <Dropdown.Item
                            className="font14 fw-medium"
                            onClick={() =>
                              navigate(
                                `/customers/transaction/${cust._id}/giftcard`
                              )
                            }
                          >
                            Details
                          </Dropdown.Item>
                          {cust.status === "Success" ||
                            cust.status === "Refund" ? (
                            <>
                              {cust.giftcards.some(
                                (giftcard) =>
                                  giftcard.status === "Success"
                              ) ? (
                                <>
                                  <Dropdown.Item
                                    className="font14 fw-medium"
                                    onClick={(e) =>
                                      pdfDownlod(cust._id)
                                    }
                                  >
                                    Download PDF
                                  </Dropdown.Item>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </DropdownButton>
                      </div>
                      <div class="row d-flex mb-2">
                        <div class="col-6">
                          <p class="mb-1"><strong>Funds:</strong></p>
                          <p>{
                            cust.business_type_option === "Bonus" ? (
                              <>
                                ${Number(cust.fund).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </>
                            ) : (
                              <>
                                ${Number(cust.fund - cust.bonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              </>
                            )
                          }
                          </p>
                        </div>
                        <div class="col-6">
                          <p class="mb-1"><strong>Bonus / Discount:</strong></p>
                          <p>
                            <span className="me-1">
                              {cust.business_type_option === "Bonus" ? '+' : '-'}
                            </span>$
                            {Number(cust.bonus)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                        </div>
                      </div>
                      <div class="row d-flex mb-2">
                        <div class="col-6">
                          <p class="mb-1"><strong>Date:</strong></p>
                          <p>{formatDate(cust.updatedAt)
                            .slice(0, 16)
                            .replace("T", "      ")}</p>
                        </div>
                        <div class="col-6">
                          <p class="mb-1"><strong>Gift Cards:</strong></p>
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                `/customers/transaction/${cust._id}/giftcard`
                              )
                            }>  {cust.giftcards.length} {cust.giftcards.length > 1 ? "Gift Cards" : "Gift Card"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}

        {numberOfPages === 0 ? (
          ""
        ) : (
          <Col className="mt-5 mb-4 d-block d-md-none">
            <ul className="custPagination d-flex justify-content-center list-unstyled font14">
              {pageNumber === 0 ? (
                ""
              ) : (
                <li onClick={prevPage}>Previous</li>
              )}
              {items}
              {numberOfPages - 1 === pageNumber ? (
                ""
              ) : (
                <li onClick={nextPage}>Next</li>
              )}
              <li className="font14">
                <input
                  style={defaultNcentiva
                    ? { border: `1px solid ${primaryColor}`, width: "25px", borderRadius: '4px', textAlign: 'center' }
                    : { border: `1px solid ${primaryColor}`, width: "25px", borderRadius: '4px', textAlign: 'center' }}
                  value={showPage}
                  type="text"
                  name="number"
                  onChange={(e) => {
                    pagenumberSearch(e.target.value);
                  }}
                />{" "}
                <span style={{ color: `${primaryColor}` }}>/</span> {numberOfPages} </li>
            </ul>
          </Col>
        )}
      </Container>
    </div>
  );
};

export default CustomerTransaction;
