import {
    Col,
    Container,
    Row,
} from "react-bootstrap";
const DashTopBar = () =>{
    return(
        <Container fluid className="topBar bg-white">
                        <Row>
                            <Col md={6} sm={5}>
                               
                            </Col>
                            <Col md={6} sm={7}>
                                {/* <div className="rightnotification d-flex justify-content-end ">
                                    <div className="box d-flex align-items-center justify-content-center">
                                        <img
                                            src="/assets/images/mail.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                        <div className="notifySymbol"></div>
                                    </div>
                                    <div className="box d-flex align-items-center justify-content-center mx-4">
                                        <img
                                            src="/assets/images/bell.png"
                                            className="img-fluid"
                                            alt=""
                                        
                                        />
                                        <div className="notifySymbol"></div>
                                    </div>
                                    <div className="box d-flex align-items-center justify-content-center">
                                        <img
                                            src="/assets/images/user.png"
                                            className="img-fluid"
                                            alt=""
                                            width="20px"
                                        />
                                        <div className="notifySymbol"></div>
                                    </div>
                                </div> */}
                            </Col>
                        </Row>
                    </Container>
    )
}

export default DashTopBar;