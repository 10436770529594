import React from 'react'
import PromotionalSlider from './PromotionalSlider'

const PromotionalSliderArea = (props) => {
  return (
    <section className={`promotional-slider_area py-2 bg-light top_brand ${props.name === null ? "margin_new" :""}`}>
        <div className={`container-fluid custom-gap `}>
            {/* <h2 className='section-headers text-center mb-3'>Our Top Brands</h2> */}
            {/* <div className={`position-reletive ${
              (props.search.length > 0 && props.promoClick === true) ? "show_class" :
              (props.promoClick === false && props.search.length > 0) ? "hide_class" :
              (props.search === "" && props.promoClick === true) ? "hide_class" :
              (props.search === "" && props.promoClick === false) ? "show_class" :
              ""
            }`}> */}
            <div className={`position-reletive ${props.promoClick === true ? "hide_class" : ""}`} style={{transition:'0.9s'}}>
            <PromotionalSlider btnColor={props?.btnColor} btnText={props?.btnText}  rbalance_redux={props.rbalance_redux}
      wallet_balance={props.wallet_balance}
      disableBtn={props.disableBtn}
      promoData={props.promoCoupondata}
      setDisableBtn={props.setDisableBtn}
      ws= {props.ws}
      handleClickSearchSelect={props.handleClickSearchSelect} primaryColor={props.primaryColor}
      secondaryColor={props.secondaryColor}
      setModalShowDC={props.setModalShowDC}
      modalShowDC={props.modalShowDC}
      setPaymentOption={props.setPaymentOption}
      paymentOption={props.paymentOption}
      modalOnchange={props.modalOnchange}
      onConfirm={props.onConfirm}
      />
      </div>
      </div>
        
    </section>
  )
}

export default PromotionalSliderArea