import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaSortUp, FaSortDown, FaRecycle } from "react-icons/fa";
import {BiPlusMedical} from "react-icons/bi";
import { Bars } from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";
import { getNewToken } from "../config/cookie.config";

const UsersManagement = () => {
  useEffect(()=>{

    const adminCheck= window.location.pathname;
    if(adminCheck.includes('admin')){ 
      document.title = "ncentiva || Admin User Managment"
    }
},[])
  

  const user = JSON.parse(window.localStorage.getItem("client"));
  let userrole;
  let usersid;
  let navigate = useNavigate();
  if (user) {
    userrole = user.user.role;
    usersid = user.user._id;
  } else {
    userrole = null;
    usersid = null;
    navigate("/admin");
  }

  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("ASC");
  const [emailorder, setEmailorder] = useState("ASC");
  const [userorder, setUserorder] = useState("ASC");
  const [phoneorder, setPhoneorder] = useState("ASC");
  const [statusorder, setStatusorder] = useState("ASC");
  const [updateorder, setUpdateorder] = useState("ASC");
  const [role] = useState(userrole);
  const [customerData, setCustomerData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const shouldLog = useRef(true);
  const [sort, setSort] = useState("");
  const [noData,setNoData] = useState(false);
  const [showPage,setShowPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusChangeInfo, setStatusChangeInfo] = useState({ id: null, status: null });
  let token;
  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);

  if (user) {
    token = user.token;
  } else {
    token = null;
  }

  
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRowsPerPageChange = (event) => {
    const selectedRowsPerPage = event.target.value;
    setRowsPerPage(selectedRowsPerPage);
    getAllUsers(pageNumber, selectedRowsPerPage);
  };


  useEffect(() => {
    setIsLoading(true);
    const getData = setTimeout(() => {
      getAllUsers(pageNumber, rowsPerPage);
      redirectToLogin();
      setIsLoading(false);
    },600)
    return () => clearTimeout(getData)
  }, [pageNumber, search, sort])

  const getAllUsers = (pageNumber, pageSize) => {
    if (pageSize === undefined) {
      pageSize = 10
    }
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/users?page=${pageNumber}&pageSize=${pageSize}&search=${search}&sort=${sort}`, {
          headers: {
            'Authorization' : `Bearer ${getNewToken()}`
          }
      }
      )
      .then((response) => {
        if(response.data.message === "Please Login"){
          localStorage.removeItem('client');
        // localStorage.removeItem('user');
        navigate('/admin');
        toast.error("Session Expired Please Login Again")
        // window.location.reload();
        }
        const allUsers = response.data.users;
        if(allUsers.length <1){
          if(pageNumber > 1){
            var d=pageNumber-1
           setPageNumber(d)
          }else{
            setNoData(true);
          }
        }else{
          setNoData(false);
        }
        const totalPages = response.data.totalPages;
        //const slice = allUsers.slice(offset - 1 , offset - 1 + postsPerPage)
        setCustomerData(allUsers);
        setNumberOfPages(totalPages);
        //setPageCount(Math.ceil(allUsers.length / postsPerPage))
      })
      .catch((error) => {
        console.error(`Error: ${error}`);
        if (error.response.data.message) {
          localStorage.removeItem('client');
          localStorage.removeItem('user');
          navigate('/admin');
          // toast.error("Session Expired. Please Login Again")
        }
      });
  };




  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number);setShowPage(number+1) }}>
        {number + 1}
      </li>,
    );
  }


  const pagenumberSearch = (e)=>{
    var pages =parseInt(e) ;
    var page= pages -1;
    if(pages<1){
      setPageNumber(0);
      setShowPage(1);
    }else if(pages>numberOfPages){
      setPageNumber(numberOfPages-1);
      setShowPage(numberOfPages);
    }else if(pages>=1 && pages<=numberOfPages){
      setPageNumber(page);
      setShowPage(pages);
    }
    else{
      setPageNumber(0);
      setShowPage(pages);
    }
    }


  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
      setShowPage(pageNumber+2);
    }
  }

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setShowPage(pageNumber);
    }
  }




  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleDeleteUser = (userId) => {
    setSelectedUserId(userId);
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    if (selectedUserId) {
      const user = JSON.parse(window.localStorage.getItem("client"));
      if (user) {
        token = user.token;
      } else {
        token = null;
      }
      axios
        .delete(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/deleteuser/${selectedUserId}`,
          {
            headers: {
              Authorization: `Bearer ${getNewToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success("User Deleted Successfully");
            localStorage.setItem("user_Delete", " ");
            getAllUsers(pageNumber, rowsPerPage);
            setShowModal(false);
          } else {
            toast.error("Failed to Delete User");
          }
        })
        .catch((error) => {
          console.log(`Error : ${error}`)
          setShowModal(false);
          toast.error("Failed to Delete User");
        });
    }
  };
  
  const handleModalShow = (id) => {
    setShowModal(true); 
  };
  const handleCloseModal = () => {
    setShowModal(false); 
  };





  //   sorting

  // name

  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");
    } else {
      setSort("ascName");

      setOrder("ASC");
    }
  };

  //   email   emailorder

  const emailsorting = (emailorder) => {
    if (emailorder === "DSC") {
      setSort("dscEmail");

      setEmailorder("DSC");
    } else {
      setSort("ascEmail");

      setEmailorder("ASC");
    }
  };

  //   type of user

  const typeusersorting = (userorder) => {
    if (userorder === "DSC") {
      setSort("dscUser");

      setUserorder("DSC");
    } else {
      setSort("ascUser");

      setUserorder("ASC");
    }
  };

  //   email

  //   type of user

  //   phone
  const phonesorting = (col) => {
    if (phoneorder === "ASC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setCustomerData(sorted);
      setPhoneorder("DSC");
    }
    if (phoneorder === "DSC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setCustomerData(sorted);
      setPhoneorder("ASC");
    }
  };

  //   status

  const statussorting = (col) => {
    if (statusorder === "ASC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setCustomerData(sorted);
      setStatusorder("DSC");
    }
    if (statusorder === "DSC") {
      const sorted = [...customerData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setCustomerData(sorted);
      setStatusorder("ASC");
    }
  };


  const updatesorting = (updateorder) => {
    if (updateorder === "DSC") {
      setSort("dscUpdate");

      setUpdateorder("DSC");
    } else {
      setSort("ascUpdate");

      setUpdateorder("ASC");
    }
  };


  //   sorting

  //   sorting

  //  search
  const handleSearch = (value) => {
    setSearch(value);
    setPageNumber(0);
    setShowPage(1);
  };
  // search

  const redirectToLogin = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      navigate("/admin");
    }
  };
  const deleteUser = (id) => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
        axios
        .put(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/user/soft-delete`,
          {
            soft_delete: true,
            type: "id",
            id: id,
            status:false
          },
          {
            headers: {
              Authorization: `Bearer ${getNewToken()}`,
            },
          }
      )
      .then((response) => {
        toast.success("User Move to Trash");
        getAllUsers(pageNumber, rowsPerPage);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  function handleStatus(id, status) {
    if (status === false) {
      status = true;
    } else {
      status = false;
    }
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/user/status`, {
        id,
        status,
      }, {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`
        }
      },)
      .then((response) => {
        toast.success(response.data.message);
        getAllUsers(pageNumber, rowsPerPage);
      })
      .catch((error) => console.error(`Error: ${error}`));
  }

  const softDelete = async (e) => {
    const { name, checked, value } = e.target;
    if (name === "allSelect") {
      let tempUser = customerData.map((user) => {
        return { ...user, isChecked: checked };
      });
      setCustomerData(tempUser);
      localStorage.setItem("buss_Delete", "All");

    } else {
      localStorage.setItem("buss_Delete", "id");
      let tempUser = customerData.map((user) =>
        user._id === name ? { ...user, isChecked: checked } : user
      );
      setCustomerData(tempUser);
      const user = tempUser.filter((e) => e.isChecked);
      localStorage.setItem("buss_checked", JSON.stringify(user));
    }
  };

  const moveTrash = async () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    const result = localStorage.getItem("buss_Delete");
    if (result === "All") {
      // Move all customers to trash
      const isDeleted = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/user/soft-delete`,
        {
          soft_delete: true,
          type: "All",
        },
        {
          headers: {
            Authorization: `Bearer ${getNewToken()}`,
          },
        }
        );
        if (isDeleted.data.users.acknowledged === true) {
          toast.success(isDeleted.data.message);
          localStorage.setItem("buss_Delete", " ");
        } else {
          toast.error("Failed to Trashed");
      }
      navigate("/admin/user_trash");
    } else if (result === "id") {
      // Move selected customers to trash
      const store = JSON.parse(localStorage.getItem("buss_checked"));
      try {
        await store.map((e) => {
          axios.put(
            `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/user/soft-delete`,
            {
              soft_delete: true,
              type: "id",
              id: e._id,
            },
            {
              headers: {
                Authorization: `Bearer ${getNewToken()}`,
              },
            }
          );
        });
        toast.success("Selected Users Deleted");
        navigate("/admin/user_trash");
        localStorage.setItem("buss_Delete", " ");
      } catch (error) {
        toast.error("Failed to  Delete Users");
      }
    } else {
      toast.warning("Please Select Any Input");
    }
  };
  const showAccuratePhoneNumber = (phoneNumber) => {
    if (phoneNumber.startsWith("+1") || phoneNumber.startsWith("1")) {
      phoneNumber = phoneNumber.substring(2);
      return phoneNumber;
    } else {
      return phoneNumber;
    }
  };
  const handleStatusClick = (id, status) => {
    setStatusChangeInfo({ id, status });
    setShowStatusModal(true);
  };

  const handleConfirmStatusChange = () => {
    handleStatus(statusChangeInfo.id, statusChangeInfo.status);
    setShowStatusModal(false);
  };
  return (
    <div className="p-3 p-lg-4 p-xl-5">
      <h1 className="font20 mb-4 text-dark">Users Management</h1>
      <Container fluid className="bg-white p-3 rounded shadow">
        <Row className="align-items-center">
          
            <Col
              md={3}
              className="remove_width"
            >
              <div>
              <div  style={{ maxWidth: "214px" }}>
                <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                  <Form.Control
                    type="text"
                    className="border-0 searchInput"
                    placeholder="Search"
                    onChange={({ currentTarget: input }) =>
                      handleSearch(input.value)
                    }
                  />
                  <Button
                    variant="primary"
                    title="Click here to search"
                    className="searchBtn newCustomSearch d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: 'var(--newMainBlue)',
                      borderColor: 'var(--newMainBlue)',
                  }}
                  >
                    <img
                      src="/assets/images/search.png"
                      className="img-fluid"
                      alt=""
                    />
                  </Button>
                </div>
              </div>
              </div>
            </Col>
            <Col 
            md={9}
            className="button_div d-md-flex align-items-center justify-content-end">
              <div className="mt-2 mt-lg-1 pe-1">
                {/* <div className="d-flex"> */}
                <Button
                  variant="primary"
                  type="button"
                  className="fullRounded newCustomButton px-4 py-2 fw-bold font12"
                  style={{
                    backgroundColor: 'var(--newOrange)',
                    borderColor: 'var(--newOrange)',
                }}
                  onClick={() => {
                    navigate("/admin/users-management/add");
                  }}
                >
                  <span className="me-2">
                    {/* <img
                      src="/assets/images/plus.svg"
                      className="img-fluid"
                      alt=""
                    /> */}
                     <BiPlusMedical />
                  </span>
                  <span>Add User</span>
                </Button>
                {/* </div> */}
              </div>
          

            </Col>
         
          <Col md={12} className="mt-4">
            <div className="table-responsive">
              <table
                className="table fontGilroy couponTable"
                style={{ verticalAlign: "middle" }}
              >
                <thead className="font10" style={{backgroundColor: 'var(--newLightBlue)'}}>
                  <tr>
                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Name</span>
                        {order === "ASC" ? (
                          <FaSortDown onClick={() => sorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => sorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Email</span>
                        {emailorder === "ASC" ? (
                          <FaSortDown onClick={() => emailsorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => emailsorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center"style={{width:"98px"}} >
                        <span className="me-1">Type of User</span>
                        {userorder === "ASC" ? (
                          <FaSortDown onClick={() => typeusersorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => typeusersorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{width:"112px"}}>
                        <span className="me-1">Business Partner</span>
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{width: "122px"}}>
                        <span className="me-1">Phone</span>
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Status</span>
                      </div>
                    </th>

                    <th className="text-end">

                      <div className="d-flex align-items-center"style={{width: "100px"}} >
                        <span className="me-1">Updated At</span>
                        {updateorder === "ASC" ? (
                          <FaSortDown onClick={() => updatesorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => updatesorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="font10 fw-medium">
                  {isLoading === true ? (
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="8">
                        <Bars
                        height="80"
                        width="100%"
                        margin='auto'
                        color="var(--newMainBlue)"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        />
                        </td>
                      </tr>
                      ) : (
                    <>
                      {noData ? (
                        <tr>
                          <td colSpan="8" className="text-center">
                            <h1 className="font16 text-dark">No Data</h1>
                          </td>
                        </tr>
                      ) : (
                        customerData.map((cust) => (
                          <tr key={cust._id}>
                            <td className="font14">
                              <span>
                                <Link
                                  className="link"
                                  to={`/admin/users-management/${cust._id}/edit`}
                                >
                                  {cust.name}
                                </Link>
                              </span>
                            </td>
                            <td className="font14">
                              <Link
                                className="link"
                                to={`/admin/users-management/${cust._id}/edit`}
                              >
                                {cust.email} {cust.id}
                              </Link>{" "}
                            </td>
                            <td className="font14">{cust.role}</td>
                            <td className="font14">{cust.business_partner}</td>
                            <td className="font14">
                              {cust.code} {showAccuratePhoneNumber(cust.phone)}
                            </td>
                            <td className="font14">
                            <Form.Check
                                type="switch"
                                label={cust?.status ? "Active" : "Inactive"}
                                className="switchLabel"
                                checked={cust?.status}
                                onChange={() => handleStatusClick(cust._id, cust?.status)}
                              />
                            </td>


                        <td className="font14">{cust.updatedAt.slice(0, 16).replace('T', '      ')}</td>


                        <td>
                          {role === "Admin" ? (
                            <DropdownButton
                              variant="white"
                              className="p-0  tableActionDropDown text-center"
                              title={
                                <div className="actionBtn">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              }
                              drop="start"
                            >
                              <Dropdown.Item
                                className="font14 fw-medium"
                                onClick={() => {
                                  navigate(
                                    `/admin/users-management/${cust._id}/edit`
                                  );
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item className="font14 fw-medium">
                                Permissions
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="font14 fw-medium"
                                onClick={() => {
                                  handleDeleteUser(cust._id);
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                            </DropdownButton>
                          ) : (
                            <DropdownButton
                              variant="white"
                              className="p-0  tableActionDropDown text-end"
                              title={
                                <div className="actionBtn">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              }
                              drop="start"
                            >
                              <Dropdown.Item className="font14 fw-medium">
                                Permissions
                              </Dropdown.Item>
                            </DropdownButton>
                          )}
                        </td>
                      </tr>
                    ))  
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Col>

          {/* <Col md={3} className="mt-3 mb-4">
            <label htmlFor="rowsPerPage">Rows Per Page:</label>
            <select
              id="rowsPerPage"
              className="form-control"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              style={{ maxWidth: '102px' }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </Col> */}

          {/* <Col xs lg="2"> */}
          {/* </Col> */}
          {
            numberOfPages === 0 ? "" :
              <Col className="mt-5 mb-4">
              {
                <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                  {pageNumber === 0 ? (
                    ""
                  ) : (

                    <li onClick={prevPage}>Previous </li>

                  )}


                  {items}


                  {numberOfPages - 1 === pageNumber ? (
                    ""
                    ) : (
                      <li onClick={nextPage}>Next</li>
                      )}
                      <li className="font14"><input  style={{width:"25px",border:'1px solid #2665F2',borderRadius:'4px',textAlign:'center'}} value={showPage?showPage:''} type="text" name="number" id="" onChange={(e)=> {
                          // if (isValidPages(e.target.value)) { 
                            pagenumberSearch(e.target.value)
                          // }
                            // else{
                            //   pagenumberSearch('')
                            // }
                        }}  ></input> <span style={{color:'#2665F2'}}>/</span> {numberOfPages}</li>
                </ul>
              }
            </Col>
            }
        </Row>
      </Container>


      <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="md" centered>
        <Modal.Body>
        <Col>
        <img className="modal-img"
            src="/assets/images/exclamation.svg"
            // className="img-fluid"
            alt=""
        />
        <br />
        <br />
        <h3 className="modal-text">Are you sure, you want to Delete?</h3>
        <br />
        <Row className="modal-row">
            <Col md ={1}></Col>
            <Col md={4}>
            <Button
              className="btn btn-success success-btn"
              onClick={() => {
                handleConfirmDelete(); 
              }}
            >
                    
                    <span><b>Confirm</b></span>
                </Button>
            </Col>
            <Col className="middle-col" md ={2}></Col>
            <Col md={4}>
            <Button className="btn btn-danger danger-btn" onClick={handleCloseModal}>
                    <span><b>Cancel</b></span>
                </Button>
             </Col>
            <Col md ={1}></Col>
        </Row>
        <br />
        </Col>
        </Modal.Body>
  </Modal> 
  <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)} backdrop="static" keyboard={false} size="md" centered>
        <Modal.Body>
          <Col>
            <img className="modal-img"
                src="/assets/images/exclamation.svg"
                alt=""
            />
            <br />
            <br />
            <h3 className="modal-text">Are you sure you want to proceed?</h3>
            <br />
            <Row className="modal-row">
              <Col md={1}></Col>
              <Col md={4}>
                <Button className="btn btn-success success-btn" onClick={handleConfirmStatusChange}>
                  <span><b>Confirm</b></span>
                </Button>
              </Col>
              <Col className="middle-col" md={2}></Col>
              <Col md={4}>
                <Button className="btn btn-danger danger-btn" onClick={() => setShowStatusModal(false)}>
                  <span><b>Cancel</b></span>
                </Button>
              </Col>
              <Col md={1}></Col>
            </Row>
            <br />
          </Col>
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default UsersManagement;
