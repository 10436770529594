import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

function MyVerticallyCenteredModal(props) {
  const nameDomain = (window.location.host.split('.'));
  const [isShown, setIsShown] = useState(false);
  const [isHiden, setIsHiden] = useState(true);
  const [brand, setBrand] = useState("Ncentiva");
  const [primaryColor, setPrimaryColor] = useState("#FF7020");
  const [secondaryColor, setSecondaryColor] = useState("#3D05B6");
  const [image, setImage] = useState("");
  const [btnColor, setBtnColor] = useState('');
  const [btnText, setBtnText] = useState('');
  const customer = JSON.parse(window.localStorage.getItem('customer'));
  const [isLoading, setIsLoading] = useState(true);
  const cmsData = useSelector((state) => state.cmsReducer);
  const defaultNcentiva = cmsData?.data?.cms?.default_ncentiva;
  var name;

  if (customer) {
    name = customer.customer.first_name;
  } else {
    name = null;

  }
  const handleSession = () => {
    sessionStorage.setItem("modalshowOne", "Only one time");
    props.onHide()
  }
  const handleClick = event => {
    setIsShown(true);
    setIsHiden(false)
  };


  useEffect(() => {
    const data = cmsData?.data?.cms;
    if (data?.default_ncentiva === false) {
      if (data?.footer_logos.length > 0) {
        setPrimaryColor(data?.primary_color);
        setSecondaryColor(data?.secondary_color);
        setBtnColor(data?.btn_color);
        setBtnText(data?.btn_text);
        setImage(data?.brand_logo);

        setBrand(data?.brand_name);
        setIsLoading(false);
      }
    } else if (data?.default_ncentiva === true) {
      setImage("https://ncentiva-development.s3.eu-west-1.amazonaws.com/ncentiva_new_logo.png");
      setBtnColor('#FFAD31');
      setBtnText('white');
      setPrimaryColor("#2665F2");
      setSecondaryColor("#65B5FF");
    }
  }, [cmsData])

  //   useEffect(()=>{
  //     // make changes over here for the api calls 
  //     if(nameDomain[0] !== "dev" || "staging" || "payouts" || "uat"){
  //     axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
  //     .then((res)=>{
  //       if(res.data?.cms?.default_ncentiva === false){
  //         setPrimaryColor(res.data?.cms?.primary_color);
  //         setSecondaryColor(res.data?.cms?.secondary_color);
  //         setBtnColor(res.data?.cms?.btn_color);
  //         setBtnText(res.data?.cms?.btn_text);
  //         setImage(res.data?.cms?.brand_logo);
  //         setBrand(res.data?.cms?.brand_name);
  //       }else{
  //         setImage("https://dev.ncentiva.com/assets/images/logo.png");
  //         setBtnColor('#3D05B6');
  //         setBtnText('white');
  //         setPrimaryColor("#FF7020");
  //         setSecondaryColor("#F5C400");
  //       }       
  //      setIsLoading(false);
  //     }).catch((err)=>{
  //         console.log(err);
  //     })
  // }else{
  //   setIsLoading(false);
  // }
  // },[]);

  const LogoStyle = {
    width: "170px",
    height: "110px",
    objectFit: "scale-down",
  }

  return (
    <>
      {cmsData?.loading ? " " :
        <Modal
          {...props}
          //   size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          className="onboard-modal"
        >
          <Modal.Header closeButton className='border-0'></Modal.Header>
          <Modal.Body className="py-5 px-5 text-center">
            {isHiden && (
              <div className="welcome-area">
                <div >
                  <img src={image} style={LogoStyle} alt="Logo" />
                </div>
                {
                  name == null ?
                    <h3 className="welcome-note mb-4">Welcome</h3>
                    :
                    <h3 className="welcome-note mb-4">Welcome, {name}!</h3>
                }

                <p className="welcome-para mb-4">
                  At <span style={{ color: `${primaryColor}` }}>{brand}</span> we are all about value. Browse through our selection of <b>300+ gift cards</b>, including <b>Mastercard & Visa</b>.
                  Additionally, receive a <b>bonus value</b> of up to <b>10%.</b>{" "}
                </p>
                <div className="welcome-btns">
                  <button type="button" onClick={handleSession} className="text-dark border-0 bg-transparent">
                    Skip this
                  </button>
                  {/* <WelcomeModal/> */}
                  <button type="button" style={defaultNcentiva
                    ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                    : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                  } className="btn btn-main ms-2 welcome-step1">
                    <Link to="/onboard" replace style={{ color: btnText, border: `1px solid transparent` }}>
                      Explore navigating the platform <FaAngleRight className="ms-1" />
                    </Link>

                  </button>
                </div>
              </div>
            )}

          </Modal.Body>
        </Modal>}
    </>
  );
}

function WelcomeModal(props) {
  const [modalShow, setModalShow] = React.useState(true);

  return (
    <>
      {props.isLoading ? "" : <>
        {/* <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button> */}

        <MyVerticallyCenteredModal
          // cmsData={props.cmsData}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </>}
    </>
  );
}

export default WelcomeModal