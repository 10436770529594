import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import axios from "axios";
import { useEffect } from "react";
import logo from "../assets/images/Ncentiva.png";
import "jspdf-autotable";
import "../styles/transaction.css"
// import "../styles/transaction.css"
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { FcDownload, FcUpload } from 'react-icons/fc';
import { Bars } from 'react-loader-spinner';
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  DropdownButton,
  Dropdown,
  Modal
} from "react-bootstrap";
import { toast } from "react-toastify";
import { DateRangePicker} from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Select from "react-select";
import "../styles/monetray.scss"
import { number } from "yup";
import { getNewToken } from "../config/cookie.config";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
const AccountHistory = (props) => {
  let navigate = useNavigate();
  var token;
  var userrole;
  var usersid;
  const user = JSON.parse(window.localStorage.getItem("client"));
  let id;
  const businessPartner = JSON.parse( window.localStorage.getItem('business-client'));
  const params = useParams();
  if(props?.admin === true){
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      userrole = user.user.role;
      usersid = user.user._id
    } else {
      userrole = null;
      usersid = null;
      navigate("/admin");
    }
    id=params.id;
  }else{
    id=businessPartner?.user?._id;
  }


  const [search, setSearch] = useState("");
  const [role] = useState(userrole);
  const [userid] = useState(usersid);
  const [customerData, setCustomerData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [customerMaxValue, setCustomerMaxValue] = useState([]);
  const [customerUpdateAtMaxValue, setCustomerUpdateAtMaxValue] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState("ASC");
  const [emailorder, setEmailorder] = useState("ASC");
  const [valueorder, setValueorder] = useState("ASC");
  const [updateorder, setUpdateorder] = useState("ASC");
  const [noData,setNoData] = useState(false);
  const [sort, setSort] = useState("");
  const [sendMail, setSendMail] = useState([]);
  const [totalFund, setTotalFund] = useState([]);
  const [usedFund, setUsedFund] = useState([]);
  const [allproducts, setAllProducts] = useState([]);
  const [startDate,setStartDate]= useState('');
  const [endDate,setEndDate]= useState('');
  const [show, setShow] = useState(false);
  const [showPage,setShowPage] = useState(1);
  const [monetaryData,setMonetaryData] = useState();
  const [custFilter, setCustFilter] = useState("");
  const [apiResponse, setApiResponse] = useState(false);
  const [invoiceData,setInvoiceData] =  useState([])
  const [invoiceLink, setInvoiceLink ] = useState();
  const [lastBusinessName, setLastBusinessName ] = useState();
  const redirectToLogin = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      navigate("/admin");
    }
  };
 
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "var(--darkblue)" : "var(--color)",
      fontSize: 14,
      padding: 10,
      marginBottom: 0,
      backgroundColor: state.isSelected ? "rgba(215, 223, 233, 0.34);" : "#fff",
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "auto",
      display: "flex",
      border: "1px solid #ced4da",
      borderRadius: ".25rem",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = "var(--color)";
      const fontSize = "14px";
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition, color, fontSize };
    },
  };

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRowsPerPageChange = (event) => {
    const selectedRowsPerPage = event.target.value;
    setRowsPerPage(selectedRowsPerPage);
    getTransactionData(pageNumber, startDate, endDate,custFilter, selectedRowsPerPage);
  };
  
  const [getCustomer, setCustomer] = useState([]);

  
  // name
  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");

    } else {
      setSort("ascName");

      setOrder("ASC");

    }
  };

  const emailsorting = (emailorder) => {
    if (emailorder === "DSC") {
      setSort("dscEmail");

      setEmailorder("DSC");
    } else {
      setSort("ascEmail");

      setEmailorder("ASC");
    }
  };

  const valuesorting = (valueorder) => {
    if (valueorder === "DSC") {
      setSort("dscName");

      setValueorder("DSC");
    } else {
      setSort("ascName");

      setValueorder("ASC");
    }
  };

  const updatesorting = (updateorder) => {
    if (updateorder === "DSC") {
      setSort("dscCreate");

      setUpdateorder("DSC");
    } else {
      setSort("ascCreate");

      setUpdateorder("ASC");
    }
  };

  const [businessPartner_data,setBusinesspartner_data]= useState('');


  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);
  const getTransactionData = (pageNumber,start,end, custFilter, pageSize) => {
    if (pageSize === undefined) {
      pageSize = 10
    }
    setIsLoading(true)
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getbusinessmonetarytransaction/${id}?page=${pageNumber}&pageSize=${pageSize}&search=${search}&sort=${sort}&startDate=${start}&endDate=${end}&custFilter=${custFilter}&loginUserId=${userIds}`, {
      headers: {
        'Authorization': `Bearer ${getNewToken()}`
      }
    })
      .then((response) => {
          setApiResponse(true);
        setCustomer(response.data.customerFilter);
        setInvoiceData(response.data.invoicedata);
        if(response.data.getFund === "NaN" || "0"){
          setTotalFund(0.00);
        }else{
          setTotalFund(response.data.getFund);
        }
        if(response.data.usedFund === "NaN" || "0"){
          setUsedFund(0.00);
        }else{
          setUsedFund(response.data.usedFund);
        }
        setMonetaryData(response.data);
        setBusinesspartner_data(response?.data?.partnerdata);
        setLastBusinessName(response?.data?.businessName)
        const alltransaction = response.data.monetary;
        const totalPages = response.data.totalPages;
        
        setCustomerData(response?.data?.monetary);
        setNumberOfPages(totalPages);
        if(alltransaction.length===0){
          setNoData(true);
        }
    
        setIsLoading(false);
      })
      .catch((error) =>
        console.log(error)
      )
  };
  //for PDF

  const handleChange = (selectedOption) => {
    setPageNumber(0);
    setShowPage(1);
    if (selectedOption.value === "All Customers") {
      setCustFilter("");
      // getTransactionData(pageNumber,startDate,endDate,custFilter);
       setIsLoading(false);
    } else {
      // setSearch(selectedOption.value);
      const promise = new Promise((resolve, reject) => {
        // Perform your asynchronous operation here
        // For example, fetch data from an API
        setCustFilter(selectedOption.value);
      });
      promise.then(data => {
        getTransactionData(pageNumber,startDate,endDate,custFilter, rowsPerPage);
        setIsLoading(false);
      }).catch(error => {
        console.error(error);
        setIsLoading(false);
      });
    }
  };
  
  
  let obj = {};
if (invoiceData) {
  const invoice = invoiceData[0]; 
  obj = {
    buisnessId: invoice?.business_partner_id,
    business: invoice?.business_partner_name,
    // balance: invoice.total
  };
}
  
  

  
const downloadInvoice = () =>{
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "portrait"; // portrait or landscape

  const doc = new jsPDF(orientation, unit, size);
  // doc.addImage(logo, "png", 30, 40, 150, 30);
              var lMargin2 = 330;
              var rMargin2 = 5;
              var pdfInMM2 = 690;

              var lMargin3 = 330;
              var rMargin3 = 5;
              var pdfInMM3 = 565;

              var lMargin4 = 330;
              var rMargin4 = 5;
              var pdfInMM4 = 690;
              
              
  var buisnessPartner = `Business Partner ID:${obj.buisnessId}`
  var buisnessName = `Buisness Partner Name:${obj.business}`
  var balance = `Total Balance in Account :$ ${Number(monetaryData?.totalBonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  doc.setFontSize(12)
  var lines2 = doc.splitTextToSize(buisnessPartner, (pdfInMM2 - lMargin2 - rMargin2));
  doc.text(lMargin2, 100, lines2);
  doc.setFontSize(12)
  var lines3 = doc.splitTextToSize(buisnessName, (pdfInMM3 - lMargin3 - rMargin3));
  doc.text(lMargin3, 120, lines3);
  
  doc.setFontSize(12)
  var lines4 = doc.splitTextToSize(balance, (pdfInMM4 - lMargin4 - rMargin4));
  doc.text(lMargin4, 150, lines4);
  doc.setFontSize(15);

  function addImageToEveryPage() {
    var totalPages = doc.internal.getNumberOfPages();
  
    for (var i = 1; i <= totalPages; i++) {
      
      doc.setPage(i);
  
      
      doc.addImage(logo, 'PNG', 30, 40, 150, 50);
    }
  }
   var pdfstyles ={
    textColor: [12, 12, 12],
    fontSize: 10,
    cellPadding: { top: 10, right: 10, bottom: 10, left: 10 },
   }
  const headers = [
    ["Date", "Transaction Type","Amount"]];

    let bodyInfo=[];
    if(invoiceData){
    invoiceData?.map((bal,i)=>{
       var date = formatDate(bal.createdAt).slice(0, 16).replace("T", "      ")
       var transactionType = bal.transaction_type
       var amount =`$ ${Number(bal.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` 
      bodyInfo.push([date,transactionType,amount])
    })
  }
    let content = {
          startY: 180,
          head: headers,
          headStyles: { fillColor: "#fff3ed" },
          styles: pdfstyles,
          body:bodyInfo,
          addPageContent: addImageToEveryPage,
          margin: { top: 100, right: 30, bottom: 10, left: 30 }
          
    }
    doc.autoTable(content);
  doc.save(`${obj.business}.pdf`);
}

const [csvData, setCsvData] = useState(null);
useEffect(() => {
  const newData = invoiceData?.map(item => ({
    business_partner_name: item.business_partner_name,
    business_partner_id: item.business_partner_id,
    payment_type: item.payment_type,
    transaction_type: item.transaction_type,
    customer_name: item?.customer_name,
    customer_email: item?.customer_email,
    deduct: item.deduct,
    amount: item.amount,
    total: item.total,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
  }));

  setCsvData(newData);
}, [invoiceData]);

const downloadData = () => {
  if (csvData && csvData.length > 0) {
    const csvContent = `${Object.keys(csvData[0]).join(',')}\n${csvData
      .map(item => Object.values(item).join(','))
      .join('\n')}`;

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.target = '_blank';
    link.download = `${businessPartner_data?.business_partner_name || 'exported_data.csv'}`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number) }}>
        {number + 1}
      </li>,
    );
  }


  // const pagenumberSearch = (e)=>{
  //   var pages =parseInt(e) ;
  //   var page= pages -1;
  //   if(pages<=numberOfPages){
  //     setPageNumber(page);
  //   }else if(pages>numberOfPages){
  //     setPageNumber(0);
  //   }else{
  //     setPageNumber(0);
  //   }
  //   }
  const pagenumberSearch = (e)=>{
    var pages =parseInt(e) ;
    var page= pages -1;
    if(pages<1){
      setPageNumber(0);
      setShowPage(1);
    }else if(pages>numberOfPages){
      setPageNumber(numberOfPages-1);
      setShowPage(numberOfPages);
    }else if(pages>=1 && pages<=numberOfPages){
      setPageNumber(page);
      setShowPage(pages);
    }
    else{
      setPageNumber(0);
      setShowPage(pages);
    }
    }


    const nextPage = () => {
      if (pageNumber < numberOfPages) {
        setPageNumber(pageNumber + 1);
        setShowPage(pageNumber+2);
      }
    }
  
    const prevPage = () => {
      if (pageNumber > 0) {
        setPageNumber(pageNumber - 1);
        setShowPage(pageNumber);
      }
    }

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
    setPageNumber(0);
  };

  useEffect(() => {
    setIsLoading(true);
    getTransactionData(pageNumber,startDate,endDate,custFilter, rowsPerPage);
    redirectToLogin();
    setIsLoading(false);
  }, [pageNumber, search, sort,custFilter]);

  const downloadHistory = (idd, invoice) => {

    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/download-invoice/${id}/${idd}/${invoice}`)
      .then((res)=>{  
        if (res.data.status == "success") {
          toast.success(res.data.message);
          const url =res.data.data
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `FileName.pdf`,
          );
      
          document.body.appendChild(link);
      
          link.click();
      
          link.parentNode.removeChild(link);
        } else {
          toast.warning(res.data.message);
        }
      })
      .catch((err)=>{
        console.log(`Error ${err}`);
      })

  }


  function uploadPd (file, ids) {
    const backendApiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/uploadinvoice/${id}/${ids}`;
    const formData = new FormData();
    formData.append('pdfFile', file);
    axios
      .post(backendApiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if(response.data.status == "success") {
          toast.success(response.data.message);
          setInvoiceLink(response.data.url)
          getTransactionData(pageNumber,startDate,endDate, custFilter, rowsPerPage)
        }
      })
      .catch((err)=>{
        console.log(`err :${err}`);
      })
  }


  const handleFileChange = (event, ids) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "text/pdf" || file.type === "application/pdf") {
        uploadPd(file, ids)
      } else {
        toast.error("Please Upload A PDF File");
      }
    }
  };


  const userID = localStorage.getItem('client');
  const users = JSON.parse(userID);
  const userIds = users?.user._id;


  const formatDate = (date) => {
    // Convert the date to EST time zone
    const estDate = new Date(date);
    const estOptions = {
      timeZone: "America/New_York",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    };
  
    // Format the date as per the EST time zone, without seconds
    return estDate.toLocaleString("en-US", estOptions).replace(",", "");
  };


  const userStatus = (userIds) => {
      axios
          .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${userIds}`)
          .then((response)=>{
              let users = response.data.user?.status
              if(users === false){
                  window.localStorage.removeItem('client');
                  navigate('/admin');
                  toast.warning("Your account has been deactivated");
          }
          })
          .catch((error) => console.error(`Error: ${error}`));
      }

  useEffect(()=>{
      userStatus(userIds);
  } ,[userIds])


  const handleSelect = (date) => {
    let filtered = allproducts.filter((product) => {
      let productDate = new Date(product["createdAt"]);
      return(
        productDate >= date.selection.startDate &&
        productDate <= date.selection.endDate
        );
      })
      setStartDate(date.selection.startDate);
      setEndDate(date.selection.endDate);
      setCustomerData(filtered);
      getTransactionData(pageNumber,date?.selection?.startDate,date?.selection?.endDate, custFilter, rowsPerPage);
      setNumberOfPages(0);
      setPageNumber(0);
      setShowPage(1);
    }

    
    const selectionRange = {
      startDate: startDate,
      endDate: endDate,
      key: 'selection',
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);   



  return (
    <>
      <div className="p-3 p-lg-5">
      {props?.admin ?<div className="d-flex mb-4 align-items-center">
          <Link
            to="/admin/businesspartners"
            className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
            variant="white"
          >
            <span className="me-2 fw-semibold">
              {/* <img
                src="/assets/images/back-org.png"
                className="img-fluid"
                alt=""
              /> */}
              <MdOutlineKeyboardBackspace  className="fs-4" style={{color: 'var(--newOrange)'}}/>
            </span>
            <span>Back</span>
          </Link>
      <h1 className="font20 text-dark">Funding History</h1>
        </div>: <div className="d-flex mb-4 align-items-center">
    <h1 className="font20 text-dark">Business partner Funding History  </h1>
      </div>}
        <Container fluid className="bg-white p-4 rounded shadow">
          <Row className="row-break1 ">
            <Col lg={4}>
              <div className="search-btn">
                <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                  <Form.Control
                    type="text"
                    className="border-0 searchInput"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                  <Button
                    variant="primary"
                    title="Click here to search"
                    className="searchBtn d-flex newCustomSearch justify-content-center align-items-center"
                    style={{
                      backgroundColor: 'var(--newMainBlue)',
                      borderColor: 'var(--newMainBlue)',
                  }}
                  >
                    <img
                      src="/assets/images/search.png"
                      className="img-fluid"
                      alt=""
                    />
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={4}>
            <div className="custom-select select-item">
                      <Select
                        styles={customStyles}
                        onChange={handleChange}
                        options={getCustomer}
                        defaultValue={{
                          value: "Filter by:All",
                          label: "Filter by:All",
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
            </Col>
            <Col lg={2}>
              <div className="date-range-modal d-flex">
                <Button variant="primary"
                  type="button"
                  className="fullRounded newCustomButton fw-bold font14 dateRangeBtn"
                  style={{
                    backgroundColor: 'var(--newOrange)',
                    borderColor: 'var(--newOrange)',
                  }}
                  onClick={handleShow}>
                  <span>Search With Date</span>
                </Button>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    size="lg"
                    centered
                    >
                  <Modal.Header closeButton>
                  <Modal.Title>Date Range</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <DateRangePicker ranges={[selectionRange]} onChange={handleSelect}
                  rangeColors={['var(--newMainBlue)']}
                  color="var(--newMainBlue)"
                  />
                  </Modal.Body>
                  </Modal>
              </div>

              
            </Col>
            <Col lg={2}>
              <div className="date-range-modal d-flex">
                <Button
                  variant="primary"
                  type="button"
                  className="fullRounded newCustomButton fw-bold font14 dateRangeBtn"
                  style={{
                    backgroundColor: 'var(--newOrange)',
                    borderColor: 'var(--newOrange)',
                  }}
                  onClick={downloadData}
                  disabled={!csvData || csvData.length === 0}
                >
                  <span>Export Data</span>
                </Button>
              </div>
            </Col>
            {/* <Col lg={2} className="d-flex ">
            <div className="download-invoice px-2" >
                <Button variant="primary"
                  type="button"
                  className="fullRounded fw-bold font14 invoicedownload"
                  onClick={downloadInvoice}>
                  <span style={{whiteSpace:'nowrap',padding:'0 2px'}}>Download Invoice</span>
                </Button>
              </div>
            </Col> */}
           
          </Row>
          <Row className="align-items-center">
          <Container>
            <Row>
              <Col xl={7} xxl={8} lg={7} className="mt-1" >
                <div className="text-dark font12">
                    <div className="d-flex align-items-center w-100 ms-0"> {
                      apiResponse ? 
                      <h3 className="font16 text-dark">Business Partner ID : {businessPartner_data?.business_partner_id}</h3>
                      : 
                      ""
                    }
                    </div>
                    <div className="d-flex align-items-center w-100 ms-0">
                      {
                        apiResponse ? 
                      <h3 className="font16 text-dark">Business Partner Name : {lastBusinessName}</h3>
                      : ""
                      }
                    </div>
                    <div className="d-flex align-items-center w-100 ms-0">
                      {
                        apiResponse ? 
                      <h3 className="font16 text-dark">Total Balance in Account :  ${Number(monetaryData?.totalBonus).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</h3>
                      : ""
                      }
                    </div>
                </div>
              </Col>
              <Col xl={5} xxl={4} lg={5} className="mt-1" >
              </Col>

            </Row>
          </Container>
            <Col md={12} className="mt-4">
              <div className="table-responsive">
                <table className="table " style={{ verticalAlign: "middle" }}>
                  <thead className="bgLightRed font14">
                    <tr className="text-center">
                    
                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 "> Date</span>{" "}
                          {updateorder === "ASC" ? (
                            <FaSortDown onClick={() => updatesorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => updatesorting("ASC")} />
                          )}
                        </div>{" "}
                      </th>
                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Transaction Type</span>
                        </div>
                      </th>
                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Business Partner Name</span>
                        </div>
                      </th>
                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Customer Name</span>
                        </div>
                      </th>
                      <th className="py-3">
                        <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                          <span className="me-1">Customer Email</span>
                        </div>
                      </th>
                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 ">Amount</span>{" "}
                        </div>
                      </th>
                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 ">Payment Status</span>{" "}
                        </div>
                      </th>
                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 ">Invoice Name</span>{" "}
                        </div>
                      </th>
                      <th className="py-3">
                        {" "}
                        <div className="d-flex align-items-center justify-content-center ">
                          {" "}
                          <span className="me-1 ">Action</span>{" "}
                        </div>
                      </th>
                      

                      
                    </tr>
                  </thead>
                  <tbody className="font14 fw-medium text-center">
               
                    {isLoading === true ? (
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="8">
                        <Bars
                        height="80"
                        width="100%"
                        margin='auto'
                        color="var(--newMainBlue)"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        />
                        </td>
                      </tr>
                      ) : (
                        customerData?.map((cust) => (
                          <tr
                            key={cust._id}
                          >
                             <td className="py-3 font14 fw-medium ">
                              {/* {cust.createdAt.slice(0, 16).replace("T", "      ")} */}
                              {formatDate(cust.createdAt).slice(0, 16).replace("T", "      ")}
                            </td>
                                                               
                            <td className="py-3 font14 fw-medium">
                              {cust.transaction_type}
                            </td>
                            <td className="py-3 font14 fw-medium">
                              {cust.business_partner_name}
                            </td>
                            <td className="py-3 font14 fw-medium">
                              {cust.customer_name}
                            </td>
                            <td className="py-3 font14 fw-medium">
                              {cust?.customer_email}
                            </td>
                            <td className="py-3 font14 fw-medium ">
                              {/* {cust.transaction_type === "Funds Updated" || "Created"? ( */}
                                {cust.deduct ? (
                                  <span style={{ color: "red" }}>
                                    -
                                  </span>
                                ) : (
                                  <span style={{ color: "green" }}>
                                    +
                                  </span>
                                )}
                              {/* ) : ""} */}

                             ${Number(cust.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </td>
                            

                            <td className="py-3 font14 fw-medium ">{cust.payment_status}</td>
                            <td className="py-3 font14 fw-medium ">{cust.invoice}</td>

                            <td className="py-3 font14 fw-medium" >
                              <span data-bs-toggle="tooltip" data-bs-placement="top" title="Upload" style={{ fontSize: '18px', cursor: 'pointer' }}>
                                <label htmlFor={`pdfInput_${cust._id}`}>
                                  <FcUpload />
                                  <input
                                    type="file"
                                    id={`pdfInput_${cust._id}`}
                                    accept=".pdf"
                                    style={{ display: 'none' }}
                                    onChange={(event) => handleFileChange(event, cust._id)}
                                  />
                                </label>
                              </span>
                              {cust.invoice ?
                              <span className="ms-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Download" style={{ fontSize: '18px' }}
                                onClick={() => downloadHistory(cust._id, cust.invoice)}
                              >
                                <FcDownload />
                              </span>
                              :""}
                            </td>
                            
                            
                          </tr>
                        )))}
                     {isLoading ? "" : 
                     <>
                     {customerData.length  === 0 ? <tr >
                        <td colSpan="8">
                        <h1 className="font16 text-dark">No Transactions</h1>
                        </td>
                      </tr>:""}
                     </>
                     }
                    
                  </tbody>
                </table>
              </div>
            </Col>

            <Col md={3} className="mt-3 mb-4">
             <label htmlFor="rowsPerPage">Rows Per Page:</label>
              <div className="position-relative rows_per_page">
                <select
                  id="rowsPerPage"
                  
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  style={{ maxWidth: '102px' }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </Col>
            {
            numberOfPages === 0 ? "" :
            <Col md={6} className="mt-5 mb-4 text-center" >
              {
                <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                  {pageNumber === 0 ? (
                    ""
                  ) : (

                    <li onClick={prevPage}>Previous </li>

                  )}


                  {items}


                  {numberOfPages - 1 === pageNumber ? (
                    ""
                    ) : (
                      <li onClick={nextPage}>Next</li>
                      )}
                      <li className="font14"><input  style={{width:"25px",border:'1px solid #4C00EF',borderRadius:'4px',textAlign:'center'}} value={showPage?showPage:''} type="text" name="number" id="" onChange={(e)=> {
                            pagenumberSearch(e.target.value)
                        }}  ></input> <span style={{color:'#4C00EF'}}>/</span> {numberOfPages}</li>
                </ul>
              }
            </Col>
            }
            {/* </>
} */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AccountHistory;
