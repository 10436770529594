import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import {useDispatch} from 'react-redux'
import { ClearhandleCategories } from '../../Redux/SendCategories/action';

const BtnArea = props => {
 const dispatch = useDispatch();
  const handleClick=()=>{
     dispatch(ClearhandleCategories());
  }
  return (
    <div>
    <div className="text-center mt-5">
        <Link onClick={()=>handleClick()} to={props.giftcards} className="btn btn-main view-all" style={{backgroundColor:props?.btnColor,color:props?.btnText,border:`1px solid ${props?.btnColor}`}}>
            {props.title}
        </Link>
    </div>
    </div>
  )
}

BtnArea.propTypes = {
    title: PropTypes.string
}
BtnArea.defaultProps = {
    title: 'View all'
};
export default BtnArea
