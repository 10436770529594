import React, { useEffect } from 'react'
import { FaMinus, FaPlus, FaTimes, FaChevronUp, FaChevronLeft, FaTrash, FaChevronRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
// import CheckoutBar from '../components/CheckoutBar'
import ProductInput from '../components/elements/ProductInput'
import InnerBanner from '../components/InnerBanner'
import NavbarComponent from '../components/Navbar'
import ProductCategories from '../components/ProductCategories'
import ProductFilterArea from '../components/ProductFilterArea'
import RedeemProgress from "../components/elements/RedeemProgress"
// import ProductListing from '../components/ProductListing'
import { useState } from 'react'
import axios from 'axios'
import { Bars } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import PromotionalSliderArea from './PromotionalSliderArea'
import { Offcanvas } from 'react-bootstrap'
import Select from 'react-select'
import { VscThreeBars } from 'react-icons/vsc';
import './onboard.css'



const OnBoard = () => {
    const [primaryColor, setPrimaryColor] = useState("");
    const nameDomain = (window.location.host.split('.'));
    const [btnColor, setBtnColor] = useState('');
    const [btnText, setBtnText] = useState('');
    const [secondaryColor, setSecondaryColor] = useState('');
    const [loading, setLoading] = useState(false);
    const [giftcards, setGiftcards] = useState("giftcards");
  const cartData = useSelector((state) => state.cartReducer);
  const [offcanvasOpen, setOffcanvasOpen]=useState(false)
  const togggleOpen=()=>{
    setOffcanvasOpen(!offcanvasOpen);
  }
  const togggleClose=()=>{
    setOffcanvasOpen(!offcanvasOpen);
    setIsShown(false);
    setIsHiden(false);
    setIsHidden(true);
    setHighlight(false);
    setHighlightAgin(false);
  }
  const options = [
    { value: "All Brands", label: "All Brands" },
    { value: "Most value", label: "Most value" },
    { value: "Most Relevant", label: "Most Relevant" },
    { value: "Order A-Z", label: "Order A-Z" },
    { value: "Order Z-A", label: "Order Z-A" }
  ]
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'var(--darkblue)' : 'var(--color)',
      fontSize: 14,
      padding: 10,
      marginBottom: 0,
      backgroundColor: state.isSelected ? 'rgba(215, 223, 233, 0.34);' : '#fff'
    }),

    control: () => ({
      width: 'auto',
      display: 'flex',
      border: '1px solid #ced4da',
      borderRadius: '.25rem',
      border: 'none', // Add this line to remove the border
      boxShadow: 'none', // Optional: Remove the box-shadow as well
      cursor: 'pointer',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = 'var(--color)';
      const fontSize = '14px';
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition, color, fontSize };
    }
  }
let lists = cartData?.data?.cart
    useEffect(() => {
        document.title = "ncentiva || Onboard"
    }, [])

    const cmsData = useSelector((state) => state.cmsReducer);

    const defaultNcentiva = cmsData?.data?.cms?.default_ncentiva;
    useEffect(() => {
        if (cmsData?.data) {
            setLoading(true);
            const data = cmsData?.data?.cms;

            if (data.default_ncentiva === false) {
                if (data.type === "bs") {
                    setGiftcards("giftcardss");
                } else {
                    setGiftcards("giftcards");
                }
                setBtnColor(data?.btn_color);
                setBtnText(data?.btn_text);
                setPrimaryColor(data?.primary_color);
                setSecondaryColor(data?.secondary_color);
            } else {
                setBtnColor('#FFAD31');
                setBtnText('white');
                setPrimaryColor("#2665F2");
                setSecondaryColor("#65B5FF");
            }

            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [cmsData]);

    const [isActive, setActive] = useState("false");
    const toggleClick = event => {
        setActive(!isActive);
    };
    const [isHighlight, setHighlight] = useState("false");
    const [isHighlightAgain, setHighlightAgin] = useState("false");
    const [isShown, setIsShown] = useState(false);
    const [isHiden, setIsHiden] = useState(true);
    const [isHidden, setIsHidden] = useState(false);
    const handleSession = () => {
        sessionStorage.setItem("modalshowOne", "Only one time");
    }

    const handleClick = event => {
        setIsShown(true);
        setIsHiden(false);
        setIsHidden(false);
        setHighlight(false);
        setHighlightAgin(false);
    };
console.log(offcanvasOpen, "off")
    const handleClickLast = event => {
        setIsShown(false);
        setIsHiden(false);
        setIsHidden(true);
        setHighlight(false);
        setHighlightAgin(false);

        setOffcanvasOpen(true)
        // setHighlightAgin(!isHighlightAgain); 
    };
    const handleClickFirst = event => {
        setIsShown(false);
        setIsHiden(true);
        setIsHidden(false);
        setHighlight(true);
        setHighlightAgin(true);
        
    }

    window.onbeforeunload = function () {
        window.scrollTo(400, 400);
    }

    let [bonusCalculated, setBonusCalculated] = useState(5.00);
    const [count, setCount] = useState(50.00);

    function incrementCount() {
        setCount(count + 1);
        var value = count;
        if (value >= 50.00) {
            setBonusCalculated((bonusCalculated) + 0.10);
        }
    }
    function decrementCount() {
        if (count >= 1) {
            setCount(count - 1);
            var value = count;
            if (value > 50.00) {
                setBonusCalculated((bonusCalculated) - 0.10);
            }

        }
    }

    let value_dispaly = count + bonusCalculated
    const rbalance_redux = useSelector((state) => state.customer_balance_Reducer.data);
    const promoCouponData = [{
        "bonus": "9",
        "bonusFee": 90,
        "bonusType": "Bonus percentage",
        "brandCategory": "Travel & Experiences",
        "brand_id": "771",
        "brand_name": "Best Buy",
        "currency": "USD",
        "denominationtype": "VARIABLE",
        "description": "Give Airbnb—amazing places to stay and things to do, all around the world. Give the perfect getaway—everything from lakeside cabins to secluded beach houses to apartments in the heart of the city. Give immersive Experiences, from guided tours to lessons to tastings, led by local experts. Airbnb gift cards never expire. Use to book stays or Experiences; spend all at once or apply to multiple bookings.",
        "discount": 10,
        "faceValue": 0,
        "fixed": [],
        "img_path": "https://d20s221jcw9wlp.cloudfront.net/88a6d48c-61a4-42bb-87b9-57ae0b8494a2",
        "internal_rebate": "0.2725",
        "maxCost": "545",
        "maxValue": 500,
        "minBonus": "2.25",
        "minCost": "27.25",
        "minValue": 25,
        "mostRelevant": "11",
        "processing": "REALTIME",
        "productname": "Airbnb $25-$500",
        "promotion": true,
        "redemptionInstructions": "Here’s how to redeem your gift: 1.Sign up or log in at https://www.airbnb.com/gift 2.Follow the on-screen prompts 3.We’ll automatically apply the credit when you book.",
        "relevantCount": 15,
        "stand_out": false,
        "termsAndConditions": "Funds do not expire. No fees apply to purchase/activation of Card. Redeemable by U.S. residents for merchandise and services on airbnb.com only. The Card is non-reloadable and, except where required by law, cannot be redeemed for cash, refunded, or returned. Treat this Card as cash; issuer is not responsible for lost, damaged or stolen cards, or for unauthorized use. Issued by Airbnb Payments, Inc. Terms apply and are subject to change without notice; visit https://www.airbnb.com/terms/gift_cards for current terms, conditions, and limitations.",
        "thresholdQuantity": "",
        "thresholdValue": "",
        "user_id": "1083",
        "_id": "66793f1f2e69533634b86a82"
    },
    {
        "bonus": "9",
        "bonusFee": 90,
        "bonusType": "Bonus percentage",
        "brandCategory": "Travel & Experiences",
        "brand_id": "771",
        "brand_name": "Google Play",
        "currency": "USD",
        "denominationtype": "VARIABLE",
        "description": "Give Airbnb—amazing places to stay and things to do, all around the world. Give the perfect getaway—everything from lakeside cabins to secluded beach houses to apartments in the heart of the city. Give immersive Experiences, from guided tours to lessons to tastings, led by local experts. Airbnb gift cards never expire. Use to book stays or Experiences; spend all at once or apply to multiple bookings.",
        "discount": 10,
        "faceValue": 0,
        "fixed": [],
        "img_path": "https://d20s221jcw9wlp.cloudfront.net/78fb3749-7b75-4081-8cee-a60f42f21204",
        "internal_rebate": "0.2725",
        "maxCost": "545",
        "maxValue": 500,
        "minBonus": "2.25",
        "minCost": "27.25",
        "minValue": 25,
        "mostRelevant": "11",
        "processing": "REALTIME",
        "productname": "Airbnb $25-$500",
        "promotion": true,
        "redemptionInstructions": "Here’s how to redeem your gift: 1.Sign up or log in at https://www.airbnb.com/gift 2.Follow the on-screen prompts 3.We’ll automatically apply the credit when you book.",
        "relevantCount": 15,
        "stand_out": false,
        "termsAndConditions": "Funds do not expire. No fees apply to purchase/activation of Card. Redeemable by U.S. residents for merchandise and services on airbnb.com only. The Card is non-reloadable and, except where required by law, cannot be redeemed for cash, refunded, or returned. Treat this Card as cash; issuer is not responsible for lost, damaged or stolen cards, or for unauthorized use. Issued by Airbnb Payments, Inc. Terms apply and are subject to change without notice; visit https://www.airbnb.com/terms/gift_cards for current terms, conditions, and limitations.",
        "thresholdQuantity": "",
        "thresholdValue": "",
        "user_id": "1083",
        "_id": "66793f1f2e69533634b86a82"
    },
    {
        "bonus": "9",
        "bonusFee": 90,
        "bonusType": "Bonus percentage",
        "brandCategory": "Travel & Experiences",
        "brand_id": "771",
        "brand_name": "Nike",
        "currency": "USD",
        "denominationtype": "VARIABLE",
        "description": "Give Airbnb—amazing places to stay and things to do, all around the world. Give the perfect getaway—everything from lakeside cabins to secluded beach houses to apartments in the heart of the city. Give immersive Experiences, from guided tours to lessons to tastings, led by local experts. Airbnb gift cards never expire. Use to book stays or Experiences; spend all at once or apply to multiple bookings.",
        "discount": 10,
        "faceValue": 0,
        "fixed": [],
        "img_path": "https://d20s221jcw9wlp.cloudfront.net/e364151d-1a42-4472-82b2-2bb1e1de3130",
        "internal_rebate": "0.2725",
        "maxCost": "545",
        "maxValue": 500,
        "minBonus": "2.25",
        "minCost": "27.25",
        "minValue": 25,
        "mostRelevant": "11",
        "processing": "REALTIME",
        "productname": "Airbnb $25-$500",
        "promotion": true,
        "redemptionInstructions": "Here’s how to redeem your gift: 1.Sign up or log in at https://www.airbnb.com/gift 2.Follow the on-screen prompts 3.We’ll automatically apply the credit when you book.",
        "relevantCount": 15,
        "stand_out": false,
        "termsAndConditions": "Funds do not expire. No fees apply to purchase/activation of Card. Redeemable by U.S. residents for merchandise and services on airbnb.com only. The Card is non-reloadable and, except where required by law, cannot be redeemed for cash, refunded, or returned. Treat this Card as cash; issuer is not responsible for lost, damaged or stolen cards, or for unauthorized use. Issued by Airbnb Payments, Inc. Terms apply and are subject to change without notice; visit https://www.airbnb.com/terms/gift_cards for current terms, conditions, and limitations.",
        "thresholdQuantity": "",
        "thresholdValue": "",
        "user_id": "1083",
        "_id": "66793f1f2e69533634b86a82"
    },
    {
        "bonus": "9",
        "bonusFee": 90,
        "bonusType": "Bonus percentage",
        "brandCategory": "Travel & Experiences",
        "brand_id": "771",
        "brand_name": "PlayStation",
        "currency": "USD",
        "denominationtype": "VARIABLE",
        "description": "Give Airbnb—amazing places to stay and things to do, all around the world. Give the perfect getaway—everything from lakeside cabins to secluded beach houses to apartments in the heart of the city. Give immersive Experiences, from guided tours to lessons to tastings, led by local experts. Airbnb gift cards never expire. Use to book stays or Experiences; spend all at once or apply to multiple bookings.",
        "discount": 10,
        "faceValue": 0,
        "fixed": [],
        "img_path": "https://d20s221jcw9wlp.cloudfront.net/f4dd9cc4-fc0c-4b89-919e-19948cb96e11",
        "internal_rebate": "0.2725",
        "maxCost": "545",
        "maxValue": 500,
        "minBonus": "2.25",
        "minCost": "27.25",
        "minValue": 25,
        "mostRelevant": "11",
        "processing": "REALTIME",
        "productname": "Airbnb $25-$500",
        "promotion": true,
        "redemptionInstructions": "Here’s how to redeem your gift: 1.Sign up or log in at https://www.airbnb.com/gift 2.Follow the on-screen prompts 3.We’ll automatically apply the credit when you book.",
        "relevantCount": 15,
        "stand_out": false,
        "termsAndConditions": "Funds do not expire. No fees apply to purchase/activation of Card. Redeemable by U.S. residents for merchandise and services on airbnb.com only. The Card is non-reloadable and, except where required by law, cannot be redeemed for cash, refunded, or returned. Treat this Card as cash; issuer is not responsible for lost, damaged or stolen cards, or for unauthorized use. Issued by Airbnb Payments, Inc. Terms apply and are subject to change without notice; visit https://www.airbnb.com/terms/gift_cards for current terms, conditions, and limitations.",
        "thresholdQuantity": "",
        "thresholdValue": "",
        "user_id": "1083",
        "_id": "66793f1f2e69533634b86a82"
    },
    {
        "bonus": "9",
        "bonusFee": 90,
        "bonusType": "Bonus percentage",
        "brandCategory": "Travel & Experiences",
        "brand_id": "771",
        "brand_name": "Roblox",
        "currency": "USD",
        "denominationtype": "VARIABLE",
        "description": "Give Airbnb—amazing places to stay and things to do, all around the world. Give the perfect getaway—everything from lakeside cabins to secluded beach houses to apartments in the heart of the city. Give immersive Experiences, from guided tours to lessons to tastings, led by local experts. Airbnb gift cards never expire. Use to book stays or Experiences; spend all at once or apply to multiple bookings.",
        "discount": 10,
        "faceValue": 0,
        "fixed": [],
        "img_path": "https://d20s221jcw9wlp.cloudfront.net/d819fdad-759c-4e76-9557-88f9d3c48fb1",
        "internal_rebate": "0.2725",
        "maxCost": "545",
        "maxValue": 500,
        "minBonus": "2.25",
        "minCost": "27.25",
        "minValue": 25,
        "mostRelevant": "11",
        "processing": "REALTIME",
        "productname": "Airbnb $25-$500",
        "promotion": true,
        "redemptionInstructions": "Here’s how to redeem your gift: 1.Sign up or log in at https://www.airbnb.com/gift 2.Follow the on-screen prompts 3.We’ll automatically apply the credit when you book.",
        "relevantCount": 15,
        "stand_out": false,
        "termsAndConditions": "Funds do not expire. No fees apply to purchase/activation of Card. Redeemable by U.S. residents for merchandise and services on airbnb.com only. The Card is non-reloadable and, except where required by law, cannot be redeemed for cash, refunded, or returned. Treat this Card as cash; issuer is not responsible for lost, damaged or stolen cards, or for unauthorized use. Issued by Airbnb Payments, Inc. Terms apply and are subject to change without notice; visit https://www.airbnb.com/terms/gift_cards for current terms, conditions, and limitations.",
        "thresholdQuantity": "",
        "thresholdValue": "",
        "user_id": "1083",
        "_id": "66793f1f2e69533634b86a82"
    },
    {
        "bonus": "9",
        "bonusFee": 90,
        "bonusType": "Bonus percentage",
        "brandCategory": "Travel & Experiences",
        "brand_id": "771",
        "brand_name": "Walmart",
        "currency": "USD",
        "denominationtype": "VARIABLE",
        "description": "Give Airbnb—amazing places to stay and things to do, all around the world. Give the perfect getaway—everything from lakeside cabins to secluded beach houses to apartments in the heart of the city. Give immersive Experiences, from guided tours to lessons to tastings, led by local experts. Airbnb gift cards never expire. Use to book stays or Experiences; spend all at once or apply to multiple bookings.",
        "discount": 10,
        "faceValue": 0,
        "fixed": [],
        "img_path": "https://d20s221jcw9wlp.cloudfront.net/f96934ce-377b-4993-9c60-7735901281e7",
        "internal_rebate": "0.2725",
        "maxCost": "545",
        "maxValue": 500,
        "minBonus": "2.25",
        "minCost": "27.25",
        "minValue": 25,
        "mostRelevant": "11",
        "processing": "REALTIME",
        "productname": "Airbnb $25-$500",
        "promotion": true,
        "redemptionInstructions": "Here’s how to redeem your gift: 1.Sign up or log in at https://www.airbnb.com/gift 2.Follow the on-screen prompts 3.We’ll automatically apply the credit when you book.",
        "relevantCount": 15,
        "stand_out": false,
        "termsAndConditions": "Funds do not expire. No fees apply to purchase/activation of Card. Redeemable by U.S. residents for merchandise and services on airbnb.com only. The Card is non-reloadable and, except where required by law, cannot be redeemed for cash, refunded, or returned. Treat this Card as cash; issuer is not responsible for lost, damaged or stolen cards, or for unauthorized use. Issued by Airbnb Payments, Inc. Terms apply and are subject to change without notice; visit https://www.airbnb.com/terms/gift_cards for current terms, conditions, and limitations.",
        "thresholdQuantity": "",
        "thresholdValue": "",
        "user_id": "1083",
        "_id": "66793f1f2e69533634b86a82"
    },
    {
        "bonus": "9",
        "bonusFee": 90,
        "bonusType": "Bonus percentage",
        "brandCategory": "Travel & Experiences",
        "brand_id": "771",
        "brand_name": "Starbucks",
        "currency": "USD",
        "denominationtype": "VARIABLE",
        "description": "Give Airbnb—amazing places to stay and things to do, all around the world. Give the perfect getaway—everything from lakeside cabins to secluded beach houses to apartments in the heart of the city. Give immersive Experiences, from guided tours to lessons to tastings, led by local experts. Airbnb gift cards never expire. Use to book stays or Experiences; spend all at once or apply to multiple bookings.",
        "discount": 10,
        "faceValue": 0,
        "fixed": [],
        "img_path": "https://d20s221jcw9wlp.cloudfront.net/4ce0fdf0-ad62-42f0-9fb8-c214af178ceb",
        "internal_rebate": "0.2725",
        "maxCost": "545",
        "maxValue": 500,
        "minBonus": "2.25",
        "minCost": "27.25",
        "minValue": 25,
        "mostRelevant": "11",
        "processing": "REALTIME",
        "productname": "Airbnb $25-$500",
        "promotion": true,
        "redemptionInstructions": "Here’s how to redeem your gift: 1.Sign up or log in at https://www.airbnb.com/gift 2.Follow the on-screen prompts 3.We’ll automatically apply the credit when you book.",
        "relevantCount": 15,
        "stand_out": false,
        "termsAndConditions": "Funds do not expire. No fees apply to purchase/activation of Card. Redeemable by U.S. residents for merchandise and services on airbnb.com only. The Card is non-reloadable and, except where required by law, cannot be redeemed for cash, refunded, or returned. Treat this Card as cash; issuer is not responsible for lost, damaged or stolen cards, or for unauthorized use. Issued by Airbnb Payments, Inc. Terms apply and are subject to change without notice; visit https://www.airbnb.com/terms/gift_cards for current terms, conditions, and limitations.",
        "thresholdQuantity": "",
        "thresholdValue": "",
        "user_id": "1083",
        "_id": "66793f1f2e69533634b86a82"
    }
    ];
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [headerHeight, setHeaderHeight] = useState("");
    const [canvasModalOpen, setCanvasModalOpen] = useState(false);
    useEffect(() => {
      const handleResize = () => {
        setDeviceWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    useEffect(() => {
      const offcanvas= setTimeout(() => {
            const headerElement1 = document.querySelector(".header");
            const headerElement2 = document.querySelector(".hero-search-modified");
  
            if (headerElement2 !== null && headerElement1 !== null) {
              const headerHeight = headerElement1.clientHeight + headerElement2.clientHeight;
            //   console.log(headerElement1.clientHeight, "headerElement1.clientHeight")
            //   console.log(headerElement2.clientHeight, "headerElement2.clientHeight")
            //   console.log(headerHeight, 'headerHeight')
              setHeaderHeight(headerHeight);
              setCanvasModalOpen(true)
             }
      }, 3000);
      return () => {
        clearTimeout(offcanvas);
      };
    }, [deviceWidth]);
    // const [primaryColor,setPrimaryColor] = useState("");
    // const [secondaryColor,setSecondaryColor] = useState("");
    const [videoUrl,setVideoUrl] = useState("");
    const [bannerColor,setBannerColor] = useState("");
    const [bannerColor1,setBannerColor1] = useState("");
    const [checkColor,setCheckColor] = useState();
    const [isLoading,setIsLoading] = useState(true);
    // const cmsData = useSelector((state) => state.cmsReducer);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
      const backgroundStyle = {
        backgroundImage: `url(${videoUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: `${100}% ${100}%`,
        boxShadow: `inset 0 0 0 2000px rgba(0,0,0,0.5)`,
      };
      const backgroundColor = {
        backgroundImage:`linear-gradient(93.59deg, ${bannerColor} -0.51%, ${bannerColor1} 105.82%)`
      }
  
      useEffect(() => {
        const data = cmsData.data?.cms;       
        if (data?.default_ncentiva === false) {
          setVideoUrl(data?.banner_image);
          setCheckColor(data?.banner_image_color);
          setBannerColor(data?.bannerColorCombination);
          setBannerColor1(data?.bannerColorCombination1);
          setPrimaryColor(data?.primary_color);
          setSecondaryColor(data?.secondary_color);
          setIsLoading(false);
        } else if(data?.default_ncentiva === true){
          setCheckColor(true);
          setBannerColor("#2665F2");
          setBannerColor1("#65B5FF");
          setPrimaryColor("#2665F2");
          setSecondaryColor("#65B5FF");
          setIsLoading(false);
        }
    }, [cmsData])

    useEffect(()=>{
        document.querySelector('.header').classList.add('new_layer');
        document.querySelector('.hero-search-modified').classList.add('new_layer');
    },[])

    return (
        <>
        <div className='zoomScreenOnBoard'>
            {loading ? <div className="d-flex justify-content-center mt-5 pt-5">
                <Bars
                    height="80"
                    width="100%"
                    margin='auto'
                    color="var(--newMainBlue)"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div> :
                <>
                    <NavbarComponent />
                <section  style={{
    ...(checkColor ? backgroundColor : backgroundStyle),
    zIndex: 10
  }}  className="hero-search-modified inner-page position-fixed banner_style">
                    <div className="container-fluid d-flex justify-content-start category_div align-items-center " style={{height: "50px", color: "white", zIndex: "1"}}>
                        <div className="dropdown" style={{padding: "15px 0px "}}>
                            <VscThreeBars className='three_bars' />
                        </div>
                        <div className="row  px-3">
                            <div className='col-auto ' style={{cursor: "pointer"}} ><h2>All Categories</h2></div>
                            <div className='col-auto vanish_responsive' style={{cursor: "pointer"}} ><h2>Clothing & Accessories</h2></div>
                            <div className='col-auto' style={{cursor: "pointer"}} ><h2>Food & Restaurants</h2></div>
                            <div className='col-auto vanish_responsive1' style={{cursor: "pointer"}}><h2>Gaming</h2></div>
                            {/* <div className='col-auto'><h2>Electronics</h2></div> */}
                            {/* <div className='col-auto'><h2>Fuel and Transportation</h2></div> */}
                            {/* <div className='col-auto'><h2>Multi Brands</h2></div> */}
                            
                        </div>
                        
                    </div>
                </section>
                    {/* <ProductFilterArea primaryColor={primaryColor} secondaryColor={secondaryColor} btnColor={btnColor} btnText={btnText}
                        defaultNcentiva={defaultNcentiva} />
                    <ProductCategories /> */}
                    <section className="onBoardPromoMarginTop">
                <PromotionalSliderArea 
                  btnColor={btnColor} btnText={btnText} rbalance_redux={rbalance_redux?.balance}
                  wallet_balance={rbalance_redux?.wallet_balance}
                //   disableBtn={disableBtn}
                //   search={search}
                //   promoClick={promoClick}
                //   searchData={searchData}
                  promoCoupondata={promoCouponData}
                //   setPromoClick={setPromoClick}
                //   setDisableBtn={setDisableBtn}
                //   ws={ws}
                  
                   />
                    </section>

                    

                    <section className="product-listing ">
                    <div className="row">
                    <div className='col-md-3 col-sm-12 d-flex align-items-center ms-5'> <span>Sort by: </span>
                      <div>
                       
                          <Select
                            styles={customStyles}
                            // onChange={handleeChange}
                            options={options}
                            value={{ value: "View: All", label: "Most Relevant" }}
                            components={{
                              IndicatorSeparator: () => null
                            }}
                            isSearchable={false}
                          />
                        
                        
                      </div>
                    </div>
                  </div>
                        <div className="container">
                            <div className='demo-listing'>
                                <div className="row g-2 g-lg-3">
                                    {/* <ProductListing className="xyz" /> */}
                                    {/* <div className="col-lg-4 col-md-6 mb-4">
                                        <div className="listing-block">
                                            <div className="listing-top">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0">
                                                        <div className="listing-top-left justify-content-center justify-content-sm-start">
                                                            <div className="listing-logo">
                                                                <img
                                                                    src="assets/images/gift-listing/xbox.png"
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <div className="listing-detail">
                                                                <h6 className="listing-name">Xbox</h6>
                                                                <p className="listing-categorydtl">Streaming &amp; Gaming</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-top-right">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="listing-bottom">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0 p-1">
                                                        <div className="listing-bottom-left">
                                                            <div className="listing-input justify-content-center justify-content-sm-start">

                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-minus me-1"
                                                                    onClick={decrementCount}
                                                                >
                                                                    <FaMinus />
                                                                </button>
                                                                <div className="inputwithunit mb-2">
                                                                    <span>$</span>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={"100.00"}
                                                                        onChange={(e) => setCount(e.target.value)}
                                                                    />
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-plus ms-1"
                                                                    onClick={incrementCount}
                                                                >
                                                                    <FaPlus />
                                                                </button>

                                                            </div>
                                                            <button type="button" style={defaultNcentiva
                                                                ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                            } className="btn btn-main btn-add py-0">
                                                                Add
                                                            </button>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-bottom-right">
                                                            <span style={{ color: 'black' }}>You get</span>
                                                            <h6>
                                                            , color:"#2665F2"    $110.00
                                                            </h6>
                                                            <span className="fw-bold" style={{ fontSize: "18px", color: 'black' }}>(+ 10.00%)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 mb-4 ps-md">
                                        <div className="card border rounded-3 h-100">
                                            <div className="card-header p-0 border-0">
                                            <img
                                                className="rounded-top-3 card_image_style"
                                                style={{ maxWidth: "100%", objectFit: "cover", width: "100%" }}
                                                src="https://d20s221jcw9wlp.cloudfront.net/d61f0e19-53c0-438e-af65-cc47c96019c3"
                                                alt="Gift Card Image"
                                            />
                                            <div className=" p-1 w-25 position-absolute text-center custom_discount" >+10.00%</div>
                                            </div>
                                            <div className="card-body py-0">
                                            <div className="d-flex justify-content-between align-items-center bg-white text-dark pt-1 responsive_text mt-0">
                                                <h6>Xbox Live</h6>
                                            </div>
                                            <small
                                                style={{ cursor: "pointer" }}
                                                className="bg-white text-dark responsive_text mt-0"
                                                title="Delivery Time"
                                            >
                                                Delivery Within 30 mins
                                            </small>
                                            <br />
                                            {/* <small
                                                style={{ cursor: "pointer" }}
                                                className="bg-white "
                                                title="Expiration Time"
                                            >
                                                Expiration Time
                                            </small> */}
                                            <div className="pt-0">
                                                <span style={{ color: "black" }}>You Receive: </span>
                                                <span
                                                className=" fw-bold mb-0 text_value"
                                                style={{ fontSize: "15px" }}
                                                >
                                                <span className="fw-bold" style={{ fontSize: "15px", color:"#2665F2"}}> $110.00</span>
                                                {/* <span className="fw-bold" style={{ fontSize: "12px", color: "rgb(245, 196, 0)" }}> + </span>
                                                <span className="fw-bold" style={{ whiteSpace: "nowrap", color: "rgb(245, 196, 0)" }}>$0.00</span> */}
                                                </span>
                                                <span className="fw-bold" style={{ color: "black" }}>
                                                {/* <small style={{ fontSize: "12px", color: "rgb(245, 196, 0)" }}>Bonus Cash</small> */}
                                                {/* <span>(+0.00%)</span> */}
                                                </span>
                                            </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0">
                                            <div className="d-sm-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center justify-content-center justify-content-sm-start"
                                                        // style={{ maxWidth: "60%" }}
                                                    >
                                                    
                                                        <div className="listing-input align-items-center justify-content-center justify-content-md-start">
                                                            {/* {count > data.maxValue ? ( */}
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-minus me-1"
                                                                onClick={decrementCount}
                                                                
                                                            >
                                                                <FaMinus />
                                                            </button>
                                                            {/* ) : (
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-minus me-1"
                                                                onClick={decrementCount}
                                                            >
                                                                <FaMinus />
                                                            </button> */}
                                                            {/* )} */}

                                                            <div className="inputwithunit ">
                                                            <span className="mr-0">$</span>
                                                            <input
                                                            type="number"
                                                            className="form-control"
                                                            value={"100.00"}
                                                            onChange={(e) => setCount(e.target.value)}
                                                            />
                                                            </div>

                                                            <button
                                                            type="button"
                                                            className="btn listing-input-btn btn-plus ms-1"
                                                            onClick={incrementCount}
                                                            >
                                                            <FaPlus />
                                                            </button>
                                                        </div>
                                            
                                                    </div>
                                                <div 
                                                    className="d-flex align-items-center mt-2 mt-sm-0" 
                                                    // style={{ maxWidth: "40%" }}
                                                >
                                                <button className="btn btn-main btn-add add_button_New py-1" style={defaultNcentiva
                                                ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }} >Add</button>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-lg-4 col-md-6 mb-4">
                                        <div className={isHighlight ? "listing-block highlight" : "listing-block"}>
                                            <div className="listing-top">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0">
                                                        <div className="listing-top-left justify-content-center justify-content-sm-start">
                                                            <div className="listing-logo">
                                                                <img
                                                                    src="assets/images/gift-logos/amazon.png"
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <div className="listing-detail">
                                                                <h6 className="listing-name">Amazon</h6>
                                                                <p className="listing-categorydtl">General merchandise</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-top-right">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="listing-bottom">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0 p-1">
                                                        <div className="listing-bottom-left">
                                                            <div className="listing-input justify-content-center justify-content-sm-start">
                                                                {count > 50.00 ? <button type="button" className="btn listing-input-btn btn-minus me-1" onClick={decrementCount} >
                                                                    <FaMinus />
                                                                </button> :
                                                                    <button disabled={true} type="button" className="btn listing-input-btn btn-minus me-1" onClick={decrementCount} >
                                                                        <FaMinus />
                                                                    </button>
                                                                }
                                                                <div className="inputwithunit mb-2">
                                                                    <span>$</span>
                                                                    <input style={{ cursor: 'initial' }}
                                                                        disabled type="number" className="form-control" value={Number(count).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                        onChange={(e) => setCount(e.target.value)} />
                                                                </div>
                                                                <button type="button" className="btn listing-input-btn btn-plus ms-1" onClick={incrementCount}>
                                                                    <FaPlus />
                                                                </button>
                                                            </div>
                                                            <button type="button" style={defaultNcentiva
                                                                ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                            } className="btn btn-main btn-add py-0">
                                                                Add
                                                            </button>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-bottom-right" style={{ marginLeft: "16px" }}>
                                                            <span style={{ color: 'black' }}>You get</span>
                                                            <h6>$ {Number(value_dispaly).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} </h6>
                                                            <span className="fw-bold" style={{ fontSize: "18px", color: 'black' }}>(+ 10.00%)</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {isHiden && (
                                                <div className="onboard-step step-1">
                                                    <div className="d-flex align-item-center justify-content-between onboard-header mb-4">
                                                        <h3>Select the branded gift card(s) of your choice and load the value.</h3>
                                                        <Link replace to='/' className="onboard-close-btn border-0">
                                                            <FaTimes />
                                                        </Link>
                                                    </div>
                                                    <div className="onboard-middle mb-4">
                                                        <p> Our gift card selections offer a variety of options, from fixed to variable. </p>
                                                        <p>*Fixed Values - “pre-selected value” $25, 50, $100</p>
                                                        <p>**Variable Values - “load the value of your choice” $.01-$100</p>
                                                    </div>
                                                    <div className="d-flex align-items-center onboard-footer">
                                                        <button
                                                            className="btn btn-main onboard-next me-3 px-4 px-lg-5"
                                                            style={defaultNcentiva
                                                                ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                            }
                                                            onClick={handleClick}>
                                                            Next
                                                        </button>
                                                        <Link replace to="/" onClick={handleSession} className="text-dark me-3">
                                                            Skip this
                                                        </Link>
                                                        <div className="d-flex align-items-center onboard-paginate">
                                                            <span className="active" style={{ background: primaryColor }} />
                                                            <span onClick={handleClick} />
                                                            <span onClick={handleClickLast} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div> */}


                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 mb-4 ps-md ">
                                        <div className={isHighlight ? "card listing-block p-0 highlight border  h-100" : "card border rounded-3 h-100"}>
                                            <div className="card-header p-0 border-0 ">
                                            <img
                                                className="rounded-top-3 card_image_style"
                                                style={{ maxWidth: "100%", objectFit: "cover", width: "100%" }}
                                                src="https://d20s221jcw9wlp.cloudfront.net/3813c67c-0b08-4191-b7d4-3165020cec42"
                                                alt="Gift Card Image"
                                            />
                                            <div className=" p-1 w-25 position-absolute text-center custom_discount" >+10.00%</div>
                                            </div>
                                            <div className="card-body py-0">
                                            <div className="d-flex justify-content-between align-items-center bg-white text-dark pt-1 responsive_text mt-0">
                                                <h6>Amazon.com</h6>
                                            </div>
                                            <small
                                                style={{ cursor: "pointer" }}
                                                className="bg-white text-dark responsive_text mt-0"
                                                title="Delivery Time"
                                            >
                                                Delivery Within 30 mins
                                            </small>
                                            <br />
                                            {/* <small
                                                style={{ cursor: "pointer" }}
                                                className="bg-white "
                                                title="Expiration Time"
                                            >
                                                Expiration Time
                                            </small> */}
                                            <div className="pt-0">
                                                <span style={{ color: "black" }}>You Receive: </span>
                                                <span
                                                className=" fw-bold mb-0 text_value"
                                                style={{ fontSize: "15px" }}
                                                >
                                                <span className="fw-bold" style={{ fontSize: "15px"}}> ${Number(value_dispaly).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                                                {/* <span className="fw-bold" style={{ fontSize: "12px", color: "rgb(245, 196, 0)" }}> + </span>
                                                <span className="fw-bold" style={{ whiteSpace: "nowrap", color: "rgb(245, 196, 0)" }}>$0.00</span> */}
                                                </span>
                                                <span className="fw-bold" style={{ color: "black" }}>
                                                {/* <small style={{ fontSize: "12px", color: "rgb(245, 196, 0)" }}>Bonus Cash</small> */}
                                                    {/* <span>(+ 10.00%)</span> */}
                                                </span>
                                            </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0">
                                            <div className="d-sm-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center w-100 justify-content-center justify-content-sm-start"
                                                        // style={{ maxWidth: "60%" }}
                                                    >
                                                    
                                                        <div className="listing-input align-items-center justify-content-center justify-content-md-start">
                                                            {count > 50.00 ? (
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-minus me-1"
                                                                onClick={decrementCount}
                                                                
                                                            >
                                                                <FaMinus />
                                                            </button>
                                                            ) : (
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-minus me-1"
                                                                onClick={decrementCount}
                                                            >
                                                                <FaMinus />
                                                            </button> 
                                                            )}

                                                            <div className="inputwithunit ">
                                                            <span className="mr-0">$</span>
                                                            <input
                                                            type="number"
                                                            className="form-control"
                                                        value={Number(count).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            onChange={(e) => setCount(e.target.value)}
                                                            style={{ cursor: 'initial' }}
                                                            disabled
                                                            />
                                                            </div>

                                                            <button
                                                            type="button"
                                                            className="btn listing-input-btn btn-plus ms-1"
                                                            onClick={incrementCount}
                                                            >
                                                            <FaPlus />
                                                            </button>
                                                        </div>
                                            
                                                    </div>
                                                <div 
                                                className="d-flex align-items-center justify-content-center mt-2 mt-sm-0" 
                                                // style={{ maxWidth: "40%" }}
                                                >
                                                    <button className="btn btn-main btn-add add_button_New py-1" style={defaultNcentiva
                                                    ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                    : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }} >Add</button>
                                                </div>
                                            </div>
                                            </div>
                                            {isHiden && (
                                                <div className="onboard-step step-1">
                                                    <div className="d-flex align-item-center justify-content-between onboard-header mb-2 mb-sm-4">
                                                        <h3>Select the branded gift card(s) of your choice and load the value.</h3>
                                                        <Link replace to='/' className="onboard-close-btn border-0">
                                                            <FaTimes />
                                                        </Link>
                                                    </div>
                                                    <div className="onboard-middle mb-2 mb-sm-4">
                                                        <p> Our gift card selections offer a variety of options, from fixed to variable. </p>
                                                        <p>*Fixed Values - “pre-selected value” $25, 50, $100</p>
                                                        <p>**Variable Values - “load the value of your choice” $.01-$100</p>
                                                    </div>
                                                    <div className="d-flex align-items-center onboard-footer">
                                                        <button
                                                            className="btn btn-main onboard-next me-3 px-4 px-lg-5"
                                                            style={defaultNcentiva
                                                                ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                            }
                                                            onClick={handleClick}>
                                                            Next
                                                        </button>
                                                        <Link replace to="/" onClick={handleSession} className="text-dark me-3">
                                                            Skip this
                                                        </Link>
                                                        <div className="d-flex align-items-center onboard-paginate">
                                                            <span className="active" style={{ background: primaryColor }} />
                                                            <span onClick={handleClick} />
                                                            <span onClick={handleClickLast} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        
                                    </div>
                                    
                                    
                                    {/* <div className="col-lg-4 col-md-6 mb-4">
                                        <div className="listing-block">
                                            <div className="listing-top">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0 ">
                                                        <div className="listing-top-left justify-content-center justify-content-sm-start">
                                                            <div className="listing-logo">
                                                                <img
                                                                    src="assets/images/logos/american-eagle-outfitters.png"
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <div className="listing-detail">
                                                                <h6 className="listing-name">American Eagle</h6>
                                                                <p className="listing-categorydtl">Clothing &amp; Accesories</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-top-right">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="listing-bottom">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0 p-1">
                                                        <div className="listing-bottom-left">
                                                            <div className="listing-input justify-content-center justify-content-sm-start">

                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-minus me-1"
                                                                    onClick={decrementCount}
                                                                >
                                                                    <FaMinus />
                                                                </button>
                                                                <div className="inputwithunit mb-2">
                                                                    <span>$</span>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={"100.00"}
                                                                        onChange={(e) => setCount(e.target.value)}
                                                                    />
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-plus ms-1"
                                                                    onClick={incrementCount}
                                                                >
                                                                    <FaPlus />
                                                                </button>

                                                            </div>
                                                            <button type="button" style={defaultNcentiva
                                                                ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                            } className="btn btn-main btn-add py-0">
                                                                Add
                                                            </button>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-bottom-right">
                                                            <span style={{ color: 'black' }}>You get</span>
                                                            <h6>
                                                            , color:"#2665F2"    $110.00
                                                            </h6>
                                                            <span className="fw-bold" style={{ fontSize: "18px", color: 'black' }}>(+ 10.00 %)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 mb-4 ps-md">
                                        <div className="card border rounded-3 h-100">
                                            <div className="card-header p-0 border-0">
                                                <img
                                                    className="rounded-top-3 card_image_style"
                                                    style={{ maxWidth: "100%", objectFit: "cover", width: "100%" }}
                                                    src="https://d20s221jcw9wlp.cloudfront.net/77bb2bf4-bb76-4478-bf50-6718c888e9e1"
                                                    // src="https://d20s221jcw9wlp.cloudfront.net/77bb2bf4-bb76-4478-bf50-6718c888e9e1"
                                                    alt="Gift Card Image"
                                                />
                                                <div className=" p-1 w-25 position-absolute text-center custom_discount" >+10.00%</div>
                                            </div>
                                            <div className="card-body py-0">
                                                <div className="d-flex justify-content-between align-items-center bg-white text-dark pt-1 responsive_text mt-0">
                                                    <h6>Airbnb - Happy Card</h6>
                                                </div>
                                                <small
                                                    style={{ cursor: "pointer" }}
                                                    className="bg-white text-dark responsive_text mt-0"
                                                    title="Delivery Time"
                                                >
                                                    Delivery Within 30 mins
                                                </small>
                                                <br />
                                                <div className="pt-0">
                                                    <span style={{ color: "black" }}>You Receive: </span>
                                                    <span
                                                        className=" fw-bold mb-0 text_value"
                                                        style={{ fontSize: "15px" }}
                                                    >
                                                        <span className="fw-bold" style={{ fontSize: "15px", color:"#2665F2"}}> $110.00</span>
                                                    </span>
                                                    <span className="fw-bold" style={{ color: "black" }}>
                                                        {/* <span>(+10.00%)</span> */}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0">
                                                <div className="d-sm-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center justify-content-center justify-content-sm-start"
                                                        // style={{ maxWidth: "60%" }}
                                                    >
                                                        <div className="listing-input align-items-center justify-content-center justify-content-md-start">
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-minus me-1"
                                                                onClick={decrementCount}
                                                            >
                                                                <FaMinus />
                                                            </button>
                                                            <div className="inputwithunit ">
                                                                <span className="mr-0">$</span>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={"100.00"}
                                                                    onChange={(e) => setCount(e.target.value)}
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-plus ms-1"
                                                                onClick={incrementCount}
                                                            >
                                                                <FaPlus />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div 
                                                        className="d-flex align-items-center mt-2 mt-sm-0" 
                                                        // style={{ maxWidth: "40%" }}
                                                    >
                                                        <button className="btn btn-main btn-add add_button_New py-1" style={defaultNcentiva
                                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }} 
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="col-lg-4 col-md-6 mb-4">
                                        <div className="listing-block">
                                            <div className="listing-top">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0">
                                                        <div className="listing-top-left justify-content-center justify-content-sm-start">
                                                            <div className="listing-logo">
                                                                <img
                                                                    src="assets/images/gift-logos/delta.png"
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <div className="listing-detail">
                                                                <h6 className="listing-name">Delta</h6>
                                                                <p className="listing-categorydtl">Travel &amp; Experiences</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-top-right">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="listing-bottom">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0 p-1">
                                                        <div className="listing-bottom-left">
                                                            <div className="listing-input justify-content-center justify-content-sm-start">

                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-minus me-1"
                                                                    onClick={decrementCount}
                                                                >
                                                                    <FaMinus />
                                                                </button>
                                                                <div className="inputwithunit mb-2">
                                                                    <span>$</span>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={"100.00"}
                                                                        onChange={(e) => setCount(e.target.value)}
                                                                    />
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-plus ms-1"
                                                                    onClick={incrementCount}
                                                                >
                                                                    <FaPlus />
                                                                </button>

                                                            </div>
                                                            <button type="button" style={defaultNcentiva
                                                                ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                            } className="btn btn-main btn-add py-0">
                                                                Add
                                                            </button>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-bottom-right">
                                                            <span style={{ color: 'black' }}>You get</span>
                                                            <h6>
                                                            , color:"#2665F2"    $110.00
                                                            </h6>
                                                            <span className="fw-bold" style={{ fontSize: "18px", color: 'black' }}>(+ 10.00%)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 mb-4 ps-md">
                                        <div className="card border rounded-3 h-100">
                                            <div className="card-header p-0 border-0">
                                                <img
                                                    className="rounded-top-3 card_image_style"
                                                    style={{ maxWidth: "100%", objectFit: "cover", width: "100%" }}
                                                    src="https://d20s221jcw9wlp.cloudfront.net/7179f82b-0d34-44d1-be55-a1d8e9209ab5"
                                                    alt="Gift Card Image"
                                                />
                                                <div className=" p-1 w-25 position-absolute text-center custom_discount" >+10.00%</div>
                                            </div>
                                            <div className="card-body py-0">
                                                <div className="d-flex justify-content-between align-items-center bg-white text-dark pt-1 responsive_text mt-0">
                                                    <h6>Chevron Texaco - Happy Card</h6>
                                                </div>
                                                <small
                                                    style={{ cursor: "pointer" }}
                                                    className="bg-white text-dark responsive_text mt-0"
                                                    title="Delivery Time"
                                                >
                                                    Specialty
                                                </small>
                                                <br />
                                                <div className="pt-0">
                                                    <span style={{ color: "black" }}>You Receive: </span>
                                                    <span
                                                        className=" fw-bold mb-0 text_value"
                                                        style={{ fontSize: "15px" }}
                                                    >
                                                        <span className="fw-bold" style={{ fontSize: "15px", color:"#2665F2"}}> $110.00</span>
                                                    </span>
                                                    <span className="fw-bold" style={{ color: "black" }}>
                                                        {/* <span>(+10.00%)</span> */}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0">
                                                <div className="d-sm-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center justify-content-center justify-content-sm-start"
                                                        // style={{ maxWidth: "60%" }}
                                                    >
                                                        <div className="listing-input align-items-center justify-content-center justify-content-md-start">
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-minus me-1"
                                                                onClick={decrementCount}
                                                            >
                                                                <FaMinus />
                                                            </button>
                                                            <div className="inputwithunit ">
                                                                <span className="mr-0">$</span>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={"100.00"}
                                                                    onChange={(e) => setCount(e.target.value)}
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-plus ms-1"
                                                                onClick={incrementCount}
                                                            >
                                                                <FaPlus />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div 
                                                        className="d-flex align-items-center mt-2 mt-sm-0" 
                                                        // style={{ maxWidth: "40%" }}
                                                    >
                                                        <button className="btn btn-main btn-add add_button_New py-1" style={defaultNcentiva
                                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }} 
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        

                                    {/* <div className="col-lg-4 col-md-6 mb-4">
                                        <div className="listing-block">
                                            <div className="listing-top">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0">
                                                        <div className="listing-top-left justify-content-center justify-content-sm-start">
                                                            <div className="listing-logo">
                                                                <img
                                                                    src="assets/images/logos/dominos-pizza.png"
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <div className="listing-detail">
                                                                <h6 className="listing-name">Domino’s</h6>
                                                                <p className="listing-categorydtl">Food &amp; Restaurants</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-top-right">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="listing-bottom">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0 p-1">
                                                        <div className="-left">
                                                            <div className="listing-input justify-content-center justify-content-sm-start">

                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-minus me-1"
                                                                    onClick={decrementCount}
                                                                >
                                                                    <FaMinus />
                                                                </button>
                                                                <div className="inputwithunit mb-2">
                                                                    <span>$</span>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={"100.00"}
                                                                        onChange={(e) => setCount(e.target.value)}
                                                                    />
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-plus ms-1"
                                                                    onClick={incrementCount}
                                                                >
                                                                    <FaPlus />
                                                                </button>

                                                            </div>
                                                            <button type="button" style={defaultNcentiva
                                                                ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                            } className="btn btn-main btn-add py-0">
                                                                Add
                                                            </button>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-bottom-right">
                                                            <span style={{ color: 'black' }}>You get</span>
                                                            <h6>
                                                            , color:"#2665F2"    $110.00
                                                            </h6>
                                                            <span className="fw-bold" style={{ fontSize: "18px", color: 'black' }}>(+ 10.00 %)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}


                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 mb-4 ps-md">
                                            <div className="card border rounded-3 h-100">
                                                <div className="card-header p-0 border-0">
                                                    <img
                                                        className="rounded-top-3 card_image_style"
                                                        style={{ maxWidth: "100%", objectFit: "cover", width: "100%" }}
                                                        src="https://d20s221jcw9wlp.cloudfront.net/a28aba6e-7d9b-494c-98b0-e58ad28f919f"
                                                        alt="Gift Card Image"
                                                    />
                                                    <div className=" p-1 w-25 position-absolute text-center custom_discount" >+10.00%</div>
                                                </div>
                                                <div className="card-body py-0">
                                                    <div className="d-flex justify-content-between align-items-center bg-white text-dark pt-1 responsive_text mt-0">
                                                        <h6>Game Stop - Happy Card</h6>
                                                    </div>
                                                    <small
                                                        style={{ cursor: "pointer" }}
                                                        className="bg-white text-dark responsive_text mt-0"
                                                        title="Delivery Time"
                                                    >
                                                        Specialty
                                                    </small>
                                                    <br />
                                                    <div className="pt-0">
                                                        <span style={{ color: "black" }}>You Receive: </span>
                                                        <span
                                                            className=" fw-bold mb-0 text_value"
                                                            style={{ fontSize: "15px" }}
                                                        >
                                                            <span className="fw-bold" style={{ fontSize: "15px", color:"#2665F2"}}> $110.00</span>
                                                        </span>
                                                        <span className="fw-bold" style={{ color: "black" }}>
                                                            {/* <span>(+10.00%)</span> */}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="card-footer bg-transparent border-0">
                                                    <div className="d-sm-flex justify-content-between align-items-center">
                                                        <div className="d-flex align-items-center justify-content-center justify-content-sm-start"
                                                            // style={{ maxWidth: "60%" }}
                                                        >
                                                            <div className="listing-input align-items-center justify-content-center justify-content-md-start">
                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-minus me-1"
                                                                    onClick={decrementCount}
                                                                >
                                                                    <FaMinus />
                                                                </button>
                                                                <div className="inputwithunit ">
                                                                    <span className="mr-0">$</span>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={"100.00"}
                                                                        onChange={(e) => setCount(e.target.value)}
                                                                    />
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-plus ms-1"
                                                                    onClick={incrementCount}
                                                                >
                                                                    <FaPlus />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div 
                                                            className="d-flex align-items-center mt-2 mt-sm-0" 
                                                            // style={{ maxWidth: "40%" }}
                                                        >
                                                            <button className="btn btn-main btn-add add_button_New py-1" style={defaultNcentiva
                                                                ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }} 
                                                            >
                                                                Add
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>



                                    {/* <div className="col-lg-4 col-md-6 mb-4">
                                        <div className="listing-block">
                                            <div className="listing-top">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0">
                                                        <div className="listing-top-left justify-content-center justify-content-sm-start">
                                                            <div className="listing-logo">
                                                                <img
                                                                    src="assets/images/logos/ebay.png"
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <div className="listing-detail">
                                                                <h6 className="listing-name">eBay</h6>
                                                                <p className="listing-categorydtl">General Merchadise</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-top-right">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="listing-bottom">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0 p-1">
                                                        <div className="listing-bottom-left">
                                                            <div className="listing-input justify-content-center justify-content-sm-start">

                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-minus me-1"
                                                                    onClick={decrementCount}
                                                                >
                                                                    <FaMinus />
                                                                </button>
                                                                <div className="inputwithunit mb-2">
                                                                    <span>$</span>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={"100.00"}
                                                                        onChange={(e) => setCount(e.target.value)}
                                                                    />
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-plus ms-1"
                                                                    onClick={incrementCount}
                                                                >
                                                                    <FaPlus />
                                                                </button>

                                                            </div>
                                                            <button type="button" style={defaultNcentiva
                                                                ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                            } className="btn btn-main btn-add py-0">
                                                                Add
                                                            </button>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-bottom-right">
                                                            <span style={{ color: 'black' }}>You get</span>
                                                            <h6>
                                                            , color:"#2665F2"    $110.00
                                                            </h6>
                                                            <span className="fw-bold" style={{ fontSize: "18px", color: 'black' }}>(+ 10.00 %)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 mb-4 ps-md">
                                        <div className="card border rounded-3 h-100">
                                            <div className="card-header p-0 border-0">
                                                <img
                                                    className="rounded-top-3 card_image_style"
                                                    style={{ maxWidth: "100%", objectFit: "cover", width: "100%" }}
                                                    src="https://d20s221jcw9wlp.cloudfront.net/fa432694-7171-4cdc-ba37-9323d6e446be"
                                                    alt="Gift Card Image"
                                                />
                                                <div className=" p-1 w-25 position-absolute text-center custom_discount" >+10.00%</div>
                                            </div>
                                            <div className="card-body py-0">
                                                <div className="d-flex justify-content-between align-items-center bg-white text-dark pt-1 responsive_text mt-0">
                                                    <h6>Home Depot</h6>
                                                </div>
                                                <small
                                                    style={{ cursor: "pointer" }}
                                                    className="bg-white text-dark responsive_text mt-0"
                                                    title="Delivery Time"
                                                >
                                                    Home & Garden
                                                </small>
                                                <br />
                                                <div className="pt-0">
                                                    <span style={{ color: "black" }}>You Receive: </span>
                                                    <span
                                                        className=" fw-bold mb-0 text_value"
                                                        style={{ fontSize: "15px" }}
                                                    >
                                                        <span className="fw-bold" style={{ fontSize: "15px", color:"#2665F2"}}> $110.00</span>
                                                    </span>
                                                    <span className="fw-bold" style={{ color: "black" }}>
                                                        {/* <span>(+10.00%)</span> */}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0">
                                                <div className="d-sm-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center justify-content-center justify-content-sm-start"
                                                        // style={{ maxWidth: "60%" }}
                                                    >
                                                        <div className="listing-input align-items-center justify-content-center justify-content-md-start">
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-minus me-1"
                                                                onClick={decrementCount}
                                                            >
                                                                <FaMinus />
                                                            </button>
                                                            <div className="inputwithunit ">
                                                                <span className="mr-0">$</span>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={"100.00"}
                                                                    onChange={(e) => setCount(e.target.value)}
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-plus ms-1"
                                                                onClick={incrementCount}
                                                            >
                                                                <FaPlus />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div 
                                                        className="d-flex align-items-center" 
                                                        // style={{ maxWidth: "40%" }}
                                                    >
                                                        <button className="btn btn-main btn-add add_button_New py-1" style={defaultNcentiva
                                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }} 
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="col-lg-4 col-md-6 mb-4">
                                        <div className="listing-block">
                                            <div className="listing-top">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0">
                                                        <div className="listing-top-left justify-content-center justify-content-sm-start">
                                                            <div className="listing-logo">
                                                                <img
                                                                    src="assets/images/gift-logos/gamestop.png"
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <div className="listing-detail">
                                                                <h6 className="listing-name">Game Stop</h6>
                                                                <p className="listing-categorydtl">Streaming &amp; Gaming</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-top-right">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="listing-bottom">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0 p-1">
                                                        <div className="listing-bottom-left">
                                                            <div className="listing-input justify-content-center justify-content-sm-start">

                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-minus me-1"
                                                                    onClick={decrementCount}
                                                                >
                                                                    <FaMinus />
                                                                </button>
                                                                <div className="inputwithunit mb-2">
                                                                    <span>$</span>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={"100.00"}
                                                                        onChange={(e) => setCount(e.target.value)}
                                                                    />
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-plus ms-1"
                                                                    onClick={incrementCount}
                                                                >
                                                                    <FaPlus />
                                                                </button>

                                                            </div>
                                                            <button type="button" style={defaultNcentiva
                                                                ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                            } className="btn btn-main btn-add py-0">
                                                                Add
                                                            </button>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-bottom-right">
                                                            <span style={{ color: 'black' }}>You get</span>
                                                            <h6>
                                                            , color:"#2665F2"    $110.00
                                                            </h6>
                                                            <span className="fw-bold" style={{ fontSize: "18px", color: 'black' }}>(+ 10.00%)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 mb-4 ps-md">
                                        <div className="card border rounded-3 h-100">
                                            <div className="card-header p-0 border-0">
                                                <img
                                                    className="rounded-top-3 card_image_style"
                                                    style={{ maxWidth: "100%", objectFit: "cover", width: "100%" }}
                                                    src="https://d20s221jcw9wlp.cloudfront.net/8efd54bf-e456-475d-9ba0-8ce1b7ee688a"
                                                    alt="Gift Card Image"
                                                />
                                                <div className=" p-1 w-25 position-absolute text-center custom_discount" >+10.00%</div>
                                            </div>
                                            <div className="card-body py-0">
                                                <div className="d-flex justify-content-between align-items-center bg-white text-dark pt-1 responsive_text mt-0">
                                                    <h6>Macy’s</h6>
                                                </div>
                                                <small
                                                    style={{ cursor: "pointer" }}
                                                    className="bg-white text-dark responsive_text mt-0"
                                                    title="Delivery Time"
                                                >
                                                    Department Stores
                                                </small>
                                                <br />
                                                <div className="pt-0">
                                                    <span style={{ color: "black" }}>You Receive: </span>
                                                    <span
                                                        className=" fw-bold mb-0 text_value"
                                                        style={{ fontSize: "15px" }}
                                                    >
                                                        <span className="fw-bold" style={{ fontSize: "15px", color:"#2665F2"}}> $110.00</span>
                                                    </span>
                                                    <span className="fw-bold" style={{ color: "black" }}>
                                                        {/* <span>(+10.00%)</span> */}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0">
                                                <div className="d-sm-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center justify-content-center justify-content-sm-start"
                                                        // style={{ maxWidth: "60%" }}
                                                    >
                                                        <div className="listing-input align-items-center justify-content-center justify-content-md-start">
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-minus me-1"
                                                                onClick={decrementCount}
                                                            >
                                                                <FaMinus />
                                                            </button>
                                                            <div className="inputwithunit ">
                                                                <span className="mr-0">$</span>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={"100.00"}
                                                                    onChange={(e) => setCount(e.target.value)}
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-plus ms-1"
                                                                onClick={incrementCount}
                                                            >
                                                                <FaPlus />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div 
                                                        className="d-flex align-items-center mt-2 mt-sm-0" 
                                                        // style={{ maxWidth: "40%" }}
                                                    >
                                                        <button className="btn btn-main btn-add add_button_New py-1" style={defaultNcentiva
                                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }} 
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    {/* <div className="col-lg-4 col-md-6 mb-4">
                                        <div className="listing-block">
                                            <div className="listing-top">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0">
                                                        <div className="listing-top-left justify-content-center justify-content-sm-start">
                                                            <div className="listing-logo">
                                                                <img
                                                                    src="assets/images/logos/the-home-depot.png"
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <div className="listing-detail">
                                                                <h6 className="listing-name">Home Depot</h6>
                                                                <p className="listing-categorydtl">Food &amp; Restaurants</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-top-right">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="listing-bottom">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0 p-1">
                                                        <div className="listing-bottom-left">
                                                            <div className="listing-input justify-content-center justify-content-sm-start">

                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-minus me-1"
                                                                    onClick={decrementCount}
                                                                >
                                                                    <FaMinus />
                                                                </button>
                                                                <div className="inputwithunit mb-2">
                                                                    <span>$</span>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={"100.00"}
                                                                        onChange={(e) => setCount(e.target.value)}
                                                                    />
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-plus ms-1"
                                                                    onClick={incrementCount}
                                                                >
                                                                    <FaPlus />
                                                                </button>

                                                            </div>
                                                            <button type="button" style={defaultNcentiva
                                                                ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                                : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                            } className="btn btn-main btn-add py-0">
                                                                Add
                                                            </button>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-bottom-right">
                                                            <span style={{ color: 'black' }}>You get</span>
                                                            <h6>
                                                            , color:"#2665F2"    $110.00
                                                            </h6>
                                                            <span className="fw-bold" style={{ fontSize: "18px", color: 'black' }}>(+ 10.00%)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 mb-4 ps-md">
                                        <div className="card border rounded-3 h-100">
                                            <div className="card-header p-0 border-0">
                                                <img
                                                    className="rounded-top-3 card_image_style"
                                                    style={{ maxWidth: "100%", objectFit: "cover", width: "100%" }}
                                                    src="https://d20s221jcw9wlp.cloudfront.net/e364151d-1a42-4472-82b2-2bb1e1de3130"
                                                    alt="Gift Card Image"
                                                />
                                                <div className=" p-1 w-25 position-absolute text-center custom_discount" >+10.00%</div>
                                            </div>
                                            <div className="card-body py-0">
                                                <div className="d-flex justify-content-between align-items-center bg-white text-dark pt-1 responsive_text mt-0">
                                                    <h6>Nike</h6>
                                                </div>
                                                <small
                                                    style={{ cursor: "pointer" }}
                                                    className="bg-white text-dark responsive_text mt-0"
                                                    title="Delivery Time"
                                                >
                                                    Clothing & Accessories
                                                </small>
                                                <br />
                                                <div className="pt-0">
                                                    <span style={{ color: "black" }}>You Receive: </span>
                                                    <span
                                                        className=" fw-bold mb-0 text_value"
                                                        style={{ fontSize: "15px" }}
                                                    >
                                                        <span className="fw-bold" style={{ fontSize: "15px", color:"#2665F2"}}> $110.00</span>
                                                    </span>
                                                    <span className="fw-bold" style={{ color: "black" }}>
                                                        {/* <span>(+10.00%)</span> */}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0">
                                                <div className="d-sm-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center justify-content-center justify-content-sm-start"
                                                        // style={{ maxWidth: "60%" }}
                                                    >
                                                        <div className="listing-input align-items-center justify-content-center justify-content-md-start">
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-minus me-1"
                                                                onClick={decrementCount}
                                                            >
                                                                <FaMinus />
                                                            </button>
                                                            <div className="inputwithunit ">
                                                                <span className="mr-0">$</span>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={"100.00"}
                                                                    onChange={(e) => setCount(e.target.value)}
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-plus ms-1"
                                                                onClick={incrementCount}
                                                            >
                                                                <FaPlus />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div 
                                                        className="d-flex align-items-center mt-2 mt-sm-0" 
                                                        // style={{ maxWidth: "40%" }}
                                                    >
                                                        <button className="btn btn-main btn-add add_button_New py-1" style={defaultNcentiva
                                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }} 
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="col-lg-4 col-md-6 mb-4">
                                        <div className="listing-block">
                                            <div className="listing-top">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0">
                                                        <div className="listing-top-left justify-content-center justify-content-sm-start">
                                                            <div className="listing-logo">
                                                                <img
                                                                    src="assets/images/logos/nike.png"
                                                                    alt=""
                                                                    className="img-fluid"
                                                                />
                                                            </div>
                                                            <div className="listing-detail">
                                                                <h6 className="listing-name">Nike</h6>
                                                                <p className="listing-categorydtl">Sports &amp; Outdoors</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-top-right">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="listing-bottom">
                                                <div className="row">
                                                    <div className="col-sm-7 mb-3 mb-sm-0 p-1">
                                                        <div className="listing-bottom-left">
                                                            <div className="listing-input justify-content-center justify-content-sm-start">
                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-minus me-1"
                                                                >
                                                                    <FaMinus />
                                                                </button>
                                                                <div className="inputwithunit mb-2">
                                                                    <span>$</span>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        defaultValue={100.00}
                                                                    />
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn listing-input-btn btn-plus ms-1"
                                                                >
                                                                    <FaPlus />
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <div className="listing-bottom-right">
                                                            <span style={{ color: 'black' }}>You get</span>
                                                            <h6>
                                                            , color:"#2665F2"    $110.00
                                                            </h6>
                                                            <span className="fw-bold" style={{ fontSize: "18px", color: 'black' }}>(+ 10.00%)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 mb-4 ps-md">
                                        <div className="card border rounded-3 h-100">
                                            <div className="card-header p-0 border-0">
                                                <img
                                                    className="rounded-top-3 card_image_style"
                                                    style={{ maxWidth: "100%", objectFit: "cover", width: "100%" }}
                                                    src="https://d20s221jcw9wlp.cloudfront.net/beb69db3-e384-4c7c-8cfb-e371b4762c39"
                                                    alt="Gift Card Image"
                                                />
                                                <div className=" p-1 w-25 position-absolute text-center custom_discount" >+10.00%</div>
                                            </div>
                                            <div className="card-body py-0">
                                                <div className="d-flex justify-content-between align-items-center bg-white text-dark pt-1 responsive_text mt-0">
                                                    <h6>Old Navy - Happy Card</h6>
                                                </div>
                                                <small
                                                    style={{ cursor: "pointer" }}
                                                    className="bg-white text-dark responsive_text mt-0"
                                                    title="Delivery Time"
                                                >
                                                    Specialty
                                                </small>
                                                <br />
                                                <div className="pt-0">
                                                    <span style={{ color: "black" }}>You Receive: </span>
                                                    <span
                                                        className=" fw-bold mb-0 text_value"
                                                        style={{ fontSize: "15px" }}
                                                    >
                                                        <span className="fw-bold" style={{ fontSize: "15px" , color:"#2665F2"}}> $110.00</span>
                                                    </span>
                                                    <span className="fw-bold" style={{ color: "black" }}>
                                                        {/* <span>(+10.00%)</span> */}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0">
                                                <div className="d-sm-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center justify-content-center justify-content-sm-start"
                                                        // style={{ maxWidth: "60%" }}
                                                    >
                                                        <div className="listing-input align-items-center justify-content-center justify-content-md-start">
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-minus me-1"
                                                            >
                                                                <FaMinus />
                                                            </button>
                                                            <div className="inputwithunit ">
                                                                <span className="mr-0">$</span>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    defaultValue={100.00}
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-plus ms-1"
                                                            >
                                                                <FaPlus />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div 
                                                        className="d-flex align-items-center mt-2 mt-sm-0" 
                                                        // style={{ maxWidth: "40%" }}
                                                    >
                                                        <button className="btn btn-main btn-add add_button_New py-1" style={defaultNcentiva
                                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }} 
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 mb-4 ps-md">
                                        <div className="card border rounded-3 h-100">
                                            <div className="card-header p-0 border-0">
                                                <img
                                                    className="rounded-top-3 card_image_style"
                                                    style={{ maxWidth: "100%", objectFit: "cover", width: "100%" }}
                                                    src="https://d20s221jcw9wlp.cloudfront.net/f4dd9cc4-fc0c-4b89-919e-19948cb96e11"
                                                    alt="Gift Card Image"
                                                />
                                                <div className=" p-1 w-25 position-absolute text-center custom_discount" >+10.00%</div>
                                            </div>
                                            <div className="card-body py-0">
                                                <div className="d-flex justify-content-between align-items-center bg-white text-dark pt-1 responsive_text mt-0">
                                                    <h6>PlayStation</h6>
                                                </div>
                                                <small
                                                    style={{ cursor: "pointer" }}
                                                    className="bg-white text-dark responsive_text mt-0"
                                                    title="Delivery Time"
                                                >
                                                    Gaming
                                                </small>
                                                <br />
                                                <div className="pt-0">
                                                    <span style={{ color: "black" }}>You Receive: </span>
                                                    <span
                                                        className=" fw-bold mb-0 text_value"
                                                        style={{ fontSize: "15px" }}
                                                    >
                                                        <span className="fw-bold" style={{ fontSize: "15px" , color:"#2665F2"}}> $110.00</span>
                                                    </span>
                                                    <span className="fw-bold" style={{ color: "black" }}>
                                                        {/* <span>(+10.00%)</span> */}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0">
                                                <div className="d-sm-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center justify-content-center justify-content-sm-start"
                                                        // style={{ maxWidth: "60%" }}
                                                    >
                                                        <div className="listing-input align-items-center justify-content-center justify-content-md-start">
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-minus me-1"
                                                            >
                                                                <FaMinus />
                                                            </button>
                                                            <div className="inputwithunit ">
                                                                <span className="mr-0">$</span>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    defaultValue={100.00}
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-plus ms-1"
                                                            >
                                                                <FaPlus />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div 
                                                        className="d-flex align-items-center mt-2 mt-sm-0" 
                                                        // style={{ maxWidth: "40%" }}
                                                    >
                                                        <button className="btn btn-main btn-add add_button_New py-1" style={defaultNcentiva
                                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }} 
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 mb-4 ps-md">
                                        <div className="card border rounded-3 h-100">
                                            <div className="card-header p-0 border-0">
                                                <img
                                                    className="rounded-top-3 card_image_style"
                                                    style={{ maxWidth: "100%", objectFit: "cover", width: "100%" }}
                                                    src="https://d20s221jcw9wlp.cloudfront.net/6e23beba-6690-40a0-8f91-9f7bb758c99e"
                                                    alt="Gift Card Image"
                                                />
                                                <div className=" p-1 w-25 position-absolute text-center custom_discount" >+10.00%</div>
                                            </div>
                                            <div className="card-body py-0">
                                                <div className="d-flex justify-content-between align-items-center bg-white text-dark pt-1 responsive_text mt-0">
                                                    <h6>Razer Gold</h6>
                                                </div>
                                                <small
                                                    style={{ cursor: "pointer" }}
                                                    className="bg-white text-dark responsive_text mt-0"
                                                    title="Delivery Time"
                                                >
                                                    Gaming
                                                </small>
                                                <br />
                                                <div className="pt-0">
                                                    <span style={{ color: "black" }}>You Receive: </span>
                                                    <span
                                                        className=" fw-bold mb-0 text_value"
                                                        style={{ fontSize: "15px" }}
                                                    >
                                                        <span className="fw-bold" style={{ fontSize: "15px" , color:"#2665F2"}}> $110.00</span>
                                                    </span>
                                                    <span className="fw-bold" style={{ color: "black" }}>
                                                        {/* <span>(+10.00%)</span> */}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0">
                                                <div className="d-sm-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center justify-content-center justify-content-sm-start"
                                                        // style={{ maxWidth: "60%" }}
                                                    >
                                                        <div className="listing-input align-items-center justify-content-center justify-content-md-start">
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-minus me-1"
                                                            >
                                                                <FaMinus />
                                                            </button>
                                                            <div className="inputwithunit ">
                                                                <span className="mr-0">$</span>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    defaultValue={100.00}
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-plus ms-1"
                                                            >
                                                                <FaPlus />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div 
                                                        className="d-flex align-items-center mt-2 mt-sm-0" 
                                                        // style={{ maxWidth: "40%" }}
                                                    >
                                                        <button className="btn btn-main btn-add add_button_New py-1" style={defaultNcentiva
                                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }} 
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 mb-4 ps-md">
                                        <div className="card border rounded-3 h-100">
                                            <div className="card-header p-0 border-0">
                                                <img
                                                    className="rounded-top-3 card_image_style"
                                                    style={{ maxWidth: "100%", objectFit: "cover", width: "100%" }}
                                                    src="https://d20s221jcw9wlp.cloudfront.net/8d2c55ac-08e9-4728-9737-7a8564f1ec71"
                                                    alt="Gift Card Image"
                                                />
                                                <div className=" p-1 w-25 position-absolute text-center custom_discount" >+10.00%</div>
                                            </div>
                                            <div className="card-body py-0">
                                                <div className="d-flex justify-content-between align-items-center bg-white text-dark pt-1 responsive_text mt-0">
                                                    <h6>Sephora </h6>
                                                </div>
                                                <small
                                                    style={{ cursor: "pointer" }}
                                                    className="bg-white text-dark responsive_text mt-0"
                                                    title="Delivery Time"
                                                >
                                                    Health, Spa & Beauty
                                                </small>
                                                <br />
                                                <div className="pt-0">
                                                    <span style={{ color: "black" }}>You Receive: </span>
                                                    <span
                                                        className=" fw-bold mb-0 text_value"
                                                        style={{ fontSize: "15px" }}
                                                    >
                                                        <span className="fw-bold" style={{ fontSize: "15px" , color:"#2665F2"}}> $110.00</span>
                                                    </span>
                                                    <span className="fw-bold" style={{ color: "black" }}>
                                                        {/* <span>(+10.00%)</span> */}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0">
                                                <div className="d-sm-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center justify-content-center justify-content-sm-start"
                                                        // style={{ maxWidth: "60%" }}
                                                    >
                                                        <div className="listing-input align-items-center justify-content-center justify-content-md-start">
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-minus me-1"
                                                            >
                                                                <FaMinus />
                                                            </button>
                                                            <div className="inputwithunit ">
                                                                <span className="mr-0">$</span>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    defaultValue={100.00}
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="btn listing-input-btn btn-plus ms-1"
                                                            >
                                                                <FaPlus />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div 
                                                        className="d-flex align-items-center mt-2 mt-sm-0" 
                                                        // style={{ maxWidth: "40%" }}
                                                    >
                                                        <button className="btn btn-main btn-add add_button_New py-1" style={defaultNcentiva
                                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }} 
                                                        >
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </section>


                    <section className={`go-checkout fixed-bottom w-100 bg-white border-top py-3 ${isHighlightAgain ? '' : 'highlight'}   ${isActive ? '' : 'active'}`}>
                        

                        {/* <button className={isActive ? "chekout-toggle text-dark" : "chekout-toggle text-dark rotate"} onClick={toggleClick}><FaChevronUp /></button> */}
                        <div className="container">
                            <div className="row">
                                {/* <div className="col-6 col-lg-3 order-1 order-lg-1">
                                    <div className="checkout-left border-end h-100">
                                        <h5 style={{ color: primaryColor }}>
                                            Funds Used <span>$0.00</span>
                                        </h5>
                                        <h5>
                                            Remaining <span>$0.00</span>
                                        </h5>
                                        <h5 style={{ color: btnColor }}>
                                            Bonus <span>$0.00</span>
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 order-3 order-lg-2">
                                    <div className="checkout-middle h-100">
                                        <RedeemProgress lwidth="80%" rwidth="20.00%" amount={0.00} bonus={0.00} rFund={0.00} />
                                        <div className="chekout-middle-table">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th />
                                                            <th className="text-dark">Gift Card Cost</th>
                                                            <th className="text-main">Gift Card Value</th>
                                                            <th />
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-uppercase">Amazon gift card</td>
                                                            <td className="text-dark">$00.00</td>
                                                            <td className="text-main">$00.00</td>
                                                            <td>
                                                                <button type="button" className="btn delete-check p-0">
                                                                    <i className="fal fa-trash-alt" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-uppercase">NIKE GIFT CARD</td>
                                                            <td className="text-dark">$0.00</td>
                                                            <td className="text-main">$0.00</td>
                                                            <td>
                                                                <button type="button" className="btn delete-check p-0">
                                                                    <i className="fal fa-trash-alt" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}


                              <Offcanvas
                                show={true}
                                placement={"end"}
                                backdrop={false}
                                className="checkout_offcanvas_content_onboard"
                                style={{
                                    // bottom: '10',
                                    // top: "auto",
                                    // maxWidth: "400px",
                                    // position: "invert-layer",
                                    // height: "86.45%",
                                    // height: `calc(100vh - ${headerHeight}px)`,
                                    transition: "all ease-in-out 1s",
                                    // border: "grey",
                                    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", 
                                    transform: !offcanvasOpen ? "translateX(96%)" : "translateX(0%)",
                                }}
                                scroll={true}
                                >
                                <Offcanvas.Header className='pt-0 '>
                                    <Offcanvas.Title>CheckOut Details</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body
                                    className="p-0 d-flex flex-column justify-content-between "
                                    
                                >
                                    <div className="overflow-auto listBox " >
                                    <div>
                                        <button
                                            //  className={isActive ? "chekout-toggle text-dark" : "chekout-toggle text-dark rotate"}
                                            className="new-chekout-toggle text-dark position-absolute"
                                            
                                        >
                                            {offcanvasOpen 
                                                ?  
                                                <span className='btn_arrow_check' onClick={togggleOpen}>
                                                    <FaChevronRight/>
                                                </span> 
                                                :
                                                <span className='btn_arrow_check me-1' onClick={togggleClose}>
                                                    <FaChevronLeft />
                                                </span>
                                            }
                                        {/* </button>  */}
                                    <div>
                                    {isShown && (
                            <div className="onboard-step step-2">
                                <div className="d-flex align-item-center justify-content-between onboard-header mb-2 mb-sm-4">
                                    <h3>Keep track of your funds</h3>
                                    <Link replace to='/' className="onboard-close-btn border-0">
                                        <FaTimes />
                                    </Link>
                                </div>
                                <div className="onboard-middle mb-2 mb-sm-4" style={{textAlign:"left"}}>
                                    <p>
                                        Use the funds tracker for a quick overview; of funds used, any remaining balance,and any bonus funds earned.
                                    </p>
                                </div>
                                <div className="d-flex align-items-center onboard-footer">
                                    <button
                                        type="button"
                                        className="btn btn-main onboard-next2 me-3 px-4 px-lg-5"
                                        style={defaultNcentiva
                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                        }
                                        onClick={handleClickLast}
                                    >
                                        Next
                                    </button>
                                    <Link replace to="/" onClick={handleSession} className="text-dark me-3">
                                        Skip this
                                    </Link>
                                    <div className="d-flex align-items-center onboard-paginate">
                                        <span onClick={handleClickFirst} />
                                        <span className="active" style={{ background: primaryColor }} />
                                        <span onClick={handleClickLast} />
                                    </div>
                                </div>
                            </div>
                        )}
                                    </div>
                                    </button>
                                    </div>
                                  
                                        <div
                                            className="  p-0 px-3 col-12 justify-content-between"
                                            style={{
                                            fontSize: "14px",
                                            lineHeight: "16px",
                                            fontWeight: "400",
                                            padding: "10px",
                                            }}
                                            
                                        >
                                            <p
                                            className="text-start pb-2"
                                            style={{ fontSize: "16px", fontWeight: "600" }}
                                            >
                                            Amazon.com
                                            </p>
                                            <div className="d-flex pb-3 flex-row justify-content-between">
                                            <div className="col-4 ">
                                                <img
                                                src="https://d20s221jcw9wlp.cloudfront.net/3813c67c-0b08-4191-b7d4-3165020cec42"
                                                style={{ height: "75px", width: "120px" }}
                                                />
                                            </div>
                                            <div className="col-5 ">
                                                <div className="d-flex pb-1 col-12 flex-row justify-content-between">
                                                <p className="col-8">Cost</p>
                                                <p className="col-4 ">$40</p>
                                                </div>
                                                <div className="d-flex pb-1 col-12 flex-row justify-content-between" style={{color:"#2665F2"}}>
                                                <p className="col-8">Bonus</p>
                                                <p className="col-4 ">$40</p>
                                                </div>
                                                <div className="d-flex pb-1 col-12 flex-row justify-content-between">
                                                <p className="col-8">Cart value</p>
                                                <p className="col-4 ">$40</p>
                                                </div>
                                                <div className="d-flex pb-1 col-12 flex-row justify-content-between">
                                                {/* <p className="col-8">Bonus Cash</p>
                                                <p className="col-4 ">
                                                    $40
                                                </p> */}
                                                </div>
                                            </div>
                                            <div className="d-flex   flex-row-reverse col-auto">
                                                <FaTrash style={{ cursor: "pointer", color: "grey" }} />
                                            </div>
                                            </div>
                                            <hr
                                            style={{
                                                width: "100%",
                                                borderTop: "1px solid black",
                                                margin: "10px 0",
                                                height: "1px",
                                            }}
                                            />
                                        </div>
                                        <div
                                            className="  p-0 px-3 col-12 justify-content-between"
                                            style={{
                                            fontSize: "14px",
                                            lineHeight: "16px",
                                            fontWeight: "400",
                                            padding: "10px",
                                            }}
                                            
                                        >
                                            <p
                                            className="text-start pb-2"
                                            style={{ fontSize: "16px", fontWeight: "600" }}
                                            >
                                            Macy’s
                                            </p>
                                            <div className="d-flex pb-3 flex-row justify-content-between">
                                            <div className="col-4 ">
                                                <img
                                                src="https://d20s221jcw9wlp.cloudfront.net/8efd54bf-e456-475d-9ba0-8ce1b7ee688a"
                                                style={{ height: "75px", width: "120px", objectFit:"cover" }}
                                                />
                                            </div>
                                            <div className="col-5 ">
                                                <div className="d-flex pb-1 col-12 flex-row justify-content-between">
                                                <p className="col-8">Cost</p>
                                                <p className="col-4 ">$40</p>
                                                </div>
                                                <div className="d-flex pb-1 col-12 flex-row justify-content-between">
                                                {/* <p className="col-8">Bonus</p>
                                                <p className="col-4 ">$40</p> */}
                                                </div>
                                                <div className="d-flex pb-1 col-12 flex-row justify-content-between">
                                                <p className="col-8">Cart value</p>
                                                <p className="col-4 ">$40</p>
                                                </div>
                                                <div className="d-flex pb-1 col-12 flex-row justify-content-between" style={{color:"#F5C400"}}>
                                                <p className="col-8">Bonus Cash</p>
                                                <p className="col-4 ">
                                                    $40
                                                </p>
                                                </div>
                                            </div>
                                            <div className="d-flex   flex-row-reverse col-auto">
                                                <FaTrash style={{ cursor: "pointer", color: "grey" }} />
                                            </div>
                                            </div>
                                            <hr
                                            style={{
                                                width: "100%",
                                                borderTop: "1px solid black",
                                                margin: "10px 0",
                                                height: "1px",
                                            }}
                                            />
                                        </div>
                                        <div
                                            className="  p-0 px-3 col-12 justify-content-between"
                                            style={{
                                            fontSize: "14px",
                                            lineHeight: "16px",
                                            fontWeight: "400",
                                            padding: "10px",
                                            }}
                                            
                                        >
                                            <p
                                            className="text-start pb-2"
                                            style={{ fontSize: "16px", fontWeight: "600" }}
                                            >
                                            Nike
                                            </p>
                                            <div className="d-flex pb-3 flex-row justify-content-between">
                                            <div className="col-4 ">
                                                <img
                                                src="https://d20s221jcw9wlp.cloudfront.net/e364151d-1a42-4472-82b2-2bb1e1de3130"
                                                style={{ height: "75px", width: "120px", objectFit:"cover" }}
                                                />
                                            </div>
                                            <div className="col-5 ">
                                                <div className="d-flex pb-1 col-12 flex-row justify-content-between">
                                                <p className="col-8">Cost</p>
                                                <p className="col-4 ">$40</p>
                                                </div>
                                                <div className="d-flex pb-1 col-12 flex-row justify-content-between">
                                                {/* <p className="col-8">Bonus</p>
                                                <p className="col-4 ">$40</p> */}
                                                </div>
                                                <div className="d-flex pb-1 col-12 flex-row justify-content-between">
                                                <p className="col-8">Cart value</p>
                                                <p className="col-4 ">$40</p>
                                                </div>
                                                <div className="d-flex pb-1 col-12 flex-row justify-content-between" style={{color:"#F5C400"}}>
                                                {/* <p className="col-8">Bonus Cash</p>
                                                <p className="col-4 ">
                                                    $40
                                                </p> */}
                                                </div>
                                            </div>
                                            <div className="d-flex   flex-row-reverse col-auto">
                                                <FaTrash style={{ cursor: "pointer", color: "grey" }} />
                                            </div>
                                            </div>
                                            <hr
                                            style={{
                                                width: "100%",
                                                borderTop: "1px solid black",
                                                margin: "10px 0",
                                                height: "1px",
                                            }}
                                            />
                                        </div>
                                        
                                    </div>
                                    
                                   
                                    <div className="px-4">
                                    <div>
                                        <RedeemProgress
                                        lwidth="80%"
                                        rwidth="20.00%"
                                        amount={100}
                                        rFund="10.00"
                                        />
                                    </div>
                                    <div>
                                        <div
                                        className="d-flex col-12 flex-row justify-content-between"
                                        style={{ color: "rgb(255, 112, 32)" }}
                                        >
                                        <p className="col-9">Funds Used</p>
                                        <p className="col-3 ">$120.00</p>
                                        </div>
                                        <div className="d-flex col-12 flex-row justify-content-between">
                                        <p className="col-9">Remaining </p>
                                        <p className="col-3 ">$400.00</p>
                                        </div>
                                        <div className="d-flex col-12 flex-row justify-content-between" style={{color:"#2665F2"}}>
                                        <p className="col-9">Bonus</p>
                                        <p className="col-3 ">$40.00</p>
                                        </div>
                                        <div className="d-flex col-12 flex-row justify-content-between" style={{color:"#F5C400"}}>
                                        <p className="col-9">Remaing Bonus Cash</p>
                                        <p className="col-3 ">$400.00</p>
                                        </div>
                                    </div>
                                    <div>
                                        
                                    {/* {cartList ?  */}
                                     <>
                                     <div className="col-lg-3 col-6 order-2 order-lg-3 d-flex justify-content-center ">
                                    <div className="checkout-right position-relative h-100">
                                        {isHidden && (
                                            <div className="onboard-step step-3" style={{ border: '1px solid black' }}>
                                                <div className="d-flex align-item-center justify-content-between onboard-header mb-2" >
                                                    <h3>Easy & Secure checkout</h3>
                                                    <Link replace to='/' className="onboard-close-btn border-0">
                                                        <FaTimes />
                                                    </Link>
                                                </div>
                                                <div className="onboard-middle mb-2">
                                                    <p>
                                                        Once you have completed shopping click “Go to Checkout” to process your order.
                                                    </p>
                                                </div>
                                                <div className="d-flex align-items-center onboard-footer">
                                                    {/* <Link  onClick={handleClick} className="text-dark me-3">
                                                        Back
                                                    </Link> */}
                                                    <Link
                                                        replace
                                                        to="/"
                                                        className="btn btn-main onboard-next2 me-3 px-4 px-lg-5"
                                                        style={defaultNcentiva
                                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                                        }
                                                        onClick={handleSession}
                                                    >
                                                        Finish
                                                    </Link>

                                                    {/* <div className="d-flex align-items-center onboard-paginate">
                                                        <span onClick={handleClickFirst} />
                                                        <span onClick={handleClick} />
                                                        <span className="active" style={{ background: primaryColor }} />
                                                    </div> */}

                                                </div>
                                            </div>
                                        )}
                                        {/* <Link to="/checkout" style={defaultNcentiva
                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                                        } className="btn btn-main w-100 mt-2">
                                            Go to Checkout
                                        </Link> */}
                                    </div>
                                    </div>
                                    { 
                                    // cartList.length > 0 &&
                                     btnColor ?
                                        <Link to="/checkout"
                                        // onClick={handleLinkClick}
                                            className="btn btn-main w-100 mt-3"
                                            // style={{ backgroundColor: "#4221e5" }}
                                            style={defaultNcentiva
                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent` }
                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }}
                                        >
                                            Go To Checkout
                                        </Link>
                                        :
                                        <>
                                        { btnColor ? <Link to="/checkout"
                                            className="btn btn-main w-100 mt-3"
                                            // style={{ backgroundColor: "#4221e5" }}
                                            style={defaultNcentiva
                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent`, pointerEvents: 'none' }
                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}`, pointerEvents: 'none' }}
                                        >
                                            Go To Checkout 
                                        </Link>: <Link to="/checkout" className='btn btn-main w-100 mt-3' style={{backgroundColor:"#4221e5"}}>Go To Checkout</Link>}
                                        </>
                                        }
                                    </>
                                        {/* :
                                        <>
                                            {btnColor ?  <Link to="/checkout"
                                            className="btn btn-main w-100 mt-3"
                                            // style={{ backgroundColor: "#4221e5" }}
                                            style={defaultNcentiva
                                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid transparent`, pointerEvents: 'none' }
                                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}`, pointerEvents: 'none' }}
                                            >
                                            Go To Checkout 14
                                        </Link> : ''}
                                        </> */}
                                       
                                     </div>
                                    </div>

                                </Offcanvas.Body>
                     </Offcanvas>  

                                
                            </div>
                        </div>
                    </section>
                   
                </>
            }
        </div>
        </>
    )
}

export default OnBoard

