import React, { useState, useEffect } from "react";
// import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Form, Button, Col, Container, Row, DropdownButton} from "react-bootstrap";
import { Bars} from "react-loader-spinner";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { toast } from 'react-toastify';
import { getNewToken } from "../config/cookie.config";
const Brands = () => {
  useEffect(()=>{
    const adminCheck= window.location.pathname;
    if(adminCheck.includes('admin')){ 
    document.title = "ncentiva || Admin Brands"
    }
},[])
  // const [counter, setCounter] = useState(10);
  // const shouldLog = useRef(true);
  var userrole;
  const user = JSON.parse(window.localStorage.getItem("client"));
  if (user) {
    userrole = user.user.role;
  } else {
    userrole = null;
  }

  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("ASC");
  const [giftorder, setGiftorder] = useState("ASC");
  const [role] = useState(userrole);
  const [customerData, setCustomerData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [sort, setSort] = useState("");
  const [noData,setNoData] = useState(false);
  const [showPage,setShowPage] = useState(1)
  var token;
  const navigate = useNavigate();
  const redirectToLogin = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      navigate("/admin");
    }
  };

  
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRowsPerPageChange = (event) => {
    pagenumberSearch(1);
    const selectedRowsPerPage = event.target.value;
    setRowsPerPage(selectedRowsPerPage);
    console.log(pageNumber, "pageNumber")
    getCouponsData(pageNumber, selectedRowsPerPage);
  };
  
  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);

  if (user) {
    token = user.token;
  } else {
    token = null;
  }
  // sorting

  // brand name
  const sorting = (order) => {
    if (order === "DSC") {
      setSort('dscName')

      setOrder('DSC')

    } else {
      setSort('ascName')

      setOrder('ASC')


    }

  };

  // gift card

  const giftSorting = (giftorder) => {
    if (giftorder === "DSC") {
      setSort('dscCard')

      setGiftorder('DSC')

    } else {
      setSort('ascCard')

      setGiftorder('ASC')


    }
  }

  // sorting

  const getCouponsData = (pageNumber, pageSize) => {
  if (pageSize === undefined) {
    pageSize = 10;
  }
  const user = JSON.parse(window.localStorage.getItem("client"));
  if (user) {
    token = user.token;
  } else {
    token = null;
  }
  setIsLoading(true); // Set isLoading to true before making the API call
  setNoData(false); // Reset noData to false before making the API call
  axios
    .get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/allbrands?page=${pageNumber}&pageSize=${pageSize}&search=${search}&sort=${sort}`,
      {
        headers: {
          'Authorization': `Bearer ${getNewToken()}`,
        },
      }
    )
    .then((response) => {
      if (response?.data?.message === "Please Login") {
        localStorage.removeItem('client');
        localStorage.removeItem('user');
        navigate('/admin');
        toast.warning("Session Expired Please Login Again");
      }
      const allcoupons = response.data.brands;
      const totalPages = response.data.totalPages;
      if (allcoupons.length < 1) {
        setNoData(true); // Set noData to true if there are no brands
      } else {
        setNoData(false); // Set noData to false if there are brands
      }
      setCustomerData(allcoupons);
      setNumberOfPages(totalPages);
      setIsLoading(false); // Set isLoading to false after receiving the response
    })
    .catch((error) => {
      console.error(`Error: ${error}`);
      if (error?.response?.data?.message) {
        localStorage.removeItem('client');
        localStorage.removeItem('user');
        navigate('/admin');
      }
    });
};





//  console.log(isLoading);
  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number);setShowPage(number+1) }}>
        {number + 1}
      </li>,
    );
  }

  const pagenumberSearch = (e)=>{
    var pages =parseInt(e) ;
    var page= pages -1;
    if(pages<1){
      setPageNumber(0);
      setShowPage(1);
    }else if(pages>numberOfPages){
      setPageNumber(numberOfPages-1);
      setShowPage(numberOfPages);
    }else if(pages>=1 && pages<=numberOfPages){
      setPageNumber(page);
      setShowPage(pages);
    }
    else{
      setPageNumber(0);
      setShowPage(pages);
    }
    }




  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
      setShowPage(pageNumber+2);
    }
  }

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1)
      setShowPage(pageNumber);
    }
  }


  //  search
  const handleSearch = (value) => {
    setSearch(value);
    setPageNumber(0);
    setShowPage(1);
  }
  // search

  useEffect(() => {
    setIsLoading(true);
    const getData = setTimeout(() => {
      getCouponsData(pageNumber, rowsPerPage);
      redirectToLogin();
      setIsLoading(false);
    },600)
    return () => clearTimeout(getData)
  }, [pageNumber, search, sort])


  const userID = localStorage.getItem('client');
  const users = JSON.parse(userID);
  const userIds = users?.user._id;


  const userStatus = (userIds) => {
      axios
          .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${userIds}`)
          .then((response)=>{
              let users = response.data.user?.status
              if(users === false){
                  window.localStorage.removeItem('client');
                  navigate('/admin');
                  toast.warning("Your account has been deactivated");
          }
          })
          .catch((error) => console.error(`Error: ${error}`));
      }

  useEffect(()=>{
      userStatus(userIds);
  } ,[userIds])


  
//   ) : (
  return ( 
    <>
      <div className="p-3 p-lg-4 px-xl-5 py-4">
        <h1 className="font20 mb-4 text-dark ">Brands</h1>
        <Container fluid className="bg-white p-4 rounded shadow">
          <Row className="align-items-center p-2">
            <Col md={4}>
            <div  style={{ maxWidth: "214px" }}>
                <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                  <Form.Control
                    type="text"
                    className="border-0 searchInput"
                    placeholder="Search"
                    onChange={({ currentTarget: input }) =>
                      handleSearch(input.value)
                    }
                  />
                  <Button
                    variant="primary"
                    title="Click here to search"
                    className="searchBtn newCustomSearch d-flex justify-content-center align-items-center"
                    style={{
                      backgroundColor: 'var(--newMainBlue)',
                      borderColor: 'var(--newMainBlue)',
                  }}
                  >
                    <img
                      src="/assets/images/search.png"
                      className="img-fluid"
                      alt=""
                    />
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={4}></Col>
            <Col md={4}></Col>

          </Row>
          {/* <Col className="mt-2" style={{ marginLeft: '10px' }}>
            <label htmlFor="rowsPerPage">Rows Per Page:</label>
            <div className="position-relative rows_per_page">
              <select
                id="rowsPerPage"

                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
                style={{ maxWidth: '102px' }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </Col> */}

          <Row>
            <Col md={12} className="mt-4">
              <div className="table-scroll">
                <table
                  className="table couponTable table-borderless fontGilroy"
                  style={{ verticalAlign: "middle" }}
                >
                  <thead className="bgLightRed font14 fw-fw-normal" >
                    <tr className="text-center">
                      <th className="text-start">Brand</th>


                      <th className="text-center" >
                        <div style={{ cursor: 'pointer' }}>
                          <span className="me-1">Brand Name
                            {order === "ASC" ? <FaSortDown onClick={() => sorting('DSC')} /> : <FaSortUp onClick={() => sorting('ASC')} />}
                          </span>
                        </div>
                      </th>

                      <th className="text-center" >
                        {/* <div style={{ cursor: 'pointer' }}> */}
                          <span className="me-1">Brand Id
                            {/* {order === "ASC" ? <FaSortDown onClick={() => sorting('DSC')} /> : <FaSortUp onClick={() => sorting('ASC')} />} */}
                          </span>
                        {/* </div> */}
                      </th>

                      <th className="text-center" >
                        <div style={{ cursor: 'pointer' }}>
                          <span className="me-1">Brand Category
                            {order === "ASC" ? <FaSortDown onClick={() => sorting('DSC')} /> : <FaSortUp onClick={() => sorting('ASC')} />}
                          </span>
                        </div>
                      </th>


                      <th className="text-center" >
                        <div style={{ cursor: 'pointer' }}>
                          <span className="me-1">Type of Brand
                            {order === "ASC" ? <FaSortDown onClick={() => sorting('DSC')} /> : <FaSortUp onClick={() => sorting('ASC')} />}
                          </span>
                        </div>
                      </th>

                      <th className="text-center">
                        <div style={{ cursor: 'pointer' }} >
                          <span className="me-1">Gift Card (s)</span>
                          {giftorder === "ASC" ? <FaSortDown onClick={() => giftSorting('DSC')} /> : <FaSortUp onClick={() => giftSorting('ASC')} />}
                        </div>
                      </th>
                      <th className="">Action</th>
                      
                    </tr>
                  </thead>
                  <tbody className="font12 fw-regular text-center">
                   {isLoading ? (
                      <tr style={{ backgroundColor: "#eee" }}>
                        <td colSpan="8">
                        <Bars
                        height="80"
                        width="100%"
                        margin='auto'
                        color="var(--newMainBlue)"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        />
                        </td>
                      </tr>
                      ) : (
                        <>
                        {noData  ? <tr >
                          <td colSpan="8" className="text-center">
                          <h1 className="font16 text-dark" >No Brands</h1>
                          </td>
                        </tr>
                     :
  customerData.map((cust) => (
    <tr key={cust._id}>
      <td className="font14 fw-medium  text-start">
        <img src={cust.img_path} alt="img" width="100px" />
      </td>
      <td className="font14 fw-medium" >{cust.brandname}</td>
      <td className="font14 fw-medium" >{cust.coupon_id}</td>
      <td className="font14 fw-medium" >{cust.brandCategory}</td>
      <td className="font14 fw-medium" >{cust.processing}</td>
      <td className="font14 fw-medium" >
        <Link to={`/admin/coupons/${cust.coupon_id}`}>
          <div>{cust.coupons} Gift Cards</div>
        </Link>
      </td>
      <td className="font14 fw-medium" >
        {role === "Admin" ? (
          <DropdownButton
            variant="white"
            className="p-0  tableActionDropDown"
            title={
              <div className="actionBtn">
                <span></span>
                <span></span>
                <span></span>
              </div>
            }
            drop="start"
          >
            <Link
              className="dropdown-item font14 fw-medium"
              to={`/admin/brands/edit/${cust._id}`}
            >
              Edit
            </Link>
          </DropdownButton>
        ) : (
          <DropdownButton
            variant="white"
            className="p-0  tableActionDropDown"
            title={
              <div className="actionBtn">
                <span></span>
                <span></span>
                <span></span>
              </div>
            }
            drop="start"
          >
            <Link
              className="dropdown-item font14 fw-medium"
              to={`/admin/brands/edit/${cust._id}`}
            >
              Edit
            </Link>
          </DropdownButton>
        )}
      </td>
    </tr>
  ))
          }
  </>
)}

                  </tbody>
                </table>
              </div>
            </Col>


          <Col className="mt-4" sm={12}>
              <Row>
            <Col md={3} className="mt-3 mb-4">
              <label htmlFor="rowsPerPage">Rows Per Page:</label>
              <div className="position-relative rows_per_page">
                <select
                  id="rowsPerPage"
                  
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  style={{ maxWidth: '102px' }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </Col>


          {
              numberOfPages === 0 ? "" :
              <Col md={6} className="text-center  d-flex  justify-content-center align-items-center">
              {
                <ul className="custPagination mb-0 d-flex justify-content-center list-unstyled font14">
                  {pageNumber === 0 ? (
                    ""
                  ) : (

                    <li onClick={prevPage}>Previous </li>

                  )}


                  {items}


                  {numberOfPages - 1 === pageNumber ? (
                    ""
                  ) : (
                    <li onClick={nextPage}>Next</li>
                  )}
                  <li className="font14"><input  style={{width:"25px",border:'1px solid var(--newMainBlue)',borderRadius:'4px',textAlign:'center'}} value={showPage?showPage:''} type="text" name="number" id="" onChange={(e)=> {pagenumberSearch(e.target.value)}}  ></input> <span style={{color:'#4C00EF'}}>/</span> {numberOfPages}</li>
                </ul>
              }
            </Col>
            }
               </Row>
            </Col>
 {/* </Row> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Brands;