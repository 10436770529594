import React, { useEffect, useState } from 'react'
import { Container, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { Row } from 'react-bootstrap';
import { Col, Form, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
// import { DropdownButton } from 'react-bootstrap';
// import { Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Bars } from "react-loader-spinner";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from 'axios';
import styles from '.././customersByBusiness.module.css'
import {BiPlusMedical} from "react-icons/bi";
import Modal from "react-bootstrap/Modal";
import CustomerForm from "../../components/CustomerForm";
import CsvForm from './CsvForm';
import { useDispatch } from 'react-redux';
import { getBusinessContact } from '../../Redux/BusinessContacts/action';
import { getNewPartnerToken } from '../../config/cookie.config';

const BusinessCustomer = (props) => {

  let navigate = useNavigate();
  let token;
  const businessPartner = JSON.parse(window.localStorage.getItem('business-client'));
  token = businessPartner?.token
  const id = businessPartner?.user?._id;
  const customerIds = businessPartner?.user?.business_partner_id;
  const b_id = businessPartner?.user?.business_partner_id;
  const userIds = businessPartner?.userData?._id;
  const [customerData, setCustomerData] = useState([]);
  const [downloadCustomerData, setDownloadCustomerData] = useState([]);
  const [order, setOrder] = useState("ASC");
  const [emailorder, setEmailorder] = useState("ASC");
  const [partnerorder, setPartnerorder] = useState("ASC");
  const [fundorder, setFundorder] = useState("ASC");
  const [updateorder, setUpdateorder] = useState("ASC");
  const [statusorder, setStatusorder] = useState("ASC");
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [partnerId, setPartnerId] = useState(id);
  const [showPage, setShowPage] = useState(1);
  const [zero, setZero] = useState(false);
  const [showCsvModal, setshowCsvModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [showReduceModal, setshowReduceModal] = useState(false);
  const [previewData,setPreviewData] = useState();
  const [previewData1,setPreviewData1] = useState();
  const [previewModal,setPreviewModal] = useState(false);
  const [loadingCsv,setLoadingCsv] = useState(false);
  const [filename, setFilename] = useState('');
  const [reduces, setReduces] = useState(true);
  const [customerId1, setCustomerId1] = useState("");


  const [isChecked, setIsChecked] = useState(true); 

  const reduceRadioChange = () => {
    
    setIsChecked(true);
    setReduces(true);
  };
  const addRadioChange = () => {
    setIsChecked(false);
    setReduces(false);
  };

  const handlePreview =()=>{
    setPreviewModal(!previewModal);
   }
  
   const handleClearData =()=>{
    setFilename('');
   }

   const handleClosePreviewModal =()=>{
    setPreviewModal(false);
    setshowCsvModal(false)
   }
  

  const handleCloseReduceModal = () => {
    setshowReduceModal(!showReduceModal);
    setStartCustomerClick(false)

    setselectedCustomerValidate('default');


  };

  const handleClosehandleCsvModal = () => {
setFilename('');
    setshowCsvModal(!showCsvModal);

  };



  const handleCustomerSelection = (event) => {
    const selectedCustomerId = event.target.value;
    setselectedCustomerValidate(selectedCustomerId);
    if(selectedCustomerId !== 'default'){
      sendCustomer(selectedCustomerId);
    }
    else{
      setCustomerName('');
      setCustomerLastName('');
      setCustomerEmail('');
      setCustomerPhone('');
      setCustomerAmount('');
    }
  };


  useEffect(() => {
    sendPartner(customerIds)
  }, [customerIds,id])
  


  const [getbusinessCustomer, setbusinessCustomer] = useState([]);
  // const [businessEnable, setbusinessEnable] = useState(false);
  const [sendPartnerID, setSendPartnerID] = useState('');
  const [startPartnerClick, setStartPartnerClick] = useState(false);
  const [startCustomerClick, setStartCustomerClick] = useState(false);
  const [startFundValidation, setStartFundValidation] = useState(false);

  const [allValidation, setAllValidation] = useState(0);


  const [getbusinessPartner, setbusinessPartner] = useState([]);
  const [selectedPartnerValidate, setselectedPartnerValidate] = useState('default');
  const [selectedCustomerValidate, setselectedCustomerValidate] = useState('default');

  const [fundValue, setFundValue] = useState('');

  const [customerName, setCustomerName] = useState('');
  const [customerLastName, setCustomerLastName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [customerAmount, setCustomerAmount] = useState('');
  const [businessName, setBusinessName] = useState('');




  const handleFundChange = (event) => {
    const inputValue = event.target.value.replace(/[eE-]/g, '');
    if (inputValue.includes('e') || inputValue.includes('E')) {
      return; 
    }
    setFundValue(inputValue);
    setStartFundValidation(true);
  }

  useEffect(() => {

    if (selectedCustomerValidate !== 'default' && fundValue.length !== 0) {
      setAllValidation(1);
    } else {
      setAllValidation(0);
    }
  }, [fundValue, selectedCustomerValidate]);


  const handlePartnerSelection = (event) => {
    const selectedPartnerId = event.target.value;
    // sendPartner(selectedPartnerId);
    setselectedPartnerValidate(selectedPartnerId)
  };
  const PartnerClick = () => {
    setStartPartnerClick(true);
  }
  const CustomerClick = () => {
    setStartCustomerClick(true);
  }


  const handleKeyDown = (event) => {
    // Prevent the user from typing "e"
    if (event.key === "e" || event.key === "E" || event.key === "-") {
      event.preventDefault();
    }
    setStartFundValidation(true);
  };


  const formatDate = (date) => {
    // Convert the date to EST time zone
    const estDate = new Date(date);
    const estOptions = {
      timeZone: "America/New_York",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
    };
  
    // Format the date as per the EST time zone, without seconds
    return estDate.toLocaleString("en-US", estOptions).replace(",", "");
  };


  const sendPartner = (customerIds) => {
    // console.log(partnerId);
    // const apiUrl = `https://backend.payouts.ncentiva.com/api/admin/customerpartner/${partnerId}`;
    setSendPartnerID(customerIds)
    
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/business-customer/${customerIds}`, {
          headers: {
            'Authorization' : `Bearer ${getNewPartnerToken()}`
          }
      }
      )
      .then((response) => {
        if(response.data.message == "Please Login"){
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
        }    
        const businessCustomer = response.data.customers

        setbusinessCustomer(businessCustomer);
        // setbusinessEnable(true);

      })
      .catch((error) => console.error(`Error: ${error}`));
  };





  const sendCustomer = (customerId) =>{
    const user = JSON.parse(window.localStorage.getItem("client"));
      if (user) {
        token = user.token;
      } else {
        token = null;
      }
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getcustomer/${customerId}`, {
            headers: {
              'Authorization' : `Bearer ${getNewPartnerToken()}`
            }
          
        }
        )
        .then((response) => {
          if(response.data.message == "Please Login"){
            localStorage.removeItem('business-client');
            navigate('/business-login');
            toast.error("Session Expired Please Login Again")
          }    
          const customerData = response.data.customer
          setCustomerName(customerData.first_name)
          setCustomerLastName(customerData.last_name)
          setCustomerEmail(customerData.email)
          setCustomerPhone(customerData.phone)
          setCustomerAmount(customerData.balance)

        })
        .catch((error) => console.error(`Error: ${error}`));
  }






  const setCustomers = (userDataInString) => {
    var userDataInJSON = [];

    // Coverting String to Array of JSON Objects
    var rows = userDataInString.split("\n");

    var er = rows[0].search("\r");
    if (er > 1) {
      // rows[0] = rows[0].slice(0);
      rows[0] = rows[0].slice(0, -1);
    } else {
      rows[0] = rows[0].slice(0);
      //  rows[0] = rows[0].slice(0, -1);
    }

    var fields = rows[0].split(",");

    if(fields[0] =="first_name" && fields[1] =="last_name" &&  fields[2] == "email" && fields[3] == "code" && fields[4] == "phone" && fields[5] == "balance"&& fields[6] == "business_partner"){  

    for (var i = 1; i < rows.length; i++) {
      // To check null fields
      var flag = false;

      // Creating individual JSON objects from string
      var singleUserObject = {};

      // To handle the extra space in the end
      // rows[i] = rows[i].slice(0, -1);


      var ers = rows[i].search("\r");
      if (ers >= 0) {
        // rows[i] = rows[i].slice(0);
        rows[i] = rows[i].slice(0, -1);

      } else {
        rows[i] = rows[i].slice(0);
        //  rows[i] = rows[i].slice(0, -1);
      }



      // Extracting the data for the objects
      var singleRow = rows[i].split(",");

      for (var j = 0; j < fields.length; j++) {
        if (singleRow[j] === "") {
          flag = true;
        }

        // Key-Value Pairs
        singleUserObject[fields[j]] = singleRow[j];
        singleUserObject["partner_id"] = partnerId;
        singleUserObject["user_id"] = userIds;
        singleUserObject["password"] = "cust@1234";
        singleUserObject["status"] = 1;
      }


      if (flag) {
        // To be added to Invalid Users
      } else {
        // To be sent to backend
        userDataInJSON.push(singleUserObject);
      }
    }
    setCsvData(userDataInJSON);
    axios
    .post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/csv-preview`,
      userDataInJSON,{
        headers: {
          'Authorization' : `Bearer ${getNewPartnerToken()}`
        }
      },
    )
    .then((res) => {
      if (res.data?.status === "failed") {
         toast.warning(res.data.message);
        return false;
      }else {
        // toast.success(res.data.message);
        setPreviewData(res.data.customers);
        setPreviewData1(res.data);
        setBusinessName(res.data.businessName)
        setLoadingCsv(true)
        setTimeout(() => {
          setLoadingCsv(false)
          setPreviewModal(true)
        }, 800);
      }        
      // toast.success(res.data.message);
      // setPreviewData(res.data.customers);
      // setPreviewData1(res.data);
      // setBusinessName(res.data.businessName)
     
    })
    .catch((err) => {
      console.log(err);
      if(err?.response?.data?.message){
        toast.error(err?.response?.data?.message);
      }
    });
  }else{
    toast.warning("Invalid CSV File")
    setCsvData([]);

  }
  };




  const handleSubmit = async (e) => {
    setLoadingCsv(true);
    e.preventDefault();
    setDisable(true)
    setTimeout(() => {
      setDisable(false);
    }, 2000);

    if (csvData.length == 0) {
      toast.warning("Please Upload A CSV File");
      return false;
    }



    for (var i = 0; i < csvData.length; i++) {
      if(csvData[i].phone){
        if (csvData[i].phone.length < 9) {
          toast.warning("Phone number must be 10 digits");
          return false;
        }

      }


      let business_partner =csvData[i].business_partner;
      if(csvData[i].business_partner){  
      business_partner =business_partner?.replace(/^\s+|\s+$/gm,'')
      }   

      let texts = csvData[i].first_name;
      let results;
      if(csvData[i].first_name){
        results = texts.replace(/^\s+|\s+$/gm, '');
      }
      

      if (results.length <= 0) {

        toast.warning("Please enter a Name");
        return false;
      }

      let text = csvData[i].email
      let result;
      if(csvData[i].email){
        result = text.replace(/^\s+|\s+$/gm, '');
      }

      if(business_partner.includes("NCT-B-XXXXXX") || business_partner.includes("X")){
        toast.warning("Please enter Business partner");
        return false;
      }
      
      if (result.length <= 0) {
        toast.warning("Please enter a valid email address");
        return false;
      }
    }
    setLoadingCsv(true)
    axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/customer/createcsv/${partnerId}`,
      csvData, {
      headers: {
        'Authorization': `Bearer ${getNewPartnerToken()}`
      }
    },
    )
      .then((res) => {
        setLoadingCsv(false);
        setPreviewModal(false)
        if (res.data?.status === "failed") {
          toast.warning(res.data.message);
          return false;
        }
        handleClosehandleCsvModal();
        setFilename('')
        setTimeout(() => {
          // getAllCustomers();
          setLoadingCsv(false)
          handleClosehandleCsvModal();
          window.location.reload();
          // getAllCustomers();
        }, 400);

        toast.success("Csv Uploaded Successfully");
        window.location.reload();
        // getAllCustomers();
        // navigate("/admin/customers");
      })
      .catch((err) => {
        console.log(err);
        // if(err.response.data.msg){
        //           toast.warning(err.response.data.msg);
        // }

      });
  };








  const getCustomerByBusinessPartner = (partnerId, pageNumber) => {

    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/customerpartner/${partnerId}?page=${pageNumber}&search=${search}&sort=${sort}`, {
        headers: {
          'Authorization': `Bearer ${getNewPartnerToken()}`
        }
      })
      .then((response) => {
        if(response.data.message == "Please Login"){
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
        }    
        if (response.data.customers?.length < 1) {
          setZero(true);
        } else {
          setZero(false);
          const totalPages = response.data.totalPages;
          setNumberOfPages(totalPages);
          const allcustomers = response.data.customers;
          setCustomerData(allcustomers);
        }
      })
      .catch((error) => {
        console.log(`Error : ${error}`)
        if (error.response.data.message === "Unauthorized User" ){
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
      }
      })
  }

  // const downloadCustomers = (partnerId) => {

  //   const user = JSON.parse(window.localStorage.getItem("business-client"));

  // if (user) {
  //   token = user.token;
  // } else {
  //   token = null;
  // }
  //   axios
  //       .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/pdfcsvdownload/${partnerId}`,{
  //         headers: {
  //           'Authorization' : `Bearer ${token}`
  //         }
  //     })
  //       .then((response) => {
  //         const allCustomerDownload = response.data.customersDownload;

  //         setDownloadCustomerData(allCustomerDownload);
  //       })
  // }

  
  const dispatch = useDispatch();
  
  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem("business-client"));
    const id = user?.userData?._id;
    const token = user?.token;
  
    dispatch(getBusinessContact(id, token, navigate, toast));
  
  }, [dispatch, navigate, toast]);
  

  const pagenumberSearch = (e) => {
    var pages = parseInt(e);
    var page = pages - 1;
    if (pages < 1) {
      setPageNumber(0);
      setShowPage(1);
    } else if (pages > numberOfPages) {
      setPageNumber(numberOfPages - 1);
      setShowPage(numberOfPages);
    } else if (pages >= 1 && pages <= numberOfPages) {
      setPageNumber(page);
      setShowPage(pages);
    }
    else {
      setPageNumber(0);
      setShowPage(pages);
    }
  }


  // //for pdf

  // const getCustomerspdf = () => {


  // const unit = "pt";
  // const size = "A4"; // Use A1, A2, A3 or A4
  // const orientation = "portrait"; // portrait or landscape

  // const doc = new jsPDF(orientation, unit, size);
  // doc.setFontSize(15);
  // const headers = [
  //   ["Name", "Email", "Currency Type", "Available funds", "Contact", "Status"]];

  //   doc.setFontSize(20)
  //   doc.setFont("times");
  //   doc.text(30, 30, `Customers list of ${downloadCustomerData[0]?.business_partner}`)
  //   let info = []
  //   downloadCustomerData?.forEach((element) => {
  //     var name = `${element.first_name} ${element.last_name}`
  //     var balance = `$ ${Number(element.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  //     var status = element.status === true ? element.status = "Active" : element.status = "Inactive";
  //     var currency = "USD";
  //     info.push([name, element.email, currency, balance, element.phone, status])
  // })
  // let content = {
  //   startY: 50,
  //   head: headers,
  //   headStyles: { fillColor: "#fff3ed" },
  //   styles: { textColor: [10, 10, 10] },
  //   body: info,
  //   margin: { top: 10, right: 20, bottom: 0, left: 20 }

  // };

  // doc.autoTable(content);
  // doc.save(`${downloadCustomerData[0]?.business_partner}.pdf`);

  // }


  const headers = [
    "Name",
    "Email",
    "Currency Type",
    "Available Funds",
    "Contact",
    "Status"
  ];
  let infos = [];

  downloadCustomerData?.forEach((element) => {
    var name = `${element.first_name} ${element.last_name}`
    var balance = `$ ${Number(element.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    let status = element?.status === false ? "Inactive" : "Active";
    var currency = "USD";
    infos.push([name, element.email, currency, balance, element.phone, status])
  })


  const csvReport = {
    data: infos,
    headers: headers,
    filename: `${downloadCustomerData[0]?.business_partner}.csv`
  };



  //  search
  const handleSearch = (value) => {
    setSearch(value);
    setPageNumber(0);
    setShowPage(1);
  };


  const [isSubmitting, setIsSubmitting] = useState(false);
  const balanceSubmit = () => {

    if(allValidation !== 1 ){
      setStartPartnerClick(true);
      setStartCustomerClick(true);
      setStartFundValidation(true);

    }
    else{
      if (!isSubmitting) {
        setIsSubmitting(true);
        const apiUrl = `${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/modifyfunds`;
        // Data to send in the POST request body
        const data = {
          business_partner: sendPartnerID,
          customer_email: customerEmail,
          reduce_funds: isChecked,
          funds: fundValue,
        };
    
        const user = JSON.parse(window.localStorage.getItem("client"));
        if (user) {
          token = user.token;
        } else {
          token = null;
        }
    
        axios.post(apiUrl, data ,{
          headers: {
            'Authorization': `Bearer ${token}`
          }})
          .then(response => {
            // Handle the API response here (if needed)
            if(response.data.customer){
              toast.success(response.data.message);
              setshowReduceModal(false);
              // getAllCustomers()
              setIsChecked(true)
              // window.location.reload();
              if(response.data.message){
                setTimeout(() => {
                  setIsSubmitting(false);
                  window.location.reload();
                }, 1000); 
              }

            }else{
              // toast.warning(response.data.message);
              setIsSubmitting(false);
              
            }
          })
          .catch(error => {
            console.error('API Error:', error);
          });
        }

      }
    
  };






  // name
  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");
    } else {
      setSort("ascName");

      setOrder("ASC");
    }
  };

  // email
  const emailsorting = (emailorder) => {
    if (emailorder === "DSC") {
      setSort("dscEmail");

      setEmailorder("DSC");
    } else {
      setSort("ascEmail");

      setEmailorder("ASC");
    }
  };

  // partner

  const partnersorting = (partnerorder) => {
    if (partnerorder === "DSC") {
      setSort("dscPartner");

      setPartnerorder("DSC");
    } else {
      setSort("ascPartner");

      setPartnerorder("ASC");
    }
  };

  // partner

  // availablefund
  const fundsorting = (fundorder) => {
    if (fundorder === "DSC") {
      setSort("dscFund");

      setFundorder("DSC");
    } else {
      setSort("ascFund");

      setFundorder("ASC");
    }
  };

  const updatesorting = (updateorder) => {
    if (updateorder === "DSC") {
      setSort("dscUpdate");

      setUpdateorder("DSC");
    } else {
      setSort("ascUpdate");

      setUpdateorder("ASC");
    }
  };

  const statusSorting = (statusOrder) => {
    if (statusOrder === "DSC") {
      setSort("dscStatus");

      setStatusorder("DSC");
    } else {
      setSort("ascStatus");

      setStatusorder("ASC");
    }
  };






  function handleStatus(id, status, email, business_partner) {
    if (status === false) {
      status = true;
    } else {
      status = false;
    }

    const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/customer/status`, {
        id,
        status,
        email,
        business_partner

      }, {
        headers: {
          'Authorization': `Bearer ${getNewPartnerToken()}`
        }
      },)
      .then((response) => {
        if(response.data.message == "Please Login"){
          localStorage.removeItem('business-client');
          navigate('/business-login');
          toast.error("Session Expired Please Login Again")
        }    
        toast.then(response.data.message);
        getCustomerByBusinessPartner(partnerId, pageNumber)
      })
      .catch((error) => console.error(`Error: ${error}`));
  }

  useEffect(() => {
    setIsLoading(true);
    getCustomerByBusinessPartner(partnerId, pageNumber);
    //   downloadCustomers(partnerId);
    setTimeout(() => {

      setIsLoading(false);
    }, 600);
  }, [pageNumber, search, sort]);

  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {

    items.push(
      <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number); setShowPage(number + 1) }}>
        {number + 1}
      </li>,
    );
  }

  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
      setShowPage(pageNumber + 2);
    }
  }

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setShowPage(pageNumber);
    }
  }
 
  const handleModifyFunds = (val) =>{
      navigate("/partner/modify", {
        state: {
          customerId: val,
        }})
  }

  const [btnColor,setBtnColor] = useState('');
  const [loading,setLoading] = useState(true);
  const [primaryColor,setPrimaryColor]= useState('');
  const [secondaryColor,setSecondaryColor] = useState('');


  // useEffect(()=>{
  //   setLoading(true);
  //  axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getcmsbusiness/${id}`)
  //  .then((res)=>{
  //    if(res.data?.cms?.default_ncentiva === false){ 
  //    setBtnColor(res.data?.cms?.btn_color);
  //    setPrimaryColor(res.data?.cms?.primary_color);
  //    setSecondaryColor(res.data?.cms?.secondary_color);
  //  }else{
  //      setBtnColor('#3D05B6');
  //      setPrimaryColor("#FF7020");
  //      setSecondaryColor("#F5C400");
  //    }
  //    setLoading(false);
  //  }).catch((err)=>{
  //    console.log(err);
  //  })
  // },[])



  return (
    <div className="p-3 p-lg-4 p-xl-5">
      {props?.admin ? <div className="d-flex mb-4 align-items-center">
        <Link
          onClick={() => navigate(-1)}
          to="/admin/businesspartners"
          className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
          variant="white"
        >
          <span className="me-2 fw-semibold">
            <img
              src="/assets/images/back-org.png"
              className="img-fluid"
              alt=""
            />
          </span>
          <span>Back</span>
        </Link>
        <h1 className="font20 text-dark">Customer List</h1>
      </div> :
        <div className="d-flex mb-4 align-items-center">
          <h1 className="font20 text-dark">Customer List</h1>
        </div>}
      {/* <div className="p-5"> */}
      {/* <h1 className="font20 mb-4 text-dark">Customers</h1> */}
      <Container fluid className="bg-white p-3 rounded shadow">
        <Row className="align-items-center">
          <Container>
            <Row>
              <Col xl={5} xxl={6} lg={4} className="mt-1">
                <div className="textGray font12">

                  <div style={{ maxWidth: "214px" }}>
                    <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                      <Form.Control
                        type="text"
                        className="border-0 searchInput"
                        placeholder="Search"
                        onChange={({ currentTarget: input }) =>
                          handleSearch(input.value)
                        }
                      />
                      <Button
                        variant="primary"
                        title="Click here to search"
                        className="searchBtn newCustomSearch d-flex justify-content-center align-items-center"
                        style={{
                          backgroundColor: 'var(--newMainBlue)',
                          borderColor: 'var(--newMainBlue)',
                      }}
                      >
                        <img
                          src="/assets/images/search.png"
                          className="img-fluid"
                          alt=""
                        />
                      </Button>
                    </div>
                  </div>

                </div>
              </Col>
              {/* <Col lg={{ span: 3, offset: 1 }} className="mt-1" > */}
              <Col xl={7} xxl={6} lg={8} className="mt-1" >
                <Row className={styles.breakBtn1} >
                  <Col lg={5} className="mt-1 me-3 ps-3 ps-lg-0" style={{ width: "145px" }}>

                    <Button
                      variant="primary"
                      className="fullRounded newCustomButton px-4 py-2 fw-bold font12"
                      onClick={() => handleModifyFunds()}
                      style={{ width: "150px", backgroundColor: 'var(--newOrange)',
                        borderColor: 'var(--newOrange)' }}
                    >
                      <span className="me-2">
                        {/* <img
                          src="/assets/images/plus.svg"
                          className="img-fluid"
                          alt=""
                        /> */}
                         <BiPlusMedical />
                      </span>
                      <span>Modify funds</span>
                    </Button>
                  </Col>
                  <Col lg={4} className="mt-1 ps-3 ps-lg-0" style={{ width: "163.75px" }}>
                    <Button
                      variant="primary"
                      className="fullRounded newCustomButton px-4 px-lg-3 px-xl-4 py-2 fw-bold font12"
                      onClick={() => navigate("/partner/customers/add")}
                      style={{ width: "149.8px",backgroundColor: 'var(--newOrange)',
                        borderColor: 'var(--newOrange)' }}
                    >
                      <span className="me-2">
                        {/* <img
                          src="/assets/images/plus.svg"
                          className="img-fluid"
                          alt=""
                        /> */}
                         <BiPlusMedical />
                      </span>
                      <span>Add Customer</span>
                    </Button>
                  </Col>
                  <Col lg={4} className="mt-1 ps-3 ps-lg-0" style={{ width: "146.6px" }}>

                    <Button
                      variant="primary"
                      onClick={() => setshowCsvModal(!showCsvModal)}
                      className="fullRounded newCustomButton px-4 py-2 fw-bold font12"
                      style={{ width: "136.6px", backgroundColor: 'var(--newOrange)',
                        borderColor: 'var(--newOrange)' }}
                    >
                      <span className="me-2">
                        {/* <img
                          src="/assets/images/plus.svg"
                          className="img-fluid"
                          alt=""
                        /> */}
                         <BiPlusMedical />
                      </span>
                      <span>Upload CSV</span>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <Col md={12} className="mt-4">
            <div className="table-responsive">
              <table className="table fontGilroy"
                style={{ verticalAlign: "middle" }}>
                <thead className="bgLightRed font14">
                  <tr>
                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Name</span>
                        {order === "ASC" ? (
                          <FaSortDown onClick={() => sorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => sorting("ASC")} />
                        )}
                      </div>
                    </th>
                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Email
                          {emailorder === "ASC" ? (
                            <FaSortDown onClick={() => emailsorting("DSC")} />
                          ) : (
                            <FaSortUp onClick={() => emailsorting("ASC")} />
                          )}
                        </span>

                      </div>
                    </th>
                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Partner</span>
                        {partnerorder === "ASC" ? (
                          <FaSortDown onClick={() => partnersorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => partnersorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Available Funds</span>
                        {fundorder === "ASC" ? (
                          <FaSortDown onClick={() => fundsorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => fundsorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Updated At</span>
                        {updateorder === "ASC" ? (
                          <FaSortDown onClick={() => updatesorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => updatesorting("ASC")} />
                        )}
                      </div>
                    </th>
                    <th className="text-center">
                      <div className="d-flex align-items-center">
                      <span className="me-1"> Status</span>
                        {statusorder === "ASC" ? (
                          <FaSortDown onClick={() => statusSorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => statusSorting("ASC")} />
                        )}
                      </div>
                    </th>
                    <th className='text-center'></th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="font14 fw-medium">
                  {isLoading === true ? (
                    <tr style={{ backgroundColor: "#eee" }}>
                      <td colSpan="8">
                        <Bars
                          height="80"
                          width="100%"
                          margin='auto'
                          color="var(--newMainBlue)"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          visible={true}
                        />
                      </td>
                    </tr>
                  ) :
                    (
                      <>

                        {zero ? <tr >
                          <td colSpan="9" className="text-center">
                            <h1 className="font16 text-dark" >No Customers</h1>
                          </td>
                        </tr> :
                          customerData?.map((cust) => (
                            <tr key={cust._id}>
                              <td className="font14 fw-medium">
                                {cust.first_name} {cust.last_name}
                              </td>
                              <td className="font14 fw-medium">
                                {/* <Link to={`/admin/customers/${cust._id}/edit`}> */}
                                {cust.email} {cust.id}
                                {/* </Link> */}
                              </td>
                              <td className="font14 fw-medium">{cust.business_partner}</td>
                              <td className="font14 fw-medium ps-xxl-5 ps-4">${Number(cust.balance)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                              <td className="font14 fw-medium">{formatDate(cust.updatedAt).slice(0, 16).replace("T", "      ")}</td>
                              <td className="font14 fw-medium">
                                {cust?.status ? "Active" : "Inactive"}

                              </td>
                              <td className="font14 fw-medium">
                                <div className="transactionLabel d-inline-flex align-items-center">
                                  <div className="me-2">
                                    <img
                                      src="/assets/images/transcation.png"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  <div>
                                    <Link to={`/partner/transaction/${cust._id}`}  >
                                      Transaction
                                    </Link>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <DropdownButton
                                  variant="white"
                                  className="p-0  tableActionDropDown text-center"
                                  title={
                                    <div className="actionBtn">
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  }
                                  drop="start"
                                >
                                  <Dropdown.Item
                                    className="font14 fw-medium"
                                    onClick={() => navigate(`/partner/customers/${cust._id}/edit`)}
                                  >View
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="font14 fw-medium"
                                    onClick={() => handleModifyFunds(cust._id)}
                                  >Modify Funds
                                  </Dropdown.Item>
                                </DropdownButton>
                              </td>
                            </tr>

                          ))
                        }
                      </>
                    )}
                </tbody>
              </table>
            </div>
          </Col>
          <Col className="mt-5 mb-4">
            {
              <ul className="custPagination d-flex justify-content-center list-unstyled font14">


                {pageNumber === 0 ? (
                  ""
                ) : (

                  <li onClick={prevPage}>Previous </li>

                )}


                {items}


                {numberOfPages - 1 === pageNumber ? (
                  ""
                ) : (
                  <li onClick={nextPage}>Next</li>
                )}
                <li className="font14"><input style={{ width: "25px", border: '1px solid var(--newMainBlue)', borderRadius: '4px', textAlign: 'center' }} value={showPage ? showPage : ''} type="text" name="number" id="" onChange={(e) => { pagenumberSearch(e.target.value) }}  ></input> <span style={{ color: 'var(--newMainBlue)' }}>/</span> {numberOfPages}</li>
              </ul>
            }
          </Col>
        </Row>
      </Container>
      <Modal
        show={showCsvModal}
        onHide={handleClosehandleCsvModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title
              className="font20 text-dark mb-0"
              id="contained-modal-title-vcenter"
            >
              File Upload
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {loadingCsv ? <Bars
                        height="80"
                        width="100%"
                        margin='auto'
                        color="var(--newMainBlue)"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        />
             :
            <>
              <div className="d-flex justify-content-between align-items-center">
            {/* <h4 className="fs-6 text-dark mb-2">Select CSV File</h4>
            {previewData?.length>0 ?<div className="d-flex align-items-center">
              <h6 className="me-3 mb-2">{filename}</h6>
              <Button
            onClick={()=>handlePreview()}
              variant="primary"
              className="fullRounded px-4 py-2 fw-bold font12 mb-2"
            >
              Preview
            </Button>
            </div>:''} */}
              </div>
              <CsvForm variant="primary" setCustomerData={setCustomers} business_id={b_id} setFilename={setFilename} filename={filename}/>
            </>
}
          </Modal.Body>
          <Modal.Footer>{filename.length>0 ? <Button
             variant="secondary"
            className="fullRounded newCustomButton px-4 py-2 fw-bold font12"
             style={{
                    backgroundColor: 'var(--newOrange)',
                    borderColor: 'var(--newOrange)',
                }}
            disabled

          >
            Upload
          </Button>
            :
            <Button
              variant="primary"
              type="submit"
              className="fullRounded newCustomButton px-4 py-2 fw-bold font12"
               style={{
                    backgroundColor: 'var(--newOrange)',
                    borderColor: 'var(--newOrange)',
                }}
            >
              Upload
            </Button>
          }

            <Button
              variant="secondary"
              className="fullRounded px-4 py-2 fw-bold font12"
              onClick={() => handleClosehandleCsvModal()}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>


      <Modal
      scrollable={true}
      show={previewModal}
      onHide={handleClosePreviewModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
          <Modal.Header closeButton>
            <Modal.Title
              className="font20 text-dark mb-0"
              id="contained-modal-title-vcenter"
            >
             CSV Preview
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
            <div className="d-flex justify-content-between">
            <h6>Total Funds : $ {previewData1?.totalFunds?.toFixed(2)}</h6>
              <h6>Register Customers : {previewData1?.registerCustomer}</h6>
            </div>
            <div className="table-responsive">
              <table
                className="table fontGilroy"
                style={{ verticalAlign: "middle" }}
              >
                <thead className="bgLightRed font14">
                  <tr>
                  <th className="text-center">
                      <div className="d-flex align-items-center">
                       
                        <span className="me-1">Name</span>
                     
                      </div>
                    </th>
                   
                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Email
                      
                        </span>

                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Phone
                      
                        </span>

                      </div>
                    </th>
                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1"> Partner</span>
                     
                      </div>
                    </th>

                    <th>
                      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                        <span className="me-1">Available Funds</span>
                     
                      </div>
                    </th>
                
                  </tr>
                </thead>
                <tbody className="font12 fw-medium">
              
                   
                       
                   {

                     previewData?.map((cust) => (
                       <tr key={cust._id}>
                        <td className="font14">
                             
                              <span>
                                <Link
                                  className="link"
                                
                                >
                                  {cust.first_name} {cust.last_name}
                                </Link>
                              </span>
                            </td>
                      
                        <td className="font14 fw-medium">
                          {/* <div> */}
                            {cust.email} {cust.id}
                          {/* </Link> */}
                        </td>
                        <td className="font14 fw-medium">{cust.code} {cust.phone}</td>
                        <td className="font14 fw-medium">{cust.business_name}</td>
                        <td className="font14 fw-medium">${Number(cust.balance)?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                        {/* <td className="font14 fw-medium">
                        {formatDate(cust.updatedAt).slice(0, 16).replace("T", "      ")}
                          </td> */}
                       
                        
                        
                      </tr>
                    ))
                  
                      }
                </tbody>
              </table>
            </div>
            </>
            
          </Modal.Body>
          <Modal.Footer>
            
            <Button
              variant="primary"
              className="fullRounded newCustomButton px-4 py-2 fw-bold font12"
              style={{
                backgroundColor: 'var(--newOrange)',
                borderColor: 'var(--newOrange)',
            }}
              onClick={handleSubmit}
              disabled={loadingCsv}
            >
              Confirm
            </Button>
            <Button
              variant="secondary"
              className="fullRounded px-4 py-2 fw-bold font12"
              onClick={() => {
                handleClosePreviewModal();
                handleClearData(); 
              }}
            >
              Close
            </Button>
          </Modal.Footer>
    
      </Modal>

      {/* Reduce balance modal */}
      <Modal
        show={showReduceModal}
        onHide={handleCloseReduceModal}
        backdrop="static"
        keyboard={false}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modify Fund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="reduce_modal_body">
            <div className="row g-3">

                  <div className="col-12">
                    <label htmlFor="Customers-partner" className="form-label" >Customers</label>
                    <select name="" id="Customers-partner" className={`form-select ${selectedCustomerValidate === 'default' && startCustomerClick  ? 'is-invalid' : ''}`}  onChange={handleCustomerSelection} onClick={CustomerClick} >
                      <option value="default">Select Customers</option>
                      {getbusinessCustomer.map((option, index) => (
                        <option key={index} value={option._id}>
                          {option.first_name} {option?.last_name}
                        </option>
                      ))}
                    </select> 
                    {selectedCustomerValidate === 'default' && selectedPartnerValidate !== 'default' && startCustomerClick && (
                        <div className="invalid-feedback">Please select a valid Customer.</div>
                    )}             
                    {selectedPartnerValidate === 'default' && startPartnerClick && (
                        <div className="invalid-feedback">Please select a Business partner first.</div>
                    )}              
                  </div>
               
                {selectedCustomerValidate !== 'default' && <>
                  <div className="col-12">
                    <div className="my-2">
                      <label htmlFor="">Name: </label>
                      <span className="text-dark"> {customerName}  {customerLastName}</span>
                    </div>
                    <div className="my-2">
                      <label htmlFor="">Email: </label>
                      <span className="text-dark"> {customerEmail}</span>
                    </div>
                    <div className="my-2">
                      <label htmlFor="">phone Number: </label>
                      <span className="text-dark"> {customerPhone}</span>
                    </div>
                    <div className="my-2">
                      <label htmlFor="">Balance: </label>
                      <span className="text-dark"> {customerAmount =="NaN" ? '0' : customerAmount}</span>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row g-2">
                      <div className="col-auto">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="fund_change"
                            id="fund_change1"
                            defaultChecked
                            onClick={reduceRadioChange}
                          />
                          <label className="form-check-label" htmlFor="fund_change1">
                            Reduce funds
                          </label>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="fund_change"
                            id="fund_change2"
                            onClick={addRadioChange}
                          />
                          <label className="form-check-label" htmlFor="fund_change2">
                            Add funds
                          </label>
                        </div>
                      </div>
                      <div className="col-12 ">
                        <div className="form-group">
                          <input type="number" className={`form-control ${fundValue.length == 0 && startFundValidation || fundValue.length >6 && startFundValidation && (reduces == false) ||  parseFloat(fundValue) > parseFloat(customerAmount) && startFundValidation && (reduces == true) ? 'is-invalid' : ''}`} value={fundValue} onChange={handleFundChange} onKeyDown={handleKeyDown} placeholder="Enter amount" style={{maxWidth: "100%", }}/>
                          {startFundValidation && <>
                            {fundValue.length > 0 && (
                              <div className="valid-feedback">Looks good!</div>
                            )}
                          
                          {parseFloat(fundValue) > parseFloat(customerAmount) && (reduces == true)  &&(
                              <div className="invalid-feedback">Amount is more than Available Balance</div>
                            )}
                             {fundValue.length > 6 && (reduces == false) && (
                              <div className="invalid-feedback">Maximum 6 digits allowed for Balance Amount</div>
                            )}
                            {fundValue.length === 0 && (
                              <div className="invalid-feedback">Please enter an amount.</div>
                            )}
                            </>
                          }
                        </div>
                      </div>    
                    </div>
                  </div>
                </>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" className="fullRounded px-4 py-2 fw-bold font12" onClick={handleCloseReduceModal}>
            Close
          </Button>
          <Button variant="primary" disabled={isSubmitting} className="fullRounded px-4 py-2 fw-bold font12 btn btn-primary" onClick={balanceSubmit}>{isSubmitting ? 'Update' : 'Update'}</Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}


export default BusinessCustomer;