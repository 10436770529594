import React from 'react';
import Category from './elements/Category';
import '../styles/herobanner.css';
import ModalOpen from './elements/CategoryModalOpen';
import { FaSearch } from "react-icons/fa"
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import  axios  from 'axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {useDispatch} from 'react-redux'
import { ClearhandleCategories } from '../Redux/SendCategories/action';
import { globalSearch } from '../Redux/GlobalSearch/action';

const hcategories = ["Clothing & Accessories", "Food & Restaurants", "Gaming"];

const Herobanner = (props) => {
  const cmsData = useSelector((state) => state.cmsReducer);      
  const nameDomain = ( window.location.host.split('.')); 
  const [videoUrl,setVideoUrl] = useState("");
  const [primaryColor,setPrimaryColor] = useState("");
  const [secondaryColor,setSecondaryColor] = useState("")
  const [checkColor,setCheckColor] = useState(true);
  const [isVideo,setIsVideo] = useState(true);
  const [btnColor,setBtnColor] = useState('');
  const [btnText,setBtnText] = useState('');
  const [homeBannerColor,setHomeBannerColor] = useState();
  const [homeBannerColor1,setHomeBannerColor1] = useState();
  const [isLoading,setIsLoading] = useState(true);
  const [giftcards,setGiftcards] = useState("giftcards");
  const navigate = useNavigate();
  const categoryName = useSelector((state)=>state.sendCategorie_reducer);
  const [search,setSearch] = useState('');
  const backgroundStyle = {
    backgroundImage: `url(${videoUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: `${100}% ${100}%`,
    boxShadow: `inset 0 0 0 2000px rgba(0,0,0,0.5)`,
    // height: '',
  };
  const backgroundColor = {
    backgroundImage:`linear-gradient(93.59deg, ${homeBannerColor} -0.51%, ${homeBannerColor1} 105.82%)`
  }
  useEffect(() => {
    const data = cmsData.data?.cms; 
    setGiftcards("giftcards")  
    if(data?.default_ncentiva === false){
      setVideoUrl(data?.home_image);
      setCheckColor(data?.home_image_color);
      setPrimaryColor(data?.primary_color);
      setHomeBannerColor(data?.homeBannerColorCombination);
      setHomeBannerColor1(data?.homeBannerColorCombination1);
      setSecondaryColor(data?.secondary_color);
      setBtnColor(data?.btn_color);
      setBtnText(data?.btn_text);
    }else{
      setCheckColor(true);
      setBtnColor('#3D05B6');
      setBtnText('white');
      setHomeBannerColor("#FF7020");
      setHomeBannerColor1("#F5C400")
      setPrimaryColor("#FF7020");
      setSecondaryColor("#F5C400");
    }
    setIsLoading(false);
}, [cmsData])


//   useEffect(()=>{
//     if(nameDomain[0] !== "dev" || "staging" || "payouts" || "uat"){
//     axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
//     .then((res)=>{
//       setGiftcards("giftcards")  
//       if(res.data?.cms?.default_ncentiva === false){
//         setVideoUrl(res.data?.cms?.home_image);
//         setCheckColor(res.data?.cms?.home_image_color);
//         setPrimaryColor(res.data?.cms?.primary_color);
//         setHomeBannerColor(res.data?.cms?.homeBannerColorCombination);
//         setHomeBannerColor1(res.data?.cms?.homeBannerColorCombination1);
//         setSecondaryColor(res.data?.cms?.secondary_color);
//         setBtnColor(res.data?.cms?.btn_color);
//         setBtnText(res.data?.cms?.btn_text);
//       }else{
//         setCheckColor(true);
//         setBtnColor('#3D05B6');
//         setBtnText('white');
//         setHomeBannerColor("#FF7020");
//         setHomeBannerColor1("#F5C400")
//         setPrimaryColor("#FF7020");
//         setSecondaryColor("#F5C400");
//       }
//       setIsLoading(false);
//     }).catch((err)=>{
//         console.log(err);
//     })
//   }else{
//     setIsLoading(false);
//   }
// },[])
const dispatch = useDispatch();
const handleKeyPress = (event) => {
  if (event.key === 'Enter') {
    // Call your function here
    handleClick();
    navigate('/giftcards');
  }
};
const handleClick=()=>{
   dispatch(ClearhandleCategories());
   dispatch(globalSearch(search))
}

  return (
   <>
   {isLoading ? "" : 
    <section style={checkColor ? backgroundColor : backgroundStyle} className="hero-search">
    <div className="container">
        <div className="row justify-content-center">
        <div className="col-lg-8">
            <h2>Explore our gift cards</h2>
            <div className="search-content">
            <div className="search-bar">
                <input
                type="text"
                className="form-control"
                placeholder="Search for a brand, product or service"
                onChange={(e)=>setSearch(e.target.value)}
                onKeyPress={handleKeyPress}
                />
                {/* <i className="far fa-search search-icon" /> */}
                <FaSearch className='search-icon'/>
            </div>

            {btnColor ? <Link onClick={()=>handleClick()} to={giftcards} className="btn btn-main"  style={{backgroundColor:btnColor,color: btnText, border: `1px solid ${btnColor}`}} > <strong>Search</strong> </Link>:<Link to={giftcards} className="btn btn-main" ><strong>Search</strong></Link>}
            </div>
            <div className="ju_categories_search d-xl-flex align-items-center justify-content-center ">
            <div className="categories-area my-3 justify-content-center ">
               {hcategories?.map((hcategory) => 
                    <Category home={"true"} key={hcategory.toString()} btnColor={btnColor} btnText={btnText}  title={hcategory}/>
                    )}
            </div>

            <ModalOpen  title={<p className='text-center mb-0'><strong>View all categories+</strong></p>} home={"true"} num={0} giftcards={giftcards} btnColor={btnColor} btnText={btnText} data={props?.categories}/>
            </div>
        </div>
        </div>
    </div>
</section>}
   </>
  )
}

export default Herobanner
