// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 576px){

}

@media only screen and (max-width: 991px){
  .customersByBusiness_breakBtn1__QGRrq{
    display: flex;
    flex-direction: column;
    margin-right: 5px;
  }
}

@media only screen and (min-width: 992px){
    .customersByBusiness_breakBtn1__QGRrq{
        /* border:1px solid red; */
        min-width: 350px;
        justify-content: end;
        margin-right: 5px;
    }
}

@media only screen and (min-width: 1069px){
    .customersByBusiness_breakBtn1__QGRrq{
        /* border:1px solid black; */
        justify-content: end;
        margin-right: 5px;
      }
}

/*==== Min & Max Width media query ====*/
@media only screen and (min-width: 767px) and (max-width: 1200px){
}

/*==== Max Width media query ====*/

@media only screen and (max-width: 1200px){

}`, "",{"version":3,"sources":["webpack://./src/pages/customersByBusiness.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,iBAAiB;EACnB;AACF;;AAEA;IACI;QACI,0BAA0B;QAC1B,gBAAgB;QAChB,oBAAoB;QACpB,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,4BAA4B;QAC5B,oBAAoB;QACpB,iBAAiB;MACnB;AACN;;AAEA,wCAAwC;AACxC;AACA;;AAEA,kCAAkC;;AAElC;;AAEA","sourcesContent":["@media only screen and (min-width: 576px){\n\n}\n\n@media only screen and (max-width: 991px){\n  .breakBtn1{\n    display: flex;\n    flex-direction: column;\n    margin-right: 5px;\n  }\n}\n\n@media only screen and (min-width: 992px){\n    .breakBtn1{\n        /* border:1px solid red; */\n        min-width: 350px;\n        justify-content: end;\n        margin-right: 5px;\n    }\n}\n\n@media only screen and (min-width: 1069px){\n    .breakBtn1{\n        /* border:1px solid black; */\n        justify-content: end;\n        margin-right: 5px;\n      }\n}\n\n/*==== Min & Max Width media query ====*/\n@media only screen and (min-width: 767px) and (max-width: 1200px){\n}\n\n/*==== Max Width media query ====*/\n\n@media only screen and (max-width: 1200px){\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breakBtn1": `customersByBusiness_breakBtn1__QGRrq`
};
export default ___CSS_LOADER_EXPORT___;
