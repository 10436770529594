// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width:500px) {
    .button_style{
        display: block !important;
    }
    .button_style button{
        font-size: 13px;
    }
    /* .button_style button:nth-child(1){
        margin-bottom: 10px ;
    } */
}
.password-text {
    word-break: break-all; /* Breaks long passwords into multiple lines */
}`, "",{"version":3,"sources":["webpack://./src/styles/transaction.css"],"names":[],"mappings":"AAAA;IACI;QACI,yBAAyB;IAC7B;IACA;QACI,eAAe;IACnB;IACA;;OAEG;AACP;AACA;IACI,qBAAqB,EAAE,8CAA8C;AACzE","sourcesContent":["@media screen and (max-width:500px) {\n    .button_style{\n        display: block !important;\n    }\n    .button_style button{\n        font-size: 13px;\n    }\n    /* .button_style button:nth-child(1){\n        margin-bottom: 10px ;\n    } */\n}\n.password-text {\n    word-break: break-all; /* Breaks long passwords into multiple lines */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
