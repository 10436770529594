import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import { Link, useParams, useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import Resizer from "react-image-file-resizer";
import axios from "axios";
import "../../src/App.scss";
import { FaCloudUploadAlt } from "react-icons/fa";
import { Bars } from "react-loader-spinner";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
} from "react-bootstrap";
import ReactFileReader from "react-file-reader";
import { getNewToken } from "../config/cookie.config";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
const BrandEdit = () => {
  const { id } = useParams();
  const [active, setActive] = useState(false);
  const [filename, setFilename] = useState('');
  const [brandname, setBrandname] = useState('');
  const [brandid, setBrandid] = useState('');
  const [description, setDescription] = useState('');
  const [redemptionInstructions, setRedemptionInstructions] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [imagepath, setImagePath] = useState('');
  const [image, setImage] = useState('');
  const [imageHash] = useState(Date.now());
  const [filesize, setFilesize] = useState('');
  const [error,setError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [delay,setDelay] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      getcoupons();
    }
  }, [imagepath, imageHash]);
  const getcoupons = () => {
    setIsLoading(true);
    var token;
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getbrand/${id}`, {
          headers: {
            'Authorization' : `Bearer ${getNewToken()}`
          }
        })
        .then((response) => {
          if(response.data.message == "Please Login"){
            localStorage.removeItem('client');
            navigate('/admin');
            toast.error("Session Expired Please Login Again")
          }
          setBrandname(response.data.brand.brandname);
            setDescription(response.data.brand.description);
            setRedemptionInstructions(response.data.brand.redemptionInstructions);
            setTermsAndConditions(response.data.brand.termsAndConditions);
            setImagePath(response.data.brand.img_path);
            setBrandid(response.data.brand.coupon_id);
            setIsLoading(false);
        })
        .catch((error) => console.error(`Error: ${error}`));
  };
  const handleFiles = (files) => {
    setFilename(files[0].name);
    setFilesize(files[0].size);
    let file = files[0];

    Resizer.imageFileResizer(file, 720, 500, "JPEG", 100, 0, async (uri) => {
      setImage(uri);
    })
  }
  const handleUpload = async (e) => {
    e.preventDefault();
    setDelay(true);
    setTimeout(()=>{
      setDelay(false);
    },2300)
    if (image === "") {
      toast.warning("Please upload image");
      return false;
    }
    if (filesize >= 2097152) {
      toast.warning("Image size less then 2MB supported.");
      return false;
    }
    try {
        setIsLoading(true);
        const  {data}  = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/brand/image`, {
            brandid,
            image,
            id,
        });
         toast.success(data.message);
        if (data.status === "success") {
          navigate('/admin/brands');
          // window.location.reload();
        }
        // else{
        //     toast(data.message);
        // }
      } catch (err) {
        console.log(`Error : ${err}`);
        toast.error(err);
      }
    
  }
 
  const handleRedemption = (e)=>{
    setRedemptionInstructions(e.target.value);
    setError(false);
  }

  const handleTermandConditions = (e) =>{
    setTermsAndConditions(e.target.value);
    setError(false);
  }

  const handleDescription = (e) =>{
    setDescription(e.target.value);
    setError(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
        const  {data}  = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/editbrand`, {
            id,
            description,
            redemptionInstructions,
            termsAndConditions
        });
        toast.success(data.message);
        if (data.status === "success") {
          navigate('/admin/brands');
          // window.location.reload();
        }
      }
     catch (err) {
      console.log(`Error : ${err}`);
      toast.error(err);
    }
  }
  return isLoading === true ? (
    <div className="d-flex justify-content-center mt-5 pt-5">
      <Bars
      height="80"
      width="100%"
      margin='auto'
      color="var(--newMainBlue)"
      ariaLabel="bars-loading"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      />
    </div>
  ) : (
    <>
      <div className="p-3 p-lg-4 p-xl-5">
        <Container fluid className="bg-white p-2 p-sm-4 rounded shadow">
          <Row className="align-items-center pb-5">
            <Col md={5}>
              <div>
                <Link
                  to="/admin/brands"
                  className="p-0 btn btnOrangeTransparent fw-semibold"
                  variant="white"
                >
                  <span className="me-2 fw-semibold">
                    {/* <img
                      src="/assets/images/back-org.png"
                      className="img-fluid"
                      alt=""
                    /> */}
                     <MdOutlineKeyboardBackspace  className="fs-4" style={{color: 'var(--newOrange)'}}/>
                  </span>
                  <span>Back</span>
                </Link>
              </div>
            </Col>
            <Col md={7}></Col>
            <Col md={12} className="mt-4">
              <h1 className="font20 text-dark fw-bold mb-4">Brand</h1>
              <Row className="align-items-center">
                <Col  xl={5} xxl={6}>
                  <Form className="bg-white p-4 rounded containerShadow" onSubmit={handleSubmit}>
                    <Col md={12}>
                      <Row className="mb-4 align-items-center">
                        <Col lg={2}>
                          <div className="font12 text-dark">Brand Name</div>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            type="text"
                            value={brandname}
                            onChange={(e) => setBrandname(e.target.value)}
                            className="font14"
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row className="mb-4 align-items-center">
                        <Col lg={2}>
                          <div className="font12 text-dark">Description</div>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            type="text"
                            value={description}
                            onChange={handleDescription}
                            placeholder="$2305.00"
                            className="font14"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12}>
                      <Row className="mb-4 align-items-center">
                        <Col lg={2}>
                          <div className="font12 text-dark">Redemption Instructions</div>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            type="text"
                            value={redemptionInstructions}
                            onChange={handleRedemption}
                            placeholder="10"
                            className="font14"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12}>
                      <Row className="mb-4 align-items-center">
                        <Col lg={2}>
                          <div className="font12 text-dark">
                            Terms and Conditions
                          </div>
                        </Col>
                        <Col lg={10}>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            type="text"
                            value={termsAndConditions}
                            onChange={handleTermandConditions}
                            placeholder="Game"
                            className="font14"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mt-4">
                      <div className="d-flex align-items-center middle_button ">
                        {error ? <Button
                        style={{ filter: "blur(1px)", cursor: "default",backgroundColor: 'var(--newOrange)',
                          borderColor: 'var(--newOrange)', }}
                          variant="primary"
                          className="fullRounded px-4 py-2 font12 me-3 shadowPrimary"
                        >
                          Save Changes
                        </Button>
                        :<Button
                        type="submit"
                        variant="primary"
                        className="fullRounded newCustomButton px-4 py-2 font12 me-3 shadowPrimary"
                        style={{
                          backgroundColor: 'var(--newOrange)',
                          borderColor: 'var(--newOrange)',
                      }}
                      >
                        Save Changes
                      </Button>}
                        <Form.Check
                          type="switch"
                          label={active ? "Active" : "Inactive"}
                          className="switchLabel font12"
                          checked={active}
                          onChange={() => {
                            setActive(!active);
                          }}
                        />
                      </div>
                    </Col>
                  </Form>
                </Col>
                <Col  xl={7} xxl={6}>
                  <Form className="bg-white p-4 rounded containerShadow" onSubmit={handleUpload}>
                    <div className="editPimage text-center">
                      <img src={`${imagepath}?${imageHash}`} className="img-fluid" alt="" />
                    </div>
                    <div className="d-flex align-items-center justify-content-center my-4 last_save_button">
                      {/* <div className="fs-5 text-dark me-3">
                        Upload Image
                      </div> */}
                      <div className="d-flex align-items-center position-relative pb-4 ">
                        <Form.Control
                          type="hidden"
                          value={brandid}
                          onChange={(e) => setBrandid(e.target.value)}
                          className="font14"
                          disabled
                        />
                        <Button 
                            variant="outline-primary" 
                            className="fullRounded newCustomButton px-2 px-sm-4 py-2 font14 me-2 fw-semibold fs-6"
                            style={{
                              backgroundColor: '#fff',
                              borderColor: 'var(--newOrange)',
                              color: 'var(--newOrange)',
                            }}
                            onMouseEnter={(e) => {
                              e.target.style.backgroundColor = 'var(--newOrangeHover)';
                              e.target.style.color = '#fff'; // Change text color to white on hover
                            }}
                            onMouseLeave={(e) => {
                              e.target.style.backgroundColor = '#fff';
                              e.target.style.color = 'var(--newOrangeHover)'; // Reset text color to original
                            }}
                          >
                            <ReactFileReader handleFiles={handleFiles} fileTypes={".png,.jpg"}> 
                              <div className="d-flex align-items-center">
                                <FaCloudUploadAlt /> 
                                <span className="ms-2">Upload Image</span>
                              </div> 
                            </ReactFileReader>
                          </Button>

                        {filename && <p className="mb-0 text-danger font12 position-absolute bottom-0 w-100 text-center">{filename} <span className="text-dark " onClick={() => setFilename('')} style={{ cursor: 'pointer' }} >x</span></p>}
                      </div>
                      {delay?<Button
                        variant="primary"
                        className="fullRounded px-2 px-sm-4 py-2 font12 me-3 shadowPrimary fs-6 mb-4"
                      >
                        Save Changes
                      </Button>:<Button
                        type="submit"
                        variant="primary"
                        className="fullRounded newCustomButton px-2 px-sm-4 py-2 font12 me-sm-3 shadowPrimary fs-6 mb-4"
                        style={{
                          backgroundColor: 'var(--newOrange)',
                          borderColor: 'var(--newOrange)',
                        }}
                      >
                        Save Changes
                      </Button>}
                    </div>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default BrandEdit;