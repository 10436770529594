import { CATEGORIES_NAME, CLEAR_CATEGORIES_NAME, SHOW_CATEGORIES_NAME } from "./actionType";


const handleCategories = (value)=>({
    type:CATEGORIES_NAME,
    payload:value

})
const showhandleCategories = (value)=>({
    type:SHOW_CATEGORIES_NAME,
    payload:value

})

const clearhandleCategories = () =>({
    type:CLEAR_CATEGORIES_NAME,
})

export const handleCategory_name = (data) => (dispatch) =>{
    dispatch(handleCategories(data));
}

export const handleCategory_show = (data) => (dispatch) =>{
    dispatch(showhandleCategories(data));
}

export const ClearhandleCategories= () => (dispatch) =>{
    dispatch(clearhandleCategories());
}
