import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import "jspdf-autotable";
import "../styles/transaction.css"
import { Bars } from 'react-loader-spinner';
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  DropdownButton,
  Dropdown,
  Modal
} from "react-bootstrap";
import { toast } from "react-toastify";
import { getNewPartnerToken } from "../config/cookie.config";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
const ApiKey = (props) => {
  let navigate = useNavigate();
  var token;
  var userrole;
  var usersid;
  let id;
  const businessPartner = JSON.parse( window.localStorage.getItem('business-client'));
  const params = useParams();
  if(props?.admin === true){
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      userrole = user.user.role;
      usersid = user.user._id
    } else {
      userrole = null;
      usersid = null;
      navigate("/admin");
    }
    id=params.id;
  }else{
    const businessUser = JSON.parse(window.localStorage.getItem("business-client"));
    if (businessUser) {
      userrole = businessUser.userData.role;
      usersid = businessUser.userData._id
    } else {
      userrole = null;
      usersid = null;
      navigate("/business-login");
    }
    id=businessPartner?.user?._id;
  }


  const [role] = useState(userrole);
  const [userid] = useState(usersid); 
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const redirectToLogin = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      navigate("/admin");
    }
  };

  const getTransactionData = () => {

    setIsLoading(true)
    if(props.admin === true){
      const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    }else{
      const user = JSON.parse(window.localStorage.getItem("business-client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    }
    axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/payouts/v1/business-apikey/${id}`, {loginUserId: userid},{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => {
        toast.success(response.data.message);
        setIsLoading(false)
        setApiData(response.data.data);
      })
      .catch((error) =>
        console.log(error)
      )
  };


  useEffect(() => {
    setIsLoading(true);
    if(props.admin === true){
      redirectToLogin();
    }
    setIsLoading(false);
  }, []);


  useEffect(() => {
    const path = window.location.pathname.split('/');
    
    if (path[1] !== "admin") {
    
      const user = JSON.parse(window.localStorage.getItem("business-client"));
      let token;
      if (user) {
        token = user.token;
      } else {
        token = null;
      }
      const id = user?.userData?._id
      axios
        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getbusinesscontact/${id}`, {
          headers: {
            'Authorization': `Bearer ${getNewPartnerToken()}`
          }
        })
        .then((response) => {
          if (response.data.businessContactId.status === false || response.data.businessContactId.soft_delete === true) {
            localStorage.removeItem('business-client');
            navigate('/business-login');
            toast.warn("Your Account Is Deactivated or Deleted")
          }
        })
        .catch((error) => {
          console.log(`Error: ${error}`)
        })
      }
    }, [])


  const [btnColor,setBtnColor] = useState('');
  const [loading,setLoading] = useState(true);
  const [primaryColor,setPrimaryColor]= useState('');
  const [secondaryColor,setSecondaryColor] = useState('');

  // useEffect(()=>{
  //   setLoading(true);
  //  axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/partner/getcmsbusiness/${id}`)
  //  .then((res)=>{
  //    if(res.data?.cms?.default_ncentiva === false){ 
  //    setBtnColor(res.data?.cms?.btn_color);
  //    setPrimaryColor(res.data?.cms?.primary_color);
  //    setSecondaryColor(res.data?.cms?.secondary_color);
  //  }else{
  //      setBtnColor('#3D05B6');
  //      setPrimaryColor("#FF7020");
  //      setSecondaryColor("#F5C400");
  //    }
  //    setLoading(false);
  //  }).catch((err)=>{
  //    console.log(err);
  //  })
  // },[])


  const handleGenerateClick = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmGenerate = () => {
    setShowConfirmModal(false);
    getTransactionData(); // Call the function to generate API keys
  };

  const handleCancelGenerate = () => {
    setShowConfirmModal(false);
  };

  return (
    <>
      <div className="p-3 p-lg-5">
        {props?.admin ? <div className="d-flex mb-4 align-items-center">
          <Link
            to="/admin/businesspartners"
            className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
            variant="white"
          >
            <span className="me-2 fw-semibold">
              {/* <img
                src="/assets/images/back-org.png"
                className="img-fluid"
                alt=""
              /> */}
              <MdOutlineKeyboardBackspace  className="fs-4" style={{color: 'var(--newOrange)'}}/>
            </span>
            <span>Back</span>
          </Link>
          <h1 className="font20 text-dark">Api Key</h1>
        </div>: 
        <div className="d-flex mb-4 align-items-center">
        <h1 className="font20 text-dark">Api Key</h1>
      </div>}
        <Container fluid className="bg-white p-4 rounded shadow">
          <Row>
            <Col md={12}>
              <Form.Label >
                <span className="fw-normal text-secondary">
                </span>
              </Form.Label>
              <Col>
                <div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="font16 mb-1 " style={{ width: '50vh' }}>Access Key and secret
                    </div>
                    <Button className="ms-1 mb-1 newCustomButton Rounded px-4 px-lg-3 px-xl-4 py-2 fw-bold font12 text-left" style={{ marginTop: '-5px', width: '100px', backgroundColor: 'var(--newOrange)',
                        borderColor: 'var(--newOrange)' }} onClick={handleGenerateClick}>Generate</Button>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
        {/* {apiData.length === 0 ? */}
        <Container fluid className="mt-3 px-2 py-2 p-4 rounded shadow" style={{ backgroundColor: 'var(--newMainBlue)', textAlign:"center", minHeight:'40px', padding:'10px 20px', width: '100%',maxWidth:"900px" , margin:'0 auto' }}>
          <div>
            <h6 className="text-light" style={{ fontSize: '15px' }}>Generate a new set of kets will overwrite the current ones. Update all your API keys after generate the new ones. </h6>
          </div>
        </Container>
        {/* : ""} */}
      </div>

      <div className="mx-md-5 py-3 bg-white d-flex justify-content-center rounded shadow">
        <Col md={11} className="mt-4">
          <div className="table-responsive">
            <table className="table " style={{ verticalAlign: "middle" }}>
              <thead className="bgLightRed font14">
                <tr className="text-center">
                  <th className="py-3">
                    <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                      <span className="me-1">User Name</span>
                    </div>
                  </th>
                  <th className="py-3">
                    <div className="d-flex align-items-center justify-content-center" style={{ cursor: 'pointer' }}>
                      <span className="me-1">Password</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="font14 fw-medium text-center">

                {isLoading === true ? (
                  <tr style={{ backgroundColor: "#eee" }}>
                    <td colSpan="8">
                      <Bars
                        height="80"
                        width="100%"
                        margin='auto'
                        color='var(--newMainBlue)'
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        // style={{color: 'blue'}}
                      />
                    </td>
                  </tr>
                ) : ( apiData.length !== 0 ? 
                    <tr
                      // key={cust._id}
                    >
                      <td className="py-3 font14 fw-medium password-text" >
                        {apiData.username}
                        {apiData.username ? 
                        <Button className='me-2 ms-1 mt-1 mt-xl-0' variant="light" style={{marginTop:'-8px', padding:"3px 8px"}} onClick={() => {navigator.clipboard.writeText(apiData.username); toast.success("Copied to clipboard.");}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-files" viewBox="0 0 16 16">
                  <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
        </svg>
        </Button>: " " }
                      </td>
                      <td className="py-3 font14 fw-medium password-text">
                        {apiData.password}
                        {apiData.password ? 
                        <Button className='me-2 ms-1 mt-1 mt-xl-0' variant="light" style={{marginTop:'-8px', padding:"3px 8px"}} onClick={() => {navigator.clipboard.writeText(apiData.password); toast.success("Copied to clipboard.");}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-files" viewBox="0 0 16 16">
                  <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
        </svg>
        </Button>
        : ""}
                        
                      </td>
                      
                    </tr> : ""
                  )}
                {isLoading ? "" :
                  <>
                    {apiData.length === 0 ? <tr >
                      <td colSpan="8">
                        <h1 className="font16 text-dark">No Data</h1>
                      </td>
                    </tr> : ""}
                  </>
                }

              </tbody>
            </table>
          </div>
        </Col>
      </div>
     {/* Confirmation Modal */}
     <Modal show={showConfirmModal} onHide={handleCancelGenerate} backdrop="static" keyboard={false} size="md" centered>
        <Modal.Body>
          <Col>
            <img className="modal-img" src="/assets/images/exclamation.svg" alt="" />
            <br />
            <br />
            <h3 className="modal-text">Generating a new set of keys will overwrite the current ones. <br />Do you want to continue?</h3>
            <br />
            <Row className="modal-row">
              <Col md={1}></Col>
              <Col md={4}>
                <Button className="btn btn-success success-btn" onClick={handleConfirmGenerate}>
                  <span><b>Confirm</b></span>
                </Button>
              </Col>
              <Col className="middle-col" md={2}></Col>
              <Col md={4}>
                <Button className="btn btn-danger danger-btn" onClick={handleCancelGenerate}>
                  <span><b>Cancel</b></span>
                </Button>
              </Col>
              <Col md={1}></Col>
            </Row>
            <br />
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApiKey;
