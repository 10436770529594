import React, { useEffect, useRef } from 'react'
// import logo from './logo.svg';
import '../App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import '../styles/global.css';
import '../styles/responsive.css';
import NavbarComponent from "../components/Navbar";
import Herobanner from "../components/Herobanner"
import FavouriteBrands from '../components/FavouriteBrands';
import Footer from '../components/Footer';
import WelcomeModal from '../components/elements/WelcomeModal'
import WelcomePromotionalModal from '../components/elements/WelcomePromotionalModal'
import  axios  from 'axios';
import { useState } from 'react';
import { Bars, ThreeDots } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import RedeemSec from './../components/RedeemSec';
import DelightArea from './../components/DelightArea';
import {getCms } from '../Redux/GetCms/actions';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {Modal, Button  } from 'react-bootstrap';
import {getcart} from '../Redux/CartType/actions';
import { getcus } from '../Redux/GetCustomer/action';
import { getCustomer } from '../Redux/ProductItem/action';
 


const 
Home = () => {
  const customer = JSON.parse(window.localStorage.getItem('customer'));
  var id;
  if(customer !== null){
   id= customer?.customer?._id;
  }
  let token;
  if (customer) {
    token = customer.token;
 } else {
    token = null;
}
  const [modal_status, setModal_status] = useState();
  const [pdfPreview, setPdfPreview] = useState(true);
  var modalshowOne = sessionStorage.getItem("modalshowOne");
  const nameDomain = ( window.location.host.split('.')); 
  const [isLoading,setIsLoading] = useState(true);
  // const [cmsData,setCmsData] = useState([]);
  const [giftcards,setGiftcards] = useState("giftcards");
  const [pageLoader,setPageLoader] =  useState(true);
  const [primaryColor,setPrimaryColor]= useState('');
  const [image,setImage] = useState('');
  const render = useRef(0);
  // const dispatch = useDispatch();
  useEffect(()=>{
    document.title = "ncentiva || Home";
    const currentURL = window.location.href;

    if (!currentURL.includes('localhost')) {
        // Define _tip array in the global scope
        var _tip = _tip || [];
    
        const script = document.createElement('script');
        script.type = 'text/javascript';
        
        // Define js variable outside the template string
        var js = document.createElement('script'); // Create the script element here
    
        script.innerHTML = `
            // test
            (function(d,s,id){
                var tjs = d.getElementsByTagName(s)[0];
                if(d.getElementById(id)) { return; }
                js = d.createElement(s); js.id = id;
                js.async = true;
                js.src = d.location.protocol + '//app.truconversion.com/ti-js/33039/a1d99.js';
                tjs.parentNode.insertBefore(js, tjs);
            }(document, 'script', 'ti-js'));
        `;
        document.head.appendChild(script);
    
        // Define onExternalScriptLoad function
        function onExternalScriptLoad() {
            _tip.push(['_trackIdentity', 'dev']);
        }
    
        // Wait for the dynamically created script to load
        js.onload = function() {
            onExternalScriptLoad();
        };
      }
    
  },[])

  useEffect(() => {
    
    window.scrollTo(0, 0);

    
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  

  const [show, setShow] = useState(false);


const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
  


  const dispatch = useDispatch();
  const cmsData = useSelector((state) => state.cmsReducer);
  const cusData = useSelector((state) => state.customer_balance_Reducer)
  const cartData = useSelector((state)=>state.cartReducer);
  
  

  useEffect(() => {
  if (cmsData !== null && cmsData?.data && cmsData.data?.cms?.default_ncentiva === false) {
      setPrimaryColor(cmsData.data?.cms?.primary_color)
      setImage(cmsData?.data?.cms?.brand_logo)
    }else if(cmsData?.data?.cms?.default_ncentiva == true){
      setPrimaryColor("#FF7020")
      setImage("https://dev.ncentiva.com/assets/images/logo.png")
    }
    
  }, [cmsData]);
  
  useEffect(()=>{
    if(id){
      dispatch(getCustomer(id, token));
    }
    },[id])

// const getcustomer = (id) => {
//   if (customer) {
//       token = customer.token;
//   } else {
//       token = null;
//   }
//   if (token) {
//     setIsLoading(true);
//       axios
//           .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/customerfrontend/${id}`, {
//               headers: {
//                   'Authorization': `Bearer ${token}`
//               }
//           })
//           .then((response) => {
          
//               if (response.data.message === "Please Login") {
//                   localStorage.removeItem('customer');
//                   // localStorage.removeItem('listItem');
//                   navigate('/login');
//                   toast.error("Session Expired Please Login Again")
//                   // window.location.reload();
//               }
//               let status = response.data.customer.status;
//               setModal_status(response?.data?.customer?.modal_status);
              
//               if (status === false) {
//                   window.localStorage.removeItem('customer');
//                   navigate('/login');
//                   toast.warning("Your account has been deactivated");
//               }
//               setIsLoading(false);
//           })
//           .catch((error) => {
//               if(error?.response?.status === 404){
//                   localStorage.removeItem('customer');
//                   navigate('/login');
//                   toast.error("Your Account Doesn't Exist")
//               } else if (error?.response?.status === 401) {
//                   localStorage.removeItem('customer');
//                   navigate('/login');
//                   toast.error("Session Expired Please Login Again")
//               } else if (error?.response?.status === 400) {
//                   localStorage.removeItem('customer');
//                   navigate('/login');
//                   toast.error("Your Account Has Been Deactivated")
//               }
//               console.error(`Error: ${error}`)});

//   }
// };

useEffect(()=>{
  // setIsLoading(true);
if(cusData){
  if(cusData?.data?.message === "Please Login"){
    localStorage.removeItem('customer');
    // localStorage.removeItem('listItem');
    navigate('/login');
    toast.error("Session Expired Please Login Again")
  }
  let status = cusData?.data?.status;
  setModal_status(cusData?.data?.modal_status);
  if (status === false) {
    window.localStorage.removeItem('customer');
    navigate('/login');
    toast.warning("Your account has been deactivated");
}
if(cusData?.isError?.response?.status === 404){
  localStorage.removeItem('customer');
  navigate('/login');
  toast.error("Your Account Doesn't Exist")
}else if (cusData?.isError?.response?.status === 401){
  localStorage.removeItem('customer');
  navigate('/login');
  toast.error("Session Expired Please Login Again")
}else if (cusData?.isError?.response?.status === 400){
  localStorage.removeItem('customer');
  navigate('/login');
  toast.error("Your Account Has Been Deactivated")
}
// setIsLoading(false);
}
},[cusData])






useEffect(() => {
  if (id) {
        // getcustomer(id);
  } else {
    //setBalance(null);
  }

}, [id])

const loading = useSelector((state) => state.cmsReducer.loading);

let navigate = useNavigate() 

  useEffect(() => {
    if (nameDomain[0] !== "dev" || nameDomain[0] !== "staging" || nameDomain[0] !== "payouts" || nameDomain[0] !== "uat") {
      setIsLoading(loading);
      dispatch(getCms(nameDomain[0]));
    } else {
      setIsLoading(loading);
    }
  }, []);

  useEffect(()=>{
    dispatch(getcart(token));
   },[])

useEffect(() => {
  const queryParams = new URLSearchParams(window.location.search);
  const serializedData = queryParams.get('data');
  const data = JSON.parse(decodeURIComponent(serializedData));
  if(data){
    window.localStorage.setItem('customer',JSON.stringify(data))
    navigate('/')
    toast.success("Logged In Successfully");
  }
}, []);

const [categories,setCategories] = useState([]);
useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/get-customer-categories`)
    .then((res)=>{
      const unsortedCategories = res.data.brands[0].categories;
      const sortedCategories = unsortedCategories.sort();
      setCategories(sortedCategories);
    }).catch((err)=>{
      console.log(err);
    })
},[]);

  useEffect(()=>{
    setTimeout(() => {
      setPageLoader(false)
    }, 2500);
  },[]);

  useEffect(() => {
    // Define the Freshworks widget configuration
    window.fwSettings = {
      'widget_id': 153000001586
    };

    const loadScript = () => {
      // Create a script element
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.src = 'https://widget.freshworks.com/widgets/153000001586.js';

      // Append the script to the document body
      document.body.appendChild(script);
    };

    // Load the script when the component mounts
    loadScript();

    // Clean up function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(document.querySelector('script[src="https://widget.freshworks.com/widgets/153000001586.js"]'));
    };
  }, []);

  return (
    <>
    {(cmsData?.loading || cusData?.isLoading || cartData?.loading) ?  <div className="d-flex flex-column justify-content-center align-items-center" style={{height:'100vh'}}>
     
    <Bars
      height="80"
      width="100%"
      margin='auto'
      color="#FF7020"
      ariaLabel="bars-loading"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      />
    </div> :
    <>
    <NavbarComponent/>
    <Herobanner categories={categories}/>
    <FavouriteBrands giftcards={giftcards} cmsData={cmsData} render={render}/>
    <RedeemSec categories={categories}/>
    {/* <DelightArea/> */}
    <Footer />

    {/* {modal_status === true  ?  <WelcomeModal cmsData={cmsData} isLoading={isLoading} setIsLoading={setIsLoading}/>  :
<>
      <WelcomePromotionalModal cmsData={cmsData}  isLoading={isLoading} setIsLoading={setIsLoading} />
</>
     } */}

{modal_status === true
  ? <WelcomeModal cmsData={cmsData} isLoading={isLoading} setIsLoading={setIsLoading} />
  : modal_status === false
    ? <WelcomePromotionalModal cmsData={cmsData} isLoading={isLoading} setIsLoading={setIsLoading} />
    : " "
}

    </>
    }
    <div id="freshworks-chat-widget-container"></div>
    </>
  )
}

export default Home
