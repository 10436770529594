import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';

const ModalColor6 = (props) => {
  const [show, setShow] = useState(false);
  const [showPrimary, setShowPrimary] = useState(false);
  const [showSecondary, setShowSecondary] = useState(false);
  const [showgradient, setShowGradient] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleHomeBannerColor = () => {
    setShow(true)

  }
  const handlePrimary = () => {
    props.setHomeBannerColor(true);
    props.setHomeBannerColorCombination(props.primaryColor);
    props.setHomeBannerColorCombination1(props.primaryColor);
    if (props.homeBannerColor === true) {
      props.setImage('');
      props.setFilename('');
    }
    setShow(false);
  }
  const handleSecondary = () => {
    props.setHomeBannerColor(true);
    props.setHomeBannerColorCombination(props.secondaryColor);
    props.setHomeBannerColorCombination1(props.secondaryColor);
    if (props.homeBannerColor === true) {
      props.setImage('');
      props.setFilename('');
    }
    setShow(false);
  }
  const handleGradient = () => {
    props.setHomeBannerColor(true);
    props.setHomeBannerColorCombination(props.primaryColor);
    props.setHomeBannerColorCombination1(props.secondaryColor);
    if (props.homeBannerColor === true) {
      props.setImage('');
      props.setFilename('');
    }
    setShow(false);
  }
  const handleClear = () => {
    props.setHomeBannerColor(false);
    props.setHomeBannerColorCombination('');
    props.setHomeBannerColorCombination1('');
    setShow(false);
  }

  useEffect(() => {
    if (props.homeBannerColorCombination === props.primaryColor && props.homeBannerColorCombination1 === props.primaryColor) {
      setShowPrimary(true);
      setShowSecondary(false);
      setShowGradient(false);
    } else if (props.homeBannerColorCombination === props.secondaryColor && props.homeBannerColorCombination1 === props.secondaryColor) {
      setShowPrimary(false);
      setShowSecondary(true);
      setShowGradient(false);
    }
    else if (props.homeBannerColorCombination === props.primaryColor && props.homeBannerColorCombination1 === props.secondaryColor) {
      setShowPrimary(false);
      setShowSecondary(false);
      setShowGradient(true);
    } else {
      setShowPrimary(false);
      setShowGradient(false);
      setShowSecondary(false);
    }
  })

  return (
    <div>
      <Button

        onClick={handleHomeBannerColor}

        variant="primary"
        style={{ minWidth: '165px', backgroundColor: '#FFAD31',
          borderColor: '#FFAD31' }}
        className="fullRounded newCustomButton px-4 px-lg-3 font-14 px-xl-4 py-2 mt-2 fw-semibold">
        <span>Solid Color</span>
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        centered
      >
        <Modal.Header>
          <h3 style={{ textAlign: 'center', margin: 'auto' }}>Choose Balance solid color option</h3>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <div style={{ margin: 'auto' }} className="d-flex justify-content-between" >
              <Col md={3}>
                <Button className="btn btn-primary" style={{ width: '113px', backgroundColor: showPrimary ? props.primaryColor : '#FFAD31', border: "none" }} onClick={handlePrimary}>

                  <span>Primary</span>
                </Button>
              </Col>
              <Col md={3}>
                <Button className="btn btn-primary" style={{ width: '113px', backgroundColor: showSecondary ? props.secondaryColor : '#FFAD31', border: "none" }} onClick={handleSecondary}>
                  <span>Secondary</span>
                </Button>
              </Col>
              <Col md={3}>
                <Button className="btn btn-primary" style={{ width: '113px', backgroundImage: showgradient ? `linear-gradient(93.59deg,${props.primaryColor},${props.secondaryColor})` : '', backgroundColor: '#FFAD31', border: "none" }} onClick={handleGradient} >
                  <span>Gradient</span>
                </Button>
              </Col>
              <Col md={3}>
                <Button className="btn btn-primary" style={{ width: '113px', backgroundColor: '#FFAD31', border: "none" }} onClick={handleClear} >
                  <span>Clear</span>
                </Button>
              </Col>
            </div>
            <br />
          </Col>
        </Modal.Body>

      </Modal>
    </div>
  )
}

export default ModalColor6
