import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

function MyVerticallyCenteredModal(props) {
  const [pdfPreview, setPdfPreview] = useState('')
  const [pdfImagePreview, setPdfImagePreview] = useState('')
  const nameDomain = (window.location.host.split('.'));
  const [isShown, setIsShown] = useState(false);
  const [isHiden, setIsHiden] = useState(true);
  const [brand, setBrand] = useState("ncentiva");
  const [primaryColor, setPrimaryColor] = useState("#FF7020");
  const [secondaryColor, setSecondaryColor] = useState("#3D05B6");
  const [image, setImage] = useState("");
  const [btnColor, setBtnColor] = useState('');
  const [btnText, setBtnText] = useState('');
  const customer = JSON.parse(window.localStorage.getItem('customer'));
  const [isLoading, setIsLoading] = useState(true);
  const cmsData = useSelector((state) => state.cmsReducer);
  

  const handleHide = () => {
    props.onHide();
  };

  var name;

  if (customer) {
    name = customer.customer.first_name;
  } else {
    name = null;
  }
  const handleSession = () => {
    sessionStorage.setItem("modalshowOne", "Only one time");
    props.onHide()
  }
  const handleClick = event => {
    setIsShown(true);
    setIsHiden(false)
  };

useEffect(()=>{
  if(cmsData){
    if(cmsData?.data?.cms?.default_ncentiva === false){
     if(cmsData?.data?.cms?.pdfPreview){
      setPdfPreview(cmsData?.data?.cms?.pdfPreview);
      setPdfImagePreview('')
     }else if(cmsData?.data?.cms?.pdfimagePreview){
      setPdfImagePreview(cmsData?.data?.cms?.pdfimagePreview);
      setPdfPreview('')
     }else {
      setPdfImagePreview('')
      setPdfPreview('')
     }

     setPrimaryColor(cmsData?.data?.cms?.primary_color);
     setSecondaryColor(cmsData?.data?.cms?.secondary_color);
     setBtnColor(cmsData?.data?.cms?.btn_color);
     setBtnText(cmsData?.data?.cms?.btn_text);
     setImage(cmsData?.data?.cms?.brand_logo);
     setBrand(cmsData?.data?.cms?.brand_name);
    }else {
      setImage("https://ncentiva-development.s3.eu-west-1.amazonaws.com/ncentiva_new_logo.png");
      setBtnColor('#3D05B6');
      setBtnText('white');
      setPrimaryColor("#FF7020");
      setSecondaryColor("#F5C400");
      if(cmsData?.data?.cms?.pdfimagePreview){
        setPdfImagePreview(cmsData?.data?.cms?.pdfimagePreview);
        setPdfPreview('')
      }else{
        setPdfImagePreview('')
        setPdfPreview('')
      }
    }
    setIsLoading(false);

  }
},[cmsData])
  

  // useEffect(() => {
  //   if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
  //     axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
  //       .then((res) => {
  //         if (res.data?.cms?.default_ncentiva === false) {

  //           if (res?.data?.cms?.pdfPreview) {
  //             setPdfPreview(res.data?.cms?.pdfPreview)
  //             setPdfImagePreview('')
  //           } else if (res?.data?.cms?.pdfimagePreview) {
  //             setPdfImagePreview(res?.data?.cms?.pdfimagePreview)
  //             setPdfPreview('')
  //           } else {
  //             setPdfImagePreview('')
  //             setPdfPreview('')
  //           }

  //           setPrimaryColor(res.data?.cms?.primary_color);
  //           setSecondaryColor(res.data?.cms?.secondary_color);
  //           setBtnColor(res.data?.cms?.btn_color);
  //           setBtnText(res.data?.cms?.btn_text);
  //           setImage(res.data?.cms?.brand_logo);
  //           setBrand(res.data?.cms?.brand_name);
  //         } else {

  //           setImage("https://dev.ncentiva.com/assets/images/logo.png");
  //           setBtnColor('#3D05B6');
  //           setBtnText('white');
  //           setPrimaryColor("#FF7020");
  //           setSecondaryColor("#F5C400");
  //           if (res?.data?.cms?.pdfimagePreview) {
  //             setPdfImagePreview(res?.data?.cms?.pdfimagePreview)
  //             setPdfPreview('')
  //           } else {
  //             setPdfImagePreview('')
  //             setPdfPreview('')
  //           }
  //         }
  //         setIsLoading(false);
  //       }).catch((err) => {
  //         console.log(err);
  //       })
  //   } else {
  //     setIsLoading(false);
  //   }
  // }, []);

  const LogoStyle = {
    width: "170px",
    height: "110px",
    objectFit: "scale-down",
  }


  return (
    <>
   
      {isLoading || (pdfPreview.length === 0) && (pdfImagePreview.length === 0) ? " " :
        <Modal
          {...props} centered backdrop="static" keyboard={false}  className=""
          >
          <Modal.Body style={{ backgroundColor: primaryColor, border: "2px solid", margin: "0", padding: "3px", borderRadius:'8px' }}  >


            {pdfPreview ?
              //  <div style={{width: '100%'}} className='position_relative'>
              <>
                <iframe
                  className="pdf-viewer-container"
                  id='pdf-viewer-container-pdf'
                  src={pdfPreview}
                  title="description"
                  //  style={{  }}
                  scrolling="no"
                  toolbar='0'
                  allowFullScreen
                ></iframe>
                <div className='button_closed_pdf'>
                  <p style={{ backgroundColor: primaryColor, border: `2px dotted ${primaryColor}` }} className="button_style" onClick={handleHide}>X</p>
                </div>
              </>
              //  </div>
              :
              ''
            }
            {
              pdfImagePreview ?
                <div style={{ width: '100%' }} className="scroll-modal d-flex align-items-center justify-content-center">
                  <img className="pdf-viewer-container img-thumbnail position_relative" src={pdfImagePreview} alt="pdfimage" />
                  <div className='button_closed'>
                    <p style={{ backgroundColor: primaryColor, border: `2px dotted ${primaryColor}` }} className="  button_style" onClick={handleHide}>X</p>
                  </div>
                </div>
                : ''

            }
            <style>
              {`
              .scroll-modal::-webkit-scrollbar {
                width: 6px;
                height: 4px;
              }
              .scroll-modal::-webkit-scrollbar-thumb {
                background-color: ${secondaryColor};
                border-radius: 6px;
                
              }
              .scroll-modal::-webkit-scrollbar-track {
                background-color: transparent;
              }
            `}
            </style>

          </Modal.Body>
        </Modal>}
    </>
  );
}

function WelcomePromotionalModal(props) {
  const [modalShow, setModalShow] = React.useState(true);
  const handleClose = () => setModalShow(false);
  return (
    <>
      {props.isLoading === 0 ? "" : <>
        {/* <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button> */}

        <MyVerticallyCenteredModal
          // cmsData={props.cmsData}
          show={modalShow}
          onHide={handleClose}
        />
      </>}
    </>
  );
}

export default WelcomePromotionalModal