import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams, Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert, Modal } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { toast } from "react-toastify";

const NewCustomerLogin = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newConfirmPassword, setNewConfirmPassword] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [stillSendingOtp, setStillSendingOtp] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [token, setToken] = useState('');
  const [isLoad, setIsLoad] = useState(false);
  const [image, setImage] = useState("");
  const [btnColor, setBtnColor] = useState('');
  const [btnText, setBtnText] = useState('');
  const [loading, setLoading] = useState(true);
  const [cmsData, setCmsData] = useState(false)
  const [customerId, setCustomerId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const nameDomain = (window.location.host.split('.'));

  useEffect(() => {
    const tokenParam = searchParams.get('token');

    if (!tokenParam) {
      setModalMessage("Invalid activation link. Please request a new one.");
      setShowModal(true);
      return;
    }

    const decryptedData = CryptoJS.AES.decrypt(decodeURIComponent(tokenParam), process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);

    if (decryptedData) {
      const { email, token, customerId } = JSON.parse(decryptedData);
      setEmail(email);
      setToken(token);
      setCustomerId(customerId);

      const checkLinkValidity = async () => {
        try {
          const credentials = {
            email: email,
            token: token,
            customerId: customerId,
            timestamp: Date.now()
          };
          const jsonString = JSON.stringify(credentials);
          const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

          const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/check-link-validity`,{
            securedLogin
          });
          
          if (response.data.expired) {
            setModalMessage("This account activation link has been used already. If you require a new activation link please contact us at support@ncentiva.com");
            setShowModal(true);
          } else {
            setIsLoad(true);
          }
        } catch (error) {
          console.error('Error validating the link:', error);
          if (error.response && error.response.data.message === 'Link has expired or is invalid') {
            setModalMessage("This account activation link has been used already. If you require a new activation link please contact us at support@ncentiva.com");
          } else {
            setModalMessage("An error occurred while validating the link. Please try again or contact support.");
          }
          setShowModal(true);
        }
      };

      checkLinkValidity();
    } else {
      setModalMessage("Invalid activation link. Please request a new one.");
      setShowModal(true);
    }
  }, [searchParams]);

  // Toggle password visibility
  const togglePassword = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== newConfirmPassword) {
      toast.warning('Passwords do not match!'); // Set error message
      return;
    }

    setIsSubmitting(true);
    setStillSendingOtp(true);

    try {

      const credentials = {
        email: email,
        token: token,
        type: 'customer',
        newPassword: newPassword,
        newConfirmPassword: newConfirmPassword,
        customerId: customerId,
        timestamp: Date.now()
      };
      const jsonString = JSON.stringify(credentials);
      const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

      // Make an API call to reset the password
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/customer/savepassword`, {
        securedLogin
      });

      if (response.data.status === "success") {
        setNewPassword("");
        setNewConfirmPassword("");
        window.localStorage.setItem("customer_data", JSON.stringify(response.data));
        localStorage.removeItem('customer_email');
        navigate('/customer/verify');
        setStillSendingOtp(false);
      } else if (response.status === "failed") {
        setTimeout(() => setStillSendingOtp(false), 2000);
      }

    } catch (error) {
      console.error(error);
      if (error.response) {
        toast.error(error.response.data.message || 'Failed to set password. Please try again.'); // Set error message
      } else if (error.request) {
        toast.error('No response from server. Please check your network connection.'); // Set error message for no response
      } else {
        toast.error('An unexpected error occurred. Please try again.'); // Set generic error message
      }
    } finally {
      setIsSubmitting(false);
      setStillSendingOtp(false);
    }
  };


  useEffect(() => {
    setLoading(true);
    if (nameDomain[0] !== "dev" || "staging" || "payouts" || "uat") {
      axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${nameDomain[0]}`)
        .then((res) => {
          if (res.data?.cms?.default_ncentiva === false) {
            setCmsData(false)
            setBtnColor(res.data?.cms?.btn_color);
            setBtnText(res.data?.cms?.btn_text);
            if (res.data?.cms?.brand_logo) {
              setImage(res.data?.cms?.brand_logo)
            }
          } else {
            setCmsData(true)

            setImage("https://ncentiva-development.s3.eu-west-1.amazonaws.com/ncentiva_new_logo.png")
            setBtnColor('#FFAD31');
            setBtnText('white');
          }
          setLoading(false);
        }).catch((err) => {
          console.log(err);
        })
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      {isLoad ?
        <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh", backgroundColor: "var(--newLightBlue)" }}>
          <Container className="bg-white loginContainer p-0 rounded" style={{ maxWidth: "1065px" }}>
            <div className="d-flex align-items-center justify-content-center">
              <Link
                to='/'
              >

                <img
                  src={image}
                  className="imgLogo img-fluid mt-4 img_hover"
                  alt=""
                  style={{
                    width: '200px',
                    height: '100px',
                    objectFit: 'contain'
                  }}
                />
              </Link>
            </div>
            <Row className="align-items-center g-0">
              <Col md="6" style={{ height: "100%", borderRight: "1px solid #ccc" }} className="loginLeft j_login_left">
                <h1 className="loginH1Title text-dark mb-3">Set New Password</h1>


                <Form className="form mt-5" onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="text-dark">Email</Form.Label>
                    <Form.Control type="email" value={email} disabled />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="text-dark">New Password</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={passwordType}
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={newPassword}
                        placeholder="Enter New Password"
                      />
                      <button type="button" className="bg-transparent border-0 position-absolute eyBtn" onClick={togglePassword}>
                        {passwordType === "password" ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="text-dark">Confirm New Password</Form.Label>
                    <div className="position-relative">
                      <Form.Control
                        type={showConfirmNewPassword ? 'text' : 'password'}
                        onChange={(e) => setNewConfirmPassword(e.target.value)}
                        value={newConfirmPassword}
                        placeholder="Enter Confirm New Password"
                      />
                      {newConfirmPassword.length > 0 && (
                        <button type="button" className="bg-transparent border-0 position-absolute eyBtn" onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}>
                          {!showConfirmNewPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    {
                      stillSendingOtp && isSubmitting ? (
                        <Button
                          type="submit"
                          variant="primary"
                          style={cmsData
                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                          }
                          className="w-100 fullRounded newCustomButton font12 fw-bold py-3 shadowPrimary"
                          disabled
                        >
                          Please wait ....
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          style={cmsData
                            ? { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                            : { backgroundColor: `${btnColor}`, color: btnText, border: `1px solid ${btnColor}` }
                          }
                          variant="primary"
                          className="w-100 fullRounded newCustomButton font12 fw-bold py-3 shadowPrimary button_hover"
                        >
                          Set Password
                        </Button>
                      )
                    }
                  </Form.Group>
                </Form>
              </Col>
              <Col md="6" className="loginRight j_login_right align-items-center" style={{ height: "100%" }}>

                <p className="customer-login-verification-text text-dark">
                  <span className="verification-title" style={{ fontWeight: '500' }}>One time password verification.</span> <br /><br />
                  We will send an OTP code to your email.<br />
                  Please use it before it expires within 5 minutes.<br />
                </p>
              </Col>
            </Row>
          </Container>
        </Container>
        : null}
      
      <Modal show={showModal} onHide={() => setShowModal(false)} centered backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Account Activation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => navigate('/login')}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewCustomerLogin;