import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/page/Login.scss";
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CryptoJS from 'crypto-js';

const BusinessCreatePassword = () => {
    useEffect(() => {
        document.title = "ncentiva || Business Set Password"
    }, [])

    const navigate = useNavigate();
    const { id } = useParams();
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newConfirmPassword, setNewConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    // const [email, setEmail] = useState("");
    useEffect(() => {


    }, []);
    
    const email = window.localStorage.getItem("business_email")


    const handleSubmit = async (e) => {
      
        e.preventDefault();
        if (!newConfirmPassword && !newPassword) {
            toast.warning("All Fields Are Required")
        }
        // else if (!password) {
        //     toast.warning("Please Enter Your Password")
        // }
        else if (!newPassword) {
            toast.warning("Please Enter Your New Password")
        }
        else if (newPassword !== newConfirmPassword) {
            toast.warning("New Password And New Confirm Password Doesn't Matched")
        } else {
            if (newPassword.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/) < 0) {
                toast.warning(<div>
                    <p><b>Password must be</b></p>
                    <br />
                    <ul>
                        <li>
                            Use at least 8 characters
                        </li>
                        <li>
                            Use upper and lower case characters
                        </li>
                        <li>
                            Use 1 or more numbers
                        </li>
                        <li>
                            Use 1 or more special character
                        </li>
                    </ul>
                </div>);
                // toast("password must be 8 characters including 1 uppercase letter, 1 special character and alphanumeric characters ");
            } else {


                try {
                    const credentials = {
                        id: id,
                        email: email,
                        password: password,
                        newPassword: newPassword,
                        newConfirmPassword: newConfirmPassword
                      };
                      const jsonString = JSON.stringify(credentials);
                      const securedLogin = CryptoJS.AES.encrypt(jsonString, process.env.REACT_APP_SECRET_KEY).toString();

                    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/set-password/`, {
                        securedLogin
                    });
                    toast.success(data.message);
                    if (data.status === "success") {
                        // if(data.user.new===true){}
                        navigate('/business-login');
                    }
                } catch (err) {
                    console.log(err)
                    // toast(err.response.message);
                }

            }




        }
    }

    function keyDown(e) {
        var e = window.event || e;
        var key = e.keyCode;
        //space pressed
        if (key == 32) { //space
            e.preventDefault();
        }
    }

    function checkWhitespace(event)
    {
        var data = event.clipboardData.getData("text/plain");
        var isNullOrContainsWhitespace = (!data || data.length === 0 || /\s/g.test(data));
      
        if(isNullOrContainsWhitespace)
        {
            event.preventDefault(); 
        }
      
    }

    return (
        <>
            <Container fluid className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#fff8f3' }}>
                <Container
                    className="bg-white loginContainer p-0 rounded"
                    style={{ maxWidth: "1065px" }}
                >
                    <Row className="align-items-center g-0">
                        <Col style={{ height: "100%" }} md={6} className="loginLeft order-2 order-md-1">
                            <div className="">
                                <h4 className="text-dark">Set Password</h4>
                                <p className="font16 text-dark">

                                </p>



                                <Form className="form mt-5" onSubmit={handleSubmit}>

                                    <Form.Group className="mb-3">
                                        <Form.Label className=" text-dark">Email</Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type="email"
                                                required
                                                // onChange={(e) => setEmail(e.target.value)}
                                                placeholder="Enter Email"
                                                onKeyPress={(e) => keyDown(e)}
                                                onPaste={(e) => checkWhitespace(e)}
                                                value={email}
                                                disabled
                                            />

                                        </div>

                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label className=" text-dark">New Password</Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                // value={password}
                                                onChange={(e) => {
                                                    let trimValue = e.target.value; 
                                                    trimValue = trimValue.replace(/\s/g, '') 
                                                    setNewPassword(trimValue)}}
                                                placeholder="Enter New Password"
                                                onPaste={(e) => checkWhitespace(e)}
                                                value={newPassword}
                                            />
                                            {newPassword.length > 0 && <button type="button" className="bg-transparent border-0 position-absolute eyBtn" onClick={() => { setShowConfirmPassword(!showConfirmPassword) }} >
                                                {!showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                            </button>}
                                        </div>

                                    </Form.Group>


                                    <Form.Group className="mb-3">
                                        <Form.Label className=" text-dark">New Confirm Password</Form.Label>
                                        <div className="position-relative">
                                            <Form.Control
                                                type={showConfirmNewPassword ? 'text' : 'password'}
                                               
                                                onChange={(e) => {
                                                    let trimValue = e.target.value; 
                                                    trimValue = trimValue.replace(/\s/g, '') 
                                                    setNewConfirmPassword(trimValue)}}
                                                placeholder="Enter New Confirm Password"
                                                onPaste={(e) => checkWhitespace(e)}
                                                value={newConfirmPassword}
                                            />
                                            {newConfirmPassword.length > 0 && <button type="button" className="bg-transparent border-0 position-absolute eyBtn" onClick={() => { setShowConfirmNewPassword(!showConfirmNewPassword) }} >
                                                {!showConfirmNewPassword ? <FaEyeSlash /> : <FaEye />}
                                            </button>}
                                        </div>

                                    </Form.Group>

                                    <Form.Group className="mb-3">

                                        {error ? <Button
                                            variant="primary"
                                            className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
                                        >
                                            submit
                                        </Button> : <Button
                                            type="submit"
                                            variant="primary"
                                            className="w-100 fullRounded font12 fw-bold py-3 shadowPrimary"
                                        >
                                            submit
                                        </Button>}

                                    </Form.Group>
                                </Form>
                            </div>
                        </Col>
                        <Col
                            md={6}
                            className="loginRight d-flex justify-content-center flex-column align-items-center order-1 order-md-1"
                            style={{ height: "100%" }}
                        >
                            <img
                                src="/assets/images/logo.png"
                                className="imgLogo img-fluid"
                                style={{ width: '200px', height: '100px', marginLeft: '-18%', objectFit:'scale-down' }}
                                alt=""
                            />

                        </Col>
                    </Row>
                </Container>
            </Container>
            {/* <ToastContainer position="top-center" /> */}
        </>

    );
};

export default BusinessCreatePassword;
