import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

const DelightBlock = props => {
  return (
    <Link to="/" className="delight-card">
        <div className="delight-img">
            <img
            src={props.src}
            alt={props.alt}
            className="img-fluid"
            />
        </div>
        <div className="delight-logos">
              <div className="row">
                <div className="col-12">
                    <p className="text-center">{props.title}</p>
                </div>
                <div className="col-6 col-sm-3 mb-2">
                    <img
                    src={props.dlogo1}
                    alt={props.brandalt1}
                    className="img-fluid"
                    />
                </div>
                <div className="col-6 col-sm-3 mb-2">
                    <img
                    src={props.dlogo2}
                    alt={props.brandalt2}
                    className="img-fluid"
                    />
                </div>
                <div className="col-6 col-sm-3 mb-2">
                    <img
                    src={props.dlogo3}
                    alt={props.brandalt3}
                    className="img-fluid"
                    />
                </div>
                <div className="col-6 col-sm-3 mb-2">
                    <img
                    src={props.dlogo4}
                    alt={props.brandalt4}
                    className="img-fluid"
                    />
                </div>
              </div>
        </div>               
    </Link>

  )
}

DelightBlock.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string,
    dlogo1:PropTypes.string,
    dlogo2:PropTypes.string,
    dlogo3:PropTypes.string,
    dlogo4:PropTypes.string,
    brandalt1:PropTypes.string,
    brandalt2:PropTypes.string,
    brandalt3:PropTypes.string,
    brandalt4:PropTypes.string,
}

DelightBlock.defaultProps = {
    src: 'assets/images/gift-cards/card1.jpg',
    alt: 'delight image',
    title: 'For employees motivation',
    dlogo1: 'assets/images/gift-logos/Uber.png',
    dlogo2: 'assets/images/gift-logos/Buy_logo.png',
    dlogo3: 'assets/images/gift-logos/amazon.png',
    dlogo4: 'assets/images/gift-logos/fitbit.png',
    brandalt1: 'Delight logo',
    brandalt2: 'Delight logo',
    brandalt3: 'Delight logo',
    brandalt4: 'Delight logo',
};

export default DelightBlock
