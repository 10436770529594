import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaMinus, FaPlus } from 'react-icons/fa';
// import ViewCardTab from './ViewCardTab';
import axios from "axios";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ViewCardTab from './elements/ViewCardTab';
function ViewCardModal(props) {



    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="viewcard-modal"
      >
        <Modal.Header closeButton className='border-0'></Modal.Header>
        <Modal.Body className="pt-0 px-lg-5 pb-5">
          <div className="viewcard-product d-flex justify-content-center mb-1">
            <div className="listing-logo">
                <img src={props.imagepath} alt="" className="img-fluid"/>
            </div>
            <div className="viewcard-dtl">
                <h1>{props.name}</h1>
            </div>
          </div>
          <div className="add-viewgift d-flex align-items-center justify-content-center flex-wrap my-4">
                                <h6 className="text-main mt-2 fs-3">You get <span className="fs-1 fw-bold"></span> (+{props.b}%)</h6>
                                </div>
          <ViewCardTab description={props.description} redemptionInstructions={props.redemptionInstructions} termsAndConditions={props.termsAndConditions}/>
        </Modal.Body>
      </Modal>
    );
  }
  const ViewCardMode = props => {
    const [modalShow, setModalShow] = React.useState(false);
    const brand_id = props.brand_id
    const coupon = props.coupon
    const [name, setName] = useState(props.data.brand_name);
    const [description, setDescription] = useState(props.data.description);
    const [redemptionInstructions, setRedemptionInstructions] = useState(props.data.redemptionInstructions);
    const [termsAndConditions, setTermsAndConditions] = useState(props.data.termsAndConditions);
    const [imagepath, setImagePath] = useState(props.data.img_path);
 
  return (
    <>
        <Button variant="" onClick={() => setModalShow(true)} className="view-listing-btn ps-0">
        View card details
        </Button>
        <ViewCardModal
        name={name}
        imagepath={imagepath}
        description={description}
        redemptionInstructions={redemptionInstructions}
        termsAndConditions={termsAndConditions}
        data={props.data}
        // rbalance_redux={props.rbalance_redux} disableBtn={props.disableBtn} setDisableBtn={props.setDisableBtn}
        // primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} btnColor={props.btnColor} btnText={props.btnText} 
        b={props.b}
        show={modalShow}
        onHide={() => setModalShow(false)}
        />
    </>
  )
}
export default ViewCardMode