import React,{useState,useEffect} from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch ,useSelector} from 'react-redux';
import { ClearhandleCategories, handleCategory_name, handleCategory_show } from '../../Redux/SendCategories/action';
import axios from 'axios';

const ProductBlock = (props) => {
  const dispatch = useDispatch();

  const handleClick = (event,title) => {
      // navigate('/giftcards');
      dispatch(ClearhandleCategories())
      dispatch(handleCategory_name(encodeURIComponent(title)));
      dispatch(handleCategory_show(title));
    
  };


  const nameDomain = ( window.location.host.split('.')); 
  const [videoUrl,setVideoUrl] = useState("");
  const [primaryColor,setPrimaryColor] = useState("");
  const [secondaryColor,setSecondaryColor] = useState("")
  const [checkColor,setCheckColor] = useState(true);
  const [isVideo,setIsVideo] = useState(true);
  const [btnColor,setBtnColor] = useState('');
  const [btnText,setBtnText] = useState('');
  const [homeBannerColor,setHomeBannerColor] = useState();
  const [homeBannerColor1,setHomeBannerColor1] = useState();
  const [isLoading,setIsLoading] = useState(true);
  const [giftcards,setGiftcards] = useState("giftcards");
  // const navigate = useNavigate();
  // const categoryName = useSelector((state)=>state.sendCategorie_reducer);
  const [search,setSearch] = useState('');
  const backgroundStyle = {
    backgroundImage: `url(${videoUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: `${100}% ${100}%`,
    boxShadow: `inset 0 0 0 2000px rgba(0,0,0,0.5)`,
    // height: '',
  };
  const backgroundColor = {
    backgroundImage:`linear-gradient(93.59deg, ${homeBannerColor} -0.51%, ${homeBannerColor1} 105.82%)`
  }

  const [color, setColor] = useState();

  const cmsData = useSelector((state) => state.cmsReducer);
  useEffect(() => {
    if (cmsData?.data) {
      // Assuming you have received data from the API in the Redux store
      const data = cmsData?.data?.cms;

      if (data.default_ncentiva === false) {
        setGiftcards("giftcards");
        setVideoUrl(data.home_image);
        setCheckColor(data.home_image_color);
        setPrimaryColor(data.primary_color);
        setHomeBannerColor(data.homeBannerColorCombination);
        setHomeBannerColor1(data.homeBannerColorCombination1);
        setSecondaryColor(data.secondary_color);
        setBtnColor(data.btn_color);
        setBtnText(data.btn_text);
        setColor(data.btn_color);
      } else {
        setCheckColor(true);
        setBtnColor('#3D05B6');
        setBtnText('white');
        setHomeBannerColor("#FF7020");
        setHomeBannerColor1("#F5C400");
        setPrimaryColor("#FF7020");
        setSecondaryColor("#F5C400");
        setColor('green');
      }

      setIsLoading(false);
    }
  }, [cmsData]);

  
  return (
    <Link to="/giftcards" onClick={(event)=>handleClick(event,props.product)} className="product-block" >
            <div>

        <div key={props.id} style={{height:'8rem',width:'8rem',background:'white',border:`1.5px solid ${props.color}`,padding:"1rem"}}>
          <svg
            xmlns={props['xmlns']}
            xmlnsXlink={props['xmlns:xlink']}
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 612 523"
            style={{ enableBackground: props.style }}
            xmlSpace={props['xml:space']}
            // styleType="text/css"
          >
              {props?.path?.paths?.map((path, index) => (
              <path
                key={index}
                className={path.class}
                d={path.d}
                fill={props.color}
              />
            ))}
          </svg>
        </div>
      
    </div>
        <span >{props.name}</span>
    </Link>
  )
}

ProductBlock.propTypes = {
    title: PropTypes.string,
    alt: PropTypes.string,
    product: PropTypes.string
}

ProductBlock.defaultProps = {
    src: 'assets/images/products/product1.png',
    alt: 'Product image',
    product: 'Travel & Experiences'
};

export default ProductBlock
