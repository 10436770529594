import { SEARCH_NAME } from "./actionType"



const initState ={
    search : '',
}

export const SearchReducer = (state=initState,action)=>{
    switch(action.type){
        case (SEARCH_NAME) :{
            return{
                search : action.payload
            }
        }
         default :{
            return (state)
        }
    }
}