import {SET_CART_DATA_REQUEST,SET_CART_DATA_SUCCESS,SET_CART_DATA_FAILURE} from "./actionTypes"


const initialState = {
    data: null, 
    loading: false, 
    error: null, 
  };
  export const cartReducer = (state = initialState,action) =>{

   switch(action.type){
    case SET_CART_DATA_REQUEST:
        return{
            ...state,
            loading:true,
            error:null,
              
        };
  case SET_CART_DATA_SUCCESS:
    return{
        ...state,
        loading:false,
        data:action.payload
    };
    case SET_CART_DATA_FAILURE:
        return{
          ...state,
          loading:false,
          error:action.payload  
        };
        default:
        return state;
   }

  }