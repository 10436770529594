import React, { useState, useEffect } from 'react'
import '../App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import '../styles/global.css';
import '../styles/responsive.css';
import InnerBanner from '../components/InnerBanner'
import NavbarComponent from '../components/Navbar'
//import ProductCategories from '../components/ProductCategories'
//import ProductFilterArea from '../components/ProductFilterArea'
import ProductListing from '../components/ProductListing'
import axios from "axios";
import Select from 'react-select'
import { FaSearch } from 'react-icons/fa'
import ProductItem from './../components/elements/ProductItem';
import CheckoutBar from '../components/CheckoutBar';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomer } from '../Redux/ProductItem/action';
import { Bars } from 'react-loader-spinner';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';
import GiftProductItems from './GiftProductItems';
import Category from '../components/elements/Category';
import ModalOpen from '../components/elements/CategoryModalOpen';

const BusinessPartnerGift = () => {
  const nameDomain = ( window.location.host.split('.')); 
  const name2 = (nameDomain[0].split('-'));
  const [couponData, setCouponData] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [zero,setZero] = useState(false);
  const [loading,setLoading] = useState(true);
  const [disableBtn,setDisableBtn] = useState(false);
  const customer = JSON.parse(window.localStorage.getItem('customer'));
  const dispatch = useDispatch();
  const [btnColor,setBtnColor] = useState('');
  const [btnText,setBtnText] = useState('');
  const [paginate, setpaginate] = useState(21);
  const [loadMore,setLoadMore] = useState(false);
  const rbalance_redux = useSelector((state) => state.customer_balance_Reducer.data);
  const rbalance_redux1 = useSelector((state) => state.customer_balance_Reducer.logout);
  const navigate = useNavigate();
  const [length,setLength] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [moreLoading,setMoreLoading] = useState(true);
  const [primaryColor,setPrimaryColor]= useState('');
  const [secondaryColor,setSecondaryColor] = useState('');
  const [image,setImage] = useState("");
  const [videoUrl,setVideoUrl] = useState("");
  const [bannerColor,setBannerColor] = useState("");
  const [checkColor,setCheckColor] = useState();
  const [bannerColor1,setBannerColor1] = useState("");
  const [catergoryOption,setCategoryOption] = useState(true);
  const categoryName = useSelector((state)=>state.sendCategorie_reducer.category);
  const showCategory = useSelector(state => state.sendCategorie_reducer.showCategory);
  
  const backgroundStyle = {
    backgroundImage: `url(${videoUrl})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: `${100}% ${100}%`,
    boxShadow: `inset 0 0 0 2000px rgba(0,0,0,0.5)`,
    height:'150px',
  };
  const backgroundColor = {
    backgroundImage:`linear-gradient(93.59deg, ${bannerColor} -0.51%, ${bannerColor1} 105.82%)`,
    height:'150px',
  };
  var partner_id;
  if (customer) {
    var cust_id = customer.customer._id;
    partner_id = customer.customer.business_partner_id
    // name = customer.customer.first_name +" "+ customer.customer.last_name;
  }
  const user = JSON.parse(window.localStorage.getItem("client"));
  var token;
  const options = [
    // {label: "view: all", value: "view: all" },
    { value: "All Brands", label: "All Brands" },
    { value: "Most value", label: "Most value" },
    { value: "Order A-Z", label: "Order A-Z" },
    { value: "Order Z-A", label: "Order Z-A" }
  ]
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'var(--darkblue)' : 'var(--color)',
      fontSize: 14,
      padding: 10,
      marginBottom: 0,
      backgroundColor: state.isSelected ? 'rgba(215, 223, 233, 0.34);' : '#fff'
    }),

    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 'auto',
      display: 'flex',
      border: '1px solid #ced4da',
      borderRadius: '.25rem'
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const color = 'var(--color)';
      const fontSize = '14px';
      const transition = 'opacity 300ms';


      return { ...provided, opacity, transition, color, fontSize };
    }
    }
  
    
      
    const handleeChange = (selectedOption) =>{
      if(selectedOption.label === "All Brands"){
        
        setSearch('')
        
      }
      setSort(selectedOption.label);
    }
       
        const [getbrand, setGetBrand] = useState([]);
        const getbrands = () => {
          axios
              .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getallbrands`)
              .then((response) => {
              
               setGetBrand(response.data.brands);
              })
              .catch((error) => console.error(`Error: ${error}`));
        };

  useEffect(() => {
    document.title = "ncentiva || Gift cards"
    setLoading(true);
    const getData = setTimeout(() => {
      getCouponsData(pageNumber);
     getbrands();
     setLoading(false);
    }, 500)
    return () => clearTimeout(getData)
  }, [search,sort,pageNumber,catergoryOption])
  const handleChange = (selectedOption) =>{
    if(selectedOption.label === "All Brands"){
      setSearch('')
    } else {
      setSearch(selectedOption.label);
    }

  }

  const getCouponsData = (pageNumber) => {
    if (customer) {
      token = customer.token;
    } else {
      token = null;
    }
    setLoading(true);
     axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/allcouponsforcards?page=${pageNumber}&search=${search}&sort=${sort}&partner_url=${name2[0]}&category=${categoryName}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
         .then((response) => {
             const allcoupons = response.data.coupons;
             setLength(allcoupons.length);
             setNumberOfPages(response.data.totalPages);
             setCouponData(allcoupons);
             if(allcoupons.length===0){
              setZero(true);
              setCouponData(allcoupons);
             }else{
              setZero(false);
               setCouponData(allcoupons);
             }
             setLoading(false);
             setTimeout(()=>{
               setMoreLoading(false);
             },1000)
         })
         .catch(error => console.error(`Error: ${error}`));
     }
  useEffect(()=>{
    if(cust_id){
      dispatch(getCustomer(cust_id))
    }
  }, [cust_id]);

  const [categories,setCategories] = useState([]);
  useEffect(()=>{
      axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/get-categories`)
      .then((res)=>{
        if(showCategory?.length>0){
          const mergedArray = Array.from(new Set([...showCategory, ...res.data.brands[0].categories]));
          setCategories(mergedArray);
        }
        else{
          setCategories(res.data.brands[0].categories);
        }
      }).catch((err)=>{
        console.log(err);
      })
  },[]);
    
    useEffect(()=>{
      if(showCategory?.length>0){
        const mergedArray = Array.from(new Set([...showCategory, ...categories]));
        setCategories(mergedArray);
      }
    },[catergoryOption])


let items = [];
let leftSide = pageNumber - 1;

if (leftSide <= 0) leftSide = 0;
let rightSide = pageNumber + 2;

if (rightSide > numberOfPages) rightSide = numberOfPages;
for (let number = leftSide; number < rightSide; number++) {

  items.push(
    <li key={number} className={(number === pageNumber ? "active" : "")} onClick={() => { setPageNumber(number) }}>
      {number + 1}
    </li>,
  );
}


const nextPage = () => {
  if (pageNumber < numberOfPages) {
    setPageNumber(pageNumber + 1)
  }
}


const prevPage = () => {
  if (pageNumber > 0) {
    setPageNumber(pageNumber - 1)
  }
}
useEffect(()=>{
  if(nameDomain[0] !== "dev" || "staging" || "payouts" || "uat"){
     setLoading(true);
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getcms/${name2[1]}`)
    .then((res)=>{
      if(res.data?.cms?.default_ncentiva === false){ 
      setBtnColor(res.data?.cms?.btn_color);
      setBtnText(res.data?.cms?.btn_text);
      setPrimaryColor(res.data?.cms?.primary_color);
          setSecondaryColor(res.data?.cms?.secondary_color);
          setImage(res.data?.cms?.brand_logo);
          setVideoUrl(res.data?.cms?.banner_image);
          setCheckColor(res.data?.cms?.banner_image_color);
          setBannerColor(res.data?.cms?.bannerColorCombination);
          setBannerColor1(res.data?.cms?.bannerColorCombination1);
    }else{
      setCheckColor(true);
        setBtnColor('#3D05B6');
        setImage("https://staging.ncentiva.com/assets/images/logo.png")
        setBtnText('white');
        setPrimaryColor("#FF7020");
        setSecondaryColor("#F5C400");
        setBannerColor("#FF7020");
        setBannerColor1("#F5C400");
      }
      setLoading(false);
    }).catch((err)=>{
      console.log(err);
    })
  }else{
  }
},[])

  return (
    <>
      {/* <NavbarComponent /> */}
      <div className='d-flex justify-content-center' >
      {/* <Link to="/" className="header-logo"> */}
        {image?<img className='mt-3 mb-3' src={image} style={{width:"200px",height:'50px'}} alt="" />:''}
        {/* </Link> */}
      </div>

      <section style={checkColor ? backgroundColor : backgroundStyle} className="hero-search inner-page position-relative">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-8">
                <h2 className="mb-0">Gift Cards</h2>
            </div>
            <p className='mt-1 text-center'>You can redeem your funds for hundreds of different gift cards, and recieve an additional bonus of an average of 10% more, Take a look at the            
            </p>
            <p className='text-center'>gift cards we have an offer</p>
        </div>
    </div>
</section>
      {/* {loading?"": */}
      <section className="product-filters py-3 border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7">
              <div className="filters-left mb-4 mb-lg-0">
                <div className="search-content">
                  <div className="search-bar">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search for a brand, product or service"
                      onChange={({ currentTarget: input }) => {
                        setSearch(input.value.trim());
                        setPageNumber(0);}}
                    />
                    {/* <i className="far fa-search search-icon" /> */}
                    <FaSearch className="search-icon" />
                  </div>
                  {btnColor? <button type="button" style={{backgroundColor:btnColor,color:btnText,border:`1px solid ${btnColor}`}} className="btn btn-main px-4">
                    Search
                  </button>:''}
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5">
              <div className="filter-right">
                <div className="row">
                  <div className="col-md-6">
                    <div className="custom-select mb-2 mb-md-0" style={{zIndex:'999'}} >
                      <Select
                        styles={customStyles}
                        
                        onChange={handleeChange}
                        options={options}
                        defaultValue={{ value: "View: All", label: "View: All" }}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="custom-select"  style={{zIndex:'998'}}>
                      <Select
                        styles={customStyles}
                        onChange={handleChange}
                        options={getbrand}
                        defaultValue={{ value: "Filter by: All", label: "Filter by: All" }}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* } */}
      <section className="product-listing mt-2">
        <div className="container">
          <div className="row">
            <div className="container">
              <div className="row">
                <section className="product-listing">
                  <div className="container">
                    <div className="row">

                    <div className="col-lg-12">
                        
                        {zero?  <>
                        <div className="categories-searchh">
                  
                   <div className="categories-area">
                       {categories.slice(0, 5).map((hcategory) => 
                            <Category key={hcategory.toString()} home={"false"} btnColor={btnColor} btnText={btnText} setCategoryOption={setCategoryOption} catergoryOption={catergoryOption} title={hcategory}/>
                            )}
                    </div>
                    <ModalOpen title={"View More+"} num={0} data={categories} home={"false"} btnColor={btnColor} btnText={btnText} setCategoryOption={setCategoryOption} catergoryOption={catergoryOption} colorCheck={'black'}/>
            </div>
                        <h2 className="allbrands-head mb-4">No Gift Card Found</h2>
                        </>: <div className="categories-searchh">
                    <>
                    <div className="categories-area">
                       {categories.slice(0, 5).map((hcategory) => 
                            <Category key={hcategory.toString()} home={"false"} btnColor={btnColor} btnText={btnText} setCategoryOption={setCategoryOption} catergoryOption={catergoryOption} title={hcategory}/>
                          
                            )}
                    </div>
                   </>

            {/* <ModalOpen title={"View More+"} /> */}
            <ModalOpen title={"View More+"} num={0} data={categories} home={"false"} btnColor={btnColor} btnText={btnText} setCategoryOption={setCategoryOption} catergoryOption={catergoryOption} colorCheck={'black'}/>
            </div>}
                      </div>

                      {loading ? <>
                        <Bars
            height="80"
            width="100%"
            margin='auto'
            color="#FF7020"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            />
                      </>
                      :
                      <>
                      { couponData?.map((productitem, i) =>
                      <>
                        <GiftProductItems key={productitem._id} data={productitem} btnColor={btnColor} btnText={btnText} rbalance_redux={rbalance_redux.balance} disableBtn={disableBtn} setDisableBtn={setDisableBtn}/> 
                        </>
                      )}
                      
                      {zero ? "" :
                    <div className='text-center'>
                   {moreLoading ? "" :<Col className="mt-5 mb-4">

                   {couponData.length >= 24 || pageNumber === 1? 
                    <ul className="custPagination d-flex justify-content-center list-unstyled font14">

                      {pageNumber === 0 ? (
                        ""
                      ) : (

                        <li onClick={prevPage}>Previous </li>

                      )}


                      {items}

                      {numberOfPages - 1 === pageNumber ? (
                        ""
                      ) : (
                        <li onClick={nextPage}>Next</li>
                      )}
                    </ul>
                    :
                      ""}
          </Col>}
                 
                   </div>}
                      </>
                      
                      }
                    </div>
                  </div>
                  
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BusinessPartnerGift