import { useEffect,useState } from 'react';
import { Container } from "react-bootstrap";
import {Outlet, useNavigate} from "react-router-dom"
import DashTopBar from "../../parts/DashTopBar";
import BusinessLeftSidebar from '../../parts/BusinessLeftSidebar';
const BusinessDasboardLayout = () =>{
    useEffect(()=>{
        document.title = "ncentiva || Business Partner Dashboard"
    },[])

    const navigate = useNavigate();
    var token;
    useEffect(() => {
        redirectToLogin();
    });
    const redirectToLogin = () => {
        const user = JSON.parse( window.localStorage.getItem('business-client'));
        if(user){
             token = user.token;
        } else{
             token = null;
        }
        
        if(token === null){
            navigate('/business-login')
        }
    };

    const [toggleTwo,setToggleTwo] = useState(false)
    const [showbutton,setShowButton] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(()=>{
    setShowButton(windowWidth <= 1200);
    if (showbutton) {
      setToggleTwo(false)
    }
    
  },[windowWidth])

    return (
        <>
        <BusinessLeftSidebar toggleTwo={toggleTwo} setToggleTwo={setToggleTwo} showbutton={showbutton}/>
        <div className={`mainContent ${toggleTwo ? 'newContent' : ""}`}>
            <Container
                fluid
                className="p-0"
                style={{
                    backgroundColor: "var(--newLightBlue)",
                    minHeight: "100vh",
                }}
            >
                <DashTopBar />
                <Outlet />
            </Container>
        </div>
        </>
    )
}

export default BusinessDasboardLayout;