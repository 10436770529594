import { applyMiddleware,combineReducers, compose, legacy_createStore } from "redux";
import thunk from "redux-thunk";
import { customer_balance_Reducer } from './ProductItem/reducer';
import { sendCategorie_reducer } from './SendCategories/reducer';
import { SearchReducer } from "./GlobalSearch/reducer";
import {cmsReducer}  from "./GetCms/reducer";
import { businessContactReducer } from "./BusinessContacts/reducer";
import { cartReducer } from "./CartType/reducer";
import { getcustomer } from "./GetCustomer/reducer";

const Reducer = combineReducers({
     customer_balance_Reducer : customer_balance_Reducer,
     sendCategorie_reducer : sendCategorie_reducer,
     SearchReducer : SearchReducer,
     cmsReducer:cmsReducer,
     businessContactReducer: businessContactReducer,
     cartReducer:cartReducer,
     getCustomerData:getcustomer,
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export const store = legacy_createStore(Reducer,composeEnhancers(applyMiddleware(thunk)));
