import { GET_CUSTOMER_BALANCE_FAILURE, GET_CUSTOMER_BALANCE_REQUEST, GET_CUSTOMER_BALANCE_SUCCESS, GET_CUSTOMER_LOGOUT } from "./actionType"


const initState={
    data:[],
    isLoading:false,
    isError:false,
    err:'',
    logout:false
}

export const customer_balance_Reducer = (state=initState,action)=>{
    switch (action.type){
        case (GET_CUSTOMER_BALANCE_REQUEST):{
            return {
                isLoading:true,
                isError:false,
                logout:false
            }
        }
        case (GET_CUSTOMER_BALANCE_SUCCESS):{
            return {
                data : action.payload,
                isLoading:false,
                isError:false,
                logout:false
            }
        }
        case (GET_CUSTOMER_BALANCE_FAILURE):{
           return {
             isLoading:false,
             isError:true,
             err:action.payload,
             logout:false
           }
        }
        case (GET_CUSTOMER_LOGOUT) : {
            return {
                logout:true
            }
        }
        default:{
            return (state)
        }
    }
}