import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  Modal,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Bars } from "react-loader-spinner";
import { getNewToken } from "../config/cookie.config";

const UserManagementTrash = () => {
  const user = JSON.parse(window.localStorage.getItem("client"));
  let userrole;
  let usersid;
  let navigate = useNavigate();
  if (user) {
    userrole = user.user.role;
    usersid = user.user._id;
  } else {
    userrole = null;
    usersid = null;
    navigate("/admin");
  }

  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("ASC");
  const [emailorder, setEmailorder] = useState("ASC");
  const [userorder, setUserorder] = useState("ASC");
  const [updateorder, setUpdateorder] = useState("ASC");
  const [role] = useState(userrole);
  const [customerData, setCustomerData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const shouldLog = useRef(true);
  const [sort, setSort] = useState("");
  const [noData, setNoData] = useState(false);
  const [showPage, setShowPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  let token;
  const pages = new Array(numberOfPages).fill(null).map((v, i) => i);

  if (user) {
    token = user.token;
  } else {
    token = null;
  }

  useEffect(() => {
    setIsLoading(true);
    const getData = setTimeout(() => {
      getAllUsers(pageNumber);
      redirectToLogin();
      setIsLoading(false);
    }, 600);
    return () => clearTimeout(getData);
  }, [pageNumber, search, sort]);

  const getAllUsers = (pageNumber) => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/user_trash?page=${pageNumber}&search=${search}&sort=${sort}`,
        {
          headers: {
            Authorization: `Bearer ${getNewToken()}`,
          },
        }
      )
      .then((response) => {
        if (response.data.message === "Please Login") {
          localStorage.removeItem("client");
          localStorage.removeItem("user");
          navigate("/admin");
          toast.warning("Session Expired Please Login Again");
        }
        const allUsers = response.data.users;
        if (allUsers.length < 1) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        const totalPages = response.data.totalPages;
        setCustomerData(allUsers);
        setNumberOfPages(totalPages);
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  let items = [];
  let leftSide = pageNumber - 1;

  if (leftSide <= 0) leftSide = 0;
  let rightSide = pageNumber + 2;

  if (rightSide > numberOfPages) rightSide = numberOfPages;
  for (let number = leftSide; number < rightSide; number++) {
    items.push(
      <li
        key={number}
        className={number === pageNumber ? "active" : ""}
        onClick={() => {
          setPageNumber(number);
          setShowPage(number + 1);
        }}
      >
        {number + 1}
      </li>
    );
  }

  const pagenumberSearch = (e) => {
    var pages = parseInt(e);
    var page = pages - 1;
    if (pages < 1) {
      setPageNumber(0);
      setShowPage(1);
    } else if (pages > numberOfPages) {
      setPageNumber(numberOfPages - 1);
      setShowPage(numberOfPages);
    } else if (pages >= 1 && pages <= numberOfPages) {
      setPageNumber(page);
      setShowPage(pages);
    } else {
      setPageNumber(0);
      setShowPage(pages);
    }
  };

  const nextPage = () => {
    if (pageNumber < numberOfPages) {
      setPageNumber(pageNumber + 1);
      setShowPage(pageNumber + 2);
    }
  };

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
      setShowPage(pageNumber);
    }
  };




  const [itemId, setItemId] =useState('');
  // name

  const sorting = (order) => {
    if (order === "DSC") {
      setSort("dscName");

      setOrder("DSC");
    } else {
      setSort("ascName");

      setOrder("ASC");
    }
  };

  //   email   emailorder

  const emailsorting = (emailorder) => {
    if (emailorder === "DSC") {
      setSort("dscEmail");

      setEmailorder("DSC");
    } else {
      setSort("ascEmail");

      setEmailorder("ASC");
    }
  };

  //   type of user

  const typeusersorting = (userorder) => {
    if (userorder === "DSC") {
      setSort("dscUser");

      setUserorder("DSC");
    } else {
      setSort("ascUser");

      setUserorder("ASC");
    }
  };

  const updatesorting = (updateorder) => {
    if (updateorder === "DSC") {
      setSort("dscUpdate");

      setUpdateorder("DSC");
    } else {
      setSort("ascUpdate");

      setUpdateorder("ASC");
    }
  };


  //  search
  const handleSearch = (value) => {
    setSearch(value);
    setPageNumber(0);
    setShowPage(1);
  };
  // search

  const redirectToLogin = () => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      navigate("/admin");
    }
  };

  const allUserDelete = async () => { 
    handleClose();


    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }

    if (token === null) {
      navigate("/admin");
    }

      try {
          axios.delete(
            `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/deleteuser/${itemId}`,
            {
              headers: {
                Authorization: `Bearer ${getNewToken()}`,
              },
            }
          )
        toast.success("User Deleted Successfully");
        localStorage.setItem("user_Delete", " ");
        navigate("/admin/users-management");
      } catch (error) {
        toast.error("Failed to  Delete User");
      }
    
  };
  const userID = localStorage.getItem("client");
  const users = JSON.parse(userID);
  const userIds = users.user._id;

  const userStatus = (userIds) => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getuser/${userIds}`
      )
      .then((response) => {
        let users = response.data?.user?.status;
        if (users === false) {
          window.localStorage.removeItem("client");
          navigate("/admin");
          toast.warning("Your account has been deactivated");
        }
      });
  };

  useEffect(() => {
    userStatus(userIds);
  }, [userIds]);

  const softDelete = async (e) => {
    const { name, checked, value } = e.target;
    if (name === "allSelect") {
      let tempUser = customerData.map((user) => {
        return { ...user, isChecked: checked };
      });
      setCustomerData(tempUser);
      localStorage.setItem("user_Delete", "All");

    } else {
      localStorage.setItem("user_Delete", "id");
      let tempUser = customerData.map((user) =>
        user._id === name ? { ...user, isChecked: checked } : user
      );
      setCustomerData(tempUser);
      const user = tempUser.filter((e) => e.isChecked);
      localStorage.setItem("user_trashed", JSON.stringify(user));
    }
    if(checked === false) {
      await localStorage.removeItem("user_Delete");
    }
  };

  const retriveTrash = async (event) => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    const result = localStorage.getItem("user_Delete");
    if (result === "All") {
      // Move all customers to trash
      const isDeleted = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/user/soft-delete`,
        {
          soft_delete: false,
          type: "All",
        },
        {
          headers: {
            Authorization: `Bearer ${getNewToken()}`,
          },
        }
      );
      if (isDeleted.data) {
        toast.success("All Users Retrive Successfully");
        localStorage.setItem("user_Delete", " ");
      } else {
        toast.error("Failed to Retive Users");
      }
      navigate("/admin/users-management");
    } else if (result === "id") {
      // Move selected customers to trash
      let store = JSON.parse(localStorage.getItem("user_trashed"));
      try {
        await store.map((e) =>
          axios.put(
            `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/user/soft-delete`,
            {
              soft_delete: false,
              type: "id",
              id: e._id,
            },
            {
              headers: {
                Authorization: `Bearer ${getNewToken()}`,
              },
            }
          )
        );
        toast("Selected Partner  Retrieved");
        navigate("/admin/users-management");
        localStorage.setItem("user_Delete", " ");
      } catch (error) {
        toast.error("Failed to Retrieve User");
      }
    } else {
      toast.warning("Please Select Any Input");
    }
  };

  const retriveTrashDirect = async (userId) => {
    const user = JSON.parse(window.localStorage.getItem("client"));
    if (user) {
      token = user.token;
    } else {
      token = null;
    }
    try {
      const response = axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/user/soft-delete`,
        {
          soft_delete: false,
          type: "id",
          id: userId,
          status:true
        },
        {
          headers: {
            Authorization: `Bearer ${getNewToken()}`,
          },
        }
      ).then((response)=> {
        toast.success("User Retrieved Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
    } catch (error) {
      console.log(error);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => {
      setShow(false);
    } 
    
  const handleShow = (id) => {
    setItemId(id);
    setShow(true);
  }

  return (
    <div className="p-3 p-lg-4 p-xl-5">
      <div className="d-flex mb-4 align-items-center">
        <Link
          to="/admin/users-management"
          className="p-0 btn btnOrangeTransparent me-3 fw-semibold"
          variant="white"
        >
          <span className="me-2 fw-semibold">
            <img
              src="/assets/images/back-org.png"
              className="img-fluid"
              alt=""
            />
          </span>
          <span>Back</span>
        </Link>
        <h1 className="font20 text-dark">User Trash</h1>
      </div>
      <Container fluid className="bg-white p-3 rounded shadow">
        <Row className="align-items-center">
          <Container>
            <Row>
              <Col sm={6} className="mt-1">
                <div className="textGray font12">
                  <div style={{ maxWidth: " " }}>
                    <div className="d-flex align-items-center searchWrapper w-100 ms-0">
                      <Form.Control
                        type="text"
                        className="border-0 searchInput"
                        placeholder="Search"
                        onChange={({ currentTarget: input }) =>
                          handleSearch(input.value)
                        }
                      />
                      <Button
                        variant="primary"
                        title="Click here to search"
                        className="searchBtn d-flex justify-content-center align-items-center"
                      >
                        <img
                          src="/assets/images/search.png"
                          className="img-fluid"
                          alt=""
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={6} className="mt-1">
                <div className="d-flex justify-content-center justify-content-sm-end mt-3 mt-sm-0">
                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size="md"
                    centered
                  >

                    <Modal.Body>
                      <Col>
                        <img className="alert-img"
                          src="/assets/images/iccon.png"
                          alt=""
                        />
                        <br />
                        <br />
                        <h3 className="modal-text">Are you sure you want to delete the User Permanently?</h3>
                        <br />
                        <Row className="modal-row">
                          <Col md={1}></Col>
                          <Col md={4}>
                            <Button className="btn btn-success success-btn" onClick={allUserDelete}>

                              <span><b>Continue</b></span>
                            </Button>
                          </Col>
                          <Col className="middle-col" md={2}></Col>
                          <Col md={4}>
                            <Button className="btn btn-danger danger-btn" onClick={handleClose} >
                              <span><b>Cancel</b></span>
                            </Button>
                            
                          </Col>
                          <Col md={1}></Col>

                        </Row>
                        <br />
                      </Col>
                    </Modal.Body>

                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
          <Col md={12} className="mt-4">
            <div className="table-responsive">
              <table
                className="table fontGilroy"
                style={{ verticalAlign: "middle" }}
              >
                <thead className="bgLightRed font14">
                  <tr>

                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Name</span>
                        {order === "ASC" ? (
                          <FaSortDown onClick={() => sorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => sorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Email</span>
                        {emailorder === "ASC" ? (
                          <FaSortDown onClick={() => emailsorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => emailsorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Type of User</span>
                        {userorder === "ASC" ? (
                          <FaSortDown onClick={() => typeusersorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => typeusersorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th className="text-center">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Phone</span>
                      </div>
                    </th>

                    <th className="text-end">
                      <div className="d-flex align-items-center">
                        <span className="me-1">Update At</span>
                        {updateorder === "ASC" ? (
                          <FaSortDown onClick={() => updatesorting("DSC")} />
                        ) : (
                          <FaSortUp onClick={() => updatesorting("ASC")} />
                        )}
                      </div>
                    </th>

                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="font12 fw-medium">
                  {isLoading === true ? (
                    <tr style={{ backgroundColor: "#eee" }}>
                      <td colSpan="8">
                        <Bars
                          height="80"
                          width="100%"
                          margin="auto"
                          color="#FF7020"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {noData ? (
                        <tr>
                          <td colSpan="8" className="text-center">
                            <h1 className="font16 text-dark">No Data</h1>
                          </td>
                        </tr>
                      ) : (
                        customerData.map((cust, index) => (
                          <tr key={cust._id}>

                            <td className="font14">
                              {/* <Link
                                className="link"
                                to={`/admin/users-management/${cust._id}/edit`}
                              > */}
                                {cust.name}
                              {/* </Link> */}
                            </td>
                            <td className="font14">
                              {/* <Link
                                className="link"
                                to={`/admin/users-management/${cust._id}/edit`}
                              > */}
                                {cust.email} {cust.id}
                              {/* </Link>{" "} */}
                            </td>
                            <td className="font14">{cust.role}</td>
                            <td className="font14">
                              {cust.code} {cust.phone}
                            </td>

                            <td className="font14">
                              {cust.updatedAt
                                .slice(0, 16)
                                .replace("T", "      ")}
                            </td>

                            <td className="d-flex align-items-center justify-content-center">
                              <Button
                                // variant="success"

                                onClick={() => retriveTrashDirect(cust._id)}
                                className="fullRounded p-1 fw-bold font12"
                                style={{
                                  width: "31px",
                                  display: "flex",
                                  justifyContent: "center",
                                  backgroundColor: "white",
                                  border: "none",
                                }}
                              >
                                <span style={{ cursor: "pointer" }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    style={{ color: "green" }}
                                    className="bi bi-arrow-repeat"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                    <path
                                      fillRule="evenodd"
                                      d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                                    />
                                  </svg>
                                </span>
                              </Button>

                              <Button
                  variant="none"
                  // onClick={() => handleDelete(cust._id)}  
                  onClick={()=> handleShow(cust._id)}
                  className="fullRounded px-4 py-2 fw-bold font12"
                  style={{
                    width: "50px",
                    display: "flex",
                    justifyContent: "center",
                    background: "#FF7020",
                    color: "white",
                  }}
                >
                  <span style={{ cursor: "pointer" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-trash-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </span>
                </Button>
                            </td>
                          </tr>
                        ))
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
          {numberOfPages === 0 ? (
            ""
          ) : (
            <Col className="mt-5 mb-4">
              {
                <ul className="custPagination d-flex justify-content-center list-unstyled font14">
                  {pageNumber === 0 ? (
                    ""
                  ) : (
                    <li onClick={prevPage}>Previous </li>
                  )}

                  {items}

                  {numberOfPages - 1 === pageNumber ? (
                    ""
                  ) : (
                    <li onClick={nextPage}>Next</li>
                  )}
                  <li className="font14">
                    <input
                     className="input-sector"
                      style={{
                        width: "25px",
                        border: "1px solid #4C00EF",
                        borderRadius: "4px",
                        textAlign: "center",
                      }}
                      value={showPage ? showPage : ""}
                      type="number"
                      name="number"
                      id=""
                      onChange={(e) => {
                        pagenumberSearch(e.target.value);
                      }}
                    ></input>{" "}
                    <span style={{ color: "#4C00EF" }}>/</span> {numberOfPages}
                  </li>
                </ul>
              }
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default UserManagementTrash;
