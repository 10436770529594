// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-13cymwt-control{
    border: none !important;
}

#business-partner option:nth-child(2) {
    display: none;
}

.red-row {
    background-image: linear-gradient(93.59deg, rgb(255, 112, 32) -0.51%, rgb(245, 196, 0) 105.82%) ;
    /* background-color: #ffd54a; */
    color: white; /* You might want to adjust the text color for better visibility */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gGAAgG;IAChG,+BAA+B;IAC/B,YAAY,EAAE,kEAAkE;AACpF","sourcesContent":[".css-13cymwt-control{\n    border: none !important;\n}\n\n#business-partner option:nth-child(2) {\n    display: none;\n}\n\n.red-row {\n    background-image: linear-gradient(93.59deg, rgb(255, 112, 32) -0.51%, rgb(245, 196, 0) 105.82%) ;\n    /* background-color: #ffd54a; */\n    color: white; /* You might want to adjust the text color for better visibility */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
