// import React,  { useState , useEffect}  from 'react'
// import InnerBanner from '../components/InnerBanner'
// import NavbarComponent from '../components/Navbar'
// import ProductListing from '../components/ProductListing'
// import { Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// const Products = () => {
//   const [couponData, setCouponData] = useState([]);
//   useEffect(()=>{
//     document.title = "ncentiva || Products";
//      getCouponsData();
//   },[couponData])
//   const getCouponsData = () => {
//    // setIsLoading(true);
//     axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/allcoupons`)
//         .then((response) => {
//             const allcoupons = response.data.coupons;
//       //setIsLoading(false);

//             setCouponData(allcoupons);
//         })
//         .catch(error => console.error(`Error: ${error}`));
//     }
//   return (
//     <>
//     <NavbarComponent/>
//     <InnerBanner bannerTitle="Gift cards"/>
//     <ProductListing  data={couponData}/>
//     </>
//   )
// // }

// export default Products

